import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { ProcessGrapqlError,apollo_client } from "../helpers";

export const useComment = ({ encounterId = 1, attributeId = "" }) => {
  const ADD_COMMENT = gql`
  mutation Mutation($comment: String!, $encounterId: Int!, $attributeId: String!, $label: String!, $section: String!) {
    AddComment(comment: $comment, encounter_id: $encounterId, attribute_id: $attributeId, label: $label, section: $section) {
      comment_id
      encounter_id
      user_id
      label
      section
      username
      attribute_id
      comment
      created_on
      updated_on
      is_active
    }
  }
  `;

  const GET_COMMENTS = gql`
  query GetComments($encounterId: Int!, $attributeId: String!) {
    GetComments(encounter_id: $encounterId, attribute_id: $attributeId) {
      comment_id
      encounter_id
      user_id
      label
      section
      username
      attribute_id
      comment
      created_on
      updated_on
      is_active
    }
  }
  `;

  const GET_COMMENTS_COUNT = gql`
  query GetAllCommentsCount($encounterId: Int!) {
    GetAllCommentsCount(encounter_id: $encounterId) {
      view
      counts
    }
  }
`;

  const DELETE_COMMENT = gql`
    mutation Mutation($cityId: Int!) {
      DeleteCity(city_id: $cityId) {
        city_id
      }
    }
  `;

  const UPDATE_COMMENT = gql`
    mutation UpdateComment($encounterId: Int!) {
      UpdateComment(encounter_id: $encounterId) {
        comment_id
        encounter_id
        user_id
        username
        attribute_id
        comment
        created_on
        updated_on
        is_active
      }
    }
  `;

  const SUBMIT_COMMENT = gql`
  mutation Mutation($encounterId: Int!, $status: Int!) {
    SubmitComment(encounter_id: $encounterId, status: $status) {
      encounter_report_status
      not_submitted_count
      patient_registry_no
      visit_type
      encounter_count
    }
  }
  `;

  const useAddComment = useMutation(ADD_COMMENT, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { AddComment } }) {
      const existing = cache.readQuery({
        query: GET_COMMENTS,
        variables: { encounterId: parseInt(AddComment.encounter_id), attributeId: AddComment.attribute_id },
      });
      if (existing) {
      const newData = [...existing.GetComments, AddComment];
      cache.writeQuery({
        query: GET_COMMENTS,
        variables: {encounterId: parseInt(AddComment.encounter_id), attributeId: AddComment.attribute_id },
        data: { GetComments: newData },
      });
    }
    else {
      apollo_client
        .query({
          query: GET_COMMENTS,
          variables: { encounterId: parseInt(AddComment.encounter_id), attributeId: AddComment.attribute_id},
        })
        .then(({ data }) => {
          const newData = [ ...data.GetComments.filter((item) =>
          item.comment_id !== AddComment.comment_id
        ),AddComment];

            cache.writeQuery({
              query: GET_COMMENTS,
              variables: { encounterId: parseInt(AddComment.encounter_id), attributeId: AddComment.attribute_id },
              data: { GetComments: newData },
            });
        })
        .catch((error) => {
          console.error("Error while refetching data:", error);
        });
    }
    
    },
  });
  const useUpdateComment = useMutation(UPDATE_COMMENT, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { UpdateComment } }) {
      const existing = cache.readQuery({ query: GET_COMMENTS });
      const newData = existing.GetComments.map((item) =>
        item.comment_id !== UpdateComment.comment_id ? item : UpdateComment
      );
      cache.writeQuery({
        query: GET_COMMENTS,
        data: { GetComments: newData },
      });
    },
  });
  const useSubmitComment = useMutation(SUBMIT_COMMENT, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache) {},
  });

  const useGetCommentCount = useLazyQuery(GET_COMMENTS_COUNT);

  const useDeleteComment = useMutation(DELETE_COMMENT, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { DeleteComment } }) {
      const existing = cache.readQuery({ query: GET_COMMENTS });
      const newComments = existing.GetComments.filter(
        (item) => item.comment_id !== DeleteComment.comment_id
      );
      cache.writeQuery({
        query: GET_COMMENTS,
        data: { GetComments: newComments },
      });
    },
  });

  const useGetComments = useLazyQuery(GET_COMMENTS);

  return {
    useAddComment,
    useGetComments,
    useUpdateComment,
    useDeleteComment,
    useSubmitComment,
    useGetCommentCount
  };
};
