import { useParams } from "react-router-dom";
import { Formik } from "formik";
import {
  generateDynamicComponent,
  getSectionForm,
  postProcessData,
  preProcessData,
  useEncounter,
  useComment,
  useMedicationRequest,
  useUtil,
} from "../hooks";
import { useFormikContext } from "formik";

import {
  Alert,
  Button,
  Divider,
  Grid,
  Stack,
  Paper,
  LinearProgress,
} from "@mui/material";
import {
  AlertDialog,
  MgpButton,
  MgpModal,
  MgpText,
  Padding,
} from "../components/global";

import { useEffect, useState, useRef } from "react";
import MgpProgressBar from "../components/global/MgpProgressBar";
import { Box } from "@mui/system";
import * as React from "react";
import * as yup from "yup";
import { colors } from "../theme/colors";
import MedicationRequest from "./MedicationRequest";
import { useNavigate } from "react-router-dom";
import MgpComment from "../components/global/MgpComment";
import { useRbac, usePatient } from "../hooks";
import { PatientReject } from "./PatientApproval/PatientReject";
import Loader from "./Loader/Loader";
import MgpAlertBar from "../components/global/MgpAlertBar";
import LivingStatus from "./LivingStatus";
import { ENV, StorageHelper } from "../helpers";
import { NoteAlertDialog } from "../components/global/NoteAlertDialog";
import { useIdleTimer } from "react-idle-timer";

export const Patient = ({
  viewMode,
  newVisit,
  reopen = false,
  editable = true,
}) => {
  const formikRef = React.useRef(null);
  const { RbacWrapper, getActionRbac } = useRbac();
  const [approve, setApprove] = useState(false);
  const [serotype, setserotype] = useState(false);
  const [savedraft, setSaveDraft] = useState(0);
  const [livingStatusOpen, setlivingStatusOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitFeedback, setSubmitFeedback] = useState(false);
  const [submitReopen, setSubmitReopen] = useState(false);
  const [reject, setOpenReject] = useState(false);
  const params = useParams();

  const { useAddPatient, useUpdatePatient } = usePatient();
  const [AddPatient] = useAddPatient;
  const [UpdatePatient] = useUpdatePatient;
  const [reload, setReload] = useState({});
  const [alertOption, setAlertOption] = useState(false);
  const { useAddEncounter, useGetEncounter, useUpdateEncounter } = useEncounter(
    {
      patientId: 1,
    }
  );
  const { useSubmitComment } = useComment({
    encounterId: 1,
    attributeId: "",
  });
  const { useAddMedicationRequests } = useMedicationRequest();
  const [AddMedicationRequests] = useAddMedicationRequests;

  const [AddEncounter] = useAddEncounter;
  const [GetEncounter] = useGetEncounter;
  const [SubmitComment, { data, loading, error }] = useSubmitComment;

  const [Update_Encounter] = useUpdateEncounter;
  const { useUpdatePatientLivingStatus } = usePatient();
  const [UpdatePatientLivingStatus] = useUpdatePatientLivingStatus;
  const navigate = useNavigate();
  const [forms, setForms] = useState([]);
  const [message, setMessage] = useState("");
  const [submitErrors, setSubmitErrors] = useState({});
  const [submitTouched, setSubmitTouched] = useState({});

  const {
    patient_form,
    getValidationSchema,
    setSelectedSection,
    patient_selected_section,
    getInitialValue,
    encountedData,
    encountedDataLatestStatus,
    setEncountedDataLatestStatus,
    commentData,
    setCommentData,
    encounterData,
    pageLoad,
    encountedDatamain,
    patientActive,
    patientData,
    setCrfError,
    crfErrors,
    setCrfTouch,
    crfTouch,
  } = useUtil();
  useEffect(() => {
    setForms(getSectionForm(patient_selected_section, patient_form?.attribute));
  }, [patient_selected_section]);
  const [activeStep, setActiveStep] = useState(newVisit ? 0 : 0);
  const [errorOpen, setErrorOpen] = useState(false);
  const [autoSaveAlert, setAutoSaveAlert] = useState(false);

  const [completed, setCompleted] = useState({});

  const [mainPageloading, setMainPageloading] = useState(false);
  const acceptedPathsList = ["Edit"];

  let user = JSON.parse(StorageHelper.get("user"));

  const urlWindow = window.location;

  // Auto Save to Database <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<----------------------------------------------------------------------------------
  const idleTimer = useRef(null);
  const handleOnIdleSave = () => {
    if (
      window.location.href.includes("visitHistory") &&
      (parseInt(user?.role_id) === parseInt(ENV.doctor_role_id) ||
        (parseInt(user?.role_id) === parseInt(ENV.medical_lead_role_id) &&
          encounterData?.GetEncountersByPatientId?.[0]?.["self"])) &&
      (parseInt(encountedDatamain?.status) === 0 || // Draft
        parseInt(encountedDatamain?.status) === 4 || // Returned to Doctor
        parseInt(encountedDatamain?.status) === 5) // Re-Opened
    ) {
      const encounterId = parseInt(params.encounterId);
      const data = JSON.parse(StorageHelper.get(encounterId));
      const originalFormat = convertToOriginalFormat(data);
      const values = postProcessData(originalFormat);
      if (values) {
        delete values.demography;
        delete values.PPCI;
        setAutoSaveAlert(true);
        Update_Encounter({
          variables: {
            encounter_id: parseInt(encounterId),
            details: values,
            is_auto_save: true,
          },
          onCompleted: (dataUpdated) => {
            UpdatePatient({
              variables: {
                input: {
                  Demographic: {
                    dob:
                      data?.demography_dateofbirth === ""
                        ? null
                        : data?.demography_dateofbirth,
                    age:
                      data?.demography_age === "" ? null : data?.demography_age,
                    education: data?.demography_educationallevel,
                    email: data?.PPCI_email,
                    employment: data?.demography_employment,
                    first_name: data?.PPCI_firstname,
                    gender: data?.demography_gender,
                    city: data?.demography_city,
                    region: data?.demography_region,
                    country: data?.demography_countrylist,
                    last_name: data?.PPCI_lastname,
                    marital: data?.demography_maritalstatus,
                    mobile: data?.PPCI_phonenumber,
                    mrn_no: data?.PPCI_mrnno,
                    national_id: data?.PPCI_nationalid?.toString(),
                    nationality: data?.demography_nationality,
                    smoking_status: data?.demography_smokerstatus,
                    consentform: data?.PPCI_consentform,
                  },
                },
                patientId: dataUpdated?.UpdateEncounter?.patient_id,
              },
              onCompleted: (patientUpdated) => {
                console.log("The auto save is completed", patientUpdated);
                setAutoSaveAlert(false);

                // StorageHelper.remove(encounterId)
              },
              // onError: (error) => {
              //   alert(error);
              // },
            });
          },
          // onError: (error) => {

          // },
        });
      }
    }
  };

  useIdleTimer({
    ref: idleTimer,
    timeout: 1000 * 10,
    onIdle: handleOnIdleSave,
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500,
  });

  // ---------------------------------------------------------------------------------------------->>>>>>>>>>>>>>
  let newData = {};
  if (acceptedPathsList?.includes(urlWindow.pathname.split("/")[3])) {
    newData = {
      ...patient_form,
      sections: patient_form?.sections.filter((_, index) => index === 0),
    };
  }
  const [notemessage, setnotemessage] = useState("");
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;

    setCompleted(newCompleted);
    handleNext();
  };

  const handleLivingClose = () => {
    setlivingStatusOpen(false);
  };
  const totalSteps = () => {
    return acceptedPathsList?.includes(urlWindow.pathname.split("/")[3])
      ? newData?.sections.length
      : patient_form?.sections.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const [medicationArray, setMedicationArray] = useState([]);

  useEffect(() => {
    if (medicationArray.length > 0) {
      submitPatient(finalvalues);
    }
  }, [medicationArray]);

  const handleBack = () => {
    var element = document.getElementById("scroller");

    if (acceptedPathsList?.includes(urlWindow.pathname.split("/")[3])) {
      setSelectedSection(newData?.sections[activeStep - 1].key);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      element.scrollIntoView();
    } else {
      setSelectedSection(patient_form?.sections[activeStep - 1].key);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      element.scrollIntoView();
    }
  };

  const handleNext = (values) => {
    if (acceptedPathsList?.includes(urlWindow.pathname.split("/")[3])) {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            newData?.sections.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setSelectedSection(newData?.sections[newActiveStep].key);
      setActiveStep(newActiveStep);
    } else {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            patient_form?.sections.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setSelectedSection(patient_form?.sections[newActiveStep].key);

      setActiveStep(newActiveStep);
    }
  };

  const [registry_no, setregistry_no] = useState({});
  const [encounterdata, setencounterdata] = useState({});

  const convertToNestedObject = (input) => {
    const output = { ...input };
    const nestedFields = [
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale",
    ];

    nestedFields.forEach((field) => {
      const fieldValue = output[field];
      if (fieldValue && typeof fieldValue === "object") {
        Object.entries(fieldValue).forEach(([key, value]) => {
          const nestedFieldKey = `${field}_${key}`;
          output[nestedFieldKey] = value;
        });
        delete output[field];
      }
    });

    return output;
  };

  const convertToOriginalFormat = (data) => {
    const output = { ...data };
    const nestedFields = [
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale",
    ];

    nestedFields.forEach((field) => {
      const nestedObject = {};
      Object.keys(output).forEach((key) => {
        if (key.startsWith(`${field}_`)) {
          const nestedKey = key.replace(`${field}_`, "");
          nestedObject[nestedKey] = output[key];
          delete output[key];
        }
      });
      output[field] = nestedObject;
    });

    return output;
  };

  const CreateRegistraionNumber = (value) => {
    setMainPageloading(true);
    const originalFormat = convertToOriginalFormat(value);
    const values = postProcessData(originalFormat);
    AddPatient({
      variables: {
        input: {
          Demographic: {
            nationality: values?.demography?.nationality,
            email: values?.PPCI?.email,
            first_name: values?.PPCI?.firstname,
            last_name: values?.PPCI?.lastname,
            mobile: values?.PPCI?.phonenumber,
            mrn_no: values?.PPCI?.mrnno,
            national_id:
              values?.PPCI?.nationalid.toString() ||
              params?.nationalId.toString(),

            is_draft: true,
            consentform: values?.PPCI?.consentform,
          },
        },
      },
      onCompleted: (data) => {
        setregistry_no(data);
        delete values.demography;
        delete values.PPCI;
        setMessage(
          `A new patient has been registered successfully ${data?.AddPatient?.first_name} ${data?.AddPatient?.last_name}`
        );
        AddEncounter({
          variables: {
            patientId: parseFloat(data?.AddPatient?.patient_id),
            details: values,
            status: 0,
          },
          onCompleted: (data) => {
            StorageHelper.set("1234", JSON.stringify("basicVisit"));
            StorageHelper.set(
              data?.AddEncounter?.encounter_id,
              JSON.stringify(value)
            );
            setencounterdata(data);
            navigate(
              `/app/patients/visitHistory/${data?.AddEncounter?.encounter_id}`
            );

            setMainPageloading(false);
            setEncountedDataLatestStatus(0);
            setAlertOption(true);
            handleNext(value);
            setSaveDraft(2);
          },
          onError: (error) => {
            alert(error);
            setMainPageloading(false);
          },
        });
      },
      onError: (error) => {
        // console.log("error", error.message, JSON.stringify(error))
        if (error.message === "Mrn no. Exists")
          setErrorMessage("MRN number already exists");
        else setErrorMessage("Something went wrong");
        setErrorOpen(true);
        setMainPageloading(false);
      },
    });
  };
  const submitPatient = (data, submit, savedraft) => {
    // setencounterdata({});
    setMainPageloading(true);
    console.log("savedDraft", savedraft);

    const originalFormat = convertToOriginalFormat(data);
    const values = postProcessData(originalFormat);
    if (
      acceptedPathsList?.includes(urlWindow.pathname.split("/")[3]) &&
      patient_selected_section === "PPCI"
    ) {
      UpdatePatient({
        variables: {
          input: {
            Demographic: {
              dob:
                values?.demography?.dateofbirth === ""
                  ? null
                  : values?.demography?.dateofbirth,
              age:
                values?.demography?.year === ""
                  ? null
                  : `${values?.demography?.year}/${values?.demography?.month}`,
              education: values?.demography?.educationallevel,
              email: values?.PPCI?.email,
              employment: values?.demography?.employment,
              first_name: values?.PPCI?.firstname,
              gender: values?.demography?.gender,
              city: values?.demography?.city,
              region: values?.demography?.region,
              country: values?.demography?.countrylist,
              last_name: values?.PPCI?.lastname,
              marital: values?.demography?.maritalstatus,
              mobile: values?.PPCI?.phonenumber,
              mrn_no: values?.PPCI?.mrnno,
              national_id:
                values?.PPCI?.nationalid.toString() ||
                params?.nationalId.toString(),
              nationality: values?.demography?.nationality,
              smoking_status: values?.demography?.smokerstatus,
              consentform: values?.PPCI?.consentform,
            },
          },
          patientId: params?.nationalId
            ? parseInt(registry_no?.AddPatient?.patient_id)
            : encountedDatamain?.["patient_id"],
        },
        onCompleted: (data) => {
          setMessage("Profile has been updated");
          setMainPageloading(false);
          setAlertOption(true);
          setEncountedDataLatestStatus(1);
        },
        onError: (error) => {
          alert(error);
          setMainPageloading(false);
        },
      });
    } else {
      if ((savedraft === 1 || savedraft === 2) && !submit) {
        if (!params?.patientId) {
          if (
            !encountedDatamain?.["status"] &&
            encountedDatamain?.["status"] !== 0 &&
            !params?.nationalId
          ) {
            AddPatient({
              variables: {
                input: {
                  Demographic: {
                    dob:
                      values?.demography?.dateofbirth === ""
                        ? null
                        : values?.demography?.dateofbirth,
                    age:
                      values?.demography?.year === ""
                        ? null
                        : `${values?.demography?.year}/${values?.demography?.month}`,
                    education: values?.demography?.educationallevel,
                    email: values?.PPCI?.email,
                    employment: values?.demography?.employment,
                    first_name: values?.PPCI?.firstname,
                    gender: values?.demography?.gender,
                    city: values?.demography?.city,
                    region: values?.demography?.region,
                    country: values?.demography?.countrylist,
                    last_name: values?.PPCI?.lastname,
                    marital: values?.demography?.maritalstatus,
                    mobile: values?.PPCI?.phonenumber,
                    mrn_no: values?.PPCI?.mrnno,
                    national_id:
                      values?.PPCI?.nationalid.toString() ||
                      params?.nationalId.toString(),
                    nationality: values?.demography?.nationality,
                    smoking_status: values?.demography?.smokerstatus,
                    is_draft: true,
                    consentform: values?.PPCI?.consentform,
                  },
                },
              },
              onCompleted: (data) => {
                delete values.demography;
                delete values.PPCI;
                AddEncounter({
                  variables: {
                    patientId: parseFloat(data?.AddPatient?.patient_id),
                    details: values,
                    status: 0,
                  },
                  onCompleted: (data) => {
                    setMessage(
                      `A new patient has been registered successfully ${values?.PPCI?.firstname} ${values?.PPCI?.lastname}`
                    );
                    setAlertOption(true);
                    setMainPageloading(false);
                    setEncountedDataLatestStatus(0);
                  },
                  onError: (error) => {
                    alert(error);
                    setMainPageloading(false);
                  },
                });
              },
              onError: (error) => {
                alert(error);
                setMainPageloading(false);
              },
            });
          } else {
            if (encountedDatamain?.["status"] === 0 || params?.nationalId) {
              UpdatePatient({
                variables: {
                  input: {
                    Demographic: {
                      dob:
                        values?.demography?.dateofbirth === ""
                          ? null
                          : values?.demography?.dateofbirth,
                      age:
                        values?.demography?.year === ""
                          ? null
                          : `${values?.demography?.year}/${values?.demography?.month}`,
                      education: values?.demography?.educationallevel,
                      email: values?.PPCI?.email,
                      employment: values?.demography?.employment,
                      first_name: values?.PPCI?.firstname,
                      gender: values?.demography?.gender,
                      city: values?.demography?.city,
                      region: values?.demography?.region,
                      country: values?.demography?.countrylist,
                      last_name: values?.PPCI?.lastname,
                      marital: values?.demography?.maritalstatus,
                      mobile: values?.PPCI?.phonenumber,
                      mrn_no: values?.PPCI?.mrnno,
                      national_id:
                        values?.PPCI?.nationalid.toString() ||
                        params?.nationalId.toString(),
                      nationality: values?.demography?.nationality,
                      smoking_status: values?.demography?.smokerstatus,
                      is_draft: true,
                      consentform: values?.PPCI?.consentform,
                    },
                  },
                  patientId: params?.nationalId
                    ? parseInt(registry_no?.AddPatient?.patient_id)
                    : encountedDatamain?.["patient_id"],
                },
                onCompleted: (data) => {
                  delete values.demography;
                  delete values.PPCI;
                  Update_Encounter({
                    variables: {
                      encounter_id: params?.nationalId
                        ? parseInt(encounterdata?.AddEncounter?.encounter_id)
                        : parseInt(params?.encounterId),
                      details: values,
                      status: 0,
                    },
                    onCompleted: () => {
                      if (medicationArray.length > 0) {
                        const updatedData = medicationArray.map((item) => ({
                          ...item,
                          encounter_id: data?.AddEncounter?.encounter_id,
                        }));
                        AddMedicationRequests({
                          variables: {
                            encounterId: data?.AddEncounter?.encounter_id,
                            requests: updatedData,
                          },
                          onCompleted: async (res) => {
                            if (savedraft === 2) {
                              // setMessage(
                              //   "Patient Record Draft Updated Successfully"
                              // );
                              // setAlertOption(true);
                            }
                            setMainPageloading(false);
                            setEncountedDataLatestStatus(0);
                          },
                          onError: (error) => {
                            alert(error);
                            setMainPageloading(false);
                          },
                        });
                      } else {
                        if (savedraft === 2) {
                          // setMessage(
                          //   "Patient Record Draft Updated Successfully"
                          // );
                          // setAlertOption(true);
                        }
                        setMainPageloading(false);
                        setEncountedDataLatestStatus(0);
                      }
                    },
                    onError: (error) => {
                      alert(error);
                    },
                  });
                },
                onError: (error) => {
                  alert(error);
                  setMainPageloading(false);
                },
              });
            }
          }
        } else {
          UpdatePatient({
            variables: {
              input: {
                Demographic: {
                  dob:
                    values?.demography?.dateofbirth === ""
                      ? null
                      : values?.demography?.dateofbirth,
                  age:
                    values?.demography?.year === ""
                      ? null
                      : `${values?.demography?.year}/${values?.demography?.month}`,
                  education: values?.demography?.educationallevel,
                  email: values?.PPCI?.email,
                  employment: values?.demography?.employment,
                  first_name: values?.PPCI?.firstname,
                  gender: values?.demography?.gender,
                  city: values?.demography?.city,
                  region: values?.demography?.region,
                  country: values?.demography?.countrylist,
                  last_name: values?.PPCI?.lastname,
                  marital: values?.demography?.maritalstatus,
                  mobile: values?.PPCI?.phonenumber,
                  mrn_no: values?.PPCI?.mrnno,
                  national_id:
                    values?.PPCI?.nationalid.toString() ||
                    params?.nationalId.toString(),
                  nationality: values?.demography?.nationality,
                  smoking_status: values?.demography?.smokerstatus,
                  is_draft: true,
                  consentform: values?.PPCI?.consentform,
                },
              },
              patientId: params?.nationalId
                ? parseInt(registry_no?.AddPatient?.patient_id)
                : params?.patientId,
            },
            onCompleted: (datass) => {
              delete values.demography;
              delete values.PPCI;
              AddEncounter({
                variables: {
                  patientId: params?.nationalId
                    ? parseInt(registry_no?.AddPatient?.patient_id)
                    : parseInt(params?.patientId),
                  status: 0,
                  details: values,
                },
                onCompleted: (datas) => {
                  if (savedraft === 2) {
                    if (values.basicVisit.typeofvisit === "Baseline Visit")
                      setMessage("Patient CRF saved and locked successfully");
                    else
                      setMessage(
                        "Follow up visit saved and locked successfully"
                      );
                  }
                  console.log("ejeiuieiieManoj");

                  StorageHelper.set("1234", JSON.stringify("LivingStatus"));
                  StorageHelper.set(
                    datas?.AddEncounter?.encounter_id,
                    JSON.stringify(data)
                  );
                  navigate(
                    `/app/patients/visitHistory/${datas?.AddEncounter?.encounter_id}`
                  );
                  setAlertOption(true);
                  setMainPageloading(false);
                  setEncountedDataLatestStatus(0);
                },
                onError: (error) => {
                  alert(error);
                },
              });
            },
            onError: (error) => {
              alert(error);
              setMainPageloading(false);
            },
          });
        }
      } else {
        if (!params?.patientId) {
          if (
            encountedDatamain?.["status"] === 0 ||
            encounterdata?.AddEncounter?.status === 0
          ) {
            UpdatePatient({
              variables: {
                input: {
                  Demographic: {
                    dob:
                      values?.demography?.dateofbirth === ""
                        ? null
                        : values?.demography?.dateofbirth,
                    age:
                      values?.demography?.year === ""
                        ? null
                        : `${values?.demography?.year}/${values?.demography?.month}`,
                    education: values?.demography?.educationallevel,
                    email: values?.PPCI?.email,
                    employment: values?.demography?.employment,
                    first_name: values?.PPCI?.firstname,
                    gender: values?.demography?.gender,
                    city: values?.demography?.city,
                    region: values?.demography?.region,
                    country: values?.demography?.countrylist,
                    last_name: values?.PPCI?.lastname,
                    marital: values?.demography?.maritalstatus,
                    mobile: values?.PPCI?.phonenumber,
                    mrn_no: values?.PPCI?.mrnno,
                    national_id:
                      values?.PPCI?.nationalid?.toString() ||
                      params?.nationalId?.toString(),
                    nationality: values?.demography?.nationality,
                    smoking_status: values?.demography?.smokerstatus,
                    is_draft: false,
                    consentform: values?.PPCI?.consentform,
                  },
                },
                patientId: encountedDatamain?.["patient_id"]
                  ? encountedDatamain?.["patient_id"]
                  : encounterdata?.AddEncounter?.patient_id,
              },
              onCompleted: (data) => {
                delete values.demography;
                delete values.PPCI;
                Update_Encounter({
                  variables: {
                    encounter_id: params?.encounterId
                      ? parseInt(params?.encounterId)
                      : parseInt(encounterdata?.AddEncounter?.encounter_id),
                    details: values,
                    status: 1,
                  },
                  onCompleted: () => {
                    if (medicationArray.length > 0) {
                      const updatedData = medicationArray.map((item) => ({
                        ...item,
                        encounter_id: data?.AddEncounter?.encounter_id,
                      }));
                      AddMedicationRequests({
                        variables: {
                          encounterId: data?.AddEncounter?.encounter_id,
                          requests: updatedData,
                        },
                        onCompleted: async (res) => {
                          if (
                            values.basicVisit.typeofvisit === "Baseline Visit"
                          )
                            setMessage(
                              "Patient CRF saved and locked successfully"
                            );
                          else
                            setMessage(
                              "Follow up visit saved and locked successfully"
                            );
                          setAlertOption(true);
                          setMainPageloading(false);
                          setEncountedDataLatestStatus(0);
                        },
                        onError: (error) => {
                          alert(error);
                          setMainPageloading(false);
                        },
                      });
                    } else {
                      if (values.basicVisit.typeofvisit === "Baseline Visit")
                        setMessage("Patient CRF saved and locked successfully");
                      else
                        setMessage(
                          "Follow up visit saved and locked successfully"
                        );

                      setMainPageloading(false);
                      setEncountedDataLatestStatus(0);
                      setAlertOption(true);
                    }
                  },
                  onError: (error) => {
                    alert(error);
                  },
                });
              },
              onError: (error) => {
                alert(error);
                setMainPageloading(false);
              },
            });
          } else {
            AddPatient({
              variables: {
                input: {
                  Demographic: {
                    dob:
                      values?.demography?.dateofbirth === ""
                        ? null
                        : values?.demography?.dateofbirth,
                    age:
                      values?.demography?.year === ""
                        ? null
                        : `${values?.demography?.year}/${values?.demography?.month}`,
                    education: values?.demography?.educationallevel,
                    email: values?.PPCI?.email,
                    employment: values?.demography?.employment,
                    first_name: values?.PPCI?.firstname,
                    gender: values?.demography?.gender,
                    city: values?.demography?.city,
                    region: values?.demography?.region,
                    country: values?.demography?.countrylist,
                    last_name: values?.PPCI?.lastname,
                    marital: values?.demography?.maritalstatus,
                    mobile: values?.PPCI?.phonenumber,
                    mrn_no: values?.PPCI?.mrnno,
                    national_id:
                      values?.PPCI?.nationalid.toString() ||
                      params?.nationalId.toString(),
                    nationality: values?.demography?.nationality,
                    smoking_status: values?.demography?.smokerstatus,
                    is_draft: false,
                    consentform: values?.PPCI?.consentform,
                  },
                },
              },
              onCompleted: (data) => {
                delete values.demography;
                delete values.PPCI;
                AddEncounter({
                  variables: {
                    patientId: parseFloat(data?.AddPatient?.patient_id),
                    details: values,
                  },
                  onCompleted: (data) => {
                    if (medicationArray.length > 0) {
                      const updatedData = medicationArray.map((item) => ({
                        ...item,
                        encounter_id: data?.AddEncounter?.encounter_id,
                      }));
                      AddMedicationRequests({
                        variables: {
                          encounterId: data?.AddEncounter?.encounter_id,
                          requests: updatedData,
                        },
                        onCompleted: async (res) => {
                          if (savedraft === 2) {
                            if (
                              values.basicVisit.typeofvisit === "Baseline Visit"
                            )
                              setMessage(
                                "Patient CRF saved and locked successfully"
                              );
                            else
                              setMessage(
                                "Follow up visit saved and locked successfully"
                              );
                          }
                          setAlertOption(true);
                          setMainPageloading(false);
                          setEncountedDataLatestStatus(0);
                        },
                        onError: (error) => {
                          alert(error);
                          setMainPageloading(false);
                        },
                      });
                    } else {
                      if (savedraft === 2) {
                        if (values.basicVisit.typeofvisit === "Baseline Visit")
                          setMessage(
                            "Patient CRF saved and locked successfully"
                          );
                        else
                          setMessage(
                            "Follow up visit saved and locked successfully"
                          );
                      }
                      setMainPageloading(false);
                      setEncountedDataLatestStatus(0);
                      setAlertOption(true);
                    }
                  },
                  onError: (error) => {
                    alert(error);
                    setMainPageloading(false);
                  },
                });
              },
              onError: (error) => {
                alert(error);
                setMainPageloading(false);
              },
            });
          }
        } else {
          delete values.demography;
          delete values.PPCI;
          AddEncounter({
            variables: {
              patientId: parseInt(params?.patientId),
              encounterStartdate: new Date().toLocaleDateString("en-GB"),
              encounterEnddate: new Date().toLocaleDateString("en-GB"),
              details: values,
            },
            onCompleted: () => {
              if (values.basicVisit.typeofvisit === "Baseline Visit")
                setMessage("Patient CRF saved and locked successfully");
              else setMessage("Follow up visit saved and locked successfully");
              setAlertOption(true);
              setMainPageloading(false);
              setEncountedDataLatestStatus(1);
            },
            onError: (error) => {
              alert(error);
            },
          });
        }
      }
    }
  };
  const handleSubmitClose = () => {
    setAlertOption(false);
    setSubmitOpen(false);
    console.log("savedraft", savedraft, encounterdata);
    if (savedraft === 1 || savedraft === 0) {
      setTimeout(() => {
        setSubmitOpen(false);
        // if (!Object.keys(encounterdata).length > 0) {
        if (encounterId) {
          StorageHelper.remove(encounterId);
        }
        navigate("/app/patients/patientlist");
        // }
      }, 1000);
    }
  };
  const handleErrorClose = () => {
    setErrorOpen(false);
  };
  console.log("patient_form?.attribute", patient_form?.attribute);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);
  //Initialize the first section
  //   useEffect(() => {
  //     setSelectedSection(patient_form?.sections?.[newVisit ? 0 : 0]?.key ?? "");
  //     var obj = {};
  //     patient_form?.sections.map((label, index) => (obj[label.key] = {}));
  //     obj = {
  //       ...obj,
  //       demography: {
  //         ...obj.demography_nationalid,
  //         nationalid: "",
  //       },
  //       ParaClinicalInvestigation: {
  //         serologicaltests: {
  //           serologicaltestsheading: {},
  //         },
  //         chestimagingexam: {},
  //         repetitivenervestimulation: {},
  //         singlefiberelectromyography: {},
  //         edrophoniumtest: {},
  //         icepacktest: {},
  //         forcedvitalcapacity: {},
  //       },
  //     };
  //     obj["demography"]["nationalid"] = params.nationalId;
  //     // obj["demography"]["age"] = "1998";
  //     if (!params?.encounterId) setReload(obj);
  //   }, [patient_form]);

  //   useEffect(() => {
  //     if (params?.encounterId) {
  //       GetEncounter({
  //         variables: { encounterId: parseInt(params?.encounterId) },
  //       }).then((res) => {
  //         let obj = { ...res.data?.GetEncounter?.details };
  //         console.log("reload1", obj);
  //         obj = {
  //           ...obj,
  //           demography: {
  //             ...obj.demography,
  //             dateofbirth: "1995",
  //             age: "20",
  //           },
  //         };
  //         setReload(obj);
  //       });
  //     }
  //   }, [params?.encounterId]);
  useEffect(() => {}, [reload]);
  const [open, setOpen] = React.useState(false);
  const [submitOpen, setSubmitOpen] = React.useState(false);
  const [formikValues, setFormikValues] = useState({});
  const handleClose = () => {
    // setSelectedSection(patient_form?.sections[7].key);
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const [finalvalues, setfinalvalues] = React.useState({});
  useEffect(() => {
    if (Object.keys(finalvalues).length > 0) {
      setOpen(true);
    }
  }, [finalvalues]);

  const [numberofCount, setNumberOfCount] = useState(false);
  const commentCount = (attributName, count) => {
    setNumberOfCount(
      !getActionRbac("patients", "edit_patient_form") && count > 0
        ? false
        : true
    );
  };

  const validationSchema = getValidationSchema(
    patient_form?.attribute,
    false,
    formikRef?.current?.values
  );

  const handleFormikChange = (e, attr) => {
    delete submitErrors?.[e];
    delete submitTouched?.[e];
    delete submitErrors?.[e];
    delete submitTouched?.[e];
    if (attr?.removeerrorkeys) {
      if (Array.isArray(attr?.removeerrorkeys)) {
        attr?.removeerrorkeys.forEach((key) => {
          delete submitErrors?.[key];
          delete submitTouched?.[key];
        });
      }
    } else if (attr?.reversedependent) {
      if (Array.isArray(attr.reversedependent)) {
        attr.reversedependent.forEach((key) => {
          delete submitErrors?.[key];
          delete submitTouched?.[key];
        });
      } else if (typeof attr.reversedependent === "string") {
        const key = attr.reversedependent;
        delete submitErrors?.[key];
        delete submitTouched?.[key];
      }
    } else if (attr?.dependent) {
      if (Array.isArray(attr.dependent)) {
        attr.dependent.forEach((key) => {
          delete submitErrors?.[key];
          delete submitTouched?.[key];
        });
      } else if (typeof attr.dependent === "string") {
        const key = attr.dependent;
        delete submitErrors?.[key];
        delete submitTouched?.[key];
      }
    }
  };

  const handleApproval = (id, val, remark) => {
    setMainPageloading(true);
    delete val.demography;
    delete val.PPCI;
    Update_Encounter({
      variables: {
        encounter_id: parseInt(id),
        status: val,
        remark,
      },
      onCompleted: () => {
        setMessage("CRF reviewed successfully");
        setAlertOption(true);
        setEncountedDataLatestStatus(parseInt(val));
        setApprove(false);
        setMainPageloading(false);

        setTimeout(() => {
          setAlertOption(false);
          navigate("/app/patients/patientlist");
        }, 1000);
      },
      onError: (error) => {
        alert(error);
      },
    });
  };

  const handleEncounter = (data) => {
    setMainPageloading(true);
    const originalFormat = convertToOriginalFormat(data);
    const values = postProcessData(originalFormat);
    UpdatePatient({
      variables: {
        input: {
          Demographic: {
            dob: values?.demography?.dateofbirth,
            age:
              values?.demography?.year === ""
                ? null
                : `${values?.demography?.year}/${values?.demography?.month}`,
            education: values?.demography?.educationallevel,
            email: values?.PPCI?.email,
            employment: values?.demography?.employment,
            first_name: values?.PPCI?.firstname,
            gender: values?.demography?.gender,
            city: values?.demography?.city,
            region: values?.demography?.region,
            country: values?.demography?.countrylist,
            last_name: values?.PPCI?.lastname,
            marital: values?.demography?.maritalstatus,
            mobile: values?.PPCI?.phonenumber,
            mrn_no: values?.PPCI?.mrnno,
            national_id:
              values?.PPCI?.nationalid.toString() ||
              params?.nationalId.toString(),
            nationality: values?.demography?.nationality,
            smoking_status: values?.demography?.smokerstatus,
            is_draft: false,
            consentform: values?.PPCI?.consentform,
          },
        },
        patientId: encountedDatamain?.["patient_id"],
      },
      onCompleted: (data) => {
        delete values.demography;
        delete values.PPCI;
        Update_Encounter({
          variables: {
            encounter_id: parseInt(params?.encounterId),
            details: values,
          },
          onCompleted: () => {
            handleFeeback(params?.encounterId, 5, "Quality User");
          },
          onError: (error) => {
            alert(error);
          },
        });
      },
      onError: (error) => {
        alert(error);
        setMainPageloading(false);
      },
    });
  };
  const handleFeeback = (id, status, user) => {
    SubmitComment({
      variables: {
        encounterId: parseInt(id),
        status: status,
      },
      onCompleted: (data) => {
        if (
          data?.SubmitComment?.not_submitted_count === 0 &&
          parseInt(data?.SubmitComment.encounter_report_status) !== 5
        ) {
          setMessage("No Feedback Submitted");
          setAlertOption(true);
          setMainPageloading(false);
          setSubmitFeedback(false);
          setTimeout(() => {
            setAlertOption(false);
          }, 1000);
        } else {
          if (encountedDataLatestStatus === 4) {
            setMessage("Feedback and Patient Record Updated Successfully");
            setAlertOption(true);
            setMainPageloading(false);
            setEncountedDataLatestStatus(
              parseInt(data?.SubmitComment.encounter_report_status)
            );
          }
          if (
            encountedDataLatestStatus === 2 ||
            encountedDataLatestStatus === 3
          ) {
            setMessage(
              `The ${data?.SubmitComment?.visit_type}(${
                data?.SubmitComment?.encounter_count - 1 !== 0
                  ? data?.SubmitComment?.encounter_count - 1
                  : null
              }) - ${
                data?.SubmitComment?.patient_registry_no
              } reopened to the Doctor successfully`
            );
            setAlertOption(true);
            setMainPageloading(false);
            setEncountedDataLatestStatus(
              parseInt(data?.SubmitComment.encounter_report_status)
            );
          } else {
            setMessage(`The CRF Returned to the ${user} successfully`);
            setAlertOption(true);
            setSubmitFeedback(false);
            setMainPageloading(false);
            setEncountedDataLatestStatus(
              parseInt(data?.SubmitComment.encounter_report_status)
            );
          }
          setTimeout(() => {
            setAlertOption(false);
            navigate("/app/patients/patientlist");
          }, 1000);
        }
      },
      onError: (error) => {
        alert(error);
      },
    });
  };
  // Function to get and parse data from storage
  function getDataFromStorage(key) {
    let storedData = StorageHelper.get(key);
    if (storedData) {
      try {
        return JSON.parse(storedData);
      } catch (e) {
        console.error("Error parsing stored data", e);
        return null;
      }
    }
    return null;
  }
  const [retrieveDatas, setretrieveDatas] = useState(null);
  let retrievedData = null;
  // Using the function to retrieve the data
  let encounterId = encountedDatamain?.["encounter_id"];

  if (urlWindow.pathname.split("/")[3] === "newPatient") {
    retrievedData = getDataFromStorage(params?.["nationalId"]);
    if (retrieveDatas === null && retrievedData !== null) {
      setretrieveDatas(true);
    }
  } else {
    if (encountedDatamain) {
      if (Object.keys(encountedDatamain).length > 0) {
        retrievedData = getDataFromStorage(encounterId);
        if (retrieveDatas === null) {
          setretrieveDatas(true);
        }
        let basicVisit = null;
        basicVisit = getDataFromStorage("1234");
        setTimeout(function () {
          if (basicVisit === "LivingStatus") {
            if (basicVisit !== null && basicVisit === "LivingStatus") {
              console.log("Manoj333", basicVisit);
              StorageHelper.remove("1234");
              setSelectedSection("LivingStatus");
              setActiveStep(1);
            }
          } else {
            if (
              basicVisit !== null &&
              basicVisit !== patient_selected_section
            ) {
              StorageHelper.remove("1234");
              setSelectedSection("basicVisit");
              setActiveStep(1);
            }
          }
        }, 1000);
      }
    }
  }
  function removeKeysWithPrefix(obj, prefix) {
    const result = {};

    for (let key in obj) {
      if (!key.startsWith(prefix)) {
        result[key] = obj[key]; // Add key-value pair if it doesn't start with the prefix
      }
    }

    return result;
  }
  const [oncompleted, setOncompleted] = useState(null);
  useEffect(() => {
    if (formikRef.current) {
      if (oncompleted !== null) {
        formikRef.current.validateForm().then((resp) => {
          let err = {};
          let processeddata = postProcessData(resp);

          if (processeddata) {
            err = preProcessData(processeddata);
          }
          formikRef.current.setErrors({});
          formikRef.current.setTouched({});
          setCompleted(err);
        });
      }
    }
  }, [patient_selected_section, oncompleted]);
  useEffect(() => {
    StorageHelper.remove("123");
  }, [params]);

  let sectionvalue = null;

  sectionvalue = getDataFromStorage("123");
  if (sectionvalue !== null) {
    if (sectionvalue !== patient_selected_section) {
      const index = patient_form?.sections.findIndex(
        (e) => e?.key === sectionvalue
      );
      StorageHelper.remove("123");
      setSelectedSection(sectionvalue);
      setActiveStep(index);
    }
  }
  let addPatientButton = getActionRbac("patients", "add");
  let edit = getActionRbac("patients", "edit_patient_form");
  const removeKeys = (obj, keysToRemove) => {
    return Object.keys(obj).reduce((acc, key) => {
      if (!keysToRemove.includes(key)) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  };
  console.log("patientData", registry_no, patientData, encountedDatamain);
  return (
    <>
      <Loader open={mainPageloading || pageLoad} />
      <Box
        sx={{
          marginTop: "-24px",
          width: "96vw",
          position: "absolute",
          zIndex: "99999",
        }}
      >
        {autoSaveAlert ? (
          <>
            <LinearProgress color="inherit" />
            <MgpText sx={{ textAlign: "center" }} variant="body2">
              Saving...
            </MgpText>
          </>
        ) : (
          <></>
        )}
      </Box>

      <Grid container direction="row" spacing={2}>
        <Grid item xs={4} md={3}>
          <MgpText
            variant="h5"
            id="scroller"
            style={{
              paddingBottom: "10px",
              fontWeight: "bold",
              paddingTop:
                patient_selected_section !== "PPCI" || !params?.["nationalId"]
                  ? "47px"
                  : "0px",
            }}
          >
            Registration form
          </MgpText>{" "}
          <Divider sx={{ marginBottom: "10px" }} />
          {patient_form?.sections && (
            <div>
              <MgpProgressBar
                commentData={commentData}
                steps={
                  acceptedPathsList?.includes(urlWindow.pathname.split("/")[3])
                    ? newData?.sections
                    : patient_form?.sections
                }
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                completed={completed}
                setCompleted={setCompleted}
                setSelectedSection={setSelectedSection}
                crfErrors={crfErrors}
                crfTouch={crfTouch}
                encountedData={encountedData}
                formikRef={formikRef?.current?.values}
                patient_selected_section={patient_selected_section}
                encountedDataLatestStatus={encountedDataLatestStatus}
                submitErrors={submitErrors}
              />
              <Stack direction={"row"} spacing={1} sx={{ marginTop: "10px" }}>
                {edit &&
                // patient_selected_section ===
                // "Hospitalizations" &&
                (((encountedDataLatestStatus === 4 ||
                  encountedDataLatestStatus === 6 ||
                  !encountedDataLatestStatus) &&
                  patientActive) ||
                  (acceptedPathsList?.includes(
                    urlWindow.pathname.split("/")[3]
                  ) &&
                    patient_selected_section === "PPCI")) &&
                editable ? (
                  <MgpButton
                    variant="contained"
                    onClick={() => {
                      console.log("entered1");

                      setOncompleted(3);
                      formikRef.current.validateForm().then((resp) => {
                        console.log("entered2", resp);

                        if (
                          acceptedPathsList?.includes(
                            urlWindow.pathname.split("/")[3]
                          )
                        ) {
                          console.log("entered3");

                          let err = {};
                          let processeddata =
                            postProcessData(resp)[patient_selected_section];
                          console.log("processeddata", processeddata);
                          if (processeddata) {
                            err = preProcessData({
                              [patient_selected_section]: processeddata,
                            });
                          }
                          formikRef.current.setErrors(err);
                          // Create a touched object from the errors object
                          const touchedFields = Object.keys(err).reduce(
                            (acc, key) => {
                              acc[key] = true;
                              return acc;
                            },
                            {}
                          );

                          // Set all touched fields
                          formikRef.current.setTouched(touchedFields);
                          setSubmitErrors(err);
                          setSubmitTouched(touchedFields);

                          if (!Object.keys(err).length) {
                            if (
                              acceptedPathsList?.includes(
                                urlWindow.pathname.split("/")[3]
                              ) &&
                              patient_selected_section === "PPCI"
                            ) {
                              submitPatient(formikRef.current.values);
                            }
                          } else {
                            console.log("entered4");

                            setErrorMessage(
                              "Please fill all of the mandatory fields."
                            );
                            setErrorOpen(true);
                          }
                        } else {
                          console.log("entered5");

                          let err = {};
                          let processeddata = postProcessData(resp);
                          if (processeddata) {
                            err = preProcessData(processeddata);
                          }
                          formikRef.current.setErrors(err);
                          const touchedFields = Object.keys(err).reduce(
                            (acc, key) => {
                              acc[key] = true;
                              return acc;
                            },
                            {}
                          );
                          formikRef.current.setTouched(touchedFields);
                          setSubmitErrors(err);
                          setSubmitTouched(touchedFields);
                          console.log("entered", err, resp);

                          if (!Object.keys(err).length) {
                            if (
                              encountedDataLatestStatus === 4 ||
                              encountedDataLatestStatus === 6
                            ) {
                              handleEncounter(formikRef.current.values);
                            } else {
                              setSaveDraft((prev) => 0);
                              submitPatient(
                                formikRef.current.values,
                                "submit",
                                0
                              );
                            }
                          } else {
                            setErrorMessage(
                              "Please fill all of the mandatory fields."
                            );
                            setErrorOpen(true);
                          }
                        }
                      });
                    }}
                  >
                    Submit
                  </MgpButton>
                ) : null}

                {/* <RbacWrapper module={"patients"} code={"add"}>
                  {
                    // patient_selected_section ===
                    //   "Hospitalizations" &&
                    !encountedDataLatestStatus &&
                    patientActive &&
                    !acceptedPathsList?.includes(
                      urlWindow.pathname.split("/")[3]
                    ) && (
                      <MgpButton
                        variant="contained"
                        onClick={() => {
                          formikRef.current.validateForm().then((resp) => {
                            setSaveDraft(() => 1)
                            submitPatient(formikRef.current.values);
                          });
                        }}
                      >
                        Save as Draft
                      </MgpButton>
                    )
                  }
                </RbacWrapper> */}
                {
                  // patient_selected_section ===
                  // "Hospitalizations" &&
                  ((encountedDataLatestStatus === 1 &&
                    getActionRbac("patients", "approval")) ||
                    (encountedDataLatestStatus === 5 &&
                      getActionRbac("patients", "approval") &&
                      getActionRbac("patients", "patient_comment_all"))) &&
                    editable &&
                    patientActive && (
                      <MgpButton
                        variant="contained"
                        onClick={() => {
                          setSubmitFeedback(params.encounterId);
                        }}
                      >
                        Return to Doctor
                      </MgpButton>
                    )
                }
                {
                  // patient_selected_section ===
                  // "Hospitalizations" &&
                  urlWindow.pathname.split("/")[3] === "Edit" && (
                    <MgpButton
                      variant="contained"
                      onClick={() => {
                        navigate("/app/patients/patientlist");
                      }}
                    >
                      Cancel
                    </MgpButton>
                  )
                }
                {/* re-open */}
                {
                  // patient_selected_section ===
                  //   "Hospitalizations" &&
                  ((encountedDataLatestStatus === 2 &&
                    getActionRbac("patients", "approval")) ||
                    (encountedDataLatestStatus === 3 &&
                      getActionRbac("patients", "approval") &&
                      getActionRbac("patients", "patient_comment_all"))) &&
                    reopen &&
                    patientActive && (
                      <MgpButton
                        variant="contained"
                        onClick={() => {
                          setSubmitReopen(params.encounterId);
                        }}
                      >
                        Return to Doctor
                      </MgpButton>
                    )
                }
                {
                  // patient_selected_section ===
                  //   "Hospitalizations" &&
                  (encountedDataLatestStatus === 1 ||
                    // encountedDataLatestStatus === 4 ||
                    encountedDataLatestStatus === 5) &&
                    editable &&
                    patientActive && (
                      <RbacWrapper module={"patients"} code={"approval"}>
                        <MgpButton
                          variant="contained"
                          onClick={() => {
                            setApprove(params.encounterId);
                          }}
                        >
                          Approve
                        </MgpButton>
                        {/* <MgpButton
                                    variant="contained"
                                    onClick={() => {
                                      setOpenReject(params.encounterId);
                                    }}
                                  >
                                    Reject
                                  </MgpButton> */}
                      </RbacWrapper>
                    )
                }
              </Stack>
              <div>
                <img
                  src="Itkan Logo.png"
                  alt=""
                  style={{
                    width: "170px", // Adjust the width of the image as needed
                  }}
                />
              </div>
            </div>
          )}
        </Grid>

        {patient_form?.sections && (
          <Grid item xs={8} md={9}>
            <Box
              sx={{
                width: "100%",
                paddingLeft: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end", // Align items to the bottom
                whiteSpace: "nowrap", // Ensure content stays in a single line
                overflowX: "auto", // Enable horizontal scrolling if needed
              }}
            >
              <MgpText
                variant="h5"
                style={{ paddingBottom: "10px", fontWeight: "bold" }}
              >
                {
                  patient_form?.sections.find(
                    (elem) => elem.key === patient_selected_section
                  )?.label
                }
              </MgpText>
              {(patient_selected_section !== "PPCI" ||
                !params?.["nationalId"]) && (
                <Box
                  sx={{
                    width: "100%",
                    paddingLeft: "10px",
                    display: "flex",
                    paddingBottom: "10px",

                    justifyContent: "flex-end",
                  }}
                >
                  <Box>
                    <Paper elevation={0}>
                      <Stack direction="row" gap={2}>
                        <Stack direction="column">
                          <MgpText
                            variant="body2"
                            sx={{ textAlign: "left", fontWeight: "bold" }}
                          >
                            {user?.role_id == ENV.doctor_role_id
                              ? "Patient Name "
                              : "Principal Investigator "}
                          </MgpText>
                          <MgpText
                            variant="body2"
                            sx={{ textAlign: "left", fontWeight: "bold" }}
                          >
                            Patient Registration No
                          </MgpText>
                          <MgpText
                            sx={{ textAlign: "left", fontWeight: "bold" }}
                            variant="body2"
                          >
                            Visit Status
                          </MgpText>
                          <MgpText
                            variant="body2"
                            sx={{ textAlign: "left", fontWeight: "bold" }}
                          >
                            Current Visit
                          </MgpText>
                        </Stack>
                        <Stack direction="column">
                          <MgpText
                            variant="body2"
                            sx={{ textAlign: "left", fontWeight: "bold" }}
                          >
                            {user?.role_id == ENV.doctor_role_id ? ":" : ":"}
                          </MgpText>
                          <MgpText
                            variant="body2"
                            sx={{ textAlign: "left", fontWeight: "bold" }}
                          >
                            :
                          </MgpText>
                          <MgpText
                            sx={{ textAlign: "left", fontWeight: "bold" }}
                            variant="body2"
                          >
                            :
                          </MgpText>
                          <MgpText
                            variant="body2"
                            sx={{ textAlign: "left", fontWeight: "bold" }}
                          >
                            :
                          </MgpText>
                        </Stack>

                        <Stack direction="column">
                          <MgpText
                            variant="body2"
                            sx={{ textAlign: "left", fontWeight: "bold" }}
                          >
                            {Object.keys(encounterdata).length > 0
                              ? user?.role_id == ENV.doctor_role_id
                                ? encounterdata?.AddEncounter?.patient_name
                                : encounterdata?.AddEncounter?.current_doctor
                              : user?.role_id == ENV.doctor_role_id
                              ? encountedDatamain?.patient_name
                              : encountedDatamain?.current_doctor}
                          </MgpText>
                          <MgpText
                            variant="body2"
                            sx={{ textAlign: "left", fontWeight: "bold" }}
                          >
                            {Object.keys(registry_no).length > 0
                              ? registry_no?.AddPatient?.registry_no
                              : patientData?.ViewPatient?.registryno}
                          </MgpText>
                          <MgpText
                            variant="body2"
                            sx={{ textAlign: "left", fontWeight: "bold" }}
                          >
                            {Object.keys(encounterdata).length > 0
                              ? "Draft"
                              : encountedDatamain?.report_status}
                          </MgpText>
                          <MgpText
                            variant="body2"
                            sx={{ textAlign: "left", fontWeight: "bold" }}
                          >
                            {Object.keys(encounterdata).length > 0
                              ? "Baseline Visit"
                              : urlWindow.pathname.split("/")[3] === "newVisit"
                              ? "Follow Up Visit"
                              : encountedDatamain?.["details"]?.[
                                  "basicVisit"
                                ]?.["typeofvisit"] === ""
                              ? "Baseline Visit"
                              : encountedDatamain?.["details"]?.[
                                  "basicVisit"
                                ]?.["typeofvisit"] === "Baseline Visit"
                              ? "Baseline Visit"
                              : `${encountedDatamain?.["details"]?.["basicVisit"]?.["typeofvisit"]} (${encountedDatamain?.follow_up})`}
                          </MgpText>
                        </Stack>
                      </Stack>
                    </Paper>
                  </Box>
                </Box>
              )}
            </Box>

            <Divider
              sx={{
                marginBottom: "25px",
              }}
            />

            {reload &&
            ((urlWindow.pathname.split("/")[3] === "newPatient" &&
              retrieveDatas === null) ||
              retrieveDatas === true) ? (
              <Formik
                innerRef={formikRef}
                initialValues={
                  retrievedData === null
                    ? encountedData ||
                      getInitialValue(patient_form?.attribute, {})
                    : urlWindow.pathname.split("/")[3] === "newVisit"
                    ? encountedData ||
                      getInitialValue(patient_form?.attribute, {})
                    : Object.keys(retrievedData).length > 0 && retrievedData
                }
                enableReinitialize={true}
                validateOnChange={false} // Adjust as per your need
                validateOnBlur={false} // Adjust as per your need
                validationSchema={validationSchema}
                onSubmit={(payload) => {}}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  getFieldProps,
                  touched,
                  values,
                  validateForm,
                  setErrors,
                  setFieldError,
                  setTouched,
                  setValues,
                  setFieldTouched,
                }) => {
                  console.log("errors", crfErrors, crfTouch, errors);
                  if (JSON.stringify(errors) !== JSON.stringify(crfErrors)) {
                    setCrfError(errors);
                  }
                  if (JSON.stringify(touched) !== JSON.stringify(crfTouch)) {
                    setCrfTouch(touched);
                  }
                  setOncompleted(1);

                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid
                        container
                        spacing={3}
                        direction="row"
                        alignItems="flex-end"
                      >
                        {forms?.map((attr, idx) => {
                          if (
                            attr.showpii === false &&
                            !getActionRbac("view_patient", "view_pii")
                          ) {
                            return <></>;
                          }
                          let commentComponent = null;
                          if (params.encounterId) {
                            commentComponent = (getActionRbac(
                              "patients",
                              "patient_comment"
                            ) ||
                              getActionRbac(
                                "patients",
                                "patient_comment_all"
                              )) && (
                              <MgpComment
                                attr={{
                                  ...attr,
                                  encounterId: params.encounterId,
                                }}
                                section={
                                  patient_form?.sections.find(
                                    (e) => e.key === patient_selected_section
                                  ).label
                                }
                                // Other props as needed
                                showall={true}
                                count={commentData?.counts?.[attr.name]}
                                setCommentData={setCommentData}
                                commentData={commentData}
                                disable={
                                  !editable ||
                                  !patientActive ||
                                  (encountedDataLatestStatus === 2 &&
                                    getActionRbac("patients", "approval") &&
                                    !reopen) ||
                                  (encountedDataLatestStatus === 3 &&
                                    !getActionRbac("patients", "approval")) ||
                                  (encountedDataLatestStatus === 2 &&
                                    !getActionRbac("patients", "approval")) ||
                                  (encountedDataLatestStatus === 6 &&
                                    getActionRbac("patients", "approval")) ||
                                  (encountedDataLatestStatus === 1 &&
                                    !getActionRbac("patients", "approval")) ||
                                  (encountedDataLatestStatus === 5 &&
                                    !getActionRbac(
                                      "patients",
                                      "patient_comment_all"
                                    )) ||
                                  (encountedDataLatestStatus === 4 &&
                                    !getActionRbac(
                                      "patients",
                                      "patient_comment"
                                    )) ||
                                  getActionRbac("patients", "disable_comment")
                                }
                              />
                            );
                          }
                          return (
                            <React.Fragment key={idx}>
                              {generateDynamicComponent(
                                attr,
                                touched,
                                errors,
                                values,
                                handleBlur,
                                (e, attributeonchange) => {
                                  handleFormikChange(e, attributeonchange);
                                },
                                setFieldValue,
                                reload,
                                patientData?.ViewPatient?.is_active === false ||
                                  !editable ||
                                  encountedDataLatestStatus === 1 ||
                                  encountedDataLatestStatus === 3 ||
                                  encountedDataLatestStatus === 4 ||
                                  encountedDataLatestStatus === 6 ||
                                  encountedDataLatestStatus === 5
                                  ? true
                                  : encountedDataLatestStatus === 2 ||
                                    encountedDataLatestStatus === 6
                                  ? true
                                  : !getActionRbac(
                                      "patients",
                                      "edit_patient_form"
                                    ),
                                newVisit,
                                commentComponent,
                                setFieldTouched,
                                commentData,
                                setCommentData,
                                setValues,
                                encountedDataLatestStatus,
                                edit,
                                patientActive,
                                acceptedPathsList?.includes(
                                  urlWindow.pathname.split("/")[3]
                                ),
                                patient_form?.sections.find(
                                  (e) => e.key === patient_selected_section
                                ).label,
                                null,
                                reopen,
                                setserotype,
                                setnotemessage,
                                Object.keys(encounterdata).length > 0
                                  ? encounterdata
                                  : encountedDatamain,
                                setErrors,
                                setTouched,
                                submitErrors,
                                submitTouched
                              )}
                            </React.Fragment>
                          );
                        })}
                        <Grid item md={12} gap={3}>
                          <React.Fragment>
                            <Stack direction={"row"} gap={1}>
                              {activeStep === 0 ? (
                                <></>
                              ) : (
                                <MgpButton
                                  variant="contained"
                                  disabled={activeStep === 0}
                                  onClick={handleBack}
                                >
                                  Previous
                                </MgpButton>
                              )}

                              {/* {patient_selected_section === "Hospitalizations" &&
                              !encountedDataLatestStatus && (
                                <MgpButton
                                  variant="contained"
                                  onClick={() => {
                                    validateForm().then((resp) => {
                                      let err = {};
                                      let processeddata = postProcessData(resp);

                                      if (processeddata) {
                                        err = preProcessData(processeddata);
                                      }
                                      setErrors(err);
                                      setTouched(err);
                                      console.log("err", err);
                                      if (!Object.keys(err).length) {
                                        setfinalvalues(values);
                                      } else {
                                        alert(
                                          "Please fill all of the mandatory fields."
                                        );
                                      }
                                    });
                                  }}
                                >
                                  save & Add medication request
                                </MgpButton>
                              )} */}
                              {patient_selected_section !==
                              "Hospitalizations" ? (
                                acceptedPathsList?.includes(
                                  urlWindow.pathname.split("/")[3]
                                ) &&
                                patient_selected_section === "PPCI" ? null : (
                                  <MgpButton
                                    variant="contained"
                                    onClick={() => {
                                      setOncompleted(2);
                                      if (
                                        patientData?.ViewPatient?.is_active ===
                                          false ||
                                        encountedDatamain?.is_active ===
                                          false ||
                                        !editable
                                      ) {
                                        handleNext(values);
                                      } else {
                                        validateForm().then((resp) => {
                                          validateForm().then((resp) => {
                                            console.log("resp", resp);
                                            let err = {};
                                            let processeddata =
                                              postProcessData(resp)[
                                                patient_selected_section
                                              ];

                                            if (processeddata) {
                                              err = preProcessData({
                                                [patient_selected_section]:
                                                  processeddata,
                                              });
                                            }
                                            setErrors(err);
                                            // Create a touched object from the errors object
                                            const touchedFields = Object.keys(
                                              err
                                            ).reduce((acc, key) => {
                                              acc[key] = true;
                                              return acc;
                                            }, {});

                                            // Set all touched fields
                                            setTouched(touchedFields);

                                            console.log("errgshs", err);

                                            const finalerrs = removeKeys(err, [
                                              "TreatmentIntervention",
                                            ]);
                                            if (Object.keys(finalerrs).length) {
                                              const existingErrors =
                                                submitErrors;
                                              const existingTouched =
                                                submitTouched;
                                              console.log(
                                                "errgshs",
                                                existingErrors,
                                                existingTouched,
                                                patient_selected_section
                                              );

                                              const updatedErrors = {
                                                ...existingErrors,
                                                ...finalerrs,
                                              };
                                              const updatedTouched = {
                                                ...existingTouched,
                                                ...touchedFields,
                                              };
                                              setSubmitErrors(updatedErrors);
                                              setSubmitTouched(updatedTouched);
                                            }

                                            if (
                                              Object.keys(finalerrs).length ===
                                              0
                                            ) {
                                              const existingErrors =
                                                removeKeysWithPrefix(
                                                  submitErrors,
                                                  patient_selected_section
                                                );
                                              const existingTouched =
                                                removeKeysWithPrefix(
                                                  submitTouched,
                                                  patient_selected_section
                                                );
                                              const updatedErrors = {
                                                ...existingErrors,
                                                ...finalerrs,
                                              };
                                              const updatedTouched = {
                                                ...existingTouched,
                                                ...touchedFields,
                                              };
                                              setSubmitErrors(updatedErrors);
                                              setSubmitTouched(updatedTouched);
                                              let isAtLeastOnePositive = true;
                                              if (
                                                patient_selected_section ===
                                                  "ParaClinicalInvestigation" &&
                                                values?.[
                                                  "Diagnosis_typeofmg"
                                                ] === "Sero +"
                                              ) {
                                                const keysToCheck = [
                                                  "ParaClinicalInvestigation_serologicaltests_AntiAChRAb_Result",
                                                  "ParaClinicalInvestigation_serologicaltests_AntiMuSK_Result",
                                                  "ParaClinicalInvestigation_serologicaltests_AntiLRP4_Result",
                                                ];
                                                isAtLeastOnePositive =
                                                  keysToCheck.some(
                                                    (key) =>
                                                      values[key] === "Positive"
                                                  );
                                              }

                                              console.log(
                                                "isAtLeastOnePositive",
                                                isAtLeastOnePositive,
                                                values
                                              );

                                              if (!isAtLeastOnePositive) {
                                                setserotype(true);
                                                setnotemessage(
                                                  `If Sero+ is selected for “Type of MG” under Diagnosis section, then one of the results for Serological Tests should be positive`
                                                );
                                              } else {
                                                if (
                                                  patient_selected_section ===
                                                    "PPCI" &&
                                                  params?.["nationalId"] &&
                                                  Object.keys(registry_no)
                                                    .length === 0
                                                ) {
                                                  CreateRegistraionNumber(
                                                    values
                                                  );
                                                } else {
                                                  if (
                                                    patient_selected_section ===
                                                      "LivingStatus" &&
                                                    encountedDataLatestStatus !==
                                                      1 &&
                                                    encountedDataLatestStatus !==
                                                      2 &&
                                                    encountedDataLatestStatus !==
                                                      3 &&
                                                    encountedDataLatestStatus !==
                                                      4 &&
                                                    encountedDataLatestStatus !==
                                                      5 &&
                                                    encountedDataLatestStatus !==
                                                      6 &&
                                                    !params?.nationalId &&
                                                    encountedDatamain?.[
                                                      "details"
                                                    ]?.["typeofvisit"] !==
                                                      "Baseline Visit" &&
                                                    values?.[
                                                      "basicVisit_typeofvisit"
                                                    ] !== "Baseline Visit"
                                                  ) {
                                                    UpdatePatientLivingStatus({
                                                      variables: {
                                                        input: {
                                                          PatientLivingStatus: {
                                                            is_the_patient_alive:
                                                              values?.[
                                                                "LivingStatus_Isthepatientalive"
                                                              ] === "No"
                                                                ? false
                                                                : true,
                                                            date_of_death:
                                                              values?.[
                                                                "LivingStatus_dateofdeath"
                                                              ] === ""
                                                                ? null
                                                                : values?.[
                                                                    "LivingStatus_dateofdeath"
                                                                  ],
                                                            death_related_cause:
                                                              values?.[
                                                                "LivingStatus_DeathRelatedCause"
                                                              ],
                                                            cause_of_death:
                                                              values?.[
                                                                "LivingStatus_CauseofDeath"
                                                              ],
                                                            others_specify:
                                                              values?.[
                                                                "LivingStatus_OthersSpecify"
                                                              ],
                                                          },
                                                        },
                                                        patientId:
                                                          encounterData
                                                            ?.GetEncountersByPatientId?.[0]?.[
                                                            "patient_id"
                                                          ],
                                                      },
                                                      onCompleted: (data) => {
                                                        setMainPageloading(
                                                          false
                                                        );
                                                        handleNext(values);
                                                        var element =
                                                          document.getElementById(
                                                            "scroller"
                                                          );
                                                        element.scrollIntoView();
                                                      },
                                                      onError: (error) => {
                                                        alert(error);
                                                        setMainPageloading(
                                                          false
                                                        );
                                                      },
                                                    });
                                                  } else {
                                                    console.log(
                                                      "encountedDataLatestStatus",
                                                      encountedDataLatestStatus
                                                    );
                                                    if (
                                                      (encountedDataLatestStatus ===
                                                        0 ||
                                                        encountedDataLatestStatus ===
                                                          null) &&
                                                      getActionRbac(
                                                        "patients",
                                                        "add"
                                                      )
                                                    ) {
                                                      setSaveDraft((prev) => 2);
                                                      submitPatient(
                                                        formikRef.current
                                                          .values,
                                                        undefined,
                                                        2
                                                      );
                                                    }
                                                    handleNext(values);
                                                    var element =
                                                      document.getElementById(
                                                        "scroller"
                                                      );
                                                    element.scrollIntoView();
                                                  }
                                                }
                                              }
                                            } else {
                                              setErrorMessage(
                                                "Please fill all the mandatory fields having *"
                                              );
                                              setErrorOpen(true);
                                            }
                                          });
                                        });
                                      }
                                    }}
                                  >
                                    Next
                                  </MgpButton>
                                )
                              ) : null}
                            </Stack>
                          </React.Fragment>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            ) : null}
          </Grid>
        )}
      </Grid>

      {open && (
        <div>
          <MgpModal
            open={open}
            onClose={handleClose}
            title={"Medication Request"}
          >
            <Stack sx={{ marginX: "2%" }}>
              {/* <MgpText variant="h4">Medication Request</MgpText> */}
              <MedicationRequest
                from={"PATIENT_CRF_FORM"}
                obj={finalvalues}
                viewMode={viewMode}
                newVisit={newVisit}
                handleClose={handleClose}
                setMedicationArray={setMedicationArray}
              />
            </Stack>
          </MgpModal>
        </div>
      )}
      {livingStatusOpen && (
        <MgpModal
          styling={{}}
          open={livingStatusOpen}
          onClose={handleLivingClose}
          title={"Living Status"}
        >
          <Stack sx={{ marginX: "2%" }}>
            {/* <MgpText variant="h4">Medication Request</MgpText> */}
            <LivingStatus
              encounterData={encounterData?.GetEncountersByPatientId}
              setlivingStatusOpen={setlivingStatusOpen}
            />
          </Stack>
        </MgpModal>
      )}
      {approve && (
        <AlertDialog
          open={true}
          negative={() => {
            setApprove(false);
          }}
          positive={(param) => {
            handleApproval(Number(param), 2);
          }}
          param={approve}
          title={"Approve"}
          description={"Are you sure you want to approve?"}
        />
      )}
      {serotype && (
        <NoteAlertDialog
          open={true}
          negative={() => {
            setserotype(false);
          }}
          title={"Note"}
          description={notemessage}
        />
      )}

      {submitFeedback && (
        <AlertDialog
          open={true}
          negative={() => {
            setSubmitFeedback(false);
          }}
          positive={(param) => {
            handleFeeback(Number(param), 4, "Doctor");
            setSubmitFeedback(false);
          }}
          param={submitFeedback}
          title={"Return to Doctor"}
          description={"Are you sure you want to Return to Doctor?"}
        />
      )}
      {submitReopen && (
        <AlertDialog
          open={true}
          negative={() => {
            setSubmitReopen(false);
          }}
          positive={(param) => {
            handleFeeback(Number(param), 6, "Doctor");
            setSubmitReopen(false);
          }}
          param={submitReopen}
          title={"Re-open the Report"}
          description={"Are you sure you want to submit to re-open the report?"}
        />
      )}

      <MgpModal
        open={reject}
        onClose={() => {
          setOpenReject(false);
        }}
      >
        <PatientReject
          reject={reject}
          setOpenReject={setOpenReject}
          Update_Encounter={Update_Encounter}
          setEncountedDataLatestStatus={setEncountedDataLatestStatus}
          message={"Do you want to reject?"}
        />
      </MgpModal>
      <MgpModal open={submitOpen} onClose={handleSubmitClose}>
        <Alert severity="success">Patient CRF Submitted Successfully.</Alert>
      </MgpModal>
      <MgpAlertBar
        open={alertOption}
        setOpen={handleSubmitClose}
        alertType="success"
        message={message}
      />
      <MgpAlertBar
        open={errorOpen}
        setOpen={handleErrorClose}
        alertType="error"
        message={errorMessage}
      />
    </>
  );
};
