import { colors } from "../../theme/colors";
import { styled } from "@mui/material/styles";
import { TextField, Paper } from "@mui/material";

export const MgpTextbox = styled(TextField)(({ theme }) => ({
  "& label": {
    "&.with-asterisk::after": {
      content: '"*"',
      color: "red", // Change this to the desired color
      fontSize: "18px",
    },
  },
}));
