import { gql, useSubscription,useMutation, useLazyQuery } from "@apollo/client";
import { ProcessGrapqlError } from "../helpers";

const TEST_NOTIFICATION = gql`
mutation TestPush(
  $message: String!
  $subject: String!
 ) {
    TestPush(
      message: $message,
      subject: $subject,
    ) {
      message
      subject
    }
  }
`;

const GET_NOTIFICATIONS = gql`
query GetNotifications {
  GetNotifications {
    notification_id
    message
    message_json
    subject
    path
    user_id
    read
    created_on
    updated_on
  }
}
`;
const SUB_NOTIFICATION = gql`
  subscription Subscription($role: String!) {
    PushNotification(role: $role) {
      message
      subject
      role
      id
    }
  }
`;

const UPDATE_NOTIFICATIONS = gql`
mutation Mutation($notificationIds: [Int]) {
  ReadNotifications(notification_ids: $notificationIds) {
    notification_id
    message
    subject
    path
    user_id
    read
    created_on
    updated_on
  }
}
`;

const UPDATE_ALL_NOTIFICATIONS = gql`
mutation ReadAllNotifications {
  ReadAllNotifications {
    notification_id
    message
    message_json
    subject
    path
    user_id
    read
    created_on
    updated_on
  }
}
`;

export const useNotification = () => {
  const useNotify = useSubscription(SUB_NOTIFICATION, {
    variables: {
      role: "admin",
    },
  });
  const notify = useMutation(TEST_NOTIFICATION, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache) {
      
    },
  });

  const useUpdateNotification = useMutation(UPDATE_NOTIFICATIONS, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache) {
      
    },
  });
  
  const useUpdateAllNotification = useMutation(UPDATE_ALL_NOTIFICATIONS, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache) {
      
    },
  });

  const useGetNotifications = useLazyQuery(GET_NOTIFICATIONS);

  return { useNotify,notify,useGetNotifications,useUpdateNotification,useUpdateAllNotification };
};

 

