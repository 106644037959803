import { MaterialReactTable } from "material-react-table";
import { colors } from "../../theme/colors";
import { fontSize, positions } from "@mui/system";

export const MgpTable = ({ table }) => {
  return <MaterialReactTable table={table} TableConfig={TableConfig} />;
};


export const TableConfig = {
  initialState: {
    pagination: { pageSize: 25, pageIndex: 0 },
    showColumnFilters: true,
    showGlobalFilter: true,
    columnPinning: {
      left: ["mrt-row-expand"],
      right: ["mrt-row-actions"],
    },
  },
  enableFacetedValues: true,
  enableColumnActions: false,
  enableStickyHeader: true,
  enablePagination: true,
  enableRowActions: false,
  muiTableContainerProps: {
    sx: {
      maxHeight: '380px',
      overflowY: 'scroll',
      overflowX: 'scroll',
      '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        borderRadius: '3px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },
    },
  },
  positionToolbarAlertBanner: "bottom",
  muiSearchTextFieldProps: {
    size: "small",
    variant: "outlined",
    backgroundColor: "red"
  },
  muiPaginationProps: {
    color: "secondary",
    rowsPerPageOptions: [25, 50, 75, 100],
    shape: "rounded",
    variant: "outlined",
    showFirstButton: true,
    showLastButton: true,
  },
  mrtTheme: (theme) => ({
    baseBackgroundColor: colors.WHITE,
    draggingBorderColor: theme.palette.secondary.main,
  }),

  muiTableHeadCellProps: {
    sx: {
      backgroundColor: colors.PRIMARY_LIGHT,
      color: colors.CONTRAST_TEXT,
      borderRight: '1px solid #ccc', // Add right border for column separation
      textAlign: "center !important",
      alignItems: "center !important",

      '& .MuiInputBase-root': {
        // Additional specific styles for InputBase inside TableCell
        color: "#000000c7",
        fontSize: "12px",
        borderBottom: "0px solid",
        backgroundColor: colors.WHITE
      },
      '& .MuiFormLabel-root': {
        fontSize: "12px",
      },
      '& .MuiCollapse-root':{
        position: "absolute",
    bottom: "10px",
    right: "10px",
    left: "10px"
      }
   
      // display: 'flex',
      // flexDirection: 'column',
      // justifyContent: 'flex-start', // Adjust to ensure header text is aligned properly
      // padding: '8px',
    },
  },
  muiTablePaperProps: {
    elevation: 0, //change the mui box shadow
    sx: {
      borderRadius: "0",
      backgroundColor: colors.WHITE,
    },
  },
  muiTableBodyCellProps: ({ column }) => ({
    // Conditionally style pinned columns
    sx: {
      color: colors.PRIMARY,
      backgroundColor: colors.WHITE,
      borderRight: '1px solid #ccc',
      textAlign: 'center',
    },
  }),
};


export const TableConfigPatient = {
  initialState: {
    showColumnFilters: false,
    showGlobalFilter: true,
    columnPinning: {
      left: ["mrt-row-expand"],
      right: ["mrt-row-actions"],
    },
  },
  paginationDisplayMode: "pages",
  positionToolbarAlertBanner: "bottom",
  muiSearchTextFieldProps: {
    size: "small",
    variant: "outlined",
  },
  muiPaginationProps: {
    color: "secondary",
    rowsPerPageOptions: [10, 20, 25],
    shape: "rounded",
    variant: "outlined",
    showRowsPerPage: true,
  },
  mrtTheme: (theme) => ({
    baseBackgroundColor: colors.WHITE,
    draggingBorderColor: theme.palette.secondary.main,
  }),
  muiTablePaperProps: {
    elevation: 0, //change the mui box shadow
    //customize paper styles
    sx: {
      borderRadius: "0",
      backgroundColor: colors.WHITE,
    },
  },
  muiTableBodyCellProps: ({ column }) => ({
    //conditionally style pinned columns
    sx: {
      color: colors.SECONDARY,
      backgroundColor: colors.WHITE,
    },
  }),
  muiTableHeadCellProps: {
    //simple styling with the `sx` prop, works just like a style prop in this example
    sx: {
      backgroundColor: colors.PRIMARY_LIGHT,
      color: colors.CONTRAST_TEXT,
      textTransform: "uppercase",
      // fontFamily: "Montserrat sans Roboto",
    },
  },

};
