import React, { useState, useRef, useEffect } from "react";
import { IdleTimerProvider, useIdleTimer } from "react-idle-timer";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { StorageHelper } from "../../helpers";

const MgpIdleLogout = ({ onLogout }) => {
  const [isIdle, setIsIdle] = useState(false);
  const [starttime, setStarttime] = useState(0);
  const idleTimer = useRef(null);
  // const countdownIntervalRef = useRef(null);

  const handleOnIdle = () => {
    if (!window.location.href.includes("login") && isLastActiveTab()) {
      const lastActiveTime = getLastActiveTime().getTime() / 1000;
      const start_time = new Date().getTime() / 1000;
      const diff = start_time - lastActiveTime;
      // console.log(
      //   "william",
      //   diff,
      //   start_time,
      //   lastActiveTime,
      //   getLastActiveTime()
      // );
      if (diff >= 960) {
        onLogout();
      }
      setStarttime(start_time);
      setIsIdle(true);
      StorageHelper.set("count_stop", "false");
    }
  };

  const {
    getLastActiveTime,
    // getRemainingTime,
    // getTabId,
    // isLeader,
    isLastActiveTab,
    // message,
  } = useIdleTimer({
    ref: idleTimer,
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
    // onMessage,
  });
  // if(parseInt(Number(new Date().getTime() / 1000 - starttime).toString()) < 0){
  //   onLogout()
  // }
  return (
    <div>
      {isIdle && (
        <ModalView
          starttime={starttime}
          onLogout={onLogout}
          onClose={() => {
            setIsIdle(false);
          }}
        />
      )}
    </div>
  );
};

const ModalView = ({ starttime, onLogout, onClose, stopCount }) => {
  const [timer, setTimer] = useState(60); // 60 seconds countdown

  const startCountdown = (() => {
    let countdown;
    const initValue = 60;

    return (stop) => {
      if (stop) {
        clearInterval(countdown);
        countdown = null;
        StorageHelper.set("count_stop", "true");
        onClose();
      }
      if (!countdown && !stop) {
        countdown = setInterval(() => {
          const stopCount = JSON.parse(StorageHelper.get("count_stop"));
          const now = new Date().getTime() / 1000;
          const datedif =
            initValue - parseInt(Number(now - starttime).toString());
          setTimer(datedif);

          if (stopCount) {
            clearInterval(countdown);
            countdown = null;
          } else if (datedif <= 0) {
            clearInterval(countdown);
            onLogout();
            countdown = null;
          }
        }, 1000);
      }
    };
  })();

  useEffect(() => {
    startCountdown(false);
  }, []);

  const handleClose = () => {
    startCountdown(true);
  };

  return (
    <>
      <Modal
        xs={{ minHeight: "30%" }}
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={{ ...style, width: "43%" }}>
          <Box
            sx={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h4 style={{ color: "red" }}>Attention!</h4>
          </Box>
          <Box sx={{ overflowY: "auto", maxHeight: "80vh" }}>
            <div style={{ textAlign: "center", margin: "auto" }}>
              <h6>Your current session will be automatically logged out in</h6>
              <h2>{timer >= 0 ? timer : 0} seconds</h2>
            </div>
          </Box>
          <IconButton
            onClick={handleClose}
            sx={{
              marginBottom: "30px",
              marginLeft: "24px",
              position: "absolute",
              top: 6,
              right: 20,
            }}
          >
            <CancelIcon />
          </IconButton>
        </Paper>
      </Modal>
    </>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default MgpIdleLogout;
