import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { ProcessGrapqlError, apollo_client } from "../helpers";

export const useEncounter = ({ patientId = 1 }) => {
  const ADD_ENCOUNTER = gql`
    mutation AddEncounter($patientId: Int!, $details: JSON!, $status: Int) {
      AddEncounter(patient_id: $patientId, details: $details, status: $status) {
        encounter_id
        patient_id
        encounter_startdate
        encounter_enddate
        details
        status
        remark
        is_active
        created_on
        updated_on
        patient_name
        enrollment_date
        current_doctor
        current_hospital
        report_status
        site_number
        city_name
      }
    }
  `;

  const GET_ENCOUNTERS_BY_PATIENT_ID = gql`
    query GetEncountersByPatientId($patientId: Int!) {
      GetEncountersByPatientId(patient_id: $patientId) {
        encounter_id
        patient_id
        doctor_id
        hospital_id
        encounter_startdate
        encounter_enddate
        details
        status
        remark
        is_active
        created_on
        updated_on
        patient_name
        enrollment_date
        current_doctor
        current_hospital
        report_status
        medication_request_status_enum
        medication_request_status
        site_number
        city_name
        self
        created_by
        updated_by
        created_by_name
        updated_by_name
      }
    }
  `;

  const GET_ENCOUNTER = gql`
    query GetEncounter($encounterId: Int!) {
      GetEncounter(encounter_id: $encounterId) {
        encounter_id
        patient_id
        doctor_id
        hospital_id
        encounter_startdate
        encounter_enddate
        details
        status
        remark
        is_active
        created_on
        updated_on
        patient_name
        enrollment_date
        current_doctor
        current_hospital
        report_status
        site_number
        city_name
        follow_up
      }
    }
  `;
  const GET_VISIT_TYPE = gql`
   query GetVisitType($self:Boolean){
     GetVisitType(self:$self){
     label
     value
     }
   }
  `

  const Update_Encounter = gql`
    mutation UpdateEncounter(
      $encounter_id: Int!
      $status: Int
      $remark: String
      $details: JSON
      $is_auto_save: Boolean
    ) {
      UpdateEncounter(
        encounter_id: $encounter_id
        status: $status
        remark: $remark
        details: $details
        is_auto_save: $is_auto_save
      ) {
        encounter_id
        status
        remark
        patient_id
      }
    }
  `;
  const GETLATESTENCOUNTERBYPATIENTID = gql`
    query GetLatestEncounterByPatientId($patientId: Int!) {
      GetLatestEncounterByPatientId(patient_id: $patientId) {
        encounter_id
        patient_id
        doctor_id
        hospital_id
        encounter_startdate
        encounter_enddate
        details
        status
        remark
        is_active
        created_on
        updated_on
        patient_name
        enrollment_date
        current_doctor
        current_hospital
        report_status
        site_number
        city_name
        follow_up
      }
    }
  `;

  const GETLATESTENCOUNTERBYPATIENTIDENCOUNTERID = gql`
    query GetLatestEncounterByPatientIdEncounterId(
      $patientId: Int!
      $encounterId: Int!
    ) {
      GetLatestEncounterByPatientIdEncounterId(
        patient_id: $patientId
        encounter_id: $encounterId
      ) {
        encounter_id
        patient_id
        doctor_id
        hospital_id
        encounter_startdate
        encounter_enddate
        details
        status
        remark
        is_active
        created_on
        updated_on
        patient_name
        enrollment_date
        current_doctor
        current_hospital
        report_status
        user_id
        user_name
        user_hospital
        medication_request_status_enum
        medication_request_status
        site_number
        city_name
        follow_up
      }
    }
  `;

  const DELETE_ENCOUNTER = gql`
    mutation DeleteEncounter($encounterId: ID!, $reason: String!) {
      DeleteEncounter(encounter_id: $encounterId, reason: $reason) {
        encounter_id
      }
    }
  `;

  const useAddEncounter = useMutation(ADD_ENCOUNTER, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { AddEncounter } }) {
      console.log("patient_id", AddEncounter.patient_id);
      return AddEncounter;
      // const existing = cache.readQuery({
      //   query: GET_ENCOUNTERS_BY_PATIENT_ID,
      //   variables: { patientId: AddEncounter.patient_id },
      // });
      // if (existing) {
      //   try {
      //     console.log("patient_id", existing);
      //     const newData = [...existing.GetEncountersByPatientId, AddEncounter];
      //     cache.writeQuery({
      //       query: GET_ENCOUNTERS_BY_PATIENT_ID,
      //       data: { GetEncountersByPatientId: newData },
      //     });
      //     // Handle existing data
      //   } catch (error) {
      //     console.error("Error reading query:", error);
      //   }
      // } else {
      //   apollo_client
      //     .query({
      //       query: GET_ENCOUNTERS_BY_PATIENT_ID,
      //       variables: { patientId: AddEncounter.patient_id },
      //     })
      //     .then(({ data }) => {
      //       if (data && data.GetEncountersByPatientId) {
      //         const newData = [...data.GetEncountersByPatientId, AddEncounter];
      //         console.log("data", newData);
      //         cache.writeQuery({
      //           query: GET_ENCOUNTERS_BY_PATIENT_ID,
      //           data: { GetEncountersByPatientId: newData },
      //         });
      //       }
      //     })
      //     .catch((error) => {
      //       console.error("Error while refetching data:", error);
      //     });
      // }
    },
  });
  const useUpdateEncounter = useMutation(Update_Encounter, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache) {
      console.log("110");
    },
  });

  const useDeleteEncounter = useMutation(DELETE_ENCOUNTER, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache) {
      console.log("110");
    },
  });

  const useGetEncountersByPatientId = useLazyQuery(
    GET_ENCOUNTERS_BY_PATIENT_ID
  );
  const useGetLatestEncounterByPatientId = useLazyQuery(
    GETLATESTENCOUNTERBYPATIENTID
  );
  const useGetLatestEncounterByPatientIdencounterid = useLazyQuery(
    GETLATESTENCOUNTERBYPATIENTIDENCOUNTERID
  );

  const useGetEncounter = useLazyQuery(GET_ENCOUNTER);
  const useGetVisitType = useLazyQuery(GET_VISIT_TYPE);

  return {
    useAddEncounter,
    useGetEncountersByPatientId,
    useGetLatestEncounterByPatientId,
    useGetEncounter,
    useUpdateEncounter,
    useDeleteEncounter,
    useGetLatestEncounterByPatientIdencounterid,
    useGetVisitType
  };
};
