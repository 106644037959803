import { Container, Stack } from "@mui/material";
import { MgpButton, MgpText } from "../../../components/global";
import MgpTextarea from "../../../components/global/MgpTextarea";
import { useMedicationRequest } from "../../../hooks/useMedicationRequest";
import { useState } from "react";

export const MohApproval=({status,requestId,setOpenComment})=>{
    const { useUpdatePatientsMedicationRequestStatus } = useMedicationRequest();
  const [UpdatePatientsMedicationRequestStatus] = useUpdatePatientsMedicationRequestStatus;
  const [message,setMessage]=useState("")
    return (
        <Container>
        <Stack spacing={2} justifyContent="center">
        <MgpText>Please give reason </MgpText>
       <MgpTextarea value={message} handleChange={(e)=>{setMessage(e.target.value)}} minRows={4} placeholder={"Write reason here"}/>
       <MgpButton onClick={()=>{
        UpdatePatientsMedicationRequestStatus({
          variables: {
            requestId: Number(requestId),
            status: status,
            comment: message,
          },
          onCompleted: () => {
            setMessage('')
            setOpenComment(false);
          },
        });
       }}>Submit</MgpButton>
       </Stack>
       </Container>
    )
}