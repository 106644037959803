import { Box, Container, Stack } from "@mui/material";
import { MgpButton, MgpText } from "../../components/global";
import MgpTextarea from "../../components/global/MgpTextarea";
import { useState } from "react";
import { useEncounter } from "../../hooks";
import { useNavigate } from "react-router-dom";
import MgpAlertBar from "../../components/global/MgpAlertBar";

export const DeleteFollowup = ({
    encounterId,
    messageHeading,
    setDeleting,
    setMainPageloading,
    patientid,
}) => {
    const { useGetEncountersByPatientId, useDeleteEncounter } = useEncounter({
        patientId: 1,
    });
    const [DeleteEncounter] = useDeleteEncounter;
    const [
        GetEncountersByPatientId
    ] = useGetEncountersByPatientId;
    const [border, setBorder] = useState(null)
    const [alertOption, setAlertOption] = useState(false);
    const [message, setMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    return (
        <Container>
            <Stack spacing={2} justifyContent="center">
                <MgpText>{messageHeading}</MgpText>
                <Box>
                    <MgpTextarea
                        value={message}
                        border={border}
                        handleChange={(e) => {
                            setMessage(e.target.value);
                        }}
                        minRows={4}
                        placeholder={"Reason"}
                    />
                </Box>
                <MgpButton
                    variant="contained"
                    onClick={() => {
                        if (message !== "") {
                            setBorder(null)
                            setMainPageloading(true)
                            DeleteEncounter({
                                variables: { encounterId: Number(encounterId), reason: message },
                                onCompleted: () => {
                                    setSuccessMessage("Follow-up Deleted Success");
                                    GetEncountersByPatientId({
                                        variables: { patientId: parseInt(patientid) },
                                        fetchPolicy: "cache-and-network",
                                    });
                                    setDeleting(false);
                                    setMainPageloading(false);
                                    setAlertOption(true);
                                    setMessage("CRF Removed Successfully");
                                },
                            });
                        }
                        else {
                            setBorder("red")
                        }
                    }}
                >
                    {"Delete"}
                </MgpButton>
            </Stack>
            <MgpAlertBar
                open={alertOption}
                setOpen={setAlertOption}
                alertType="success"
                message={successMessage}
            />
        </Container>
    );
};
