import { styled } from "@mui/material/styles";
import { AppBar, Container, Toolbar } from "@mui/material";
import { colors } from "../../theme/colors";

const Appbar = styled(AppBar)(({ theme }) => ({}));

export const MgpAppbar = ({ position, children, section }) => {
  // console.log("section", section);
  return (
    <Appbar
      position={position}
      sx={{
        color: colors.BLACK,
        backgroundImage: section === "Footer" && colors.FOOTER,
        backgroundColor: section !== "Footer" && colors.WHITE,
      }}
    >
      {/* <Container maxWidth="xxl"> */}
        <Toolbar
          disableGutters
          style={{ minHeight: "35px" }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "12px",
            padding:"0px 24px 0px 12px"
          }}
        >
          {children}
        </Toolbar>
      {/* </Container> */}
    </Appbar>
  );
};
