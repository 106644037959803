import { Container, Grid } from "@mui/material";
import { MgpButton, MgpDD, MgpTextbox, MgpTextboxFormik } from "../../../components/global";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useCity, useCountry, useMaster } from "../../../hooks";
import { Formik } from "formik";
import { MgpCustomSelect } from "../../../components/global/MgpDropdown";
export const UpdateCity = ({
  setAlertOption,
  setMainPageloading,
  setMessage,
  setAlertType,
  closed = () => { },
  edit = {},
}) => {
  const {
    city_id = "",
    city_name = "",
    country_id = "",
    region_id = "",
    city_code = ""
  } = edit;
  const { useAddCity, useUpdateCity } = useCity();
  const [AddCity] = useAddCity;
  const [UpdateCity] = useUpdateCity;

  const {
    roles,
    cities,
    hospitals,
    countries,
    regions,
    setSelectedCountry,
    genders,
    visit_type,
  } = useMaster();
  return (
    <>
      <Container fluid="true">
        <Formik
          initialValues={{
            cityName: city_name,
            cityId: city_id,
            countryId: country_id,
            regionId: region_id,
            cityCode: city_code
          }}
          validationSchema={yup.object().shape({
            cityName: yup
              .string()
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("City name is required"),
            cityCode: yup.string().required("City code is required"),
            countryId: yup.string().required("Country is required"),
            regionId: yup.string().required("Region is required"),
          })}
          onSubmit={(payload) => {
            setMainPageloading(true);

            if (city_id === "") {
              setMainPageloading(true);
              AddCity({
                variables: {
                  cityName: payload.cityName,
                  countryId: Number(payload.countryId),
                  regionId: Number(payload.regionId),
                  cityCode: payload.cityCode
                },
                onCompleted: () => {
                  setAlertOption(true);
                  setAlertType("success")
                  setMessage("New City Added Successfully");
                  setTimeout(() => {
                    setMainPageloading(false);
                    closed();

                  }, 5000);
                },
                onError: (error) => {
                  if (error?.networkError?.result)
                    setMessage(error.networkError?.result?.errors[0]?.message)
                  else
                    setMessage("Something went wrong! Please try again")
                  setAlertOption(true)
                  setAlertType("error")
                  setTimeout(() => {
                    setMainPageloading(false);

                  }, 5000);
                }
              });
            } else {
              setMainPageloading(true);
              UpdateCity({
                variables: {
                  cityName: payload.cityName,
                  countryId: Number(payload.countryId),
                  cityId: Number(payload.cityId),
                  regionId: Number(payload.regionId),
                  cityCode: payload.cityCode
                },
                onCompleted: () => {
                  setAlertOption(true);
                  setAlertType("success")
                  setMessage("Updated City Successfully");
                  setTimeout(() => {
                    setMainPageloading(false);
                    closed();

                  }, 5000);
                },
                onError: (error) => {
                  if (error?.networkError?.result)
                    setMessage(error.networkError?.result?.errors[0]?.message)
                  else
                    setMessage("Something went wrong! Please try again")
                  setMainPageloading(false)
                  setAlertOption(true)
                  setAlertType("error")
                }
              });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3} sx={{ height: "220px", position: "relative" }}>
                <Grid item md={6}>
                  <MgpTextbox
                    fullWidth
                    size="small"
                    label="City Name"
                    name="cityName"
                    value={values.cityName}
                    onChange={(e)=>{
                      setFieldValue("cityName",e.target.value)
                    }}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    error={Boolean(touched["cityName"] && errors["cityName"])}
                    helperText={touched["cityName"] && errors["cityName"]}
                  />
                </Grid>
                <Grid item md={6}>
                  <MgpTextboxFormik
                    fullWidth
                    size="small"
                    label="City Code"
                    name="cityCode"
                    splits={"cityCode".split(".")}
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    error={Boolean(touched["cityCode"] && errors["cityCode"])}
                    helperText={touched["cityCode"] && errors["cityCode"]}
                  />
                </Grid>
                <Grid item md={6}>
                  <MgpDD
                    label="Country"
                    variant="outlined"
                    size="small"
                    name="countryId"
                    error={Boolean(touched["countryId"] && errors["countryId"])}
                    fullWidth
                    onChange={(e) => {
                      handleChange(e);
                      setSelectedCountry(e.target.value);
                    }}
                    options={countries}
                    helperText={touched["countryId"] && errors["countryId"]}
                    onBlur={handleBlur}
                    value={values["countryId"]}
                    multiselect={false}
                  />
                </Grid>
                <Grid item md={6}>
                  <MgpDD
                    label="Region"
                    variant="outlined"
                    size="small"
                    name="regionId"
                    error={Boolean(touched["regionId"] && errors["regionId"])}
                    fullWidth
                    onChange={(e) => {
                      console.log("12226");
                      handleChange(e);
                      // setSelectedCountry(e.target.value);
                    }}
                    options={regions}
                    helperText={touched["regionId"] && errors["regionId"]}
                    onBlur={handleBlur}
                    value={values["regionId"]}
                    multiselect={false}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  sx={{
                    textAlign: "right !important",
                    position: "absolute",
                    bottom: "0px",
                    right: "0px",
                    display: "flex",
                    gap: "15px",
                  }}
                >
                  <MgpButton onClick={closed} variant="contained">
                    Cancel
                  </MgpButton>
                  <MgpButton type="submit" variant="contained">
                    {!city_id ? "Save" : "Update"}
                  </MgpButton>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Container>
    </>
  );
};
