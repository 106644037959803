import { MultiCascader, Radio, Tag } from "rsuite";
import MgpTextarea from "./MgpTextarea";
import { useState } from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import "../MgpMulticascader.css";
import { fontSize } from "@mui/system";

export const MgpAutoCompleteFreeSolo = ({
  splits,
  name,
  label,
  title,
  touched,
  errors,
  error,
  handleBlur,
  setFieldValue,
  value,
  data,
  dependent,
  required,
  attr,
  size,
  viewMode,
  uncheckedValues,
  options,
  onChange,
  ...rest
}) => {
  //   const [value, setValue] = useState({});
  const [cascadervalue, setCascadervalue] = useState([]);
  // console.log('touched',touched,'errors',errors,'name',name,'values',values);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  return (
    <Autocomplete
      multiple
      id="tags-filled"
      value={value || []} // Default to an empty array if value is undefined
      onChange={
        onChange
        //     (event, newValue) => {
        //     console.log('event, newValue',event, newValue);
        //     // setFieldValue(name, newValue);
        //     if (onChange) {
        //       onChange(event, newValue);
        //     }
        //   }
      }
      options={options.map((option) => option.title)}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <LightTooltip title={option} key={index}>
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          </LightTooltip>
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="filled"
          placeholder={"Please select the medication"}
          onBlur={handleBlur} // Important to mark the field as touched
          //   error={Boolean(touched[name] && errors[name])} // Display error state if there's an error
          //   helperText={touched[name] && errors[name]} // Show error message if there's an error
        />
      )}
      {...rest}
    />
  );
};
