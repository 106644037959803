import { Box, Stack, CircularProgress, Typography } from "@mui/material";
import {
  MgpButton,
  MgpModal,
  MgpTable,
  MgpText,
  TableConfig,
} from "./../components/global";
import { Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useActivityLog, useRbac } from "./../hooks";
import {
  MRT_GlobalFilterTextField,
  useMaterialReactTable,
} from "material-react-table";
import Loader from "./Loader/Loader";
import moment from "moment";
import JSONPretty from "react-json-pretty";
import JSONPrettyMon from "react-json-pretty/dist/acai";
import { width } from "@mui/system";
import { ActivityLogFilter } from "./ActivityLogFilter";
import { JsonToTable } from "react-json-to-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import * as XLSX from "xlsx"
import { ENV } from "../helpers";


export const ActivityLogs = () => {
  const [open, setOpen] = useState(false);
  const [mainPageloading, setMainPageloading] = useState(true);
  const { useGetActivityLogs, useGetActivityLog } = useActivityLog();
  const [payload, setPayload] = useState({});
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const { RbacWrapper } = useRbac();
  const [GetActivityLogs] = useGetActivityLogs;
  const [GetActivityLog] = useGetActivityLog;
  const from = moment().subtract(30, "day").format("YYYY-MM-DD");
  const to = moment().format("YYYY-MM-DD");
  useEffect(() => {
    GetActivityLogs({
      variables: { filters: { from, to } },
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setTableData(data?.GetActivityLogs);
        setMainPageloading(false);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      },
    });
  }, [GetActivityLogs]);

  const handleViewDetails = (activity_log_id) => {
    setMainPageloading(true);
    //setIsLoading(true);
    GetActivityLog({
      variables: { activityLogId: parseInt(activity_log_id) },
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setPayload(data?.GetActivityLog?.payload);
        setOpen(true);
        setMainPageloading(false);
        //setIsLoading(false);
      },
    });
  };

  const handleExportData = (type) => {
    const filteredData = table
      .getFilteredRowModel()
      .rows.map((row) => row.original)
      .map((obj) => {
        const filteredObj = {};
        columns.forEach((key) => {
          if (obj.hasOwnProperty(key.accessorKey)) {
            if (key.accessorKey === "created_on") {
              filteredObj[key.header] = moment(parseInt(obj[key.accessorKey])).format("Do MMM YYYY, h:mm a");
            } else {
              filteredObj[key.header] = obj[key.accessorKey];
            }
          } else {
            if (key.accessorKey === "Details") {
              const activityLogId = obj.activity_log_id;
              const link = `${ENV.react_url}/app/activitylogs/details/${activityLogId}`;
              filteredObj[key.header] = {
                v: `${link}`,
                l: { Target: link, Tooltip: 'Click here to view more details' }
              };
            }
          }
        });
        return filteredObj;
      });

    let dataToExport = filteredData.length ? filteredData : tableData.rows;
    let filename = "Activity Logs";

    if (dataToExport.length) {
      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      // Convert the data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);

      // Calculate column widths based on headers and values
      const maxWidths = [];
      columns.forEach((col, colIndex) => {
        const header = col.header;
        maxWidths[colIndex] = header.length;
      });

      dataToExport.forEach((row) => {
        Object.keys(row).forEach((key, colIndex) => {
          const cellValue = row[key] ? row[key].toString() : '';
          maxWidths[colIndex] = Math.max(maxWidths[colIndex], cellValue.length);
        });
      });
  
      // Ensure the "Details" column width is adjusted based on the values
      columns.forEach((col, colIndex) => {
        if (col.accessorKey === "Details") {
          dataToExport.forEach((row) => {
            const cellValue = row[col.header] ? row[col.header].toString() : '';
            maxWidths[colIndex] = Math.max(maxWidths[colIndex], cellValue.length);
          });
        }
      });
  
      worksheet['!cols'] = maxWidths.map((width) => ({ wch: width + 2 }));

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);


      // Handle file type and download
      if (type === "csv") {
        XLSX.writeFile(workbook, `${filename}.csv`, { bookType: 'csv' });
      } else if (type === "xlsx") {
        XLSX.writeFile(workbook, `${filename}.xlsx`, { bookType: 'xlsx', cellStyles: true });
      } else {
        // downloadPdf(dataToExport, filename);
      }
    }
  };

  const handleChange = (values) => {
    setIsLoading(true);
    GetActivityLogs({
      variables: { filters: values },
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setTableData(data?.GetActivityLogs);
        setIsLoading(false);
      },
    });
  };

  const updateValues = (value) => {
    if (Array.isArray(value)) {
      return value.map(updateValues);
    } else if (typeof value === "object" && value !== null) {
      const updatedObject = {};
      for (const key in value) {
        if (
          key === "created_on" ||
          key === "updated_on" ||
          key === "encounter_startdate" ||
          key === "encounter_enddate"
        ) {
          updatedObject[key] = value[key]
            ? moment(value[key]).format("Do MMM YYYY, h:mm a")
            : "";
        } else if (value.hasOwnProperty(key)) {
          updatedObject[key] = updateValues(value[key]);
        }
      }
      return updatedObject;
    }
    return value;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "created_on", //access nested data with dot notation
        header: "Date & Time",
        Cell: ({ row }) => {
          const formattedDate = moment(
            parseInt(row.original.created_on)
          ).format("Do MMM YYYY, h:mm a");
          return <span>{formattedDate}</span>;
        },
      },
      {
        accessorKey: "created_by_name",
        header: "User",
      },
      {
        accessorKey: "role_name",
        header: "Role",
      },
      {
        accessorKey: "action_name",
        header: "Action",
      },
      {
        accessorKey: "module_name", //normal accessorKey
        header: "Module",
      },
      // {
      //   accessorKey: "created_on", //normal accessorKey
      //   header: "Status",
      // },
      {
        accessorKey: "Details",
        header: "Details",
        Cell: ({ renderedCellValue, row }) => {
          return (
            <Tooltip title={"Click here to view details"} arrow>
              <Typography
                fontSize={12}
                sx={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => handleViewDetails(row?.original.activity_log_id)}
              >
                View Details
              </Typography>
            </Tooltip>
          );
        },
      },
    ],
    []
  );
  const table = useMaterialReactTable({
    columns,
    data: tableData ?? [],
    ...TableConfig,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },

    renderTopToolbar: ({ table }) => (
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: "0.5rem",
          p: "16px 0px",
          justifyContent: "space-between",
        })}
      >
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <MRT_GlobalFilterTextField table={table} />
        </Box>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <MgpButton
            onClick={() => handleExportData("xlsx")}
            variant="contained"
            endIcon={
              <FileDownloadIcon sx={{ fontSize: "16px !important" }} />
            }
          >
            Export Excel
          </MgpButton>

        </Box>

      </Box>
    ),
    state: { isLoading, columnOrder: columns.map((e) => e.accessorKey) },
    renderTableBody: () => {
      return isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null;
    },
  });
  if (mainPageloading) {
    return <Loader open={mainPageloading} />;
  }

  return (
    <>
      <MgpModal
        title="View Details"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <JsonToTable json={updateValues(payload)} />
        {/* <JSONPretty
          id="json-pretty"
          theme={JSONPrettyMon}
          data={payload}
          onJSONPrettyError={(e) => console.error(e)}
          space="4"
          mainStyle="padding:1em"
          style={{ fontSize: "1.1em" }}
          // valueStyle="font-size:1.1em"
          //   replacer={
          //     function (key, value) {
          //         if (key === 'cccc') {
          //             value += '~~~abc';
          //         }
          //         if (key === 'gggg') {
          //             value *=10;
          //         }
          //         return value;
          //     }
          // }
        ></JSONPretty> */}
      </MgpModal>

      <Stack>
        <ActivityLogFilter handleChange={handleChange}></ActivityLogFilter>
        <MgpText variant="h5">Activity Logs</MgpText>
        <MgpTable table={table} />
      </Stack>
    </>
  );
};
