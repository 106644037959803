import { colors } from "../../theme/colors";

export const MgpTableStatus = ({ value = "1", label, colorPending = "" }) => {
  const enumValue = parseInt(value);
  let color = colors.PRIMARY_TEXT;
  if (colorPending !== "") {
    color = "yellow"
  } else {
    switch (enumValue) {
      case 0:
        color = "#DC6069";
        break;
      case 1:
        color = "#4082C1";
        break;
      case 2:
        color = "#54B196";
        break;
      case 3:
        color = "#E53935";
        break;
      case 4:
        color = "#EDAF68";
        break;
      case 5:
        color = "#4082C1";
        break;
      case 6:
        color = "#666666"; // Return to Quality - Blue
        break;
      default:
        color = colors.PRIMARY_DARK;
    }
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "13px",
          height: "13px",
          borderRadius: "50%",
          backgroundColor: color,
          marginRight: "5px",
        }}
      ></div>
      <span>{label}</span>
    </div>
  );
};
