import { gql, useLazyQuery, useMutation } from "@apollo/client";

export const useCode = () => {
  const GET_CODED = gql`
    query getLookupCoded($hospital_id:[Int],$self:Boolean) {
      getLookupCoded(
      hospital_id:$hospital_id
      self:$self
      )
    }
  `;
  const GET_CODE_REQUIRED = gql`
    query getLookupRequired($hospital_id:[Int],$self:Boolean) {
      getLookupRequired(
      hospital_id:$hospital_id
      self:$self
      )
    }
  `;
  const GET_CODE = gql`
    query getLookup($hospital_id:[Int],$self:Boolean) {
      getLookup(
      hospital_id:$hospital_id
      self:$self
      )
    }
  `;
  const GET_CODED_CODE_REQUIRED = gql`
    query getLookupCodedRequired($hospital_id:[Int],$self:Boolean) {
      getLookupCodedRequired(
      hospital_id:$hospital_id
      self:$self
      )
    }
  `;
  const usegetLookupcoded = useLazyQuery(GET_CODED);
  const usegetLookup = useLazyQuery(GET_CODE);
  const usegetLookuprequired = useLazyQuery(GET_CODE_REQUIRED);
  const usegetLookupCodedrequired = useLazyQuery(GET_CODED_CODE_REQUIRED);

  return {
    usegetLookup,
    usegetLookupcoded,
    usegetLookuprequired,
    usegetLookupCodedrequired,
  };
};
