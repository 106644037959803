import styled from "@emotion/styled";
import { Outlet, useNavigate } from "react-router-dom";
import { MgpNavBar } from "./MgpNavBar";
import Navbar from "./Navbar";
import { Footer } from "./Footer";
import { useMaster } from "../../hooks";
const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100vh",
  overflow: "hidden",
  flexDirection: "column",
  width: "100%",
}));

const DashboardLayoutContainer = styled("div")({
  display: "flex",
  flex: 1,
  overflow: "hidden",
});
const DashboardLayoutContent = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",
  overflow: "auto",
  padding: theme.spacing(3),
}));

export const DashboardLayout = () => {
  const navigate = useNavigate();
  const { roles } = useMaster();

  return (
    <DashboardLayoutRoot>
      <div>
        <Navbar />
      </div>
      <DashboardLayoutContainer>
        <DashboardLayoutContent>
          <Outlet />
        </DashboardLayoutContent>
      </DashboardLayoutContainer>
      <div>
        <Footer />
      </div>
    </DashboardLayoutRoot>
  );
};
