import {
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  Popover,
  Stack,
} from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { MgpText } from "./MgpText";
import { Padding } from "./Padding";
import SendIcon from "@mui/icons-material/Send";
import * as yup from "yup";
import { Formik } from "formik";
import { useComment, useNotification } from "../../hooks";
import { useEffect, useState } from "react";
import { MgpTextboxFormik } from "./MgpTextboxFormik";
import { useRbac } from "../../hooks";
import { MgpTextbox } from "./MgpTextbox";
import CircularProgress from "@mui/material/CircularProgress";
import MgpTextarea from "./MgpTextarea";
import { colors } from "../../theme/colors";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonIcon from '@mui/icons-material/Person';
import { localDate } from "./MgpNotification";

export default function MgpComment({
  attr,
  showall,
  disable,
  count,
  setCommentData,
  commentData,
  section,
}) {
  const { RbacWrapper } = useRbac();
  let { name, label, limit } = attr;
  var splits = `${name}`.split(".");
  const [anchorEl, setAnchorEl] = useState(null);
  const [commentMessage, setCommentMessage] = useState("");
  const [loaderComment, setLoaderComment] = useState(false);
  const [submitComment, setSubmitComment] = useState(false);
  const { useGetComments, useAddComment, useUpdateComment, useDeleteComment } =
    useComment({
      encounterId: parseInt(attr.encounterId),
      attributeId: name,
    });
  const { notify } = useNotification();
  const [UpdateComment] = useUpdateComment;
  const [DeleteComment] = useDeleteComment;
  const [AddComment] = useAddComment;
  const [TestPush] = notify;
  const [GetComments, { data, loading, error }] = useGetComments;

  useEffect(() => {
    if (anchorEl) {
      setLoaderComment(true);
      GetComments({
        variables: {
          encounterId: parseInt(attr.encounterId),
          attributeId: name,
        },
        fetchPolicy: "cache-and-network",
        onCompleted: () => {
          setLoaderComment(false);
        },
      });
    }
  }, [anchorEl, count]);

  const handleClick = (event) => {
    setAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleComment = (disable) => {
    if (!disable) {
      setSubmitComment(true);
      AddComment({
        variables: {
          comment: commentMessage,
          encounterId: parseInt(attr.encounterId),
          attributeId: name,
          section: section,
          label: attr.label.endsWith("*")
            ? attr.label?.slice(0, -1)
            : attr.label,
        },
        onCompleted: (data) => {
          setCommentData({
            ...commentData,
            counts: {
              ...commentData.counts,
              [name]: commentData.counts?.[name]
                ? Number(commentData.counts?.[name]) + 1
                : 1,
            },
          });
          setSubmitComment(false);
        },
      });
      setCommentMessage("");
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default behavior of textarea (new line)
      handleComment(disable);
    }
  };
  return (
    <>
      {showall ? (
        <div>
          <IconButton
            aria-describedby={id}
            type="button"
            onClick={handleClick}
            size="small"
          >
            <Badge
              variant={"standard"}
              badgeContent={count ? Number(count) : null}
              color="secondary"
            >
              <ChatBubbleOutlineIcon />
            </Badge>
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <IconButton
              onClick={handleClose}
              sx={{
                color: colors.MENULISTSECONDARY,
                marginBottom: "30px",
                marginLeft: "24px",
                position: "absolute",
                top: 3,
                right: 3,
              }}
            >
              <CancelIcon />
            </IconButton>
            <Padding size="36px 6px 6px 6px">
              <Padding size="4px 4px">
                <Divider />
              </Padding>

              <Stack>
                {/* {console.log("william", data)} */}
                <Stack alignItems={"center"} justifyContent={"center"}>
                  {loaderComment && !data?.GetComments?.length ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </Stack>
                <Stack
                  maxWidth={"320px"}
                  maxHeight={"300px"}
                  sx={{ overflowY: "auto" }}
                  spacing={0.5}
                >
                  {data?.GetComments?.map((elem) => (
                                  
                   <Stack
                    width="100%"
                    sx={{
                      // backgroundColor: '#f6f6f6', 
                      borderRadius:"5px",
                      '&:hover': {
                        backgroundColor: '#f9f9f9', 
                        cursor:"pointer"
                      }}}
                  >
                    <Padding size="3px 5px">  
                    <Grid container >
                      <Grid item xs={12}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                        <Box sx={{backgroundColor:"#D9D9D9",borderRadius:"50px",padding:"3px 5px"}}>
                          <PersonIcon sx={{color:"#404040"}} fontSize="small"/>
                          </Box>
                          <MgpText variant="subtitle2" sx={{
                            "fontWeight":600,
                            "textTransform":"capitalize"
                          }}>{elem.username}</MgpText>
                          <Box flexGrow={1} />
                          <MgpText sx={{fontSize:"11px",fontWeight:600,color:colors.MENULISTSECONDARY}}>     
                            {localDate(elem.created_on)}
                          </MgpText>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Padding size="2px 10px 2px 39px">
                          <MgpText sx={{color:"black"}}>
                            {elem.comment}
                          </MgpText>
                        </Padding>
                      </Grid>
                    </Grid>
                  </Padding>

                  </Stack>

                ))}
                {submitComment?
                <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress color="inherit" size={20}/>
                <MgpText variant="body2" sx={{margin:"15px"}}>Submitting</MgpText>
                </Stack>
                :null}
                </Stack>

                <Grid container md={12}>
                  <Padding size="10px 5px 5px 5px">
                    <Grid item>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={1}
                        justifyContent={"center"}
                      >
                        <Box
                          sx={{
                            backgroundColor: "#D9D9D9",
                            borderRadius: "50px",
                            padding: "3px 5px",
                          }}
                        >
                          <PersonIcon
                            sx={{ color: "#404040" }}
                            fontSize="small"
                          />
                        </Box>

                        <MgpTextarea
                          padding="8px 4px 6px 4px"
                          minRows={1}
                          placeholder="Comment here"
                          width="230px"
                          onKeyPress={handleKeyPress}
                          sx={{ alignSelf: "end", width: "220px" }}
                          error={error}
                          label={label}
                          name={name}
                          value={commentMessage}
                          disabled={disable || submitComment}
                          handleChange={(e) =>
                            setCommentMessage(e.target.value)
                          }
                        />
                        <IconButton
                          aria-describedby={id}
                          type="button"
                          disabled={
                            disable || commentMessage === "" || submitComment
                          }
                          onClick={() => {
                            handleComment(disable);
                          }}
                          // style={{marginTop:"-5px",backgroundColor:colors.PRIMARY_LIGHT,color:"white"}}
                        >
                          <CheckCircleIcon />
                        </IconButton>
                      </Stack>
                    </Grid>
                  </Padding>
                </Grid>
              </Stack>
            </Padding>
          </Popover>
        </div>
      ) : (
        data?.GetComments.length > 0 && (
          <div>
            <IconButton
              aria-describedby={id}
              type="button"
              onClick={handleClick}
            >
              <Badge
                variant={data?.GetComments.length ? "standard" : "dot"}
                badgeContent={data?.GetComments.length}
                color="secondary"
              >
                <ChatBubbleOutlineIcon />
              </Badge>
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Padding size="6px 6px">
                <Stack width={250}>
                  {data?.GetComments.map((elem) => (
                    <Stack
                      width="100%"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Box
                              sx={{
                                backgroundColor: "#D9D9D9",
                                borderRadius: "50px",
                                padding: "3px 5px",
                                marginTop: "-5px",
                              }}
                            >
                              <PersonIcon
                                sx={{ color: "#404040" }}
                                fontSize="small"
                              />
                            </Box>
                            <MgpText variant="body1">{elem.username}</MgpText>
                            <Box flexGrow={1} />
                            <MgpText variant="body2" color="primary">
                              {elem.created_on}
                            </MgpText>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Padding size="2px 32px">
                            <MgpText variant="body2" color="secondary">
                              {elem.comment}
                            </MgpText>
                          </Padding>
                        </Grid>
                      </Grid>
                    </Stack>
                  ))}
                  <Grid container md={12}>
                    <Grid item>
                      <Stack direction={"row"}>
                        <Box
                          sx={{
                            backgroundColor: "#D9D9D9",
                            borderRadius: "50px",
                            padding: "1px 3px",
                          }}
                        >
                          <PersonIcon
                            sx={{ color: "#404040" }}
                            fontSize={"small"}
                          />
                        </Box>

                        <Grid item>
                          <MgpText
                            fullWidth
                            size="small"
                            limit={attr.limit}
                            label={`${label}`}
                            name={`${name}_comment`}
                            values={attr.values}
                            handleChange={attr.handleChange}
                            setFieldValue={attr.setFieldValue}
                            handleBlur={attr.handleBlur}
                            variant="outlined"
                            viewMode={attr.viewMode}
                            splits={splits}
                            attr={attr.attr}
                            touched={attr.touched}
                            errors={attr.errors}
                            comment={"comment"}
                            commentMessage={commentMessage}
                            setCommentMessage={setCommentMessage}
                          />
                        </Grid>
                        <Box flexGrow={1} />
                        <Grid item>
                          <IconButton
                            sx={{ color: colors.PRIMARY }}
                            aria-describedby={id}
                            type="button"
                            onClick={handleComment}
                          >
                            <SendIcon />
                          </IconButton>
                        </Grid>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Padding>
            </Popover>
          </div>
        )
      )}
    </>
  );
}
