import { gql, useMutation, useLazyQuery } from "@apollo/client";

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    Login(username: $username, password: $password) {
      access_token
      default_page
      assigned_hospitals
      user {
        username
        first_name
        last_name
        user_id
        email
        role_id
        role_name
      }
      principal_investigators {
        username
        first_name
        last_name
        user_id
        email
        role_id
        role_name
      }
    }
  }
`;

const USER_IMPERSONATE = gql`
  mutation UserImpersonate($username: String!, $token: String!) {
    UserImpersonate(username: $username, token: $token) {
      access_token
      default_page
      assigned_hospitals
      user {
        username
        first_name
        last_name
        user_id
        email
        role_id
        role_name
      }
      sub_investigator {
        username
        first_name
        last_name
        user_id
        email
        role_id
        role_name
      }
    }
  }
`;

const SIGNUP = gql`
  mutation Mutation($email: String!, $password: String!) {
    Signup(email: $email, password: $password) {
      exp
      type
      token
    }
  }
`;
const CHECK_EMAIL_EXISTENCE = gql`
  query Query($email: String) {
    CheckEmailExistence(email: $email)
  }
`;
const CHECK_USERNAME_EXISTENCE = gql`
query Query($username: String){
CheckUsernameExistence(username:$username)
}
`

const SEND_RESET_PASSWORD_LINK = gql`
  mutation Mutation($username: String!) {
    SendResetPasswordLink(username: $username)
  }
`;
const VERIFY_RESET_TOKEN = gql`
  query Query($token: String!) {
    ValidateResetToken(token: $token) {
      valid
      username
    }
  }
`;

export const useAuth = () => {
  const useLogin = useMutation(LOGIN);
  const useUserImpersonate = useMutation(USER_IMPERSONATE);
  const useSignup = useMutation(SIGNUP);
  const useCheckEmailExistence = useLazyQuery(CHECK_EMAIL_EXISTENCE);
  const useCheckUserExistence = useLazyQuery(CHECK_USERNAME_EXISTENCE);
  const useSendResetPasswordLink = useMutation(SEND_RESET_PASSWORD_LINK);
  const useVerifyResetToken = useLazyQuery(VERIFY_RESET_TOKEN);
  return {
    useLogin,
    useUserImpersonate,
    useSignup,
    useCheckEmailExistence,
    useSendResetPasswordLink,
    useVerifyResetToken,
    useCheckUserExistence
  };
};
