import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { ProcessGrapqlError } from "../helpers";
import { createContext } from "react";

const TransferContext = createContext();

// const DOCTOR_DD = gql`
// query GetDoctors($roleId:Int!) {
//   GetDoctors(role_id:$roleId){
//     value:user_id
//     label: first_name
//   }
// }
// `

export const useTransfer = () => {
  const Add_Transfer_Patient = gql`
    mutation AddTransferRequest(
      $national_id: String!
      $patient_id: Int!
      $current_hospital_id: Int!
      $current_doctor_id: Int!
      $reason_transfer: String!
      $transfer_doctor_id: Int!
      $transfer_hospital_id: Int!
      $file_path: String!
      $verbal_consent: Boolean!
      $consent_date: String!
    ) {
      AddTransferRequest(
        national_id: $national_id
        patient_id: $patient_id
        current_hospital_id: $current_hospital_id
        current_doctor_id: $current_doctor_id
        reason_transfer: $reason_transfer
        transfer_doctor_id: $transfer_doctor_id
        transfer_hospital_id: $transfer_hospital_id
        file_path: $file_path
        verbal_consent: $verbal_consent
        consent_date: $consent_date
      ) {
        id
        requested_user_id
        national_id
        patient_id
        old_registry_no
        transfer_status_enum
        current_hospital_id
        current_doctor_id
        reason_transfer
        transfer_doctor_id
        transfer_hospital_id
        file_path
        created_on
        verbal_consent
        consent_date
        created_by
        updated_by
        created_by_name
        updated_by_name
      }
    }
  `;

  // const Get_Transfer = gql`
  //   query GetAllTransfer($hospital_id:Int) {
  //       GetAllTransfer(hospital_id:$hospital_id) {
  //       id
  //       user_id
  //       patient_id
  //       hospital_ids
  //       transfer_status_enum
  //       patient_name
  //       registration_date
  //       mrn_no
  //       national_id
  //       current_doctor
  //       transfer_doctor
  //       transfer_hospital
  //       current_hospital
  //       current_hospital_id
  //       current_doctor_id
  //       requested_user_id
  //       request_by
  //       old_registry_no
  //       new_registry_no
  //       reason_transfer
  //       transfer_doctor_id
  //       transfer_status
  //       transfer_hospital_id
  //       enrollment_date
  //       file_path
  //       status_comment
  //       verbal_consent
  //       consent_date
  //       approved_on
  //       created_on
  //     }
  //   }
  // `;
  const Get_Transfer_History = gql`
    query GetTransferHistory($patientId: Int!) {
      GetTransferHistory(patient_id: $patientId) {
        id
        user_id
        hospital_ids
        transfer_status_enum
        current_doctor
        transfer_doctor
        transfer_hospital
        current_hospital
        current_hospital_id
        current_doctor_id
        requested_user_id
        request_by
        old_registry_no
        new_registry_no
        reason_transfer
        transfer_doctor_id
        transfer_status
        transfer_hospital_id
        enrollment_date
        file_path
        status_comment
        verbal_consent
        consent_date
        approved_on
        created_on
        created_by
        updated_by
        created_by_name
        updated_by_name
      }
    }
  `;

  const Get_Transfer_Status = gql`
  query PatientTransferStatus($patientId: Int!){
    PatientTransferStatus(patient_id:$patientId){
      transfer_status
      patient_name
      enrollment_date
      current_hospital
      current_doctor
      patient_id
      hospital_id
      doctor_id
      user_id
      user_name
      user_hospital
      patient_registry_no
      approved_on
    }
}
  `;

  const Get_Patient_Details = gql`
    query GetPatientDetailByPatientId($patientId: Int!) {
      GetPatientDetailByPatientId(patient_id: $patientId) {
        patient_name
        enrollment_date
        current_hospital
        current_doctor
        patient_id
        hospital_id
        doctor_id
        user_id
        user_name
        user_hospital
      }
    }
  `;
  const Update_Transfer = gql`
    mutation UpdateTransfer(
      $id: Int!
      $transfer_status: Int
      $status_comment: String
    ) {
      UpdateTransfer(
        id: $id
        transfer_status: $transfer_status
        status_comment: $status_comment
      ) {
        id
        transfer_status
        status_comment
        transfer_status_enum
        approved_on
        new_registry_no
        updated_on
        created_by
        updated_by
        created_by_name
        updated_by_name
      }
    }
  `;

  const Get_Transfer_Hospital = gql`
    query GetTransferByHospital($hospital_id: Int!) {
      GetTransferByHospital(hospital_id: $hospital_id) {
        id
        user_id
        patient_id
        hospital_ids
        transfer_status_enum
        patient_name
        registration_date
        mrn_no
        national_id
        current_doctor
        transfer_doctor
        transfer_hospital
        current_hospital
        current_hospital_id
        current_doctor_id
        requested_user_id
        request_by
        old_registry_no
        new_registry_no
        reason_transfer
        transfer_doctor_id
        transfer_status
        transfer_hospital_id
        enrollment_date
        file_path
        status_comment
        verbal_consent
        consent_date
        approved_on
        created_on
      }
    }
  `;
  const Get_Transfer_Overview = gql`
    query GetOverViewTransfer {
      GetOverViewTransfer {
        site_number
        hospital_name
        no_of_request
        no_of_request_received
        no_of_completed_from
        no_of_completed_to
        no_of_completed_in
        no_of_rejected_request
        no_of_pending_request
        avg_time_completion
      }
    }
  `;
  const Get_Transfer_In = gql`
    query GetTransferIn($hospital_id: Int, $self: Boolean) {
      GetTransferIn(hospital_id: $hospital_id, self: $self) {
        id
        user_id
        patient_id
        hospital_ids
        transfer_status_enum
        patient_name
        registration_date
        mrn_no
        national_id
        current_doctor
        transfer_doctor
        transfer_hospital
        current_hospital
        current_hospital_id
        current_doctor_id
        requested_user_id
        request_by
        old_registry_no
        new_registry_no
        reason_transfer
        transfer_doctor_id
        transfer_status
        transfer_hospital_id
        enrollment_date
        file_path
        status_comment
        verbal_consent
        consent_date
        approved_on
        created_on
        created_by
        updated_by
        created_by_name
        updated_by_name
      }
    }
  `;
  const Get_Transfer_Out = gql`
    query GetTransferOut($hospital_id: Int, $self: Boolean) {
      GetTransferOut(hospital_id: $hospital_id, self: $self) {
        id
        user_id
        patient_id
        hospital_ids
        transfer_status_enum
        patient_name
        registration_date
        mrn_no
        national_id
        current_doctor
        transfer_doctor
        transfer_hospital
        current_hospital
        current_hospital_id
        current_doctor_id
        requested_user_id
        request_by
        old_registry_no
        new_registry_no
        reason_transfer
        transfer_doctor_id
        transfer_status
        transfer_hospital_id
        enrollment_date
        file_path
        status_comment
        verbal_consent
        consent_date
        approved_on
        created_on
        created_by
        updated_by
        created_by_name
        updated_by_name
      }
    }
  `;
  const useAddTransfer = useMutation(Add_Transfer_Patient, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache) {
      // console.log('AddTransferRequest:', AddTransferRequest);
      // Update GetTransferIn
      // try {
      //   const existingIn = cache.readQuery({ query: Get_Transfer_In });
      //   console.log('Existing GetTransferIn:', existingIn);
      //   const newDataIn = [...existingIn?.GetTransferIn, AddTransferRequest];
      //   console.log('Updated GetTransferIn:', newDataIn);
      //   if(existingIn){
      //     cache.writeQuery({
      //       query: Get_Transfer_In,
      //       data: { GetTransferIn: newDataIn },
      //     });
      //   }
      // } catch (e) {
      //   console.error('Error updating GetTransferIn cache', e);
      // }
      // Update GetTransferOut
      // try {
      //   const existingOut = cache.readQuery({ query: Get_Transfer_Out });
      //   console.log('Existing GetTransferOut:', existingOut);
      //   const newDataOut = [...existingOut?.GetTransferOut, AddTransferRequest];
      //   console.log('Updated GetTransferOut:', newDataOut);
      //   if(existingOut){
      //     cache.writeQuery({
      //       query: Get_Transfer_Out,
      //       data: { GetTransferOut: newDataOut },
      //     });
      //   }
      // } catch (e) {
      //   console.error('Error updating GetTransferOut cache', e);
      // }
    },
  });

  const useUpdateTransfer = useMutation(Update_Transfer, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { UpdateTransfer } }) {
      // const existingIn = cache.readQuery({ query: Get_Transfer_In });
      // const newDataIn = existingIn?.GetTransferIn.map((item) =>
      //   item.id !== UpdateTransfer.id ? item : UpdateTransfer
      // );
      // if(existingIn){
      //   cache.writeQuery({
      //     query: Get_Transfer_In,
      //     data: { GetTransferIn: newDataIn },
      //   });
      // }
      // const existingOut = cache.readQuery({ query: Get_Transfer_Out });
      // const newDataOut = existingOut?.GetTransferOut.map((item) =>
      //   item.id !== UpdateTransfer.id ? item : UpdateTransfer
      // );
      // if(existingOut){
      //   cache.writeQuery({
      //     query: Get_Transfer_Out,
      //     data: { GetTransferOut: newDataOut },
      //   });
      // }
    },
  });
  // const useGetTransfer = useLazyQuery(Get_Transfer);
  const useGetTransferHistory = useLazyQuery(Get_Transfer_History);
  const useGetPatient = useLazyQuery(Get_Patient_Details);
  const useGetTransferStatus = useLazyQuery(Get_Transfer_Status);
  const useGetTransferByHospital = useLazyQuery(Get_Transfer_Hospital);
  const useGetTransferOverview = useLazyQuery(Get_Transfer_Overview);
  const useGetTransferIn = useLazyQuery(Get_Transfer_In);
  const useGetTransferOut = useLazyQuery(Get_Transfer_Out);
  return {
    useAddTransfer,
    // useGetTransfer,
    useGetPatient,
    useUpdateTransfer,
    useGetTransferHistory,
    useGetTransferStatus,
    useGetTransferByHospital,
    useGetTransferOverview,
    useGetTransferIn,
    useGetTransferOut,
  };
};
