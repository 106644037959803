import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { colors } from "../../theme/colors";

export const MgpPaper = styled(Paper)(({ theme }) => ({
  "&.MuiPaper-root": {
    background: colors.PRIMARY_CONTRAST,
    height: 200,
    marginTop: 8,
    padding: 0,
  },
  "& .Mui-selected": { color: colors.GOLD, background: "#fff" },
  "& .MuiMenu-list": {
    paddingTop: "0px",
    paddingBottom: "0px",
  },

  "& li": {
    padding: "3px 6px",
    display: "flex",
    gap: "10px",
    fontSize: "14px",
    justifyContent: "start",
    alignItems: "center",
    "& span": {
      flexGrow: "2",
    },

    "& svg": {
      color: "currentColor",
      fontSize: "16px",
    },
  },
  "& li[aria-selected=`true`]": {
    backgroundColor: "#FFF",
    fontWweight: 300,
    color: `${colors.GOLD}`,
    "& svg": {
      color: `${colors.GOLD}`,
    },
  },
  "& li.focused": {
    backgroundColor: colors.GOLD,
    cursor: "pointer",
    "& svg": {
      color: colors.GOLD,
    },
  },
  "& li:hover": {
    color: colors.GOLD,
    cursor: "pointer",
    "& svg": {
      color: colors.GOLD,
    },
  },
  ".MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected": {
    backgroundColor: "#FFF",
  },
  ".MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected:hover":
    {
      backgroundColor: "#F5F5F5",
    },
  ".MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected:focus":
    {
      backgroundColor: "#F5F5F5",
    },
  ".MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected:active":
    {
      backgroundColor: "#FFF",
    },
}));
