import React, { useState, useEffect, useMemo } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Box,
  Paper,
  Stack,
  Container,
  CircularProgress,
} from "@mui/material";
import Loader from "./Loader/Loader";
import { Uploader } from "rsuite";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { Formik } from "formik";
import { Link, useParams } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CreateIcon from "@mui/icons-material/Create";
import { Alert } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  generateDynamicComponent,
  getSectionForm,
  useMedicationRequest,
  useUtil,
  useMaster,
  usePatient,
  useTransfer,
} from "../hooks";
import * as Yup from "yup";
import { colors } from "../theme/colors";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { ENV } from "../helpers/configs";
import {
  MgpButton,
  MgpModal,
  MgpDD,
  MgpText,
  MgpTextboxFormik,
  MgpTextbox,
  TableConfig,
  MgpTable,
  Padding,
} from "../components/global";

import { useMaterialReactTable } from "material-react-table";
import { useEncounter, useRbac } from "../hooks";
import { MohApproval } from "./masters/MedicationRequests/MohApproval";
import { MgpUploadButton } from "../components/global/MgpUploadButton";

import { useUser } from "../hooks/useUsers";
import { useFileUpload } from "../hooks/useFileUpload";
import axios from "axios";
import { MgpAutoCompleteFreeSolo } from "../components/global/MgpAutoCompleteFreeSolo";

export default function MedicationRequest({
  edit,
  obj,
  closed,
  handleClose,
  from,
  setMedicationArray,
  request_id,
  dataSelected,
}) {
  console.log("obj", obj);
  const [isLoading, setIsLoading] = useState(true);
  const [mainPageloading, setMainPageloading] = useState(true);
  const CURRENT_PAGE_KEY = "MedicationRequest";
  const [forms, setForms] = useState([]);
  const [medicationtRows, setMedicationtRows] = useState([]);

  const [value, setValue] = React.useState("Visit History");
  const [open, setOpen] = useState(false);
  const { useGetEncountersByPatientId } = useEncounter({
    patientId: 1,
  });
  const [openComment, setOpenComment] = useState(false);
  const [status, setStatus] = useState(0);
  const [disableForm, setDisableForm] = useState(false);

  const {
    useUpdatePatientsMedicationRequestStatus,
    useGetMedicationVote,
    useGetMedicationRequests,
    useAddMedicationRequests,
    useGetLastMedicationRequest,
  } = useMedicationRequest();
  const [UpdatePatientsMedicationRequestStatus] =
    useUpdatePatientsMedicationRequestStatus;

  //? Handle Approve by Moh admin

  const handleApprove = () => {
    UpdatePatientsMedicationRequestStatus({
      variables: {
        requestId: request_id,
        status: 3,
      },
      onCompleted: () => {
        handleClose();
      },
    });
  };
  const handleReject = () => {
    setStatus(4);
    setOpenComment(true);
  };

  const handleReturnDoctor = () => {
    setStatus(5);
    setOpenComment(true);
  };
  const handleSecondVote = () => {
    setStatus(6);
    setOpenComment(true);
  };
  const params = useParams();

  const [
    GetMedicationVote,
    {
      data: medicationVoteData,
      loading: medicationVoteLoading,
      error: medicationVoteError,
    },
  ] = useGetMedicationVote;

  const medicationColumns = useMemo(
    () => [
      {
        accessorKey: "medication_type", //access nested data with dot notation
        header: "Medication Type",
        width: 500,
      },
      {
        accessorKey: "medicine", //normal accessorKey
        header: "Medication Name",
      },
      {
        accessorKey: "dosage", //normal accessorKey
        header: "Dosage",
      },
      {
        accessorKey: "regimen", //normal accessorKey
        header: "Regimen",
      },
      {
        accessorKey: "comment", //normal accessorKey
        header: "Medication Request Note",
      },
      {
        accessorKey: "old_medicine", //normal accessorKey
        header: "Old Medicine",
      },
      {
        accessorKey: "new_medicine", //normal accessorKey
        header: "New Medicine",
      },
      {
        accessorKey: "other_regimen", //normal accessorKey
        header: "Other Regimen",
      },
      {
        accessorKey: "notes", //normal accessorKey
        header: "Notes",
      },
      {
        accessorKey: "reason_for_medication", //normal accessorKey
        header: "Reason For Medication",
      },
    ],
    []
  );

  const { useNationalIdCheck } = usePatient();
  const [IsNationalIdExists, { data, loading, error }] = useNationalIdCheck;
  const { useGetPatient } = useTransfer();
  const [
    GetPatientDetailByPatientId,
    {
      data: dataViewPatient,
      loading: loadingViewPatient,
      error: errorViewPatient,
    },
  ] = useGetPatient;

  const [
    GetLastMedicationRequest,
    {
      data: getLastMedicationRequestsData,
      loading: loadinggetLastMedicationRequests,
      error: errorgetLastMedicationRequests,
    },
  ] = useGetLastMedicationRequest;
  const { useGetDoctorUser } = useUser();
  const [
    GetDoctors,
    { data: doctorData, loading: loadingDoctorList, error: errorDoctorList },
  ] = useGetDoctorUser;
  const [initialValues, setInitialValues] = useState({
    medication_type: "Start",
    old_medicine: [],
    new_medicine: [],
    comment: "",
    reason: "",
    regimen: "",
    other_regimen: "",
  });

  useEffect(() => {
    let newArr = [];
    dataSelected?.map((item) => {
      newArr.push({
        reason_for_medication: item["reason_for_medication"],
        notes: item["notes"],
        regimen: item["regimen"],
        medication_type: item["medication_type"],
        other_regimen: item["other_regimen"],
        old_medicine: item["old_medicine"],

        new_medicine: item["new_medicine"],
        dosage: item["dosage"],
        medicine: item?.["medicine"],
      });
    });
    setMedicationtRows(newArr);
    if (from === "MEDICATION_HISTORY") setDisableForm(true);
  }, []);

  useEffect(() => {
    if (getLastMedicationRequestsData || error) {
      setMainPageloading(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [getLastMedicationRequestsData]);
  const votingColumns = useMemo(() => [
    {
      accessorKey: "user_name",
      header: "Committee User",
    },
    {
      accessorKey: "created_on",
      header: "Voting Date",
    },
    {
      accessorKey: "status",
      header: "Voting Status",
    },
    {
      accessorKey: "comment",
      header: "Rejection Reason",
    },
  ]);
  // React.useEffect(() => {
  //   GetMedicationVote({
  //     variables: { request_id: request_id },
  //   });
  // }, [GetMedicationVote]);

  React.useEffect(() => {
    if (
      obj?.["basicVisit_typeofvisit"] !== "Baseline Visit" &&
      obj?.["details"]?.["basicVisit"]?.["typeofvisit"] !== "Baseline Visit"
    ) {
      GetLastMedicationRequest({
        variables: { patientId: obj?.["patient_id"] },
      });
    }
  }, [GetLastMedicationRequest]);

  const table = useMaterialReactTable({
    columns: medicationColumns,
    data: medicationtRows,
    ...TableConfig,
    enableRowActions: true,
    state: { columnOrder:medicationColumns.map((e)=>e.accessorKey) },
    renderTopToolbar: ({ table }) => (
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: "0.5rem",
          p: "16px 0px",
          justifyContent: "flex-end",
        })}
      ></Box>
    ),
    muiSearchTextFieldProps: ({ table }) => {},
    renderRowActions: ({ row, index }) => {
      if (!disableForm) {
        return (
          <Box sx={{ display: "flex", alignItems: "center", width: 200 }}>
            <IconButton>
              <CreateIcon
                fontSize="6px"
                color="primary"
                onClick={() => {
                  console.log("row", row);

                  console.log("log");
                }}
              />
            </IconButton>

            <IconButton>
              <DeleteIcon
                fontSize="6px"
                color="primary"
                onClick={() => {
                  console.log("log", row, index);

                  let newArr = medicationtRows.filter(
                    (_, idx) => idx !== row?.index
                  );

                  setMedicationtRows(newArr);
                  document.body.scrollTop =
                    document.documentElement.scrollTop = 0;

                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              />
            </IconButton>
          </Box>
        );
      }
    },
  });

  const tableVoting = useMaterialReactTable({
    columns: votingColumns,
    data: medicationVoteData?.GetMedicationVote ?? [],
    ...TableConfig,
    enableRowActions: true,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
  });
  const { hospitals } = useMaster();
  // const FROM = "MEDICATION_REQUEST";

  const validationSchema = Yup.object({
    old_medicine: Yup.array()
      .of(Yup.string())
      .required(`Request Type is required`)
      .min(1),
    new_medicine: Yup.array()
      .of(Yup.string())
      .required(`Request Type is required`)
      .min(1),
    medication_type: Yup.string().required("Medication Type Id is required"),
    notes: Yup.string().required("comment is required"),
    reason_for_medication: Yup.string().required("Reason is required"),
    regimen: Yup.string().required("Regimen is required"),
  });

  // const validationSchema = Yup.object({
  //   MedicationRequest_requesttype: Yup.array()
  //     .of(Yup.string())
  //     .required(`Request Type is required`)
  //     .min(1),
  //   MedicationRequest_startmedicationname: Yup.string().required(
  //     "MedicationRequest_startmedicationname Id is required"
  //   ),
  //   MedicationRequest_reasonToStart:
  //     Yup.string().required("Reason is required"),
  //   MedicationRequest_note: Yup.string().required("Notes is required"),
  //   MedicationRequest_oldmedicationname: Yup.string().required(
  //     "Old Medication is required"
  //   ),
  //   MedicationRequest_newmedicationname: Yup.string().required(
  //     "New Medication is required"
  //   ),
  //   MedicationRequest_reasonToSwitch: Yup.string().required(
  //     "Reason to Switch is required"
  //   ),
  //   MedicationRequest_adjustmedicationname: Yup.string().required(
  //     "Adjust Medicine is required"
  //   ),
  //   MedicationRequest_reasonToAdjust: Yup.string().required(
  //     "Reason to Adjust is required"
  //   ),
  //   MedicationRequest_regimen: Yup.string().required("Regimen is required"),

  //   other_regimen: Yup.string().required("Other Regimen is required"),
  //   MedicationRequest_reasonToRefill: Yup.string().required(
  //     "Reason to Refill is required"
  //   ),
  // });
  const regimenList = [
    { label: "Once daily", value: "Once daily" },
    { label: "Twice a day", value: "Twice a day" },
    { label: "Thrice daily", value: "Thrice daily" },
    { label: "Other, please specify", value: "Other, please specify" },
  ];

  const handleReset = (resetForm) => {
    resetForm({ ...initialValues });
  };
  const [AddMedicationRequests] = useAddMedicationRequests;

  const handleSubmitData = (values) => {
    if (setMedicationArray !== undefined) {
      setMedicationArray(medicationtRows);
      handleClose();
    } else {
      AddMedicationRequests({
        variables: {
          encounterId: obj?.["encounter_id"],
          requests: medicationtRows,
        },
        onCompleted: (res) => {
          console.log("AddMedicationRequests", res);
          alert("Medication request sent successfully");

          closed();
        },
      });
    }
  };
  const medicationList = [
    {
      title: "Acetylcholinesterase Inhibitors->Pyridostigmine->30mg",
    },
    {
      title: "Acetylcholinesterase Inhibitors->Pyridostigmine->120mg",
    },
    {
      title: "Acetylcholinesterase Inhibitors->Neostigmine->30mg",
    },
    {
      title: "Acetylcholinesterase Inhibitors->Neostigmine->120mg",
    },
    {
      title: "Acetylcholinesterase Inhibitors->Edrophonium->30mg",
    },
    {
      title: "Acetylcholinesterase Inhibitors->Edrophonium->120mg",
    },
    {
      title: "Corticosteroids Treatment",
    },
    {
      title: "Immunosuppressive Treatment->Azathioprine->50mg",
    },
    {
      title: "Immunosuppressive Treatment->Azathioprine->100mg",
    },
    {
      title:
        "Immunosuppressive Treatment->Mycophenolate ​​Mofetil->250mg to 500 mg",
    },
    {
      title: "Immunosuppressive Treatment->Tacrolimus->0.5mg to 5​​mg",
    },
    {
      title: "Immunosuppressive Treatment->Cyclosporine->25mg to 100​​mg",
    },
    {
      title: "Immunosuppressive Treatment->Methotrexate->2.5mg to 15mg",
    },
    {
      title: "Immunosuppressive Treatment->Cyclophosphamide->0.5mg to 5​mg",
    },
    {
      title: "Immunosuppressive Treatment->Tacrolimus->0.5mg to 5​​mg",
    },
    {
      title: "Immunosuppressive Treatment->Rituximab->10mg",
    },
    {
      title: "Immunosuppressive Treatment->Rituximab->10ml",
    },
    {
      title: "Immunosuppressive Treatment->Rituximab->50ml",
    },
    {
      title: "Immunosuppressive Treatment->C5 Inhibitor, Eculizumab->900mg",
    },
    {
      title: "Immunosuppressive Treatment->C5 Inhibitor, Eculizumab->1200mg",
    },
    {
      title: "Immunosuppressive Treatment->C5 Inhibitor, Eculizumab->2400mg",
    },
    {
      title: "Immunosuppressive Treatment->C5 Inhibitor, Eculizumab->3600mg",
    },
    {
      title: "Immunosuppressive Treatment->C5 Inhibitor, Eculizumab->16.6mg",
    },
    {
      title: "Immunosuppressive Treatment->C5 Inhibitor, Eculizumab->32.4mg",
    },
    {
      title:
        "Immunosuppressive Treatment->FcRn Inhibitors, Efgartigimod alfa->10 mg/kg to 1200mg",
    },
    {
      title:
        "Immunosuppressive Treatment->FcRN Inhibitors, Rozanolixizumab->420mg",
    },
    {
      title:
        "Immunosuppressive Treatment->FcRN Inhibitors, Rozanolixizumab->840mg",
    },
    {
      title: "Other->Other_textbox",
    },
  ];
  const handleCloseBackdrop = () => {
    // setOpenBackdrop(false);
  };
  const handleOpenBackdrop = () => {
    // setOpenBackdrop(true);
  };
  const { RbacWrapper } = useRbac();

  const fetchPatientDetails = (val, setFieldValue, values) => {
    IsNationalIdExists({
      variables: { nationalId: val.toString() },
      onCompleted: (data) => {
        if (data?.IsNationalIdExists) {
          console.log("The data In ", data.IsNationalIdExists);
          GetPatientDetailByPatientId({
            variables: { patientId: parseInt(data.IsNationalIdExists) },
            onCompleted: (data) => {
              console.log("The data in useGetPatient is", data);
              setFieldValue(
                "patient_name",
                data.GetPatientDetailByPatientId.patient_name
              );
              setFieldValue(
                "current_doctor",
                data.GetPatientDetailByPatientId.current_doctor
              );
              setFieldValue(
                "current_hospital",
                data.GetPatientDetailByPatientId.current_hospital
              );
              setFieldValue(
                "enrollment_date",
                data.GetPatientDetailByPatientId.enrollment_date
              );
              setFieldValue(
                "patient_id",
                data.GetPatientDetailByPatientId.patient_id
              );
              setFieldValue(
                "current_doctor_id",
                data.GetPatientDetailByPatientId.doctor_id
              );
              setFieldValue(
                "current_hospital_id",
                data.GetPatientDetailByPatientId.hospital_id
              );
            },
          });
          // handleCloseBackdrop();
        } else {
          console.log("The patient is not registered", data);
        }
      },
    });
  };
  const handleChangeNationalId = (val, setFieldValue, values) => {
    console.log("the handle CHANGE ");
    // handleOpenBackdrop();
    setTimeout(() => {
      fetchPatientDetails(val, setFieldValue, values);
    }, 500);
    setFieldValue("national_id", val);
  };
  // const {handleFileUpload} = useFileUpload();
  const handleUpload = async (files) => {
    // Currently not using directly calling in Uploader Action
    try {
      const formData = new FormData();
      formData.append("file", files);
      const response = await axios.post(`${ENV.file_upload_url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      console.log("The error happened in file upload is", err);
    }
  };

  const TableWithActions = ({ resetForm, setMedicationtRows, config }) => {
    const table = useMaterialReactTable(config);

    return <MgpTable table={table} />;
  };
  // if (mainPageloading) {
  //   return <Loader open={mainPageloading} />;
  // }
  return (
    <Container fluid>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          old_medicine: Yup.array()
            .of(Yup.string())
            .required(`Request Type is required`)
            .min(1),
          new_medicine: Yup.array()
            .of(Yup.string())
            .required(`Request Type is required`)
            .min(1),
          medication_type: Yup.string().required(
            "Medication Type Id is required"
          ),
          notes: Yup.string().required("comment is required"),
          reason_for_medication: Yup.string().required("Reason is required"),
          regimen: Yup.string().required("Regimen is required"),
        })}
      >
        {({
          error,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          value,
          values,
          errors,
          resetForm,
        }) => {
          const config = {
            columns: medicationColumns,
            data: getLastMedicationRequestsData?.GetLastMedicationRequest,
            ...TableConfig,
            enableRowActions: true,
            renderTopToolbar: ({ table }) => (
              <Box
                sx={(theme) => ({
                  display: "flex",
                  gap: "0.5rem",
                  p: "16px 0px",
                  justifyContent: "flex-end",
                })}
              ></Box>
            ),
            muiSearchTextFieldProps: ({ table }) => {},
            renderRowActions: ({ row, index }) => {
              return (
                <Box sx={{ display: "flex", alignItems: "center", width: 200 }}>
                  <IconButton>
                    <CreateIcon
                      fontSize="6px"
                      color="primary"
                      onClick={() => {
                        console.log("row", row.original);

                        resetForm({
                          values: {
                            medication_type: row.original?.["medication_type"],
                            old_medicine: [
                              row?.original?.medicine +
                                "->" +
                                row?.original?.dosage,
                            ],
                            regimen: row?.original?.["regimen"],
                            reason: row?.original?.["reason"],
                            comment: row?.original?.["comment"],
                          },
                        });
                      }}
                    />
                  </IconButton>

                  <IconButton>
                    <DeleteIcon
                      fontSize="6px"
                      color="primary"
                      onClick={() => {
                        // console.log("log", row, index);

                        let newArr = medicationtRows.filter(
                          (_, idx) => idx !== row?.index
                        );

                        setMedicationtRows(newArr);
                        document.body.scrollTop =
                          document.documentElement.scrollTop = 0;

                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                    />
                  </IconButton>
                </Box>
              );
            },
            state: { isLoading },
            renderTableBody: () => {
              return isLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : null;
            },
          };
          return (
            <form onSubmit={handleSubmit}>
              <>
                {!disableForm ? (
                  <>
                    <Grid container spacing={3}>
                      {obj?.["basicVisit_typeofvisit"] !== "Baseline Visit" &&
                        obj?.["details"]?.["basicVisit"]?.["typeofvisit"] !==
                          "Baseline Visit" && (
                          <Grid item xs={12} sm={6}>
                            <MgpDD
                              label="Request Type"
                              variant="outlined"
                              size="small"
                              name="medication_type"
                              fullWidth
                              onChange={handleChange}
                              options={[
                                { label: "Start", value: "Start" },
                                { label: "Adjust", value: "Adjust" },
                                { label: "Switch", value: "Switch" },
                                { label: "Refill", value: "Refill" },
                              ]}
                              helperText={
                                touched["medication_type"] &&
                                errors["medication_type"]
                              }
                              handleBlur={handleBlur}
                              value={values["medication_type"]}
                              multiselect={false}
                              onClick={() => {
                                // console.log("values", values);
                              }}
                              touched={touched}
                              errors={errors}
                            />
                          </Grid>
                        )}
                      <Grid item xs={12} sm={6}>
                        <MgpAutoCompleteFreeSolo
                          label={
                            values["medication_type"] === "Adjust" ||
                            values["medication_type"] === "Switch"
                              ? "Old Medication"
                              : "Start Medication"
                          }
                          variant="outlined"
                          size="small"
                          name="old_medicine"
                          error={Boolean(
                            touched["old_medicine"] && errors["old_medicine"]
                          )}
                          fullWidth
                          onChange={
                            (e, val) => {
                              // console.log("e", e, "val", val);
                              // handleChange("MedicationRequest_startmedicationname",val)
                              setFieldValue("old_medicine", val);
                            }
                            //     (e) => {
                            //   GetDoctors({
                            //     variables: { hospitalId: Number(e.target.value) },
                            //     onCompleted: ({ GetDoctors: doctorData }) => {
                            //       handleChange(e);
                            //     },
                            //   });
                            // }
                          }
                          options={medicationList}
                          helperText={
                            touched["old_medicine"] && errors["old_medicine"]
                          }
                          onBlur={handleBlur}
                          value={values["old_medicine"]}
                          multiselect={false}
                        />
                      </Grid>
                      {(values["medication_type"] === "Adjust" ||
                        values["medication_type"] === "Switch") && (
                        <Grid item xs={12} sm={6}>
                          <MgpAutoCompleteFreeSolo
                            label={
                              values["medication_type"] === "Adjust"
                                ? "Adjust Medication"
                                : "Switch Medication"
                            }
                            variant="outlined"
                            size="small"
                            name="new_medicine"
                            error={Boolean(
                              touched["new_medicine"] && errors["new_medicine"]
                            )}
                            fullWidth
                            onChange={(e, val) => {
                              // console.log("e", e, "val", val);
                              setFieldValue("new_medicine", val);
                            }}
                            options={medicationList}
                            helperText={
                              touched["new_medicine"] && errors["new_medicine"]
                            }
                            onBlur={handleBlur}
                            value={values["new_medicine"]}
                            multiselect={false}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} sm={6}>
                        <MgpDD
                          label="Medication Regimen"
                          variant="outlined"
                          size="small"
                          name="regimen"
                          fullWidth
                          onChange={handleChange}
                          options={regimenList}
                          helperText={touched["regimen"] && errors["regimen"]}
                          handleBlur={handleBlur}
                          value={values["regimen"]}
                          multiselect={false}
                          onClick={() => {
                            // console.log("values", values);
                          }}
                          touched={touched}
                          errors={errors}
                        />
                      </Grid>
                      {values["regimen"] === "Other, please specify" && (
                        <Grid item xs={12} sm={6}>
                          <MgpTextboxFormik
                            fullWidth
                            size="small"
                            label="Other Regimen"
                            name="other_regimen"
                            // splits={"enrollment_date".split(".")}
                            values={values}
                            // disabled
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            variant="outlined"
                            helperText={
                              touched["other_regimen"] &&
                              errors["other_regimen"]
                            }
                            touched={touched}
                            errors={errors}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <MgpTextboxFormik
                          fullWidth
                          size="small"
                          label={
                            values["medication_type"] === "Adjust"
                              ? "Reason For Adjust Medication"
                              : values["medication_type"] === "Switch"
                              ? "Reason For Switch Medication"
                              : values["medication_type"] === "Refill"
                              ? "Reason For Refill Medication"
                              : "Reason For Start Medication"
                          }
                          name="reason_for_medication"
                          // splits={"enrollment_date".split(".")}
                          values={values}
                          // disabled
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          variant="outlined"
                          helperText={
                            touched["reason_for_medication"] &&
                            errors["reason_for_medication"]
                          }
                          touched={touched}
                          // errors={errors}
                          error={error}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MgpTextboxFormik
                          fullWidth
                          size="small"
                          label="Notes"
                          name="notes"
                          // splits={"enrollment_date".split(".")}
                          values={values}
                          // disabled
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          variant="outlined"
                          helperText={touched["notes"] && errors["notes"]}
                          touched={touched}
                          // errors={errors}
                          error={error}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Grid>
                          <MgpText
                            variant="h4"
                            sx={{
                              marginBottom: "10px",
                            }}
                          >
                            Upload
                          </MgpText>

                          <Uploader
                            draggable
                            disabled={disableForm}
                            style={{
                              marginTop: "0.3rem",
                            }}
                            // listType='text'
                            multiple={false}
                            // onChange={handleUpload}
                            action={`${ENV.file_upload_url}`}
                            onSuccess={(response, file, event, xhr) => {
                              setFieldValue("file_path", response.object_name);
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                height: 100,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <span>Click or Drag files</span>
                            </div>
                          </Uploader>
                        </Grid>
                      </Grid>
                      <Grid container direction="row">
                        <Padding size="20px 20px">
                          <MgpButton
                            variant="contained"
                            sx={{ marginLeft: "5px", marginBottom: "5px" }}
                            // type="submit"
                            onClick={() => {
                              // handleSubmitData(values);
                              console.log("values", values);
                              // let temp =
                              //   values["medication_type"] === "Adjust" ||
                              //   values["medication_type"] === "Switch"
                              //     ? values?.["new_medicine"][0].split("->")
                              //     : values?.["old_medicine"][0].split("->");
                              setMedicationtRows([
                                ...medicationtRows,
                                {
                                  encounter_id: setMedicationArray
                                    ? ""
                                    : obj?.["encounter_id"],
                                  reason_for_medication:
                                    values["reason_for_medication"],
                                  notes: values["notes"],
                                  regimen: values["regimen"],
                                  medication_type: values["medication_type"],
                                  other_regimen: values["other_regimen"],
                                  old_medicine:
                                    values["medication_type"] === "Adjust" ||
                                    values["medication_type"] === "Switch"
                                      ? values["old_medicine"][0]
                                      : "",
                                  new_medicine:
                                    values["medication_type"] === "Adjust" ||
                                    values["medication_type"] === "Switch"
                                      ? values["new_medicine"][0]
                                      : "",
                                  // dosage: temp[2],
                                  medicine:
                                    values["medication_type"] === "Adjust" ||
                                    values["medication_type"] === "Switch"
                                      ? values?.["new_medicine"][0]
                                      : values?.["old_medicine"][0],
                                },
                              ]);

                              console.log("valuesssss", medicationtRows);

                              resetForm({
                                values: {
                                  medication_type:
                                    obj?.["basicVisit_typeofvisit"] !==
                                      "Baseline Visit" &&
                                    obj?.["details"]?.["basicVisit"]?.[
                                      "typeofvisit"
                                    ] !== "Baseline Visit"
                                      ? "Start"
                                      : "",
                                  old_medicine: [],
                                  new_medicine: [],
                                  reason_for_medication: "",
                                  notes: "",
                                  regimen: "",
                                },
                              });
                            }}
                            disabled={disableForm}
                          >
                            Add
                          </MgpButton>
                        </Padding>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}

                <Grid container spacing={5}>
                  <Grid item xs={12} sm={12}>
                    {medicationtRows && <MgpTable table={table}></MgpTable>}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {getLastMedicationRequestsData?.GetLastMedicationRequest
                      .length > 0 && (
                      <TableWithActions
                        resetForm={resetForm}
                        setMedicationtRows={setMedicationtRows}
                        config={config}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            </form>
          );
        }}
      </Formik>

      <RbacWrapper module={"medication"} code={"medication_request_approval"}>
        <Stack>
          <MgpText variant="h4">Voting History</MgpText>
          <MgpTable table={tableVoting} />
        </Stack>
        <Grid item md={12}>
          <React.Fragment>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end", // Center the buttons horizontally
                alignItems: "center",
                pt: 2,
              }}
            >
              <MgpButton
                onClick={() => {
                  handleReturnDoctor();
                  handleClose();
                }}
              >
                Return to Doctor
              </MgpButton>

              <MgpButton
                onClick={() => {
                  handleSecondVote();
                  handleClose();
                }}
              >
                Call for runoff vote
              </MgpButton>

              <MgpButton
                onClick={() => {
                  handleReject();
                }}
              >
                Reject Request
              </MgpButton>

              <MgpButton
                onClick={() => {
                  handleApprove();
                }}
              >
                Approve Request
              </MgpButton>
            </Box>
          </React.Fragment>
        </Grid>
      </RbacWrapper>
      <RbacWrapper module={"medication"} code={"add_mr"}>
        {!disableForm ? (
          <>
            <Grid item md={12}>
              <React.Fragment>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end", // Center the buttons horizontally
                    alignItems: "center",
                    pt: 2,
                  }}
                >
                  <MgpButton onClick={handleClose}>Cancel Request</MgpButton>

                  <MgpButton
                    type="submit" // Ensure this button submits the form
                    onClick={() => {
                      handleSubmitData();
                    }}
                  >
                    Submit Request
                  </MgpButton>
                </Box>
              </React.Fragment>
            </Grid>
          </>
        ) : (
          <>
            <Grid item md={12}>
              <React.Fragment>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end", // Center the buttons horizontally
                    alignItems: "center",
                    pt: 2,
                  }}
                >
                  <MgpButton onClick={handleClose}>Close</MgpButton>
                </Box>
              </React.Fragment>
            </Grid>
          </>
        )}
      </RbacWrapper>

      <MgpModal
        open={openComment}
        onClose={() => {
          setOpenComment(false);
        }}
      >
        <MohApproval
          status={status}
          requestId={request_id}
          setOpenComment={setOpenComment}
        />
      </MgpModal>
    </Container>
  );
}
