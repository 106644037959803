import * as React from "react";
import { MgpButton, MgpText, MgpTextboxFormik } from "../components/global";
import { Container, Grid } from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { useUser } from "../hooks/useUsers";
import { MgpBackdrop } from "../components/global/MgpBackdrop";

export const UpdatePassword = ({
  handleClose,
  setAlertOption,
  setAlertType,
  setMessage }) => {
  const { useUpdatePassword } = useUser();
  const [UpdatePassword] = useUpdatePassword;
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [loading, setLoading] = React.useState(false)
  return (
    <Container fluid="true">
      <Formik
        initialValues={{
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={yup.object().shape({
          newPassword: yup
            .string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
          confirmPassword: yup
            .string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        })}
        onSubmit={(payload) => {

          if (payload.newPassword != payload.confirmPassword) {
            setMessage("Confirm password does not match!");
            setAlertOption(true);
            setAlertType("error")
          } else {
            setLoading(true)
            setOpenBackdrop(true);
            UpdatePassword({
              variables: {
                password: payload.confirmPassword,
              },
              onCompleted: () => {
                setOpenBackdrop(false);
                handleClose();
                setMessage("Password changed successfully")
                setAlertOption(true);
                setAlertType("success")
                setLoading(false);
              },
              onError: ({ error }) => {
                console.log("error", JSON.stringify(error));
                setAlertOption(true);
                setAlertType("error")
                setMessage("Can't able to update password,Please try again later!")
              },
            });
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <MgpText variant="h4">Change Password</MgpText>
              </Grid>
              <Grid item md={12}>
                <MgpTextboxFormik
                  fullWidth
                  size="small"
                  label="New Password"
                  name="newPassword"
                  values={values}
                  type="password"
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  variant="outlined"
                  touched={touched}
                  errors={errors}
                />
              </Grid>
              <Grid item md={12}>
                <MgpTextboxFormik
                  fullWidth
                  size="small"
                  label="Confirm New Password"
                  name="confirmPassword"
                  values={values}
                  type="password"
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  variant="outlined"
                  touched={touched}
                  errors={errors}
                />
              </Grid>
              <Grid item md={12}>
                <MgpButton type="submit" variant="contained" fullWidth>
                  Update
                </MgpButton>
                <MgpBackdrop
                  openBackdrop={openBackdrop}
                  message={"Updating..."}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Container>
  );
};
