import React, { useState } from 'react';
import { Stack, Typography,CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useTransfer } from '../../hooks/useTransfer';
import { MgpButton, MgpTextbox } from '../../components/global';

export const RejectForm=({reject,setOpenReject,setAlertMessage,setAlertOption,setMainPageloading})=>{
    const { useUpdateTransfer } = useTransfer();
  const [Update_Transfer] = useUpdateTransfer;
  const [message,setMessage]=useState("");
  const [loading, setLoading] = useState(false);

  const handleReject = () => {
    setLoading(true);
    Update_Transfer({
      variables: {
        id: Number(reject),
        transfer_status: 3,
        status_comment: message,
      },
      onCompleted: () => {
        setLoading(false);
        setAlertOption(true);
        setAlertMessage('Patient Transfer Rejected Successfully');
        setMessage('');
        setOpenReject(false);
      },
      onError: () => {
        setLoading(false);
        setAlertMessage('An error occurred while rejecting the transfer');
        setAlertOption(true);
      },
    });
  };

  return (
    <Dialog open={true} onClose={() => setOpenReject(false)}>

      <DialogTitle>
        <Typography variant="h2" color="primary" sx={{ fontWeight: 'bold', fontSize: "19px" }}> {/* Increased title size */}
          Reject Patient Transfer
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body1">Do you want to reject this transfer request?</Typography>
          <MgpTextbox
            label="Reason for Rejection"
            multiline
            minRows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            variant="outlined"
            fullWidth
            placeholder="Enter your reason here..."
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ margin: '0 10px' }}>

        <MgpButton variant="contained" onClick={() => setOpenReject(false)} >
          Cancel
        </MgpButton>
        <MgpButton variant="contained" onClick={handleReject} disabled={loading || message == ""}>
          {loading ? <CircularProgress size={24} /> : 'Reject'}
        </MgpButton>
      </DialogActions>
    </Dialog>
  );
};
