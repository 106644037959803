import { gql, useLazyQuery } from "@apollo/client";

export const useActivityLog = () => {
  const GET_ACTIVITY_LOGS = gql`
    query GetActivityLogs($filters: FiltersInput) {
      GetActivityLogs(filters: $filters) {
        activity_log_id
        created_by
        created_by_name
        role_name
        action_name
        module_name
        payload
        is_active
        created_on
        updated_on
      }
    }
  `;

  const MY_ACTIVITY_LOGS = gql`
    query MyActivityLogs {
      MyActivityLogs {
        activity_log_id
        created_by
        created_by_name
        role_name
        action_name
        module_name
        payload
        is_active
        created_on
        updated_on
      }
    }
  `;

  const GET_ACTIVITY_LOG = gql`
    query GetActivityLog($activityLogId: Int!) {
      GetActivityLog(activity_log_id: $activityLogId) {
        activity_log_id
        created_by
        created_by_name
        role_name
        action_name
        module_name
        payload
        is_active
        created_on
        updated_on
      }
    }
  `;

  const GET_ACTIVITY_LOGS_BY_MODULE_NAME = gql`
    query GetActivityLogsByModuleName($moduleName: String!) {
      GetActivityLogsByModuleName(module_name: $moduleName) {
        activity_log_id
        created_by
        created_by_name
        role_name
        action_name
        module_name
        payload
        is_active
        created_on
        updated_on
      }
    }
  `;

  const GET_ACTIVITY_LOGS_BY_ACTION_NAME = gql`
    query GetActivityLogsByActionName($actionName: String!) {
      GetActivityLogsByActionName(action_name: $actionName) {
        activity_log_id
        created_by
        created_by_name
        role_name
        action_name
        module_name
        payload
        is_active
        created_on
        updated_on
      }
    }
  `;

  const GET_ACTIVITY_LOGS_BY_USER_ID = gql`
    query GetActivityLogsByUserId($userId: Int!) {
      GetActivityLogsByUserId(user_id: $userId) {
        activity_log_id
        created_by
        created_by_name
        role_name
        action_name
        module_name
        payload
        is_active
        created_on
        updated_on
      }
    }
  `;

  const useGetActivityLogs = useLazyQuery(GET_ACTIVITY_LOGS);
  const useMyActivityLogs = useLazyQuery(MY_ACTIVITY_LOGS);
  const useGetActivityLog = useLazyQuery(GET_ACTIVITY_LOG);
  const useGetActivityLogsByModuleName = useLazyQuery(
    GET_ACTIVITY_LOGS_BY_MODULE_NAME
  );
  const useGetActivityLogsByActionName = useLazyQuery(
    GET_ACTIVITY_LOGS_BY_ACTION_NAME
  );
  const useGetActivityLogsByUserId = useLazyQuery(GET_ACTIVITY_LOGS_BY_USER_ID);

  return {
    useGetActivityLogs,
    useMyActivityLogs,
    useGetActivityLog,
    useGetActivityLogsByModuleName,
    useGetActivityLogsByActionName,
    useGetActivityLogsByUserId,
  };
};
