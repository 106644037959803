import React, { useState, useEffect } from "react";
import { Grid, FormControlLabel, Paper } from "@mui/material";
import {
  MgpButton,
  MgpCheckbox,
  MgpText,
  MgpTextboxFormik,
  Padding,
  MgpModal,
} from "../components/global";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../hooks";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { colors } from "../theme/colors";
import { Stack } from "@mui/material";
import * as yup from "yup";
import { useUser } from "../hooks/useUsers";
import { ForgotPassword } from "./ForgotPassword";
import MgpAlertBar from "../components/global/MgpAlertBar";
import { MgpBackdrop } from "../components/global/MgpBackdrop";
// import { StorageHelper } from "../../helpers";
// import { ForgotPassword } from "../ForgotPassword";

export const ResetPassword = () => {
  const params = useParams();
  const { useVerifyResetToken } = useAuth();
  const [open, setOpen] = useState(false);
  const [ValidateResetToken, { data, error, loading: loadingData }] = useVerifyResetToken;
  const navigate = useNavigate();
  const { useUpdatePassword } = useUser();
  const [UpdatePassword] = useUpdatePassword;
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [loading, setLoading] = React.useState(false)
  const [validate, setValidate] = useState(true);
  const [alertType, setAlertType] = useState("");
  const [message, setMessage] = useState("");
  const [alertOption, setAlertOption] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClose = () => {
    navigate('/login')
  }
  const handleSubmitClose = () => {
    setAlertOption(false);
  };
  useEffect(() => {
    ValidateResetToken({
      variables: {
        token: params?.token
      },
      onCompleted: (data) => {
        if (!data?.ValidateResetToken?.valid) {
          setAlertOption(true)
          setAlertType("error")
          setMessage("Session expired!, Please generate new link")
          setValidate(false);
          setTimeout(() => {
            setAlertOption(false);
            setAlertType("")

          }, 3000)
        } else {
          setValidate(true)
        }
      },
      fetchPolicy: "cache-and-network",
    })
  }, [])

  return (
    <>
      {validate ?
        (
          <Grid container>
            <Grid item xs={6}>
              <Stack>
                <img
                  src="../MGPR Landing Page BG.png"
                  alt=""
                  style={{
                    width: "100%", // Ensure the image covers the entire width
                    height: "100vh", // Ensure the image covers the entire height
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack
                height="100vh"
                alignItems="center"
                justifyContent="center"
                spacing={2}
                overflow="auto" // Add overflow property for scrolling
                paddingX={{ xs: 2, md: 5 }}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                  paddingX={{ xs: 2, md: 5 }}
                >
                  <Grid item>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <img src="../logo.png" alt="" style={{ width: "170px" }} />
                      <Stack direction="column">
                        <MgpText color="textPrimary">Myasthenia Gravis</MgpText>
                        <MgpText color="textPrimary">Patient Registry</MgpText>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Stack
                      flexGrow={1}
                      display="flex"
                      flexDirection="column"
                      width="100%"
                      border={`1px solid ${colors.WHITE}`}
                      borderRadius="20px"
                      boxShadow={`4px 2px 4px 4px rgba(0, 0, 0, 0.1)`}
                    >

                      <Padding size="5px 20px 20px">
                        <MgpText>Please Reset Your Password</MgpText>
                      </Padding>

                      <Grid xs={12} paddingLeft={"20px"} />

                      <Stack
                        width="100%"
                        border={`1px solid ${colors.PRIMARY_LIGHT}`}
                        borderRadius="20px"
                        boxShadow="4px 2px 4px 4px rgba(0, 0, 0, 0.1)"
                        display="flex"
                        flexDirection="column"
                      >
                        <Padding size="20px">
                          <Stack flexGrow={1}>
                            <Stack spacing={2}>
                              <Formik
                                initialValues={{
                                  newPassword: "",
                                  password: "",
                                }}
                                validationSchema={yup.object().shape({
                                  newPassword: yup
                                    .string()
                                    .min(2, "Too Short!")
                                    .max(50, "Too Long!")
                                    .required("Required"),
                                  password: yup
                                    .string()
                                    .min(2, "Too Short!")
                                    .max(50, "Too Long!")
                                    .required("Required"),
                                })}
                                onSubmit={(payload) => {

                                  if (payload.newPassword != payload.password) {
                                    setMessage("Passwords does not match!");
                                    setAlertOption(true);
                                    setAlertType("error")
                                  } else {
                                    setLoading(true)
                                    setOpenBackdrop(true);
                                    UpdatePassword({
                                      variables: {
                                        username: data?.ValidateResetToken?.username,
                                        password: payload.password,
                                      },
                                      onCompleted: () => {

                                        setMessage("Password changed successfully")
                                        setAlertOption(true);
                                        setAlertType("success")
                                        setLoading(false);
                                        setTimeout(() => {
                                          setAlertOption(false);
                                          setAlertType("")
                                          setMessage("")
                                          setOpenBackdrop(false);
                                          handleClose();
                                        }, 3000)
                                      },
                                      onError: ({ error }) => {
                                        console.log("error", JSON.stringify(error));
                                        setAlertOption(true);
                                        setAlertType("error")
                                        setMessage("Can't able to update password,Please try again later!")
                                      },
                                    });
                                  }
                                }}
                              >
                                {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  setFieldValue,
                                  touched,
                                  values,
                                  resetForm,
                                }) => (
                                  <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>

                                      <Grid item md={12}>
                                        <MgpTextboxFormik
                                          fullWidth
                                          size="small"
                                          label="New Password"
                                          name="newPassword"
                                          values={values}
                                          type="password"
                                          handleChange={handleChange}
                                          setFieldValue={setFieldValue}
                                          handleBlur={handleBlur}
                                          variant="outlined"
                                          touched={touched}
                                          errors={errors}
                                        />
                                      </Grid>
                                      <Grid item md={12}>
                                        <MgpTextboxFormik
                                          fullWidth
                                          size="small"
                                          label="Confirm New Password"
                                          name="password"
                                          splits={"password".split(".")}
                                          values={values}
                                          showPassword={showPassword}
                                          type={showPassword ? "text" : "password"}
                                          handleChange={handleChange}
                                          setFieldValue={setFieldValue}
                                          handleBlur={handleBlur}
                                          variant="outlined"
                                          touched={touched}
                                          errors={errors}
                                          handleClickShowPassword={handleClickShowPassword}
                                          handleMouseDownPassword={handleMouseDownPassword}
                                        />
                                      </Grid>
                                      <Grid item md={12}>
                                        <MgpButton type="submit" variant="contained" fullWidth>
                                          Reset Password
                                        </MgpButton>
                                        <MgpBackdrop
                                          openBackdrop={openBackdrop}
                                          message={"Updating..."}
                                        />
                                      </Grid>
                                    </Grid>
                                  </form>
                                )}
                              </Formik>
                            </Stack>
                          </Stack>
                        </Padding>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>

                <div
            style={{
              // border:"2px solid yellow",
              backgroundImage: colors.FOOTER,
              borderRadius:"5px",
              position: "fixed",
              bottom: 16,
              right: 16,
              padding: "10px 20px",
              // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            <img src={process.env.PUBLIC_URL + "/Itkan-W_edited.webp"} alt="" style={{ width: "90px" }} />
          </div>
              </Stack>

            </Grid>
          </Grid >
        ) : (
          <MgpModal
            styling={{ width: "40vw", overflow: "auto" }}
            open={true}
            onClose={handleClose}
          >
            <ForgotPassword handleClose={handleClose}></ForgotPassword>
          </MgpModal>
        )

      }
      <MgpAlertBar
        open={alertOption}
        setOpen={handleSubmitClose}
        alertType={alertType}
        message={message}
      />
    </>
  );
};
