import {
  Backdrop,
  CircularProgress,
  Container,
  Grid,
  Stack,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { MgpButton, MgpDD } from "../components/global";
import { ENV } from "../helpers";
import { useEncounter, useMaster } from "../hooks";
import { useEffect, useRef, useState } from "react";
import { MgpDatePicker } from "../components/global/MgpDatePicker";
import { StorageHelper } from "../helpers";
import { MgpDatePickerOther } from "../components/global/MgpDatePickerOther";

export const DashboardFilter = ({
  setMainPageloading,
  closed = () => { },
  handleChange = () => { },
  isAnalytics,
  viewType,
  self
}) => {
  const { hospitals } = useMaster();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const validationSchema = yup.object().shape({});
  const user = JSON.parse(StorageHelper.get("user"));
  const assigned_hospitals = JSON.parse(
    StorageHelper.get("assigned_hospitals")
  );
  var filterHospitals = hospitals.filter((x) =>
    assigned_hospitals?.includes(x.value)
  );
  const { useGetVisitType } = useEncounter({patientId:1});
  const initialValues = { hospital_id: "", visit_type: [] }
  const [GetVisitType, { data, loading }] = useGetVisitType;
  const formikRef = useRef();
  useEffect(() => {
    GetVisitType({
      fetchPolicy: "cache-and-network",
      variables:{self: self === "1" ? true : false},
      onCompleted: (data) => {     
        if (formikRef.current) {
          formikRef.current.resetForm();
          formikRef.current.setFieldValue("visit_type",[]);
          formikRef.current.setFieldValue("hospital_id","");

        }
      }
    })
  }, [self])
  return (
    <>
      <Container fluid>
        <Formik
          initialValues={initialValues}
          innerRef={formikRef}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            // setMainPageloading(true);
            // setLoadingSubmit(true);
            handleChange(values, viewType);
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            resetForm,
            touched,
            errors,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {(user?.role_id == ENV.itkan_role_id ||
                  user?.role_id == ENV.quality_user_role_id) &&
                  (viewType === "graph" || viewType === "table") && (
                    <>
                      <Grid item md={6}>
                        <MgpDD
                          label="Hospital"
                          variant="outlined"
                          size="small"
                          name="hospital_id"
                          multiselect={false}
                          fullWidth
                          value={values.hospital_id}
                          options={
                            assigned_hospitals?.length > 0
                              ? filterHospitals
                              : hospitals
                          }
                          onChange={(e) => {
                            setFieldValue("hospital_id", e.target.value);
                          }}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.hospital_id && errors.hospital_id
                          )}
                          helperText={touched.hospital_id && errors.hospital_id}
                        />
                      </Grid>
                      {isAnalytics ? (
                        <Grid item md={6}>
                          <MgpDD
                            label="Visit Type"
                            variant="outlined"
                            size="small"
                            name="visit_type"
                            multiselect={true}
                            fullWidth
                            value={values.visit_type}
                            options={data?.GetVisitType}
                            onChange={(e) => {
                              setFieldValue("visit_type", e.target.value);
                            }}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.visit_type && errors.visit_type
                            )}
                            helperText={touched.visit_type && errors.visit_type}
                          />
                        </Grid>
                      ) : (
                        <Grid item md={6}></Grid>
                      )}
                    </>
                  )}

                {(user?.role_id == ENV.itkan_role_id ||
                  user?.role_id == ENV.quality_user_role_id) &&
                  isAnalytics && (
                    <>
                      <Grid item md={6}>
                        <MgpDD
                          label="Hospital"
                          variant="outlined"
                          size="small"
                          name="hospital_id"
                          multiselect={false}
                          fullWidth
                          value={values.hospital_id}
                          options={
                            assigned_hospitals?.length > 0
                              ? filterHospitals
                              : hospitals
                          }
                          onChange={(e) => {
                            setFieldValue("hospital_id", e.target.value);
                          }}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.hospital_id && errors.hospital_id
                          )}
                          helperText={touched.hospital_id && errors.hospital_id}
                        />
                      </Grid>
                      {parseInt(user?.role_id) !== parseInt(ENV.itkan_role_id) &&
                        <Grid item md={6}></Grid>}
                    </>
                  )}

                {(user?.role_id == ENV.doctor_role_id || user?.role_id == ENV.itkan_role_id ||
                  user?.role_id == ENV.medical_lead_role_id) &&
                  isAnalytics && (
                    <>
                      <Grid item md={6}>
                        <MgpDD
                          label="Visit Type"
                          variant="outlined"
                          size="small"
                          name="visit_type"
                          multiselect={true}
                          fullWidth
                          value={values.visit_type}
                          options={data?.GetVisitType}
                          onChange={(e) => {
                            setFieldValue("visit_type", e.target.value);
                          }}
                          onBlur={handleBlur}
                          error={Boolean(
                            touched.visit_type && errors.visit_type
                          )}
                          helperText={touched.visit_type && errors.visit_type}
                        />
                      </Grid>
                      {parseInt(user?.role_id) !== parseInt(ENV.itkan_role_id) &&
                        <Grid item md={6}></Grid>
                      }
                    </>
                  )}

                <Grid item md={6}>
                  <MgpDatePickerOther
                    viewMode={false}
                    size="small"
                    fullWidth
                    onChange={setFieldValue}
                    errors={Boolean(errors?.["from"] && touched?.["from"])}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    handleChange={handleChange}
                    label={"From"}
                    name={"from"}
                    values={values}
                    handleBlur={handleBlur}
                    startDate={"Form_Date"}
                    disablePast={false}
                    monthly={true}
                    disableFuture={true}
                    removetoday={true}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6}>
                  <MgpDatePickerOther
                    viewMode={false}
                    size="small"
                    fullWidth
                    onChange={setFieldValue}
                    errors={Boolean(errors?.["to"] && touched?.["to"])}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    handleChange={handleChange}
                    label={"To"}
                    name={"to"}
                    values={values}
                    handleBlur={handleBlur}
                    startDate={"To_Date"}
                    disablePast={false}
                    monthly={true}
                    disableFuture={true}
                    removetoday={true}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={12}>
                  <Stack
                    spacing={2}
                    justifyContent={"flex-end"}
                    direction={"row"}
                  >
                    <MgpButton
                      onClick={() => {
                        resetForm();
                        setFieldValue("hospital_id", "");
                        setFieldValue("visit_type", []);
                      }}
                      variant="contained"
                    >
                      Clear
                    </MgpButton>
                    <MgpButton type="submit" variant="contained">
                      Apply
                    </MgpButton>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Container>
      {loadingSubmit && (
        <Backdrop open={loadingSubmit} style={{ zIndex: 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};
