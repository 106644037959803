import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PostAddIcon from "@mui/icons-material/PostAdd";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import Chip from "@mui/material/Chip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  IconButton,
  Tooltip,
  CircularProgress,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Stack,
  Grid,
} from "@mui/material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { ENV, StorageHelper } from "../helpers";
import {
  MRT_GlobalFilterTextField,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AlertDialog,
  MgpButton,
  MgpModal,
  MgpRadioButton,
  MgpTable,
  MgpTableStatus,
  MgpText,
  MgpTextbox,
  TableConfig,
  MgpDD,
} from "../components/global";
import { usePatient, useRbac } from "../hooks";
import { colors } from "../theme/colors";
import { VerifyPatient } from "./VerifyPatient";
import Loader from "./Loader/Loader";
import { MgpChip } from "../components/global/MgpChip";
import StatusColors from "../components/global/StatusColors";
import { PatientReject } from "./PatientApproval/PatientReject";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import FlagIcon from "@mui/icons-material/Flag";
import { downloadPdf } from "../helpers/dowloadPdf";
import * as XLSX from 'xlsx';


const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

let radioButtonList = [
  { value: "1", label: "Ready to Review" },
  { value: "2", label: "Reviewed" },
  { value: "3", label: "Rejected" },
];

export const PatientsDashboard = ({
  hospitalName,
  handleHospitalChange = () => {},
  filters,
  self,
}) => {
  const { RbacWrapper, rbac } = useRbac();
  const [mainPageloading, setMainPageloading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [openTransfer, setOpenTransfer] = React.useState(false);
  const [patientData, setData] = React.useState({ columns: [], rows: {} });
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const handleOpenTransfer = () => {
    setTimeout(() => {
      navigate(`transfer`);
    }, 1000);
  };
  const handleCloseTransfer = () => setOpenTransfer(false);
  const [filterStatus, setFilterStatus] = React.useState("");
  const [tableData, setTableData] = useState([]);
  const selectItem = (item) => {
    setFilterStatus(item);
    setTableData(
      data?.GetPatients.filter(
        (elem) => parseInt(elem.status_value) === parseInt(item)
      ) ?? []
    );
  };
  const [userDetails, setUserDetails] = React.useState({});

  const [delete_ref, setDeleting] = React.useState(false);
  const { useGetPatientsDashboard, useDeletePatient } = usePatient();
  const [tableLoading, setTableLoading] = useState(true);
  const [openDeletePatient, setOpenDelectePatient] = useState(false);
  const [patientid, setPatientId] = useState(0);
  const memoizedFilters = useMemo(() => filters, [filters]);
  // const { RbacWrapper } = useRbac();
  const [GetPatientsDashboard, { data, loading: patientLoading, error }] =
    useGetPatientsDashboard;
  const [DeletePatient] = useDeletePatient;
  const user = JSON.parse(StorageHelper.get("user"));

  let columns = [
    {
      accessorKey: "registry_no",
      header: "Patient Registry No",
      headerAlign: "center",
      Cell: ({ row }) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textDecoration: "underline",
              color: colors.PRIMARY,
            }}
            component="a"
            href={"patients/viewPatient/" + row?.original?.patient_id}
            target="_blank"
          >
            {row?.original?.registry_no}
          </Box>
        );
      },
    },
    {
      accessorKey: "totalvisits", //normal accessorKey
      header: "Number Of Reports",
      headerAlign: "center",
    },
    {
      accessorKey: "firstvisit", //normal accessorKey
      header: "Date of Baseline Visit",
      headerAlign: "center",
      size: 210,
    },
    {
      accessorKey: "follow_up_count",
      header: "Number of Total Follow-up Visits",
      size: 210,
    },
    {
      accessorKey: "lastvisit", //normal accessorKey
      header: "Date of Last Follow Up",
      headerAlign: "center",
      size: 210,
    },
    {
      accessorKey: "status", //normal accessorKey
      header: "Last Visit Report Status",

      headerAlign: "center",
      Cell: ({ row }) => {
        return (
          <MgpTableStatus
            value={row.original.status_value}
            label={row.original.status}
          />
        );
      },
    },
  ];

  useEffect(() => {
    const variables =
      user?.role_id == ENV.medical_lead_role_id
        ? { filters: memoizedFilters ? memoizedFilters : {}, self }
        : { filters: memoizedFilters ? memoizedFilters : {} };
    GetPatientsDashboard({
      variables: variables,
      fetchPolicy: "cache-and-network",
    });
  }, [GetPatientsDashboard, memoizedFilters, self]);

  useEffect(() => {
    if (data || error) {
      setMainPageloading(false);
      setTableData(data?.GetPatientsDashboard);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [data, error]);

  const table = useMaterialReactTable({
    columns,
    data: tableData ?? [],
    ...TableConfig,
    enableRowActions: false,
    // enableColumnResizing: true,
    renderTopToolbar: ({ table }) => (
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: "0.5rem",
          p: "16px 0px",
          justifyContent: "space-between",
        })}
      >
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <MRT_GlobalFilterTextField table={table} />
        </Box>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <RbacWrapper module={"patients"} code={"export"}>
            <MgpButton
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => handleExportData("xlsx")}
              variant="contained"
              endIcon={
                <FileDownloadIcon sx={{ fontSize: "16px !important" }} />
              }
            >
              Export Excel
            </MgpButton>
            <MgpButton
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => handleExportData("pdf")}
              variant="contained"
              endIcon={
                <FileDownloadIcon sx={{ fontSize: "16px !important" }} />
              }
            >
              Export PDF
            </MgpButton>
          </RbacWrapper>
        </Box>
      </Box>
    ),
    muiSearchTextFieldProps: ({ table }) => {},

    state: {
      isLoading,
      columnOrder: columns,
    },
    renderTableBody: () => {
      return isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null;
    },
  });
  const handleExportData = (type) => {
    const filteredData = table
      .getFilteredRowModel()
      .rows.map((row) => row.original)
      .map((obj) => {
        const filteredObj = {};
        columns.forEach((key) => {
          if (obj.hasOwnProperty(key.accessorKey)) {
            filteredObj[key.header] = obj[key.accessorKey];
          }
        });
        return filteredObj;
      });
    let dataToExport = filteredData ? filteredData : data.rows;
    let filename = "Site Details";
    if (dataToExport.length) {
      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      // Convert the data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
  
      // Calculate column widths based on headers and values
      const maxWidths = [];
      columns.forEach((col, colIndex) => {
        const header = col.header;
        maxWidths[colIndex] = header.length;
      });
  
      dataToExport.forEach((row) => {
        Object.keys(row).forEach((key, colIndex) => {
          const cellValue = row[key] ? row[key].toString() : '';
          maxWidths[colIndex] = Math.max(maxWidths[colIndex], cellValue.length);
        });
      });
  
      worksheet['!cols'] = maxWidths.map((width) => ({ wch: width + 2 }));
  
      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      
  
      // Handle file type and download
      if (type === "csv") {
        XLSX.writeFile(workbook, `${filename}.csv`, { bookType: 'csv' });
      } else if (type === "xlsx") {
        XLSX.writeFile(workbook, `${filename}.xlsx`, { bookType: 'xlsx' });
      }else {
        downloadPdf(dataToExport, filename);
      }
    }
  };

  const handleDelete = (row) => {
    setOpenDelectePatient(true);
    setPatientId(row.patient_id);
  };

  if (mainPageloading) {
    return <Loader open={mainPageloading} />;
  }
  const statuses = [
    {
      label:
        "Draft - Indicates the incompletion of the record, i.e. missing information and entries are still yet to be completed.",
      value: "0",
    }, // Draft - Amber
    {
      label:
        "Ready to Review - Indicates data entry and record completion and is ready to be reviewed by the “Monitor / QC”. Data here refers to the mandatory data / required.",
      value: "1",
    }, // Ready to Review - Green
    {
      label:
        "Reviewed - Indicates that the “Monitor” has reviewed the record and approved it.",
      value: "2",
    }, // Reviewed - Light Green
    {
      label:
        "Return to Doctor - Indicates that the comments / concerns raised by the “Monitor” on the record are pending / awaiting to be addressed / amended by the “PI / Doctor.”",
      value: "4",
    }, // Return to Doctor - Gray
    {
      label:
        "Re-opened - Indicates that the “Reviewed” report is reopened by quality user to edit and modify information.",
      value: "6",
    },
  ];

  return (
    <>
      {delete_ref && (
        <AlertDialog
          open={true}
          negative={() => {
            setDeleting(false);
          }}
          positive={(param) => {
            DeletePatient({ variables: { patientId: Number(param) } });
          }}
          param={delete_ref}
          title={"Delete"}
          description={"Are you sure you want to delete?"}
        />
      )}

      <Box>
        <Stack direction="row" spacing={1}>
          <MgpText
            variant="h5"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleHospitalChange(false, "");
            }}
          >
            {(user?.role_id != ENV.doctor_role_id ||
              user?.role_id != ENV.medical_lead_role_id) && (
              <ArrowBackIcon></ArrowBackIcon>
            )}
          </MgpText>
          <MgpText variant="h5">{hospitalName}</MgpText>
        </Stack>
        <MgpTable table={table} />
        <StatusColors statuses={statuses}></StatusColors>
        <MgpModal open={open} handleOpen={handleOpen} onClose={handleClose}>
          <VerifyPatient />
        </MgpModal>
      </Box>
      <MgpModal
        open={openDeletePatient}
        onClose={() => {
          setOpenDelectePatient(false);
        }}
      >
        <PatientReject
          reject={openDeletePatient}
          setOpenReject={setOpenDelectePatient}
          messageHeading={
            "Are you sure to delete this patient report? If yes, all records will be deleted too. Please add the deletion reason."
          }
          Delete={
            "Are you sure to delete this patient report? If yes, all records will be deleted too. Please add the deletion reason."
          }
          DeletePatient={DeletePatient}
          patientid={patientid}
        />
      </MgpModal>
    </>
  );
};
