import { colors } from "../../theme/colors";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const useButtonStyles = () => {
  return {
    disabledButton: {
      opacity: 0.1,
    },
  };
};

export const MgpButton = styled(Button)(({ theme }) => ({
  // background: "linear-gradient(to right, #106881, #083846)",
  // color: "#ffffff",
  // padding: "10px 20px",
  // borderRadius: "20px",
  // border: "none",
  // transition: "background 0.3s ease",
  // "&:hover": {
  //   background: "linear-gradient(to right, #083846, #106881)",
  //   cursor: "pointer",
  // },
  // textTransform: "none",
  // "&:disabled": {
  //   opacity:"0.5",
  //   color:"white",
  // },
  // color: colors.SECONDARY_TEXT,
  // backgroundColor: colors.SECONDARY,
  // "&:hover": {
  //   backgroundColor: colors.SECONDARY_LIGHT,
  // },
  textTransform: "capitalize",
  fontSize:"12px",
}));
