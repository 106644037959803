import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

export const MgpRadioButton = (
  {value,onChange = () => {}, list = []}
  )=>{
  return (
    <FormControl
    onChange={(event)=>
      {
        onChange(event.target.value)}}
    >
      <RadioGroup
      value={value}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        {list && list.map(item=> (<FormControlLabel value={item.value} control={<Radio />} label={item.label} />))}
      </RadioGroup>
    </FormControl>
  );
}