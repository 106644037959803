import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { colors } from "../../theme/colors";
import { InputLabel, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import { dateView } from "../../helpers/dateViewConfig";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/en-gb";
import { RedAsterisk } from "./MgpAccordion";

dayjs.extend(utc);
export const MgpDatePicker = ({
  sectionName,
  nameLast,
  name,
  disableFuture,
  disablePast,
  label,
  touched,
  validation,
  errors,
  handleBlur,
  setFieldValue,
  values,
  options,
  subtracting,
  disabled,
  dependent,
  viewMode,
  startDate,
  endDate,
  monthly,
  showtodayDate,
  reversedependent,
  commentData,
  encountedDataLatestStatus,
  dependentMain,
  aggrigation,
  dependent1,
  edit,
  restrictto,
  removetoday,
  edit_patientform,
  dateFormat,
  mininumDate = true,
  handleChange,
  attribute,
}) => {
  const onKeyDown = (e) => {
    e.preventDefault();
  };
  // const today = new Date().toISOString();
  // if (values[name] === "" && showtodayDate) {
  //   setFieldValue(name, today);
  // }
  // if (validation === "disableFuture") disableFuture = true;
  // else if (validation === "disablePast") disablePast = true;
  // disableFuture = false;
  // else {
  //   disableFuture = false;
  //   disablePast = false;
  // }
  const [error, setError] = useState(null);
  // console.log("disablePast", disableFuture, disablePast);
  const errorMessage = useMemo(() => {
    switch (error) {
      case "maxDate":
      case "minDate": {
        return "Please select a date in the first quarter of 2022";
      }

      case "invalidDate": {
        return "Your date is not valid";
      }

      default: {
        return "";
      }
    }
  }, [error]);
  // const maxDate = dayjs().subtract(1, "year").toDate(); // Example: Sets the max date to one year ago

  const calculateAge = (dob) => {
    const currentDate = new Date();
    const selectedDate = new Date(dob);
    let ageYears = currentDate.getFullYear() - selectedDate.getFullYear();
    let ageMonths = currentDate.getMonth() - selectedDate.getMonth();
    const dateDiff = currentDate.getDate() - selectedDate.getDate();

    if (dateDiff < 0) {
      ageMonths--;
    }

    if (ageMonths < 0) {
      ageYears--;
      ageMonths += 12;
    }

    let fractionOfMonth = 0;
    if (dateDiff < 0) {
      const previousMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
      );
      fractionOfMonth =
        (previousMonth.getDate() + dateDiff) / previousMonth.getDate();
    } else {
      fractionOfMonth = dateDiff / currentDate.getDate();
    }

    const ageDecimal = ageYears + (ageMonths + fractionOfMonth) / 12;

    const fullMonths = Math.floor(ageDecimal * 12);
    const years = Math.floor(fullMonths / 12);
    const months = fullMonths % 12;

    return `${years}/${months}`;
  };

  function monthsDiff(date1, date2) {
    let startDate = new Date(date1);
    let endDate = new Date(date2);
    if (startDate > endDate) {
      [startDate, endDate] = [endDate, startDate];
    }
    const yearsDiff = endDate.getFullYear() - startDate.getFullYear();
    const monthsDiff = endDate.getMonth() - startDate.getMonth();

    const totalMonthsDiff = yearsDiff * 12 + monthsDiff;

    return !isNaN(totalMonthsDiff) ? `${totalMonthsDiff} Months` : "";
  }
  function monthsDiffYM(date1, date2) {
    let startDate = new Date(date1);
    let endDate = new Date(date2);

    // Swap dates if startDate is later than endDate
    if (startDate > endDate) {
      [startDate, endDate] = [endDate, startDate];
    }

    // Calculate the difference in years and months
    let yearsDiff = endDate.getFullYear() - startDate.getFullYear();
    let monthsDiff = endDate.getMonth() - startDate.getMonth();

    // If the months difference is negative, adjust the years and months difference
    if (monthsDiff < 0) {
      yearsDiff -= 1;
      monthsDiff += 12;
    }

    // Return the result as an object
    return {
      years: !isNaN(yearsDiff) ? `${yearsDiff} Years` : "",
      months: !isNaN(monthsDiff) ? `${monthsDiff} Months` : "",
    };
  }

  const hasAsterisk = label?.endsWith("*");

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <StyledDatePicker
        disableFuture={disableFuture}
        disablePast={disablePast}
        views={monthly ? dateView.yearMonthDay : dateView.year}
        openTo={dateFormat}
        minDate={
          mininumDate
            ? restrictto
              ? dayjs(new Date()).subtract(14, "day")
              : startDate && removetoday
              ? dayjs(`${values?.[startDate]}`)
              : startDate === "enrollment_date" || startDate === "approved_on"
              ? dayjs(values[startDate], "DD/MM/YYYY")
              : startDate && dayjs(`${values?.[startDate]}`)
            : null
        } // Set the minDate to the specific start date
        slotProps={{
          textField: {
            size: "small",
            fullWidth: true,
            error: errors,
            onBlur: handleBlur,
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, readOnly: true }}
          />
        )}
        label={
          hasAsterisk ? (
            <>
              {label?.slice(0, -1)}
              <RedAsterisk>*</RedAsterisk>
            </>
          ) : (
            label
          )
        }
        name={name}
        onChange={(date) => {
          console.log("onChangeonChange", date);
          handleChange(name, attribute);
          const selectedDate = date.toDate();
          const utcDate = new Date(
            selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
          );
          const isoString = utcDate.toISOString().split("T")[0];
          console.log("Adjusted date in ISO format:", isoString, date);

          if (date === null) {
            if (reversedependent) {
              setFieldValue(reversedependent, "");
              setFieldValue(name, "");
            } else {
              setFieldValue(dependent, "");
              setFieldValue(name, "");
            }
          } else {
            if (reversedependent) {
              if (subtracting) {
                let decimalString = values["demography_year"];
                setFieldValue(
                  reversedependent,

                  Math.round(
                    parseFloat(decimalString) -
                      parseFloat(calculateAge(date).replaceAll("/", "."))
                  )
                ); // Update field2 with the same value
              } else {
                setFieldValue(
                  reversedependent,
                  parseFloat(calculateAge(date).replaceAll("/", "."))
                );
                // Update field2 with the same value
              }
              setFieldValue(name, isoString);
            } else {
              if (dependent) {
                if (subtracting) {
                  let decimalString = values?.["demography_year"];

                  setFieldValue(
                    dependent,
                    parseFloat(decimalString) -
                      parseFloat(calculateAge(date).replaceAll("/", "."))
                  ); // Update field2 with the same value
                } else {
                  const [yearsValue, monthsValue] =
                    calculateAge(date).split("/");
                  setFieldValue(dependent[0], yearsValue);
                  setFieldValue(dependent[1], monthsValue);

                  // Update field2 with the same value
                }
              }
              setFieldValue(name, isoString);
            }

            if (aggrigation === "5") {
              if (Array.isArray(dependentMain)) {
                let valueCheck = dependentMain.map((elem) => {
                  if (values?.[elem] !== "" || values?.[elem] !== null)
                    return true;
                });

                if (!valueCheck.includes(false)) {
                  let first =
                    dependentMain[1] === name
                      ? date
                      : values?.[dependentMain[1]];

                  let second =
                    dependentMain[0] === name
                      ? date
                      : values?.[dependentMain[0]];

                  let diff = monthsDiff(first, second);
                  let diffYM = monthsDiffYM(first, second);

                  setFieldValue(dependent1, diff);
                  setFieldValue(`${dependent1}-Y`, diffYM?.years);
                  setFieldValue(`${dependent1}-M`, diffYM?.months);
                }
              }
            }
          }
        }}
        onBlur={handleBlur}
        value={values?.[name] ? dayjs(values[name]) : null}
        inputFormat="DD/MM/YYYY"
        disabled={
          edit_patientform && !disabled
            ? false
            : encountedDataLatestStatus === 4 || encountedDataLatestStatus === 6
            ? !edit
              ? true
              : disabled
            : disabled || viewMode
            ? true
            : false
        }
      />
    </LocalizationProvider>
  );
};

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated": {
    fontSize: "16px !important",
    paddingTop: "1px",
    fontWeight: "500 !important",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  "& .MuiInputBase-input": { fontSize: "14px", marginTop: "6px" },
  "& .MuiInputBase-input::placeholder": {
    fontSize: "15px !important",
  },
  "& .MuiInputAdornment-root .MuiInputBase-input": {
    width: "100% !important",
    fontFamily: "Montserrat",
    fontSize: "16px",
    color: colors.TEXTCOLOR,
    paddingLeft: "20px",
    "&::placeholder": {
      fontSize: "20px !important",
    },
  },
  "& .MuiTextField-root": {
    fontSize: "16px",
  },
}));
