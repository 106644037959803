import React from "react";
import {
  EmbedReport,
  Login,
  Patient,
  Roles,
  EmbedAnalyticalDashboard,
} from "../views";
import {
  MasterContextProvider,
  RbacContextProvider,
  UtilContextProvider,
} from "../hooks";
import { DashboardLayout, Mgp404Page } from "../components/global";
import { HomeDashboard } from "../views/HomeDashboard";
import { ViewPatientDetails } from "../views/ViewPatient";
//import MedicationRequest from "../views/MedicationRequest";
//import ResetPassword from "../views/ResetPassword";
import { Hospitals } from "../views/masters/Hospitals/Hospitals";
import { AddUser } from "../views/masters/AddUser/AddUser";
import { Country } from "../views/masters/Country/Country";
import { City } from "../views/masters/City/City";
import { TransferPatient } from "../views/transfer/TransferPatient";
import { MedicationRequests } from "../views/masters/MedicationRequests/MedicationRequests";
import { DeletedPatientList } from "../views/Deletedpatient/DeletedpatientList";
import { Navigate } from "react-router-dom";
import { TransferHistory } from "../views/transfer/TransferHistory";
import { Region } from "../views/masters/Region/Region";
import { SuperRegion } from "../views/masters/SuperRegion/SuperRegion";
import { ResetPassword } from "../views/ResetPassword";
import { ActivityLogs } from "../views/ActivityLogs";
import { ActivityLogDetails } from "../views/ActivityLogDetails";
const URLS = {
  LOGIN: "/login",
  TRANSFER: "patients/transfer",
  TRANSFER_HISTORY: "patients/transfer/history/:patient_id",
  RESET_PASSWORD: "/resetPassword",
};

const routes = [
  {
    path: "*",
    element: <Mgp404Page />,
  },
  {
    path: "",
    element: <Navigate to={URLS.LOGIN} />,
    children: [],
  },
  {
    path: URLS.LOGIN,
    element: <Login />,
  },
  {
    path: `${URLS.RESET_PASSWORD}/:token`,
    element: <ResetPassword />,
  },
  {
    path: "app",
    element: (
      <RbacContextProvider>
        <MasterContextProvider>
          <DashboardLayout />
        </MasterContextProvider>
      </RbacContextProvider>
    ),
    children: [
      {
        path: "patients/patientlist",
        element: (
          <UtilContextProvider>
            <HomeDashboard />
          </UtilContextProvider>
        ),
      },
      {
        path: "patients/deletedPatients",
        element: (
          <UtilContextProvider>
            <DeletedPatientList />
          </UtilContextProvider>
        ),
      },
      {
        path: "patients/viewPatient/:patientId",
        element: (
          <UtilContextProvider>
            <ViewPatientDetails />
          </UtilContextProvider>
        ),
      },
      {
        path: "patients/viewPatient/patientId",
        element: (
          <UtilContextProvider>
            <Patient viewMode={false} newVisit={false} />
          </UtilContextProvider>
        ),
      },
      {
        path: "patients/visitHistory/:encounterId",
        element: (
          <UtilContextProvider>
            <Patient viewMode={true} newVisit={true} editable={true} />
          </UtilContextProvider>
        ),
      },
      {
        path: "patients/visitHistory/viewCrf/:encounterId",
        element: (
          <UtilContextProvider>
            <Patient viewMode={true} newVisit={true} editable={false} />
          </UtilContextProvider>
        ),
      },
      {
        path: "patients/reopenVisitHistory/:encounterId",
        element: (
          <UtilContextProvider>
            <Patient viewMode={true} newVisit={true} reopen={true} />
          </UtilContextProvider>
        ),
      },
      {
        path: "patients/Edit/:encounterId",
        element: (
          <UtilContextProvider>
            <Patient viewMode={true} newVisit={true} />
          </UtilContextProvider>
        ),
      },
      {
        path: URLS.TRANSFER,
        element: (
          <UtilContextProvider>
            <TransferPatient />
          </UtilContextProvider>
        ),
      },
      {
        path: URLS.TRANSFER_HISTORY,
        element: (
          <UtilContextProvider>
            <TransferHistory />
          </UtilContextProvider>
        ),
      },
      {
        path: "patients/newVisit/:patientId",
        element: (
          <UtilContextProvider>
            <Patient viewMode={true} newVisit={true} />
          </UtilContextProvider>
        ),
      },
      {
        path: "patients",
        element: <EmbedReport />,
        // element:<HomeDashboard />
      },
      // {
      //   path: "patientlist",
      //   // element: <EmbedReport />,
      //   element:<HomeDashboard />
      // },
      {
        path: "analytics",
        element: <EmbedAnalyticalDashboard />,
        // element:<HomeDashboard />
      },

      {
        path: "patients/:id",
        element: (
          <UtilContextProvider>
            <Patient viewMode={false} newVisit={false} />
          </UtilContextProvider>
        ),
      },
      {
        path: "patients/newPatient/:nationalId",
        element: (
          <UtilContextProvider>
            <Patient viewMode={false} newVisit={false} />
          </UtilContextProvider>
        ),
      },
      {
        path: "setting",
        element: <>Test</>,
      },
      {
        path: "roles",
        element: <Roles />,
      },
      {
        path: "users",
        element: <AddUser />,
      },
      {
        path: "country",
        element: <Country />,
      },
      {
        path: "city",
        element: <City />,
      },
      {
        path: "hospital",
        element: <Hospitals />,
      },
      {
        path: "region",
        element: <Region />,
      },
      {
        path: "greaterRegion",
        element: <SuperRegion />,
      },
      {
        path: "patients/:id",
        element: (
          <UtilContextProvider>
            <Patient viewMode={false} newVisit={false} />
          </UtilContextProvider>
        ),
      },
      {
        path: "medication",
        element: <MedicationRequests />,
      },
      {
        path: "activitylogs",
        element: (
          <UtilContextProvider>
            <ActivityLogs />
          </UtilContextProvider>
        ),
      },
      {
        path: "activitylogs/:userId",
        element: (
          <UtilContextProvider>
            <>User Activity Logs</>
          </UtilContextProvider>
        ),
      },
      {
        path:"activitylogs/details/:activity_log_id", // For seeing detailed activity log.
        element:(
          <UtilContextProvider>
            <ActivityLogDetails />
          </UtilContextProvider>
        )
      }
    ],
  },
];

export default routes;
