import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { ProcessGrapqlError, apollo_client } from "../helpers";

export const useUser = () => {
  const ADD_USER = gql`
    mutation AddUser(
      $username: String!
      $firstName: String!
      $lastName: String!
      $email: String!
      $phone: String!
      $roleId: Int!
      $address: String
      $cityId: Int
      $hospitalId: [Int]
      $gender: Int
      $isActive: Boolean
      $primaryInvestigatorDoctorIds: [Int]
      $isSub: Boolean
    ) {
      AddUser(
        username: $username
        first_name: $firstName
        last_name: $lastName
        email: $email
        phone: $phone
        role_id: $roleId
        address: $address
        city_id: $cityId
        hospital_id: $hospitalId
        gender: $gender
        is_active: $isActive
        primary_investigator_doctor_ids: $primaryInvestigatorDoctorIds
        is_sub: $isSub
      ) {
        user_id
        username
        first_name
        full_name
        last_name
        email
        phone
        address
        role_id
        gender
        gender_name
        city_id
        hospital_id
        created_on
        updated_on
        country_name
        country_id
        city_name
        role_name
        region_id
        region_name
        hospital_list
        primary_investigator_doctor_ids
        is_sub
        primary_investigator_doctors
      }
    }
  `;

  const GET_USER = gql`
    query GetUsers {
      GetUsers {
        user_id
        username
        first_name
        full_name
        last_name
        email
        phone
        address
        role_id
        gender
        gender_name
        city_id
        created_on
        updated_on
        country_name
        country_id
        city_name
        role_name
        is_active
        region_id
        region_name
        created_by_name
        updated_by_name
        hospital_mapping {
          hospital_name
          user_hospital_mapping_id
          user_id
          hospital_id
          created_on
          updated_on
          is_active
        }
        hospital_list
        primary_investigator_doctor_ids
        is_sub
        primary_investigator_doctors
      }
    }
  `;

  const GET_DOCTOR_USER = gql`
    query GetDoctors($hospitalId: Int!) {
      GetDoctors(hospital_id: $hospitalId) {
        label: full_name
        value: user_id
      }
    }
  `;

  //   const DELETE_USER = gql``;
  const DELETE_USER = gql`
    mutation DeleteUser($userId: ID!) {
      DeleteUser(user_id: $userId) {
        user_id
        username
        first_name
        full_name
        last_name
        email
        phone
        address
        role_id
        gender
        gender_name
        city_id
        hospital_mapping {
          user_hospital_mapping_id
          user_id
          hospital_id
          created_on
          updated_on
          is_active
        }
        created_on
        updated_on
        country_name
        country_id
        city_name
        role_name
        is_active
        primary_investigator_doctor_ids
        is_sub
        primary_investigator_doctors
      }
    }
  `;

  const UPDATE_USER = gql`
    mutation UpdateUser(
      $userId: ID!
      $username: String!
      $firstName: String
      $email: String
      $lastName: String
      $phone: String
      $cityId: Int
      $address: String
      $gender: Int
      $roleId: Int
      $hospitalId: [Int]
      $isActive: Boolean
      $primaryInvestigatorDoctorIds: [Int]
      $isSub: Boolean
    ) {
      UpdateUser(
        user_id: $userId
        username: $username
        first_name: $firstName
        email: $email
        last_name: $lastName
        phone: $phone
        city_id: $cityId
        address: $address
        gender: $gender
        role_id: $roleId
        hospital_id: $hospitalId
        is_active: $isActive
        primary_investigator_doctor_ids: $primaryInvestigatorDoctorIds
        is_sub: $isSub
      ) {
        user_id
        username
        first_name
        full_name
        last_name
        email
        phone
        address
        role_id
        gender
        gender_name
        city_id
        hospital_id
        created_on
        updated_on
        country_name
        country_id
        city_name
        role_name
        region_id
        region_name
        primary_investigator_doctor_ids
        is_sub
        primary_investigator_doctors
      }
    }
  `;

  const UPDATE_PASSWORD = gql`
    mutation Mutation($password: String, $username: String) {
      UpdatePassword(password: $password, username: $username)
    }
  `;

  const VIEW_USER = gql`
    query ViewUser($userId: ID!) {
      ViewUser(user_id: $userId) {
        user_id
        username
        first_name
        full_name
        last_name
        email
        phone
        address
        role_id
        gender
        gender_name
        city_id
        hospital_mapping {
          user_hospital_mapping_id
          user_id
          hospital_id
          created_on
          updated_on
          is_active
        }
        created_on
        updated_on
        country_name
        country_id
        city_name
        role_name
        region_id
        region_name
        is_active
        primary_investigator_doctor_ids
        is_sub
        primary_investigator_doctors
      }
    }
  `;

  const useAddUser = useMutation(ADD_USER, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { AddUser } }) {
      try {
        const existingData = cache.readQuery({ query: GET_USER });
        if (existingData) {
          const newData = [AddUser, ...existingData.GetUsers];
          cache.writeQuery({
            query: GET_USER,
            data: { GetUsers: newData },
          });
        } else {
          // Refetch data if it's not available in the cache
          apollo_client
            .query({
              query: GET_USER,
            })
            .then(({ data }) => {
              if (data && data.GetUsers) {
                const newData = [AddUser, ...data.GetUsers];
                cache.writeQuery({
                  query: GET_USER,
                  data: { GetUsers: newData },
                });
              }
            })
            .catch((error) => {
              console.error("Error while refetching data:", error);
            });
        }
      } catch (error) {
        console.error(
          "An error occurred while updating the cache after updating a country:"
        );
      }
    },
  });
  const useUpdateUser = useMutation(UPDATE_USER, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { UpdateUser } }) {
      try {
        const existingData = cache.readQuery({ query: GET_USER });
        if (existingData) {
          const newData = existingData.GetUsers.map((item) =>
            item.user_id !== UpdateUser.user_id ? item : UpdateUser
          );
          cache.writeQuery({
            query: GET_USER,
            data: { GetUsers: newData },
          });
        } else {
          // Refetch data if it's not available in the cache
          apollo_client
            .query({
              query: GET_USER,
            })
            .then(({ data }) => {
              if (data && data.GetUsers) {
                const newData = data.GetUsers.map((item) =>
                  item.user_id !== UpdateUser.user_id ? item : UpdateUser
                );
                cache.writeQuery({
                  query: GET_USER,
                  data: { GetUsers: newData },
                });
              }
            })
            .catch((error) => {
              console.error("Error while refetching data:", error);
            });
        }
      } catch (error) {
        console.error(
          "An error occurred while updating the cache after updating a country:"
        );
      }
    },
  });

  const useDeleteUser = useMutation(DELETE_USER, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { DeleteUser } }) {
      try {
        const existingData = cache.readQuery({ query: GET_USER });
        console.log("existingData", existingData);
        if (existingData) {
          console.log("existingData1", existingData);

          const newData = existingData.GetUsers.map((item) =>
            item.user_id !== DeleteUser.user_id ? item : DeleteUser
          );
          cache.writeQuery({
            query: GET_USER,
            data: { GetUsers: newData },
          });
        } else {
          // Refetch data if it's not available in the cache
          apollo_client
            .query({
              query: GET_USER,
            })
            .then(({ data }) => {
              console.log("existingData2", existingData);

              if (data && data.GetUsers) {
                const newData = data.GetUsers.map((item) =>
                  item.user_id !== DeleteUser.user_id ? item : DeleteUser
                );
                cache.writeQuery({
                  query: GET_USER,
                  data: { GetUsers: newData },
                });
              }
            })
            .catch((error) => {
              console.error("Error while refetching data:", error);
            });
        }
      } catch (error) {
        console.error(
          "An error occurred while updating the cache after updating a country:"
        );
      }
    },
  });
  const useGetUser = useLazyQuery(GET_USER);
  const useGetDoctorUser = useLazyQuery(GET_DOCTOR_USER);
  const useUpdatePassword = useMutation(UPDATE_PASSWORD);
  const useViewUser = useLazyQuery(VIEW_USER);

  return {
    useAddUser,
    useGetUser,
    useUpdateUser,
    useGetDoctorUser,
    // useDeleteUser,
    useDeleteUser,
    useUpdatePassword,
    useViewUser,
  };
};
