import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { ProcessGrapqlError, apollo_client } from "../helpers";

export const useCity = () => {
  const ADD_CITY = gql`
    mutation Mutation($cityName: String!, $countryId: Int!, $regionId: Int!,$cityCode: String) {
      AddCity(
        city_name: $cityName
        country_id: $countryId
        region_id: $regionId
        city_code: $cityCode
      ) {
        city_id
        city_name
        region_id
        city_code
        country_name
        country_id
        is_active
        created_on
        updated_on
      }
    }
  `;

  const GET_CITIES = gql`
    query Query {
      GetCities {
        is_active
        city_id
        city_code
        region_id
        region_name
        city_name
        country_name
        country_id
        created_on
        updated_on
      }
    }
  `;
  const GET_CITIES_BY_COUNTRY = gql`
    query GetCitiesByCountry($countryId: Int!) {
      GetCitiesByCountry(country_id: $countryId) {
        city_id
        city_name
        country_id
        city_code
        region_id
        region_name
        country_name
        is_active
        created_on
        updated_on
      }
    }
  `;

  const DELETE_CITY = gql`
    mutation Mutation($cityId: Int!) {
      DeleteCity(city_id: $cityId) {
        city_id
      }
    }
  `;

  const UPDATE_CITY = gql`
    mutation UpdateCity(
      $cityId: Int!
      $cityName: String!
      $countryId: Int!
      $regionId: Int!
      $cityCode: String
    ) {
      UpdateCity(
        city_id: $cityId
        city_name: $cityName
        region_id: $regionId
        city_code: $cityCode
        country_id: $countryId
      ) {
        city_id
        city_name
        country_name
        region_id
        city_code
        country_id
        is_active
        created_on
        updated_on
      }
    }
  `;
  const useAddCity = useMutation(ADD_CITY, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { AddCity } }) {
      try {
        const existingData = cache.readQuery({ query: GET_CITIES });
        if (existingData) {
          const newData = [AddCity, ...existingData.GetCities];
          cache.writeQuery({
            query: GET_CITIES,
            data: { GetCities: newData },
          });
        } else {
          // Refetch data if it's not available in the cache
          apollo_client
            .query({
              query: GET_CITIES,
            })
            .then(({ data }) => {
              if (data && data.GetCities) {
                const newData = [AddCity, ...data.GetCities];
                cache.writeQuery({
                  query: GET_CITIES,
                  data: { GetCities: newData },
                });
              }
            })
            .catch((error) => {
              console.error("Error while refetching data:", error);
            });
        }
      } catch (error) {
        console.error(
          "An error occurred while updating the cache after updating a country:"
        );
      }
    },
  });
  const useUpdateCity = useMutation(UPDATE_CITY, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { UpdateCity } }) {
      try {
        const existingData = cache.readQuery({ query: GET_CITIES });
        if (existingData) {
          const newData = existingData.GetCities.map((item) =>
            item.city_id !== UpdateCity.city_id ? item : UpdateCity
          );
          cache.writeQuery({
            query: GET_CITIES,
            data: { GetCities: newData },
          });
        } else {
          // Refetch data if it's not available in the cache
          apollo_client
            .query({
              query: GET_CITIES,
            })
            .then(({ data }) => {
              if (data && data.GetCities) {
                const newData = data.GetCities.map((item) =>
                  item.city_id !== UpdateCity.city_id ? item : UpdateCity
                );
                cache.writeQuery({
                  query: GET_CITIES,
                  data: { GetCities: newData },
                });
              }
            })
            .catch((error) => {
              console.error("Error while refetching data:", error);
            });
        }
      } catch (error) {
        console.error(
          "An error occurred while updating the cache after updating a country:"
        );
      }
    },
  });
  const useDeleteCity = useMutation(DELETE_CITY, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { DeleteCity } }) {
      const existing = cache.readQuery({
        query: GET_CITIES,
        fetchPolicy: "cache-and-network",
      });
      if(existing){
      const newCities = existing.GetCities.filter(
        (item) => item.city_id !== DeleteCity.city_id
      );
      cache.writeQuery({
        query: GET_CITIES,
        data: { GetCities: newCities },
      });
    } else {
      // Refetch data if it's not available in the cache
      apollo_client
        .query({
          query: GET_CITIES,
        })
        .then(({ data }) => {
          if (data && data.GetCities) {
            const newData = data.GetCities.map((item) =>
              item.city_id !== DeleteCity.city_id ? item : DeleteCity
            );
            cache.writeQuery({
              query: GET_CITIES,
              data: { GetCities: newData },
            });
          }
        })
        .catch((error) => {
          console.error("Error while refetching data:", error);
        });
    }
    },
  });

  const useGetCities = useLazyQuery(GET_CITIES);
  const GetCitiesByCountry = useLazyQuery(GET_CITIES_BY_COUNTRY);

  return {
    useAddCity,
    useGetCities,
    useUpdateCity,
    useDeleteCity,
    GetCitiesByCountry,
  };
};
