import { gql, useLazyQuery } from "@apollo/client";

export const useReport = () => {
  const GET_GUEST_TOKEN = gql`
  query GetGuestToken($dashboardType: String!, $filters: FiltersInput, $section: String, $view: String,$self:Boolean) {
    GetGuestToken(dashboardType: $dashboardType, filters: $filters, section: $section, view: $view,,self:$self) {
      token
    }
  }
  `;

  const useGetGuestToken = useLazyQuery(GET_GUEST_TOKEN);

  return {
    useGetGuestToken,
  };
};
