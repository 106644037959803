import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  ListSubheader,
  InputAdornment,
  FormHelperText,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { MgpTextbox } from "./MgpTextbox";
import { styled } from "@mui/material/styles";
import { useMemo, useState } from "react";
import { MgpPaper } from "./MgpPaper";

const MgpSelect = styled(Select)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    fontSize: "13px",
    paddingTop: "6px",
  },
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1), // Add left padding here
  },
  "& .MuiFormLabel-root": {
    color: "red !important",
  },
}));

export const MgpDD = ({
  options = [],
  multiselect = false,
  name,
  label,
  value,
  onChange,
  fullWidth,
  onBlur,
  error,
  helperText,
  disabled,
  ...rest
}) => {
  const containsText = (text, searchText) =>
    text?.toLowerCase().indexOf(searchText?.toLowerCase()) > -1;
  const [searchText, setSearchText] = useState("");

  const renderValue = (selected) => {
    if (multiselect) {
      const selectedLabels = selected.map((val) => {
        const foundOption = displayedOptions.find(
          (option) => option.value.toString() === val.toString()
        );
        return foundOption ? foundOption.label : "";
      });
      return selectedLabels.join(", "); // Concatenates labels for display
    } else {
      const foundOption = displayedOptions.find(
        (option) => option.value.toString() === selected.toString()
      );
      return foundOption ? foundOption.label : "";
    }
  };

  const displayedOptions = useMemo(() => {
    return options.filter((option) => containsText(option.label, searchText));
  }, [options, searchText]);
  console.log(
    "dettt",
    Boolean(
      (value && (Array.isArray(value) ? value.length : value)) ||
      searchText !== ""
    )
  );
  return (
    <FormControl fullWidth={fullWidth} error={error}>
      <InputLabel
        id={`${name}-label`}
        shrink={Boolean(
          (value && (Array.isArray(value) ? value.length : value)) ||
          searchText !== ""
        )}
        style={{
          marginTop: Boolean(
            (value && (Array.isArray(value) ? value.length : value)) ||
            searchText !== ""
          ) ? "2px" : null,
          fontSize: "13px",
          position: !Boolean(
            (value && (Array.isArray(value) ? value.length : value)) ||
            searchText !== ""
          )
            ? "absolute"
            : "",
          top: !Boolean(
            (value && (Array.isArray(value) ? value.length : value)) ||
            searchText !== ""
          )
            ? "50%"
            : "",
          transform: !Boolean(
            (value && (Array.isArray(value) ? value.length : value)) ||
            searchText !== ""
          )
            ? "translateY(-50%)"
            : "",
          paddingLeft: !Boolean(
            (value && (Array.isArray(value) ? value.length : value)) ||
            searchText !== ""
          )
            ? "8px"
            : "",
        }}
      >
        {label}
      </InputLabel>
      <MgpSelect
        labelId={`${name}-label`}
        id={`${name}-select`}
        MenuProps={{
          autoFocus: false,
          PaperProps: {
            component: MgpPaper,
          },
        }}
        disabled={disabled}
        multiple={multiselect}
        name={name}
        renderValue={(selected) => <Box sx={{ whiteSpace: "wrap", fontSize: "13px" }}>{renderValue(selected)}</Box>}
        value={value}
        onChange={onChange}
        input={
          <OutlinedInput
            size="small"
            label={label}
            notched={Boolean(
              (value && (Array.isArray(value) ? value.length : value)) ||
              searchText !== ""
            )}
          />
        }
        onBlur={onBlur}
        {...rest}
      >
        <ListSubheader>
          <MgpTextbox
            size="small"
            name={name}
            id={name}
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputLabelProps={{
              style: {
                fontSize: "13px !important",
                fontWeight: "400",
                paddingTop: "2px",
                lineHeight: "28px",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ width: "21px", height: "22px" }} />
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {displayedOptions.map((row) => (
          <MenuItem key={row.value} value={row.value}>
            {row.label}
          </MenuItem>
        ))}
      </MgpSelect>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
