const set = (key, value) => {
  localStorage.setItem(key, value);
};

const get = (key) => {
  return localStorage.getItem(key);
};

const remove = (key) => {
  return localStorage.removeItem(key);
};

const clear = () => {
  return localStorage.clear();
};
export const StorageHelper = {
  set,
  get,
  remove,
  clear,
};
