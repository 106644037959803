import { ToggleButton, InputLabel, FormHelperText } from "@mui/material";
import { Box, color } from "@mui/system";
import { colors } from "../../theme/colors";
import { styled } from "@mui/material/styles";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";
import { useEffect } from "react";
import { RedAsterisk } from "./MgpAccordion";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    borderColor: `${colors.GOLD} `,
    "&:active": {
      backgroundColor: `${colors.PRIMARY_DARK} `,
      color: `${colors.PRIMARY_CONTRAST}`,
    },
    "&.Mui-selected": {
      backgroundColor: `${colors.PRIMARY_DARK} `,
      color: `${colors.PRIMARY_CONTRAST}`,
    },
    // "&:focus": {
    //   backgroundColor: `${colors.PRIMARY_DARK} `,
    //   color: `${colors.PRIMARY_CONTRAST}`,
    // },
    "&:hover": {
      backgroundColor: `${colors.PRIMARY_DARK} `,
      color: `${colors.PRIMARY_CONTRAST}`,
    },
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      // border: "2px solid",
      // borderRadius: `1px solid ${colors.GOLD}`,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton}, & .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: `2px solid ${colors.GOLD}`,
    },
  [`& .${toggleButtonGroupClasses.firstButton}`]: {
    borderColor: `${colors.GOLD} `,
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
  },
  [`& .${toggleButtonGroupClasses.lastButton}`]: {
    borderColor: `${colors.GOLD} `,
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
  },
}));
export const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  "& .red-asterisk": {
    color: "red", // Change this to the desired color
    fontSize: "16px",
  },
}));
export const MgpToggleButtonGroup = ({
  disabled,
  splits,
  name,
  label,
  touched,
  errors,
  handleBlur,
  setFieldValue,
  values,
  options,
  defaultValue,
  dependent,
  required,
  attr,
  size,
  viewMode,
  newVisit,
  removeDependence,
  checkfieldvalue,
  setValues,
  commentData,
  encountedDataLatestStatus,
  edit,
  parentName,
  parentType,
  edit_patientform,
  followuplable,
  handleFocus,
  handleChange,
  attribute,
}) => {
  const hasAsterisk = label.endsWith("*");
  const onBlur = (event) => {
    if (handleBlur) {
      handleBlur(event);
      // if (handleFocus) {
      //   console.log("gendermale");
      //   handleFocus(event, currentValue);
      // }
    }
  };
  return (
    <>
      <div>
        <CustomInputLabel
          sx={{
            marginBottom: "4px",
            color: Boolean(touched[name] && errors[name])
              ? colors.ERROR
              : colors.GOLD,
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontSize: "15px",
            fontWeight: "500",
          }}
        >
          {values?.["basicVisit_typeofvisit"] === "Follow Up Visit" ? (
            followuplable === undefined ? (
              hasAsterisk ? (
                <>
                  {label?.slice(0, -1)}
                  <RedAsterisk>*</RedAsterisk>
                </>
              ) : (
                label
              )
            ) : hasAsterisk ? (
              <>
                {followuplable?.slice(0, -1)}
                <RedAsterisk>*</RedAsterisk>
              </>
            ) : (
              followuplable
            )
          ) : hasAsterisk ? (
            <>
              {label?.slice(0, -1)}
              <RedAsterisk>*</RedAsterisk>
            </>
          ) : (
            label
          )}
          {/* {hasAsterisk ? (
            <>
              {label?.slice(0, -1)}
              <span className="red-asterisk">*</span>
            </>
          ) : (
            label
          )} */}
        </CustomInputLabel>
        <Box>
          <StyledToggleButtonGroup
            size={size}
            id={name}
            name={name}
            fullWidth
            onBlur={onBlur}
            exclusive={true}
            data-toggle-group-name={name} // Ensure this attribute is set
            onChange={(event, newValue) => {
              handleChange(name, attribute);

              if (handleFocus && name === "demography_gender") {
                handleFocus(event, newValue);
              }

              if (newValue === null) {
                setFieldValue(name, "");
              } else {
                setFieldValue(name, newValue);
              }

              if (
                removeDependence &&
                (newValue === null || newValue !== null)
              ) {
                if (checkfieldvalue.includes(newValue)) {
                  const updatedValues = {};
                  updatedValues[name] = newValue;

                  removeDependence.forEach((nameToRemove) => {
                    updatedValues[nameToRemove] = "";
                  });
                  setValues((prevValues) => ({
                    ...prevValues,
                    ...updatedValues,
                  }));
                }
              }
            }}
            value={values[name]}
            variant="outlined"
            aria-label="text alignment"
          >
            {options.map((option, idx) => {
              return (
                <ToggleButton
                  key={idx}
                  disabled={
                    edit_patientform && !disabled
                      ? false
                      : (parentType === "Accordion" &&
                          (encountedDataLatestStatus === 4 ||
                            encountedDataLatestStatus === 6)) ||
                        encountedDataLatestStatus === 4 ||
                        encountedDataLatestStatus === 6
                      ? !edit
                        ? true
                        : disabled
                      : disabled || viewMode
                      ? true
                      : false
                  }
                  value={option}
                  sx={{
                    textTransform: "none",
                  }}
                >
                  {option}
                </ToggleButton>
              );
              // }
            })}
          </StyledToggleButtonGroup>
        </Box>
        <FormHelperText error={Boolean(touched[name] && errors[name])}>
          {/* {touched[name] && errors[name]} */}
        </FormHelperText>
      </div>
    </>
  );
};

export const MgpToggleButtonGroupTab = ({
  options,
  defaultValue,
  value,
  onChange,
}) => {
  return (
    <>
      <div>
        <Box>
          <StyledToggleButtonGroup
            size={"small"}
            fullWidth
            exclusive={true}
            variant="outlined"
            aria-label="text alignment"
            value={value} // Set value here
            onChange={onChange} // Pass onChange handler
          >
            {options.map((option) => {
              return (
                <ToggleButton
                  value={option}
                  sx={{
                    textTransform: "none",
                  }}
                >
                  {option}
                </ToggleButton>
              );
            })}
          </StyledToggleButtonGroup>
        </Box>
      </div>
    </>
  );
};
