import {
  Backdrop,
  CircularProgress,
  Container,
  Grid,
  Stack,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import {
  MgpButton,
  MgpTextboxFormik,
  MgpDD,
  MgpCheckbox,
} from "../../../components/global";
import "react-phone-input-2/lib/style.css";
import { isValidNumber, parsePhoneNumber } from "libphonenumber-js";
import { ENV } from "../../../helpers";
import { useMaster } from "../../../hooks";
import { useUser } from "../../../hooks/useUsers";
import Loader from "../../Loader/Loader";
import { colors } from "../../../theme/colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CheckBox } from "@mui/icons-material";
import { useEffect, useState, useRef } from "react";
import PhoneInput from "react-phone-input-2";

export const UpdateUser = ({
  setAlertOption,
  setMainPageloading,
  setMessage,
  setAlertType,
  closed = () => {},
  edit = {},
  self = false,
}) => {
  const {
    user_id = "",
    username = "",
    first_name = "",
    last_name = "",
    email = "",
    phone = "",
    role_id = "",
    hospital_mapping = "",
    is_active = true,
    address = "",
    city_id = "",
    gender = "",
    country_id = "",
    region_id = "",
    countryCode = "",
    primary_investigator_doctor_ids = [],
    is_sub = false,
  } = edit;

  const qualityUser = ENV.quality_user_role_id;
  const formikRef = useRef(null);
  console.log("edit", edit);
  const {
    roles,
    cities,
    hospitals,
    countries,
    setSelectedCountry,
    genders,
    setSelectedRegion,
    regions,
    setSelectedCity,
    doctors,
    setSelectedHospital,
  } = useMaster();

  const { useAddUser, useUpdateUser } = useUser();
  const [AddUser] = useAddUser;
  const [UpdateUser] = useUpdateUser;
  const initialHospitalIds = hospital_mapping
    ? hospital_mapping.map((r) => String(r.hospital_id))
    : [];

  const hospitalId =
    initialHospitalIds.length > 0 ? initialHospitalIds[0] : "" || "";

  useEffect(() => {
    if (user_id && hospitalId) {
      setSelectedHospital(hospitalId);
    }
    if (user_id && country_id) {
      setSelectedCountry(country_id);
    }
    if (user_id && region_id) {
      setSelectedRegion(region_id);
    }
    if (user_id && city_id) {
      setSelectedCity(city_id);
    }
  }, [hospitalId]);

  const isValidPhoneNumber = () => {
    console.log("formikRef?.current?.values", formikRef?.current?.values);
    const phoneNumber = `+${formikRef?.current?.values?.phone}`;
    try {
      // Parse and validate the phone number
      const parsedNumber = parsePhoneNumber(
        phoneNumber,
        formikRef?.current?.values?.countryCode.toUpperCase()
      );
      console.log(
        "parsedNumber",
        parsedNumber,
        formikRef?.current?.values?.countryCode,
        parsedNumber.isValid()
      );
      return parsedNumber.isValid();
    } catch (error) {
      console.log("Error parsing phone number:", error.message, countryCode);
    }
  };
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const validationSchema = yup.object().shape({
    username: yup.string().required("Required"),
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    email: yup.string().required("Required"),
    // address: yup.string().required("Required"),
    regionId: yup.string().required("Required"),
    cityId: yup.string().required("Required"),
    countryId: yup.string().required("Required"),
    roleId: yup.string().required("Required"),
    phone: yup
      .string()
      .test("is-valid-phone", "Invalid phone number", (value) => {
        const isValid = isValidPhoneNumber();
        return isValid ? true : false;
      })
      .required("Required"),
    gender: yup.string().required("Required"),
    hospitalIds: yup.array().when("roleId", (roleId) => {
      if (parseInt(roleId) === parseInt(ENV.quality_user_role_id)) {
        return yup.array().min(1, "Select at least one hospital");
      }
      return yup.array().optional();
    }),
    hospitalId: yup.string().when("roleId", (roleId) => {
      if (
        parseInt(roleId) === parseInt(ENV.doctor_role_id) ||
        parseInt(roleId) === parseInt(ENV.medical_lead_role_id) ||
        parseInt(roleId) === parseInt(ENV.secondary_investigator_role_id)
      ) {
        return yup.string().required("Required");
      }
      return yup.string().nullable();
    }),
    primaryInvestigatorDoctorIds: yup.array().when("roleId", (roleId) => {
      if (parseInt(roleId) === parseInt(ENV.secondary_investigator_role_id)) {
        return yup.array().min(1, "Select at least one primary investigator");
      }
    }),
    primaryInvestigatorDoctorIds: yup.array().when("isSub", (isSub) => {
      if (isSub[0]) {
        return yup.array().min(1, "Select at least one primary investigator");
      }
    }),
  });

  console.log("william4", primary_investigator_doctor_ids);

  return (
    <>
      <Container fluid>
        <Formik
          innerRef={formikRef}
          initialValues={{
            username,
            firstName: first_name,
            lastName: last_name,
            email,
            phone: phone,
            countryCode,
            isActive: is_active,
            roleId: role_id,
            hospitalIds: initialHospitalIds,
            hospitalId:
              initialHospitalIds.length > 0 ? initialHospitalIds[0] : "" || "",
            address,
            cityId: city_id,
            countryId: country_id,
            gender,
            regionId: region_id,
            primaryInvestigatorDoctorIds: primary_investigator_doctor_ids,
            isSub: is_sub,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setMainPageloading(true);
            setLoadingSubmit(true);
            const payload = {
              userId: values.userId,
              username: values.username,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email.toLowerCase().trim(),
              phone: values.phone,
              address: values.address,
              roleId: Number(values.roleId),
              cityId: Number(values.cityId),
              countryId: Number(values.countryId),
              isActive: values.isActive,
              gender: Number(values.gender),
              hospitalId: (() => {
                if (Number(values.roleId) === Number(qualityUser)) {
                  return [...new Set(values.hospitalIds.map(Number))];
                } else if (
                  values.hospitalId === "" ||
                  values.hospitalId.length === 0
                ) {
                  return [];
                } else {
                  return [Number(values.hospitalId)];
                }
              })(),
              primaryInvestigatorDoctorIds: (() => {
                return [
                  ...new Set(values.primaryInvestigatorDoctorIds.map(Number)),
                ];
              })(),
              isSub: values.isSub,
            };

            if (user_id === "") {
              AddUser({
                variables: payload,
                onCompleted: () => {
                  setMainPageloading(false);
                  closed();
                  setMainPageloading(false);
                  setLoadingSubmit(false);
                  setAlertOption(true);
                  setMessage("User added successfully");
                  setAlertType("success");
                },
                onError: (err) => {
                  setMainPageloading(false);
                  setAlertOption(true);
                  setAlertType("error");
                  setMessage(err.message);
                },
              });
            } else {
              payload.userId = Number(user_id);
              UpdateUser({
                variables: payload,
                onCompleted: () => {
                  setMainPageloading(false);
                  closed();
                  setMainPageloading(false);
                  setAlertOption(true);
                  setAlertType("success");
                  setMessage("Profile has been updated");
                },
                onError: (err) => {
                  setAlertOption(true);
                  setAlertType("error");
                  setMessage(
                    "Can't able to update user,Please try again later!"
                  );
                },
              });
            }
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            touched,
            errors,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <MgpTextboxFormik
                    fullWidth
                    size="small"
                    label="User Name"
                    name="username"
                    variant="outlined"
                    splits={"username".split(".")}
                    values={values}
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    disabled={user_id === "" ? false : true}
                    onBlur={handleBlur}
                    error={Boolean(touched.username && errors.username)}
                    helperText={touched.username && errors.username}
                  />
                </Grid>

                <Grid item md={6}>
                  <MgpTextboxFormik
                    fullWidth
                    size="small"
                    label="First Name"
                    name="firstName"
                    variant="outlined"
                    splits={"firstName".split(".")}
                    values={values}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                </Grid>

                <Grid item md={6}>
                  <MgpTextboxFormik
                    fullWidth
                    size="small"
                    label="Last Name"
                    name="lastName"
                    variant="outlined"
                    values={values}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Grid>

                <Grid item md={6}>
                  <PhoneInput
                    style={{ width: "100%" }}
                    inputProps={{
                      style: {
                        borderColor:
                          touched.phone && errors.phone
                            ? colors.ERROR
                            : colors.GOLD,
                        width: "100%",
                        height: "41px",
                      },
                    }}
                    id="outlined-required"
                    label="Phone"
                    type="text"
                    name="phone"
                    fullWidth
                    value={values?.phone}
                    country={"sa"}
                    onChange={(value, country, e) => {
                      console.log("countryCode", values?.phone);
                      setFieldValue("countryCode", country?.countryCode);
                      setFieldValue("phone", value);
                    }}
                    error={Boolean(touched.phone && errors.phone)}
                  />
                </Grid>

                <Grid item md={6}>
                  <MgpTextboxFormik
                    fullWidth
                    size="small"
                    label="Email"
                    name="email"
                    variant="outlined"
                    values={values}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>

                {/* Drop-downs for roles and hospital */}
                <Grid item md={6}>
                  <MgpDD
                    label="Role"
                    variant="outlined"
                    size="small"
                    name="roleId"
                    value={values.roleId}
                    onChange={(e) => {
                      // if(parseInt(e.target.value) !== parseInt(ENV.doctor_role_id))
                      setFieldValue("isSub", false);
                      setFieldValue("primaryInvestigatorDoctorIds", []);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    options={roles}
                    fullWidth
                    error={Boolean(touched.roleId && errors.roleId)}
                    helperText={touched.roleId && errors.roleId}
                    disabled={self ? true : false}
                  />
                </Grid>
                <Grid item md={6}>
                  <MgpDD
                    label="Country"
                    variant="outlined"
                    size="small"
                    name="countryId"
                    multiselect={false}
                    fullWidth
                    value={values.countryId}
                    options={countries}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue("regionId", "");
                      setFieldValue("cityId", "");
                      setFieldValue("hospitalId", "");
                      setFieldValue("hospitalIds", []);
                      setSelectedCountry(e.target.value);
                    }}
                    onBlur={handleBlur}
                    error={Boolean(touched.countryId && errors.countryId)}
                    helperText={touched.countryId && errors.countryId}
                    disabled={self ? true : false}
                  />
                </Grid>
                <Grid item md={6}>
                  <MgpDD
                    label="Region"
                    variant="outlined"
                    size="small"
                    name="regionId"
                    error={Boolean(touched["regionId"] && errors["regionId"])}
                    fullWidth
                    disabled={values["countryId"] == "" || self ? true : false}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue("cityId", "");
                      setFieldValue("hospitalId", "");
                      setFieldValue("hospitalIds", []);
                      setSelectedRegion(e.target.value);
                    }}
                    options={regions}
                    helperText={touched["regionId"] && errors["regionId"]}
                    onBlur={handleBlur}
                    value={values["regionId"]}
                    multiselect={false}
                  />
                </Grid>
                <Grid item md={6}>
                  <MgpDD
                    label="City"
                    variant="outlined"
                    size="small"
                    name="cityId"
                    multiselect={false}
                    fullWidth
                    value={values.cityId}
                    options={cities}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue("hospitalId", "");
                      setFieldValue("hospitalIds", []);
                      setSelectedCity(e.target.value);
                    }}
                    onBlur={handleBlur}
                    error={Boolean(touched.cityId && errors.cityId)}
                    helperText={touched.cityId && errors.cityId}
                    disabled={values["regionId"] == "" || self ? true : false}
                  />
                </Grid>

                {values.roleId == qualityUser ? (
                  <Grid item md={6}>
                    <MgpDD
                      label="Hospital(s)"
                      variant="outlined"
                      size="small"
                      name="hospitalIds"
                      multiselect={true}
                      fullWidth
                      value={values.hospitalIds}
                      options={hospitals}
                      onChange={(e) => {
                        // handleChange(e)
                        setFieldValue("hospitalIds", e.target.value);
                      }}
                      onBlur={handleBlur}
                      error={Boolean(touched.hospitalIds && errors.hospitalIds)}
                      helperText={touched.hospitalIds && errors.hospitalIds}
                      disabled={values["cityId"] == "" || self ? true : false}
                    />
                  </Grid>
                ) : values.roleId == ENV.doctor_role_id ||
                  values.roleId == ENV.medical_lead_role_id ||
                  values.roleId == ENV.secondary_investigator_role_id ? (
                  <Grid item md={6}>
                    <MgpDD
                      label="Hospital"
                      variant="outlined"
                      size="small"
                      name="hospitalId"
                      multiselect={false}
                      fullWidth
                      value={values.hospitalId}
                      options={hospitals}
                      onChange={(e) => {
                        // handleChange(e)

                        setFieldValue("hospitalId", e.target.value);
                        setFieldValue("primaryInvestigatorDoctorIds", []);
                        setSelectedHospital(e.target.value);
                      }}
                      onBlur={handleBlur}
                      error={Boolean(touched.hospitalId && errors.hospitalId)}
                      helperText={touched.hospitalId && errors.hospitalId}
                      disabled={values["cityId"] == "" || self ? true : false}
                    />
                  </Grid>
                ) : (
                  <Grid item md={6}>
                    <MgpDD
                      label={values.roleId ? "All Hospital(s)" : "Hospital"}
                      variant="outlined"
                      size="small"
                      name="hospitalId"
                      multiselect={false}
                      fullWidth
                      value={values.hospitalId}
                      options={hospitals}
                      onChange={(e) => {
                        // handleChange(e)
                        setFieldValue("hospitalId", e.target.value);
                      }}
                      onBlur={handleBlur}
                      error={Boolean(touched.hospitalId && errors.hospitalId)}
                      helperText={touched.hospitalId && errors.hospitalId}
                      disabled={true}
                    />
                  </Grid>
                )}

                <Grid item md={6}>
                  <MgpDD
                    label="Gender"
                    variant="outlined"
                    size="small"
                    name="gender"
                    multiselect={false}
                    fullWidth
                    value={values.gender}
                    options={genders}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.gender && errors.gender)}
                    helperText={touched.gender && errors.gender}
                  />
                </Grid>
                <Grid item md={6}>
                  <MgpTextboxFormik
                    fullWidth
                    size="small"
                    label="Address"
                    name="address"
                    variant="outlined"
                    values={values}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    error={Boolean(touched.address && errors.address)}
                    helperText={touched.address && errors.address}
                  />
                </Grid>
                {!self &&
                (values.isSub ||
                  parseInt(values.roleId) ==
                    parseInt(ENV.secondary_investigator_role_id)) ? (
                  <Grid item md={6}>
                    <MgpDD
                      label="Select Principal Investigator"
                      variant="outlined"
                      size="small"
                      name="primaryInvestigatorDoctorIds"
                      multiselect={true}
                      fullWidth
                      value={values.primaryInvestigatorDoctorIds.map(String)}
                      options={doctors}
                      disabled={values.hospitalId === "" || self ? true : false}
                      onChange={(e) => {
                        //handleChange(e);
                        setFieldValue(
                          "primaryInvestigatorDoctorIds",
                          e.target.value
                        );
                      }}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.primaryInvestigatorDoctorIds &&
                          errors.primaryInvestigatorDoctorIds
                      )}
                      helperText={
                        touched.primaryInvestigatorDoctorIds &&
                        errors.primaryInvestigatorDoctorIds
                      }
                    />
                  </Grid>
                ) : (
                  <Grid item md={6}></Grid>
                )}

                {!self ? (
                  <Grid item md={6}>
                    <FormControlLabel
                      sx={{
                        // color: colors.SECONDARY_TEXT,
                        fontSize: "12px",
                        "& .MuiFormControlLabel-label": {
                          fontSize: "12px", // Ensure this targets the label specifically
                        },
                      }}
                      control={
                        <MgpCheckbox
                          checked={values["isActive"]}
                          name="isActive"
                          label={"Activate"}
                          onChange={(event) => {
                            const { name, checked } = event.target;
                            // Update the values object with the new checkbox state
                            console.log(
                              "The e target in Values All is",
                              values
                            );
                            setFieldValue("isActive", checked);
                          }}
                          style={{
                            color: colors.SECONDARY_TEXT,
                          }}
                          size="small"
                          inputProps={{ "aria-label": "controlled" }}
                          disabled={self ? true : false}
                        />
                      }
                      label={"Activate"}
                    />
                  </Grid>
                ) : (
                  <></>
                )}

                {!self && values.roleId == ENV.doctor_role_id ? (
                  <Grid item md={6}>
                    <FormControlLabel
                      sx={{
                        // color: colors.SECONDARY_TEXT,
                        fontSize: "12px",
                        "& .MuiFormControlLabel-label": {
                          fontSize: "12px", // Ensure this targets the label specifically
                        },
                      }}
                      control={
                        <MgpCheckbox
                          checked={values["isSub"]}
                          name="isSub"
                          label={"Assign Sub Investigator Role"}
                          onChange={(event) => {
                            const { name, checked } = event.target;
                            // Update the values object with the new checkbox state
                            setFieldValue("isSub", checked);
                            setFieldValue("primaryInvestigatorDoctorIds", []);
                          }}
                          style={{
                            color: colors.SECONDARY_TEXT,
                          }}
                          size="small"
                          inputProps={{ "aria-label": "controlled" }}
                          disabled={self ? true : false}
                        />
                      }
                      label={"Assign Sub Investigator Role"}
                    />
                  </Grid>
                ) : (
                  <Grid item md={6}></Grid>
                )}

                <Grid item md={12}>
                  <Stack
                    spacing={2}
                    justifyContent={"flex-end"}
                    direction={"row"}
                  >
                    <MgpButton onClick={closed} variant="contained">
                      Cancel
                    </MgpButton>
                    <MgpButton type="submit" variant="contained">
                      {user_id === "" ? "Save" : "Update"}
                    </MgpButton>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Container>
      {loadingSubmit && (
        <Backdrop open={loadingSubmit} style={{ zIndex: 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};
