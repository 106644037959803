import {
  Badge,
  Box,
  Divider,
  Grid,
  IconButton,
  Menu,
  Popover,
  Stack,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { MgpText } from "./MgpText";
import { Padding } from "./Padding";
import { useEffect, useState } from "react";
import { colors } from "../../theme/colors";
import { useNotification } from "../../hooks";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/AccountCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DoneAllIcon from "@mui/icons-material/DoneAll";

export const localDate = (timedate) => {
  let date = new Date(parseInt(timedate));

  // Manually extract the date parts
  let day = String(date.getDate()).padStart(2, "0");
  let month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  let year = date.getFullYear();

  // Extract the time parts
  let hours = date.getHours();
  let minutes = String(date.getMinutes()).padStart(2, "0");
  let seconds = String(date.getSeconds()).padStart(2, "0");
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'
  let formattedHours = String(hours).padStart(2, "0");

  // Construct the final formatted date string
  let formattedDate = `${day}/${month}/${year}, ${formattedHours}:${minutes} ${ampm}`;

  return formattedDate;
};

export default function MgpNotification({
  badgeContent,
  notification,
  setNotification,
  loaders,
  setLoaders,
}) {
  const {
    useGetNotifications,
    useUpdateNotification,
    useUpdateAllNotification,
  } = useNotification();
  const [GetNotifications, { data, loading, error }] = useGetNotifications;
  const [ReadNotifications] = useUpdateNotification;
  const [ReadAllNotifications] = useUpdateAllNotification;
  const navigate = useNavigate();
  // console.log("badgeContent",badgeContent,notification)

  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationData, setNotificationData] = useState([]);

  const handleClick = (event) => {
    setAnchorEl((prevAnchorEl) => (prevAnchorEl ? null : event.currentTarget));
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (anchorEl) {
      setLoaders(true);
      setNotification([]);
      GetNotifications({
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
          setNotificationData([...data.GetNotifications]);
          setLoaders(false);
          // if(data.GetNotifications.length)
          // ReadNotifications({variables:{
          //   notificationIds:data?.GetNotifications.map((elem)=>Number(elem.notification_id))
          // }})
        },
      });
    }
  }, [anchorEl]);

  const messages = (keyValue) => {
    let types = typeof keyValue;
    if (types === "object" && Array.isArray(keyValue)) {
      types = "array";
    }
    switch (types) {
      case "string":
        return (
          <MgpText
            sx={{ fontSize: "12px", fontWeight: 400, lineHeight: "20px" }}
          >
            {keyValue}
          </MgpText>
        );
      case "object":
        if (!keyValue.is_notify) break;
        return (
          <MgpText
            sx={{ fontSize: "12px", fontWeight: 400, lineHeight: "20px" }}
          >
            {keyValue.key} : {keyValue.value}
          </MgpText>
        );
      case "array":
        return keyValue.map((elem, index) => (
          <>
            <MgpText
              sx={{ fontSize: "12px", fontWeight: 400, lineHeight: "20px" }}
            >
              {elem.key1}({index + 1}) : {elem.value1}
            </MgpText>
            <MgpText
              sx={{ fontSize: "12px", fontWeight: 400, lineHeight: "20px" }}
            >
              {elem.key2}({index + 1}) : {elem.value2}
            </MgpText>
          </>
        ));
      default:
        return null;
    }
  };

  return (
    <>
      <div>
        <IconButton
          aria-describedby={id}
          type="button"
          onClick={handleClick}
          size="large"
        >
          <Badge
            variant={"standard"}
            badgeContent={badgeContent}
            color="success"
          >
            <NotificationsIcon sx={{ color: colors.NAVBAR, height: "20px" }} />
          </Badge>
        </IconButton>
        <Menu
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{ paddingBottom: "0px" }}
        >
          <Stack width={320}>
            <Padding size={"6px 8px"}>
              <MgpText variant="h5" sx={{ color: colors.BLACK }}>
                Notifications
              </MgpText>
              <IconButton
                onClick={handleClose}
                sx={{
                  color: colors.BLACK,
                  marginBottom: "30px",
                  marginLeft: "24px",
                  position: "absolute",
                  top: 3,
                  right: 3,
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33333 11.6667L10 10M10 10L11.6667 8.33333M10 10L8.33333 8.33333M10 10L11.6667 11.6667M17.5 10C17.5 10.9849 17.306 11.9602 16.9291 12.8701C16.5522 13.7801 15.9997 14.6069 15.3033 15.3033C14.6069 15.9997 13.7801 16.5522 12.8701 16.9291C11.9602 17.306 10.9849 17.5 10 17.5C9.01509 17.5 8.03982 17.306 7.12987 16.9291C6.21993 16.5522 5.39314 15.9997 4.6967 15.3033C4.00026 14.6069 3.44781 13.7801 3.0709 12.8701C2.69399 11.9602 2.5 10.9849 2.5 10C2.5 8.01088 3.29018 6.10322 4.6967 4.6967C6.10322 3.29018 8.01088 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z"
                    stroke="#2C2C2C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </IconButton>
            </Padding>
            <Divider></Divider>
            <Box
              sx={{
                minHeight: "250px",
                maxHeight: "330px",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "6px",
                  height: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  borderRadius: "3px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Stack direction={"row"} justifyContent={"center"}>
                {loaders ? (
                  <Padding size="5px 5px">
                    <CircularProgress color="secondary" size={20} />
                  </Padding>
                ) : !notificationData.length ? (
                  <Padding size="5px 5px">
                    <MgpText variant="caption" color={colors.PRIMARY}>
                      No Notification
                    </MgpText>
                  </Padding>
                ) : null}
              </Stack>
              {!loaders &&
                notificationData.map((elem) => (
                  <Stack
                    sx={{
                      "&:hover": {
                        backgroundColor: "#EEF8FB",
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      ReadNotifications({
                        variables: {
                          notificationIds: [Number(elem.notification_id)],
                        },
                        onCompleted: () => {
                          navigate(elem.path);
                        },
                      });
                    }}
                  >
                    <Padding size="4px 8px">
                      {!elem.read ? (
                        <Box
                          sx={{
                            backgroundColor: colors.PRIMARY,
                            height: "10px",
                            width: "10px",
                            float: "right",
                            marginBottom: "-20px",
                            borderRadius: "20px",
                          }}
                        ></Box>
                      ) : null}
                      <Grid container direction="row" spacing={0}>
                        <Grid item md={1} sx={{ marginTop: "4px" }}>
                          {/* <PersonIcon color="primary" sx={{ height: "20px" }} /> */}
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8ZM10 5C10 5.53043 9.78929 6.03914 9.41421 6.41421C9.03914 6.78929 8.53043 7 8 7C7.46957 7 6.96086 6.78929 6.58579 6.41421C6.21071 6.03914 6 5.53043 6 5C6 4.46957 6.21071 3.96086 6.58579 3.58579C6.96086 3.21071 7.46957 3 8 3C8.53043 3 9.03914 3.21071 9.41421 3.58579C9.78929 3.96086 10 4.46957 10 5ZM8 9C7.0426 8.99981 6.10528 9.27449 5.29942 9.7914C4.49356 10.3083 3.85304 11.0457 3.454 11.916C4.01668 12.5706 4.71427 13.0958 5.49894 13.4555C6.28362 13.8152 7.13681 14.0009 8 14C8.86319 14.0009 9.71638 13.8152 10.5011 13.4555C11.2857 13.0958 11.9833 12.5706 12.546 11.916C12.147 11.0457 11.5064 10.3083 10.7006 9.7914C9.89472 9.27449 8.9574 8.99981 8 9Z"
                              fill="#106881"
                            />
                          </svg>
                        </Grid>
                        <Grid item md={11}>
                          <MgpText
                            sx={{ fontWeight: 600, fontSize: "13px" }}
                            color={colors.PRIMARY}
                          >
                            {elem.subject}
                          </MgpText>
                          <Box sx={{ fontSize: "10px", color: "#336953" }}>
                            {Object.keys(elem.message_json).map((e) =>
                              messages(elem.message_json[e])
                            )}
                          </Box>
                          <MgpText
                            sx={{
                              fontSize: "9px",
                              color: "#4A4A4A",
                              marginTop: "3px",
                              fontStyle: "italic",
                            }}
                          >
                            {localDate(elem.created_on).toUpperCase()}
                          </MgpText>
                        </Grid>
                      </Grid>
                    </Padding>
                  </Stack>
                ))}
            </Box>
            <Stack
              sx={{
                margin: "6px 2px -6px 2px",
                // marginBottom:"-6px",
                // marginTop:"6px",
                borderBottomLeftRadius: "6px",
                borderBottomRightRadius: "6px",
                backgroundColor:
                  loaders || !notificationData.filter((e) => !e.read).length
                    ? "#EEF8FB"
                    : "#106881",
                color:
                  loaders || !notificationData.filter((e) => !e.read).length
                    ? colors.BLACK
                    : "white",
                "&:hover": {
                  backgroundColor: "#EEF8FB",
                  cursor: "pointer",
                  color: "#336953",
                  "& svg path": {
                    stroke: "#336953",
                  },
                },
              }}
              direction={"row"}
              justifyContent={"center"}
              onClick={() => {
                if (
                  !loaders &&
                  notificationData.filter((e) => !e.read).length
                ) {
                  setLoaders(true);
                  ReadAllNotifications({
                    onCompleted: () => {
                      GetNotifications({
                        fetchPolicy: "cache-and-network",
                        onCompleted: (data) => {
                          setNotificationData([...data.GetNotifications]);
                          setLoaders(false);
                          // if(data.GetNotifications.length)
                          // ReadNotifications({variables:{
                          //   notificationIds:data?.GetNotifications.map((elem)=>Number(elem.notification_id))
                          // }})
                        },
                      });
                    },
                  });
                }
              }}
            >
              <Padding size="6px 4px">
                <Stack
                  direction={"row"}
                  justifyContent={"space-around"}
                  alignItems={"center"}
                >
                  <svg
                    width="22"
                    height="11"
                    viewBox="0 0 22 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 6.25L4.84615 10L14.0769 1M10.2308 8.5L11.7692 10L21 1"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {/* <DoneAllIcon sx={{height:"20px"}}/> */}
                  <Box sx={{ fontSize: "12px", marginLeft: "5px" }}>
                    Mark all as read
                  </Box>
                </Stack>
              </Padding>
            </Stack>
          </Stack>
        </Menu>
      </div>
    </>
  );
}
