// import { MgpButton, MgpText, MgpTextbox } from "../components/global";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { MgpCustomSelect } from "../components/global/MgpDropdown";
// import MgpProgressBar from "../components/global/MgpProgressBar";
// import MgpAccordion from "../components/global/MgpAccordion";
// import { Typography } from "@mui/material";

// export const Components = () => {
//   const steps = [
//     {
//       label: "Select campaign settings",
//     },
//     {
//       label: "Create an ad group",
//     },
//     {
//       label: "Create an ad",
//     },
//   ];
//   return (
//     <>
//       <MgpButton variant="contained">Button</MgpButton>
//       <MgpText variant="h1">H1</MgpText>
//       <MgpText variant="h2">H2</MgpText>
//       <MgpText variant="h3">H3</MgpText>
//       <MgpText variant="h4">H4</MgpText>
//       <MgpText variant="h5">H5</MgpText>
//       <MgpText variant="h6">H6</MgpText>
//       <MgpTextbox placeholder="Textbox" variant="outlined" label="Textbox" />
//       <DatePicker />
//       {/* <MgpCustomSelect
//         label={"Demo select"}
//         name="test"
//         value={[]}
//         onChange={(e) => {
//           console.log("🚀 ~ Components ~ e:", e);
//         }}
//       /> */}
//       {/* <MgpProgressBar steps={steps} /> */}
//       <MgpAccordion
//         name={"panel1"}
//         title={"General settings"}
//         content={
//           <Typography>
//             Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
//             Aliquam eget maximus est, id dignissim quam.
//           </Typography>
//         }
//       />
//     </>
//   );
// };

import React from "react";
import { Formik, Form, Field } from "formik";
import { TextField, Button } from "@mui/material";
import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const validationSchema = Yup.object().shape({
  basic: Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
  }),
});

// console.log("validationSchema", validationSchema);
export const Components = () => {
  return <></>;
};

// export default App;

{
  /* <Form>
<Field
  as={TextField}
  type="text"
  name="basic.firstName"
  label="First Name"
  value={values["basic"]["firstName"]}
  onChange={handleChange}
  onBlur={handleBlur}
  error={
    touched["basic"] &&
    touched["basic"]["firstName"] &&
    Boolean(
      errors && errors["basic"]
        ? errors["basic"]["firstName"]
        : undefined
    )
  }
  helperText={
    touched["basic"] &&
    touched["basic"]["firstName"] &&
    errors &&
    errors["basic"]
      ? errors["basic"]["firstName"]
      : ""
  }
/>
<Field
  as={TextField}
  type="text"
  name="basic.lastName"
  label="First Name"
  value={values["basic"]["lastName"]}
  onChange={handleChange}
  onBlur={handleBlur}
  error={
    touched["basic"] &&
    touched["basic"]["lastName"] &&
    Boolean(
      errors && errors["basic"]
        ? errors["basic"]["lastName"]
        : undefined
    )
  }
  helperText={
    touched["basic"] &&
    touched["basic"]["lastName"] &&
    errors &&
    errors["basic"]
      ? errors["basic"]["lastName"]
      : ""
  } */
}
// />
{
  /* <Field
  as={TextField}
  type="text"
  name="user.lastName"
  label="Last Name"
  value={values.user.lastName}
  onChange={handleChange}
  onBlur={handleBlur}
  error={
    touched.user &&
    touched.user.lastName &&
    Boolean(errors.user?.lastName)
  }
  helperText={
    touched.user && touched.user.lastName && errors.user?.lastName
  }
/> */
}

{
  /* <Button type="submit" disabled={isSubmitting}>
  Submit
</Button>
</Form> */
}
