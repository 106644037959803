import React from "react";
import { colors } from "../../theme/colors";

const StatusColors = ({ statuses }) => {
  const getStatusColor = (value) => {
    switch (value) {
      case "0":
        return "#DC6069"; // Draft - Orange
      case "1":
        return "#4082C1"; // Ready to Review - Green
      case "2":
        return "#54B196"; // Reviewed - Dark Green
      case "3":
        return "#E53935"; // Rejected - Red
      case "4":
        return "#EDAF68"; // Return to Doctor - Gray
      case "5":
        return "#4082C1"; // Return to Quality - Blue
      case "6":
        return "#666666"; // Return to Quality - Blue
      default:
        return colors.PRIMARY_DARK;
    }
  };

  return (
    <div style={{ marginTop: "20px", display: "inline-block" }}>
      {statuses.map((status, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            marginRight: "20px",
            alignItems: "center",
            marginBottom: "5px",
            fontSize:"12px"
          }}
        >
          <div
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: status.color || getStatusColor(status.value),
              marginRight: "5px",
            }}
          ></div>
          <span>{status.label}</span>
        </div>
      ))}
    </div>
  );
};

export default StatusColors;
