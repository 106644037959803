import {
  Divider,
  Grid,
  IconButton,
  Box,
  Paper,
  Stack,
  CircularProgress,
} from "@mui/material";
import { colors } from "../theme/colors";
import PostAddIcon from "@mui/icons-material/PostAdd";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Link, useParams } from "react-router-dom";
import React, { useMemo, useState, useEffect } from "react";
import Loader from "./Loader/Loader";
import MedicationRequest from "./MedicationRequest";
import { MedicationRequests } from "./masters/MedicationRequests/MedicationRequests";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LockResetIcon from "@mui/icons-material/LockReset";
import {
  MgpButton,
  MgpModal,
  MgpTable,
  MgpTableStatus,
  MgpText,
  Padding,
  TableConfig,
  AlertDialog,
} from "../components/global";
import Tooltip from "@mui/material/Tooltip";
import { useMaterialReactTable } from "material-react-table";
import { MgpToggleButtonGroupTab } from "../components/global/MgpToggleButtonGroup";
import {
  usePatient,
  useEncounter,
  useRbac,
  useMedicationRequest,
  getActionRbac,
  useComment,
} from "../hooks";
import * as XLSX from "xlsx";

import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import MedicationIcon from "@mui/icons-material/Medication";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { download, generateCsv, mkConfig } from "export-to-csv";
import LivingStatus from "./LivingStatus";
import StatusColors from "../components/global/StatusColors";
import { downloadPdf } from "../helpers/dowloadPdf";
import MgpAlertBar from "../components/global/MgpAlertBar";
import { DeleteFollowup } from "./Deletedpatient/DeleteRecord";
import { ENV, StorageHelper } from "../helpers";

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

const medicationtRows = [
  {
    id: 1,
    medication_details: "Start -  Medication - 5mg - twice a day",
    hospital_name: "XYZ Hospital",
    doctor_name: "Dr.XYZ",
    request_date: "23/02/2024",
    dispensed_date: "23/02/2024",
    status: "New",
    action: "id",
  },
  {
    id: 2,
    medication_details: "Start -  Medication - 5mg - twice a day",
    hospital_name: "XYZ Hospital",
    doctor_name: "Dr.XYZ",
    request_date: "26/02/2024",
    dispensed_date: "26/02/2024",
    status: "New",
    action: "id",
  },
  {
    id: 3,
    medication_details: "Start -  Medication - 5mg - twice a day",
    hospital_name: "XYZ Hospital",
    doctor_name: "Dr.XYZ",
    request_date: "02/03/2024",
    dispensed_date: "02/03/2024",
    status: "New",
    action: "id",
  },
];

export const ViewPatientDetails = () => {
  const [mainPageloading, setMainPageloading] = useState(true);
  const [delete_ref, setDeleting] = useState(false);
  const [message, setMessage] = React.useState("");
  const [alertOption, setAlertOption] = React.useState(false);
  // const [submitReopen, setSubmitReopen] = useState(false);
  const {
    useGetPatientsMedicationRequests,
    useUpdatePatientsMedicationRequestStatus,
  } = useMedicationRequest();
  const [
    GetPatientsMedicationRequests,
    { medicationData, medicationLoading, medicationError },
  ] = useGetPatientsMedicationRequests;
  const { useSubmitComment } = useComment({
    encounterId: 1,
    attributeId: "",
  });
  const [SubmitComment] = useSubmitComment;
  const [isLoading, setIsLoading] = useState(true);

  const { RbacWrapper, getActionRbac } = useRbac();
  const [open, setOpen] = useState(false);
  const [livingopen, setLivingOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleLivingClose = () => {
    setLivingOpen(false);
  };

  const handleSubmitCloseAlert = () => {
    setAlertOption(false);
    setMessage("");
  };

  const handleExportData = (type) => {
    const filteredData = table
      .getFilteredRowModel()
      .rows.map((row) => row.original)
      .map((obj) => {
        const filteredObj = {};
        visitColumns.forEach((key) => {
          if (key.header === "Last Action Date") {
            filteredObj[key.header] = new Date(
              parseInt(obj[key.accessorKey])
            ).toLocaleDateString("en-GB");
          } else if (obj.hasOwnProperty(key.accessorKey)) {
            filteredObj[key.header] = obj[key.accessorKey];
          } else if (key.accessorKey == "details.basicVisit.typeofvisit") {
            filteredObj[key.header] = obj?.details?.basicVisit?.typeofvisit;
          }
        });
        return filteredObj;
      });
    let dataToExport = filteredData ? filteredData : data.rows;
    let filename = "Patient Visit History";
    if (dataToExport.length) {
      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      // Convert the data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);

      // Calculate column widths based on headers and values
      const maxWidths = [];
      visitColumns.forEach((col, colIndex) => {
        const header = col.header;
        maxWidths[colIndex] = header.length;
      });

      dataToExport.forEach((row) => {
        Object.keys(row).forEach((key, colIndex) => {
          const cellValue = row[key] ? row[key].toString() : "";
          maxWidths[colIndex] = Math.max(maxWidths[colIndex], cellValue.length);
        });
      });

      worksheet["!cols"] = maxWidths.map((width) => ({ wch: width + 2 }));

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);

      // Handle file type and download
      if (type === "csv") {
        XLSX.writeFile(workbook, `${filename}.csv`, { bookType: "csv" });
      } else if (type === "xlsx") {
        XLSX.writeFile(workbook, `${filename}.xlsx`, { bookType: "xlsx" });
      } else {
        downloadPdf(dataToExport, filename);
      }
    }
  };

  const [encountertableData, setEncountertableData] = useState({});
  const handleOpen = (data) => {
    console.log("setEncountertableData", data);
    setEncountertableData(data);
    setOpen(true);
  };
  const handleLivingOpen = (data) => {
    setLivingOpen(true);
  };

  // 👇️ Get the ID from the URL
  const params = useParams();

  const { useViewPatient } = usePatient();
  const [ViewPatient, { data, loading, error }] = useViewPatient;

  const { useGetEncountersByPatientId, useDeleteEncounter } = useEncounter({
    patientId: 1,
  });
  const [
    GetEncountersByPatientId,
    { data: encounterData, loading: encounterLoading, error: encouterError },
  ] = useGetEncountersByPatientId;

  const [DeleteEncounter] = useDeleteEncounter;

  React.useEffect(() => {
    ViewPatient({ variables: { patientId: parseInt(params?.patientId) } });
    GetEncountersByPatientId({
      variables: { patientId: parseInt(params?.patientId) },
      fetchPolicy: "cache-and-network",
    });
  }, [ViewPatient, GetEncountersByPatientId]);

  // React.useEffect(()=>{
  //     let user=JSON.parse(localStorage.getItem("userDetails"))
  //     setUserDetails({...user})
  //   },[])

  const statuses = [
    {
      label:
        "Draft - Indicates the incompletion of the record, i.e. missing information and entries are still yet to be completed.",
      value: "0",
    }, // Draft - Amber
    {
      label:
        "Ready to Review - Indicates data entry and record completion and is ready to be reviewed by the “Monitor / QC”. Data here refers to the mandatory data / required.",
      value: "1",
    }, // Ready to Review - Green
    {
      label:
        "Reviewed - Indicates that the “Monitor” has reviewed the record and approved it.",
      value: "2",
    }, // Reviewed - Light Green
    {
      label:
        "Return to Doctor - Indicates that the comments / concerns raised by the “Monitor” on the record are pending / awaiting to be addressed / amended by the “PI / Doctor.”",
      value: "4",
    }, // Return to Doctor - Gray
    {
      label:
        "Re-opened - Indicates that the “Reviewed” report is reopened by quality user to edit and modify information.",
      value: "6",
    },
  ];

  const visitColumns = useMemo(
    () => [
      {
        accessorKey: "details.basicVisit.typeofvisit", //access nested data with dot notation
        header: "Visit Type",
      },
      {
        accessorKey: "report_status", //normal accessorKey
        header: "Visit Status",
        Cell: ({ row }) => {
          return (
            <MgpTableStatus
              value={row.original?.status}
              label={row.original?.report_status}
            />
          );
        },
      },
      {
        accessorKey: "created_on", //normal accessorKey
        header: "Creation Date",
      },
      {
        accessorKey: "updated_on", //normal accessorKey
        header: "Last Action Date",
        Cell: ({ row }) => {
          return (
            <>
              {new Date(parseInt(row.original.updated_on)).toLocaleDateString(
                "en-GB"
              )}
            </>
          );
        },
      },
      {
        accessorKey: "created_by_name", //normal accessorKey
        header: "Created By",
      },
      {
        accessorKey: "updated_by_name", //normal accessorKey
        header: "Last Action Done By",
      },
    ],
    []
  );
  const CustomComponent = ({ details }) => {
    return details.map((item) => {
      return (
        <p>
          {item.medicine} - {item.dosage} - {item.regimen}
        </p>
      );
    });
  };
  const medicationColumns = useMemo(
    () => [
      {
        accessorKey: "registry_no", //access nested data with dot notation
        header: "Registry No.",
      },
      {
        accessorKey: "national_id",
        header: "National ID",
      },
      {
        accessorKey: "patient_name", //normal accessorKey
        header: "Patient Name",
      },
      {
        accessorKey: "doctor_name", //normal accessorKey
        header: "Doctor",
      },
      {
        accessorKey: "hospital_name", //normal accessorKey
        header: "Hospital",
      },
      {
        accessorKey: "details", //normal accessorKey
        header: "Medication",
        Cell: ({ row }) => <CustomComponent details={row.original.details} />,
      },
      {
        accessorKey: "created_on", //normal accessorKey
        header: "Created On",
      },
      {
        accessorKey: "dispensed_date", //normal accessorKey
        header: "Dispensed Date",
      },
      {
        accessorKey: "status_enum", //normal accessorKey
        header: "Status",
      },
      {
        accessorKey: "dispensed_comment", //normal accessorKey
        header: "Comment",
      },
    ],
    []
  );
  let user = JSON.parse(StorageHelper.get("user"));

  const ComponentButton = ({ type }) => {
    return type === "Visit History" ? (
      data?.ViewPatient?.is_active && (
        <RbacWrapper module={"view_patient"} code={"add_visit"}>
          <MgpButton
            variant="contained"
            disabled={
              encounterData?.GetEncountersByPatientId?.[
                encounterData.GetEncountersByPatientId.length - 1
              ]?.self === false &&
              parseInt(user?.role_id) === parseInt(ENV.medical_lead_role_id)
                ? true
                : encounterData?.GetEncountersByPatientId?.[0]?.status === 1 ||
                  encounterData?.GetEncountersByPatientId?.[0]?.status === 0 ||
                  encounterData?.GetEncountersByPatientId?.[0]?.status === 4 ||
                  encounterData?.GetEncountersByPatientId?.[0]?.status === 5 ||
                  encounterData?.GetEncountersByPatientId?.find(
                    (elem) => elem?.status === 6
                  )
                ? true
                : false
            }
            // onClick={handleLivingOpen}
            component={Link}
            to={"/app/patients/newVisit/" + parseInt(params?.patientId)}
            endIcon={<PostAddIcon sx={{ marginTop: "-3px" }} />}
          >
            Add Follow Up Visit
          </MgpButton>
        </RbacWrapper>
      )
    ) : (
      <RbacWrapper module={"view_patient"} code={"view_medication_details"}>
        <MgpButton
          onClick={handleOpen}
          endIcon={<PostAddIcon sx={{ marginTop: "-3px" }} />}
        >
          New Medication
        </MgpButton>
      </RbacWrapper>
    );
  };

  const [value, setValue] = React.useState("Visit History");

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleDelete = (row) => {
    setDeleting(row.encounter_id);
  };
  useEffect(() => {
    if (encounterData || error) {
      setMainPageloading(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [encounterData, error]);

  // const handleFeeback = (id, status,user) => {
  //   setMainPageloading(true);
  //   SubmitComment({
  //     variables: {
  //       encounterId: parseInt(id),
  //       status: status,
  //     },
  //     onCompleted: (data) => {
  //           setMessage(`The CRF Returned to the ${user} successfully`);
  //           setAlertOption(true);
  //           GetEncountersByPatientId({
  //             variables: { patientId: parseInt(params?.patientId) },
  //             fetchPolicy: "cache-and-network",
  //           });
  //           setSubmitReopen(false);
  //           setMainPageloading(false);
  //       },

  //     onError: (error) => {
  //       setMainPageloading(true);
  //     },
  //   });
  // };

  const userDetails = JSON.parse(StorageHelper.get("user")); // For fetching userDetails from local storage

  const table = useMaterialReactTable({
    columns: value === "Visit History" ? visitColumns : medicationColumns,
    data:
      value === "Visit History"
        ? encounterData?.GetEncountersByPatientId ?? []
        : [],
    ...TableConfig,
    enableRowActions: true,
    renderTopToolbar: ({ table }) => (
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: "0.5rem",
          p: "16px 0px",
          justifyContent: "flex-end",
        })}
      >
        <Box sx={{ display: "flex", gap: "16px" }}>
          {/* Move the search bar here */}
          <ComponentButton type={value} />
          <RbacWrapper module={"patients"} code={"export_visit_history"}>
            <MgpButton
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => handleExportData("xlsx")}
              endIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export Excel
            </MgpButton>
            <MgpButton
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => handleExportData("pdf")}
              endIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export PDF
            </MgpButton>
          </RbacWrapper>
        </Box>
      </Box>
    ),
    muiSearchTextFieldProps: ({ table }) => {},
    renderRowActions: ({ row }) => {
      console.log("The row.original value is", row.original);
      return (
        <>
          {/* {delete_ref && (
            <AlertDialog
              open={true}
              negative={() => {
                setDeleting(false);
              }}
              positive={(param) => {
                setMainPageloading(true);
                DeleteEncounter({
                  variables: { encounterId: Number(param) },
                  onCompleted: () => {
                    GetEncountersByPatientId({
                      variables: { patientId: parseInt(params?.patientId) },
                      fetchPolicy: "cache-and-network",
                    });
                    setDeleting(false);
                    setMainPageloading(false);
                    setAlertOption(true);
                    setMessage("CRF Removed Successfully");
                  },
                });
              }}
              param={delete_ref}
              title={"Delete"}
              description={"Are you sure you want to delete?"}
            />
          )} */}

          <Box>
            {value === "Visit History" ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {((parseInt(ENV.quality_user_role_id) ===
                  parseInt(userDetails?.role_id) &&
                  (parseInt(row.original.status) === 0 ||
                    parseInt(row.original.status) === 2 ||
                    parseInt(row.original.status) === 4 ||
                    parseInt(row.original.status) === 3)) ||
                  (parseInt(ENV.doctor_role_id) ===
                    parseInt(userDetails?.role_id) &&
                    (parseInt(row.original.status) === 1 ||
                      parseInt(row.original.status) === 2 ||
                      parseInt(row.original.status) === 5 ||
                      parseInt(row.original.status) === 3)) ||
                  (parseInt(ENV.medical_lead_role_id) ===
                    parseInt(userDetails?.role_id) &&
                    !encounterData?.GetEncountersByPatientId?.[0]?.self) ||
                  (parseInt(ENV.medical_lead_role_id) ===
                    parseInt(userDetails?.role_id) &&
                    (parseInt(row.original.status) === 1 ||
                      parseInt(row.original.status) === 2 ||
                      parseInt(row.original.status) === 5 ||
                      parseInt(row.original.status) === 3)) ||
                  parseInt(ENV.itkan_role_id) ===
                    parseInt(userDetails?.role_id)) && (
                  <Tooltip title={"View Visit"} arrow>
                    <IconButton
                      size="small"
                      component="a"
                      onClick={() => {
                        StorageHelper.remove(row?.original?.encounter_id);
                      }}
                      href={
                        "/app/patients/visitHistory/viewCrf/" +
                        row?.original?.encounter_id
                      }
                      // target="_blank"
                    >
                      {parseInt(row.original.status) === 4 ||
                      parseInt(row.original.status) === 6 ? (
                        <VisibilityOutlinedIcon
                          sx={{
                            height: "18px",
                            color: colors.MENULISTPRIMARY,
                          }}
                        />
                      ) : (
                        <VisibilityOutlinedIcon
                          sx={{
                            height: "18px",
                            color: colors.MENULISTPRIMARY,
                          }}
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
                {getActionRbac("patients", "edit_patient_form") &&
                  data?.ViewPatient?.is_active &&
                  (row?.original?.report_status === "Draft" ||
                    row?.original?.report_status === "Re-opened" ||
                    row?.original?.report_status === "Return to Doctor") &&
                  (parseInt(userDetails?.role_id) ===
                  parseInt(ENV.medical_lead_role_id)
                    ? encounterData?.GetEncountersByPatientId?.[0]?.self
                    : true) && (
                    <Tooltip title={"Edit Form"} arrow>
                      <IconButton
                        size="small"
                        component="a"
                        onClick={() => {
                          StorageHelper.remove(row?.original?.encounter_id);
                        }}
                        href={
                          "/app/patients/visitHistory/" +
                          row?.original?.encounter_id
                        }
                        // target="_blank"
                      >
                        <EditIcon
                          sx={{ height: "16px", color: colors.MENULISTPRIMARY }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                {getActionRbac("patients", "approval") &&
                  data?.ViewPatient?.is_active &&
                  row?.original?.report_status === "Ready to Review" && (
                    <Tooltip title={"Review Form"} arrow>
                      <IconButton
                        size="small"
                        component="a"
                        onClick={() => {
                          StorageHelper.remove(row?.original?.encounter_id);
                        }}
                        href={
                          "/app/patients/visitHistory/" +
                          row?.original?.encounter_id
                        }
                        // target="_blank"
                      >
                        <EditIcon
                          sx={{ height: "16px", color: colors.MENULISTPRIMARY }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                {getActionRbac("patients", "delete_patient_form") &&
                  data?.ViewPatient?.is_active &&
                  row.original.details.basicVisit.typeofvisit !==
                    "Baseline Visit" && (
                    <Tooltip title={"Delete Visit Report"} arrow>
                      <IconButton
                        size="small"
                        onClick={() => {
                          StorageHelper.remove(row?.original?.encounter_id);

                          handleDelete(row.original);
                        }}
                      >
                        <DeleteIcon
                          sx={{ height: "18px", color: colors.MENULISTPRIMARY }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                {getActionRbac("patients", "approval") &&
                  data?.ViewPatient?.is_active &&
                  row.original.status == 2 && (
                    <Tooltip title={"Re-open"} arrow>
                      <IconButton
                        size="small"
                        component="a"
                        onClick={() => {
                          StorageHelper.remove(row?.original?.encounter_id);
                        }}
                        href={
                          "/app/patients/reopenVisitHistory/" +
                          row?.original?.encounter_id
                        }
                        // target="_blank"
                      >
                        <LockResetIcon
                          sx={{ height: "18px", color: colors.MENULISTPRIMARY }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                <RbacWrapper module={"view_patient"} code={"add_mr"}>
                  {row.original.medication_request_status_enum === 0 ||
                  row.original.medication_request_status_enum === 5 ? (
                    <Tooltip title={"Add Medication"} arrow>
                      <IconButton
                        size="small"
                        onClick={() => {
                          StorageHelper.remove(row?.original?.encounter_id);

                          handleOpen(row?.original);
                        }}
                      >
                        {
                          <MedicationIcon
                            sx={{
                              height: "18px",
                              color: colors.MENULISTPRIMARY,
                            }}
                          />
                        }
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </RbacWrapper>
              </div>
            ) : (
              <Tooltip title={"View report"} arrow>
                <IconButton
                  component="a"
                  onClick={() => {
                    StorageHelper.remove(row?.original?.encounter_id);
                  }}
                  href="/viewPatient/patientId"
                  target="_blank"
                >
                  <VisibilityOutlinedIcon
                    sx={{ height: "16px", color: colors.MENULISTPRIMARY }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </>
      );
    },
    state: {
      isLoading,
      columnOrder: (value === "Visit History"
        ? visitColumns
        : medicationColumns
      ).map((e) => e.accessorKey),
    },
    renderTableBody: () => {
      return isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null;
    },
  });

  console.log("data", data?.ViewPatient?.is_the_patient_alive);

  if (mainPageloading) {
    return <Loader open={mainPageloading} />;
  }
  return (
    <>
      <MgpModal
        styling={{}}
        open={open}
        onClose={handleClose}
        title={"Medication Request"}
      >
        <Stack sx={{ marginX: "2%" }}>
          {/* <MgpText variant="h4">Medication Request</MgpText> */}
          <MedicationRequest
            obj={encountertableData}
            viewMode={false}
            newVisit={false}
            handleClose={handleClose}
          />
        </Stack>
      </MgpModal>

      <MgpModal
        styling={{}}
        open={livingopen}
        onClose={handleLivingClose}
        title={"Living Status"}
      >
        <Stack sx={{ marginX: "2%" }}>
          {/* <MgpText variant="h4">Medication Request</MgpText> */}
          <LivingStatus
            encounterData={encounterData?.GetEncountersByPatientId}
          />
        </Stack>
      </MgpModal>

      <Grid container direction="row">
        <Grid item md="3">
          <Paper elevation={0}>
            <Padding size="10px 20px 10px 0px">
              <Stack gap={2}>
                <MgpText variant="h5">Basic Profile Information</MgpText>
                <Divider />
                {/* {Object.keys(userDetails?.role==="Itkan"?basicInfoItkan:basicInfo).map((e) => {
                  return (
                    <> */}
                {(parseInt(userDetails?.role_id) ===
                parseInt(ENV.medical_lead_role_id)
                  ? encounterData?.GetEncountersByPatientId?.[0]?.self
                  : true) && (
                  <RbacWrapper module={"view_patient"} code={"view_pii"}>
                    <Stack gap={1}>
                      <MgpText variant="body">Name</MgpText>
                      <MgpText color={"secondary"} variant="body2">
                        {/* {userDetails?.role==="Itkan"?basicInfoItkan[e]:basicInfo[e]} */}
                        {data?.ViewPatient?.firstname +
                          " " +
                          data?.ViewPatient?.lastname}
                      </MgpText>
                    </Stack>
                  </RbacWrapper>
                )}
                {(parseInt(userDetails?.role_id) ===
                parseInt(ENV.medical_lead_role_id)
                  ? encounterData?.GetEncountersByPatientId?.[0]?.self
                  : true) && (
                  <RbacWrapper module={"view_patient"} code={"view_pii"}>
                    <Stack gap={1}>
                      <MgpText variant="body">Date Of Birth</MgpText>
                      <MgpText color={"secondary"} variant="body2">
                        {data?.ViewPatient?.dateofbirth}
                      </MgpText>
                    </Stack>
                  </RbacWrapper>
                )}
                <Stack gap={1}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <MgpText
                      variant="body"
                      style={{
                        marginRight: "5px",
                      }}
                    >
                      {data?.ViewPatient?.is_the_patient_alive
                        ? "Living Status"
                        : "Living Status"}
                    </MgpText>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: data?.ViewPatient?.is_the_patient_alive
                          ? "green"
                          : "Red",
                        marginRight: "5px",
                      }}
                    ></div>
                  </div>
                  <MgpText color={"secondary"} variant="body2">
                    {data?.ViewPatient?.is_the_patient_alive
                      ? "Alive"
                      : "Not Alive"}
                  </MgpText>
                </Stack>
                {!data?.ViewPatient?.is_the_patient_alive && (
                  <Stack gap={1}>
                    <MgpText variant="body">Date of Death</MgpText>
                    <MgpText color={"secondary"} variant="body2">
                      {data?.ViewPatient?.date_of_death}
                    </MgpText>
                  </Stack>
                )}
                {!data?.ViewPatient?.is_the_patient_alive && (
                  <Stack gap={1}>
                    <MgpText variant="body">Death Related Cause</MgpText>
                    <MgpText color={"secondary"} variant="body2">
                      {data?.ViewPatient?.death_related_cause}
                    </MgpText>
                  </Stack>
                )}
                {!data?.ViewPatient?.is_the_patient_alive && (
                  <Stack gap={1}>
                    <MgpText variant="body">Cause of Death</MgpText>
                    <MgpText color={"secondary"} variant="body2">
                      {` ${
                        data?.ViewPatient?.others_specify
                          ? data?.ViewPatient?.others_specify
                          : data?.ViewPatient?.cause_of_death
                      }`}
                    </MgpText>
                  </Stack>
                )}

                <Stack gap={1}>
                  <MgpText variant="body">Registry Number</MgpText>
                  <MgpText color={"secondary"} variant="body2">
                    {data?.ViewPatient?.registryno}
                  </MgpText>
                </Stack>
                {(parseInt(userDetails?.role_id) ===
                parseInt(ENV.medical_lead_role_id)
                  ? encounterData?.GetEncountersByPatientId?.[0]?.self
                  : true) && (
                  <RbacWrapper module={"view_patient"} code={"view_pii"}>
                    <Stack gap={1}>
                      <MgpText variant="body">MRN No.</MgpText>
                      <MgpText color={"secondary"} variant="body2">
                        {data?.ViewPatient?.mrnno}
                      </MgpText>
                    </Stack>
                  </RbacWrapper>
                )}
                {(parseInt(userDetails?.role_id) ===
                parseInt(ENV.medical_lead_role_id)
                  ? encounterData?.GetEncountersByPatientId?.[0]?.self
                  : true) && (
                  <RbacWrapper module={"view_patient"} code={"view_pii"}>
                    <Stack gap={1}>
                      <MgpText variant="body">National ID</MgpText>
                      <MgpText color={"secondary"} variant="body2">
                        {data?.ViewPatient?.nationalid}
                      </MgpText>
                    </Stack>
                  </RbacWrapper>
                )}
                {/* </>
                  );
                })} */}
              </Stack>
            </Padding>
          </Paper>
        </Grid>
        <Grid item md="9">
          <Padding size="10px 0px">
            <Stack gap={2}>
              {/* <RbacWrapper
      module={"view_patient"}
      code={"view_medication_details"}
    > */}
              <MgpToggleButtonGroupTab
                // options={["Visit History", "Medication History"]}
                options={["Visit History"]}
                defaultValue={value}
                value={value}
                onChange={(event, newValue) => {
                  if (newValue) handleChange(newValue);
                }}
              />
              {/* </RbacWrapper> */}
              <Paper>
                {value === "Medication History" ? (
                  <Padding size="0px 20px">
                    <MedicationRequests
                      from="VIEW_PATIENT"
                      id={params?.patientId}
                    />
                  </Padding>
                ) : (
                  <>
                    <Padding size="0px 20px">
                      <MgpTable table={table} />
                    </Padding>
                    <StatusColors statuses={statuses}></StatusColors>
                  </>
                )}
              </Paper>
            </Stack>
          </Padding>
        </Grid>
      </Grid>
      {/* {submitReopen && (
        <AlertDialog
          open={true}
          negative={() => {
            setSubmitReopen(false);
          }}
          positive={(param) => {
            handleFeeback(Number(param), 6,'Doctor');
            setSubmitReopen(false);
          }}
          param={submitReopen}
          title={"Re-open the Report"}
          description={"Are you sure you want to submit to re-open the report?"}
        />
      )} */}
      <MgpModal
        open={delete_ref}
        onClose={() => {
          setDeleting(false);
        }}
      >
        <DeleteFollowup
          encounterId={delete_ref}
          patientid={parseInt(params?.patientId)}
          messageHeading={
            "Are you sure to delete this follow up report? If yes, Please add the deletion reason."
          }
          setDeleting={setDeleting}
          setMainPageloading={setMainPageloading}
        />
      </MgpModal>
      <MgpAlertBar
        open={alertOption}
        setOpen={handleSubmitCloseAlert}
        alertType="error"
        message={message}
      />
    </>
  );
};
