import jsPDF from "jspdf";
import "jspdf-autotable";

export const downloadPdf = (jsonData, filename) => {
  const doc = new jsPDF("portrait", "mm", "a3");
  doc.addFont(
    "/Amiri-Regular.ttf",
    "Amiri",
    "normal"
  );
  doc.setFont("Amiri"); // set font
  console.log("Download Pdf is called");

  const tableColumn = Object.keys(jsonData[0]);
  const tableRows = jsonData.map((item) => Object.values(item));

  const pageWidth = doc.internal.pageSize.getWidth();
  const margin = 14;
  const availablePageWidth = pageWidth - 2 * margin;
  const columnWidth = availablePageWidth / tableColumn.length;

  const columnStyles = tableColumn.reduce((acc, _, index) => {
    acc[index] = { cellWidth: columnWidth };
    return acc;
  }, {});
  console.log("The download data before auto table is ", tableRows);
  doc.autoTable({
    head: [tableColumn],
    headStyles: { font: "Amiri" },
    bodyStyles: { font: "Amiri" },
    body: tableRows,
    startY: 20,
    columnStyles,
    styles: {
      overflow: "linebreak",
      cellPadding: 1,
      lineWidth: 0.05,
      lineColor: [0, 0, 0],
    },
  });
  console.log("The download data after auto table is ", doc);

  doc.save(`${filename}.pdf`);
};
// export const downloadPdf = (jsonData,filename) =>{
//   console.log("The download function is called in Download function starting",jsonData,"The filename is",filename)
//   const doc = new jsPDF();
//   const MyPdfComponentInstance = <MyPdfComponent jsonData={jsonData} />; // Render the component

//   // Access the table element using ReactDOM.findDOMNode
//   const tableElement = ReactDOM.findDOMNode(MyPdfComponentInstance);

//   const htmlContent = tableElement.outerHTML;

//   // // Add options for PDF generation (adjust as needed)
//   // const options = {
//   //   type: 'html',
//   //   filename: `${filename}.pdf`,
//   //   // styles: { // Include any necessary styles for the table
//   //   //   '.export-table-style': { // Target your table class
//   //   //     // Add styles for font, padding, etc.
//   //   //   }
//   //   // }
//   // };

//   // try {
//   //   doc.html(htmlContent, options); // Convert HTML content to PDF
//   //   doc.save(filename); // Save the PDF with the specified filename
//   //   console.log('PDF downloaded successfully');
//   // } catch (error) {
//   //   console.error('Error downloading PDF:', error);
//   // }
// }
// export default MyPdfComponent;
