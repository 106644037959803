import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box, IconButton, Tooltip, CircularProgress, Tab } from "@mui/material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  MRT_GlobalFilterTextField,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  MgpButton,
  MgpTable,
  MgpTableStatus,
  MgpText,
  TableConfig,
} from "../../components/global";
import { usePatient, useRbac } from "../../hooks";
import { colors } from "../../theme/colors";
import Loader from "../Loader/Loader";
import { downloadPdf } from "../../helpers/dowloadPdf";
import * as XLSX from 'xlsx';

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

export const DeletedPatientList = () => {
  const { RbacWrapper, rbac } = useRbac();
  const [tableData, setTableData] = useState([]);
  const [tableDataRecord, setTableDataRecord] = useState([]);

  const [mainPageloading, setMainPageloading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState("1");
  const { useGetPatients, useGetPatientsRecords } = usePatient();
  // const { RbacWrapper } = useRbac();
  const [GetPatients, { data, loading, error }] = useGetPatients;
  const [GetPatientRecords, { data1 }] = useGetPatientsRecords;
  const [filteredcolumns, setFilteredColumns] = useState([]);

  let columns = [
    {
      accessorKey: "registry_no",
      header: "Registry No.",
      Cell: ({ row }) => {
        let shouldRenderIcon = row.original.is_transferred;

        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {row.original.registry_no}{" "}
            {shouldRenderIcon ? (
              <Tooltip title="patient transferred" arrow>
                <CompareArrowsIcon sx={{ fontSize: "16px", marginLeft: "7px" }} />
              </Tooltip>
            ) : (
              <></>
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: "first_name", //normal accessorKey
      header: "Patient Name",
    },
    {
      accessorKey: "national_id", //normal accessorKey
      header: "National ID",
    },
    {
      accessorKey: "mrn_no", //normal accessorKey
      header: "MRN No.",
    },
    {
      accessorKey: "doctor_name", //normal accessorKey
      header: "Doctor Name",
    },
    {
      accessorKey: "hospital_name", //normal accessorKey
      header: "Hospital Name",
      size: 310,
    },
    {
      accessorKey: "hospital_city", //normal accessorKey
      header: "City",
    },
    {
      accessorKey: "reason", //normal accessorKey
      header: "Deleted Reason",
    },
    {
      accessorKey: "typeofvisit", //normal accessorKey
      header: "Latest Visit Type",
      //todo For adding followup count number - api needs to be updated with is active false as well
      // Cell:({row})=>{
      //   return (
      //     <>
      //     {row.original.typeofvisit} {row.original.typeofvisit === "Follow Up Visit"?`(${row.original.follow_up_count})`:""} 
      //     </>
      //   )
      // },
    },
    {
      accessorKey: "updated_on",
      header: "Deleted Date",
      Cell: ({ row }) => {
        return (
          <>{new Date(parseInt(row.original.updated_on)).toLocaleDateString("en-GB")}</>
        );
      },
    },
  ];
  const handleChange = (event, newValue) => {
    setIsLoading(true);
    setValue(newValue);

  };
  // let filteredcolumns =
  //   rbac?.tables?.["deleted_patient_table"]?.map((e) => columns.find((row) => {
  //     if (row.accessorKey === e) {

  //       return row
  //     }
  //   }));

  useEffect(() => {
    if (value === "2") {
      setFilteredColumns(rbac?.tables?.["deleted_patient_table_hospital"]?.map((e) => columns.find((row) => {
        if (row.accessorKey === e) {

          return row
        }
      })))
      GetPatients({
        variables: { isActive: false, self: false },
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
          GetPatientRecords({
            variables: { isActive: false, self: false },
            fetchPolicy: "cache-and-network",
            onCompleted: (data) => {
              setTableDataRecord(data?.GetPatientRecords ?? []);
              setTimeout(() => {
                setIsLoading(false);
              }, 1000);
            }
          });

        }
      });


    } else {
      setFilteredColumns(
        rbac?.tables?.["deleted_patient_table"]?.map((e) => columns.find((row) => {
          if (row.accessorKey === e) {

            return row
          }
        })))
      GetPatients({
        variables: { isActive: false },
        fetchPolicy: "cache-and-network",
        onCompleted: (data) => {
          GetPatientRecords({
            variables: { isActive: false },
            fetchPolicy: "cache-and-network",
            onCompleted: (data) => {
              setTableDataRecord(data?.GetPatientRecords ?? []);
              setTimeout(() => {
                setIsLoading(false);
              }, 1000);
            }
          });

        }
      });

    }
  }, [GetPatients, GetPatientRecords, value]);
  useEffect(() => {
    setTableData(data?.GetPatients ?? []);
  }, [data?.GetPatients]);
  useEffect(() => {
    setTableDataRecord(data?.GetPatientRecords ?? []);
  }, [data1?.GetPatientRecords]);
  useEffect(() => {
    if (data || error) {
      setMainPageloading(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [data, error]);
  const table = useMaterialReactTable({
    columns: filteredcolumns,
    data: tableData,
    ...TableConfig,
    enableRowActions: true,
    //enableRowSelection: true,
    renderTopToolbar: ({ table }) => (
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: "0.5rem",
          p: "16px 0px",
          justifyContent: "space-between",
        })}
      >
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <MRT_GlobalFilterTextField table={table} />
        </Box>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <RbacWrapper module={"patients"} code={"export"}>
            <MgpButton
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => handleExportData('xlsx', "table")}
              endIcon={<FileDownloadIcon sx={{ fontSize: "16px !important" }} />}
              variant="contained"
            >
              Export Excel
            </MgpButton>
            <MgpButton
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => handleExportData('pdf', "table"
              )}
              endIcon={<FileDownloadIcon sx={{ fontSize: "16px !important" }} />}
              variant="contained"
            >
              Export PDF
            </MgpButton>
          </RbacWrapper>
        </Box>
      </Box>
    ),
    muiSearchTextFieldProps: ({ table }) => { },
    renderRowActions: ({ row }) => {
      return (
        <Box >
          <>
            <RbacWrapper module={"patients"} code={"view_patient"}>
              <Tooltip title={"View Reports"}>
                <IconButton
                  sx={{ padding: "2px" }}
                  size="small"
                  component="a"
                  href={
                    "/app/patients/viewPatient/" +
                    row?.original?.patient_id}
                >
                  <VisibilityOutlinedIcon
                    sx={{ height: "16px", color: colors.MENULISTPRIMARY }}
                  />
                </IconButton>
              </Tooltip>
            </RbacWrapper>
          </>
        </Box>
      );
    },
    state: { isLoading, columnOrder: filteredcolumns.map((e) => e.accessorKey) },
    renderTableBody: () => {
      return isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null;
    },
  });
  const tableFollowup = useMaterialReactTable({
    columns: filteredcolumns.map((item) => {
      if (item.accessorKey === "typeofvisit") {
        return { ...item, header: "Follow Up Visit Type" }
      }
      else {
        return item;
      }
    }),
    data: tableDataRecord,
    ...TableConfig,
    enableRowActions: true,
    //enableRowSelection: true,
    renderTopToolbar: ({ table }) => (
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: "0.5rem",
          p: "16px 0px",
          justifyContent: "space-between",
        })}
      >
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <MRT_GlobalFilterTextField table={table} />
        </Box>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <RbacWrapper module={"patients"} code={"export"}>
            <MgpButton
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => handleExportData('xlsx', "tableFollowup")}
              endIcon={<FileDownloadIcon sx={{ fontSize: "16px !important" }} />}
              variant="contained"
            >
              Export Excel
            </MgpButton>
            <MgpButton
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => handleExportData('pdf', "tableFollowup")}
              endIcon={<FileDownloadIcon sx={{ fontSize: "16px !important" }} />}
              variant="contained"
            >
              Export PDF
            </MgpButton>
          </RbacWrapper>
        </Box>
      </Box>
    ),
    muiSearchTextFieldProps: ({ table }) => { },
    renderRowActions: ({ row }) => {
      return (
        <Box>
          <>
            <RbacWrapper module={"patients"} code={"view_patient"}>
              <Tooltip title={"View Report"}>
                <IconButton
                  sx={{ padding: "2px" }}
                  size="small"
                  component="a"
                  href={
                    "/app/patients/visitHistory/viewCrf/" +
                    row?.original?.encounter_id
                  }
                >
                  <VisibilityOutlinedIcon
                    sx={{ height: "16px", color: colors.MENULISTPRIMARY }}
                  />
                </IconButton>
              </Tooltip>
            </RbacWrapper>
          </>
        </Box>
      );
    },
    state: { isLoading, columnOrder: filteredcolumns.map((e) => e.accessorKey) },
    renderTableBody: () => {
      return isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null;
    },
  });
  const handleExportData = (type, tableType) => {
    const filteredData = (tableType === "table" ? table : tableFollowup)
      .getFilteredRowModel()
      .rows.map((row) => row.original)
      .map(obj => {
        const filteredObj = {};
        filteredcolumns.forEach(key => {
          if (tableType === "tableFollowup" && key.accessorKey == "typeofvisit") {
            key.header = "Follow Up Visit Type"
          }else if (tableType === "table" && key.accessorKey == "typeofvisit") {
            key.header = "Latest Visit Type"
          }
          if (key.header === "Deleted Date") {
            filteredObj[key.header] = new Date(parseInt(obj[key.accessorKey])).toLocaleDateString("en-GB")
          }
          else if (obj.hasOwnProperty(key.accessorKey)) {
            filteredObj[key.header] = obj[key.accessorKey];
          }

        });
        console.log("filteredObj", filteredObj)
        return filteredObj;
      });
    let dataToExport = filteredData ? filteredData : data.rows;
    let filename = 'Deleted Patient List'
    if (dataToExport.length) {
      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      // Convert the data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);

      // Calculate column widths based on headers and values
      const maxWidths = [];
      filteredcolumns.forEach((col, colIndex) => {
        const header = col.header;
        maxWidths[colIndex] = header.length;
      });

      dataToExport.forEach((row) => {
        Object.keys(row).forEach((key, colIndex) => {
          const cellValue = row[key] ? row[key].toString() : '';
          maxWidths[colIndex] = Math.max(maxWidths[colIndex], cellValue.length);
        });
      });

      worksheet['!cols'] = maxWidths.map((width) => ({ wch: width + 2 }));

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);


      // Handle file type and download
      if (type === "csv") {
        XLSX.writeFile(workbook, `${filename}.csv`, { bookType: 'csv' });
      } else if (type === "xlsx") {
        XLSX.writeFile(workbook, `${filename}.xlsx`, { bookType: 'xlsx' });
      } else {
        downloadPdf(dataToExport, filename);
      }
    }
  };

  if (mainPageloading) {
    return <Loader open={mainPageloading} />;
  }
  return (
    <>
      <RbacWrapper module={"patients"} code={"patient_list_medical_lead"}>
        <Box sx={{ width: "100%", typography: "body1", marginBottom: "1%" }}>

          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                variant="fullWidth"
              >
                <Tab
                  label="My Patient List"
                  value="1"
                  sx={{
                    fontSize: "12px",
                    // backgroundColor: colors.PRIMARY_LIGHT,
                    // color: "white",
                    // '&.Mui-selected': {
                    //   backgroundColor: colors.PRIMARY_DARK,
                    // },
                    // '&:hover': {
                    //   backgroundColor: colors.PRIMARY_LIGHT,
                    // }
                  }}
                />
                <Tab
                  label="Hospital Patient List"
                  value="2"
                  sx={{
                    fontSize: "12px",
                    // backgroundColor: colors.PRIMARY_LIGHT,
                    // color: colors.PRIMARY_TEXT,
                    // '&.Mui-selected': {
                    //   backgroundColor: colors.PRIMARY_DARK,
                    // },
                    // '&:hover': {
                    //   backgroundColor: colors.PRIMARY_LIGHT,
                    // }
                  }}
                />
              </TabList>
            </Box>

          </TabContext>
        </Box>
      </RbacWrapper>
      <Box>
        <MgpText variant="h5">Deleted Patient List</MgpText>
        <MgpTable table={table} />
      </Box>
      <Box>
        <MgpText variant="h5">Deleted Follow-up Reports</MgpText>
        <MgpTable table={tableFollowup} />
      </Box>
    </>
  );
};
