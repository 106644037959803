import React, { useState } from "react";
import { Grid, Container, Box } from "@mui/material";

import { Formik } from "formik";
import * as Yup from "yup";
import { MgpToggleButtonGroup } from "../components/global/MgpToggleButtonGroup";
import {
  MgpButton,
  MgpDD,
  MgpTextboxFormik,
  Padding,
} from "../components/global";
import { MgpDatePicker } from "../components/global/MgpDatePicker";
import { MgpCustomSelect } from "../components/global/MgpDropdown";
import { usePatient } from "../hooks";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader/Loader";

export default function LivingStatus({ encounterData, setlivingStatusOpen }) {
  const [mainPageloading, setMainPageloading] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const [initialValues, setInitialValues] = useState({
    Is_the_patient_alive: "",
    Date_of_death: "",
    Death_Related_Cause: "",
    Cause_of_Death: "",
    Others_specify: "",
    Start_Date:
      encounterData?.[0]?.["details"]?.["basicVisit"]?.["dateofvisit"],
  });
  const { useUpdatePatientLivingStatus } = usePatient();
  const [UpdatePatientLivingStatus] = useUpdatePatientLivingStatus;
  const [message, setMessage] = useState("");
  const [alertOption, setAlertOption] = useState(false);

  const navigate = useNavigate();
  console.log("encounterData", encounterData?.[0]?.["details"]?.["basicVisit"]);
  return (
    <>
      <Loader open={mainPageloading} />

      <Container fluid>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            Is_the_patient_alive: Yup.string().required(
              "Is the patient alive is required"
            ),
            Date_of_death: Yup.string().when(
              "Is_the_patient_alive",
              (value) => {
                console.log("Is_the_patient_alive", value[0]);
                if (value[0] === "No") {
                  return Yup.string().required(
                    "Date of death cause is required"
                  );
                } else {
                  return Yup.string().nullable().optional();
                }
              }
            ),
            Death_Related_Cause: Yup.string().when(
              "Is_the_patient_alive",
              (value) => {
                if (value[0] === "No") {
                  return Yup.string().required(
                    "Death-related cause is required"
                  );
                } else {
                  return Yup.string().nullable().optional();
                }
              }
            ),
            Cause_of_Death: Yup.string().when(
              "Death_Related_Cause",
              (value) => {
                if (value[0] === "MG-Related") {
                  return Yup.string().required("Cause of death is required");
                } else {
                  return Yup.string().nullable().optional();
                }
              }
            ),
            Others_specify: Yup.string().when("Cause_of_Death", (value) => {
              if (value[0] === "Others, specify") {
                return Yup.string().required("Others Specify is required");
              } else {
                return Yup.string().nullable().optional();
              }
            }),
          })}
        >
          {({
            error,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setValues,
            touched,
            value,
            values,
            errors,
            resetForm,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <>
                  <>
                    <Grid
                      container
                      spacing={3}
                      direction="row"
                      alignItems="flex-end"
                    >
                      <Grid
                        position="relative"
                        item
                        sm={values?.["Is_the_patient_alive"] === "No" ? 6 : 12}
                      >
                        <MgpToggleButtonGroup
                          disabled={false}
                          viewMode={false}
                          touched={touched}
                          errors={errors}
                          name={"Is_the_patient_alive"}
                          size="small"
                          label={"Is the patient alive?"}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          values={values}
                          options={["Yes", "No"]}
                          required={"false"}
                          setValues={setValues}
                          removeDependence={[
                            "Date_of_death",
                            "Death_Related_Cause",
                            "Cause_of_Death",
                            "Others_specify",
                          ]}
                          checkfieldvalue={["Yes"]}
                          newVisit={"false"}
                        ></MgpToggleButtonGroup>
                      </Grid>
                      {values?.["Is_the_patient_alive"] === "No" && (
                        <Grid item md={6}>
                          <Box position="relative">
                            <MgpDatePicker
                              viewMode={false}
                              size="small"
                              fullWidth
                              disabled={
                                values?.["Is_the_patient_alive"] === "No"
                                  ? false
                                  : true
                              }
                              onChange={setFieldValue}
                              errors={errors}
                              setFieldValue={setFieldValue}
                              touched={touched}
                              handleChange={handleChange}
                              label={"Date of death"}
                              name={"Date_of_death"}
                              values={values}
                              handleBlur={handleBlur}
                              startDate={"Start_Date"}
                              disablePast={false}
                              monthly={true}
                              disableFuture={true}
                              removetoday={true}
                              // onChange={handleChange}
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                      )}
                      {values?.["Is_the_patient_alive"] === "No" && (
                        <Grid item md={6}>
                          <Box position="relative">
                            <MgpCustomSelect
                              options={["MG-Related", "Non-MG-Related"]}
                              label={"Death-Related Cause"}
                              name={"Death_Related_Cause"}
                              size="small"
                              viewMode={false}
                              error={Boolean(
                                touched["Death_Related_Cause"] &&
                                  errors["Death_Related_Cause"]
                              )}
                              helperText={
                                touched["Death_Related_Cause"] &&
                                errors["Death_Related_Cause"]
                              }
                              disabled={
                                values?.["Is_the_patient_alive"] === "No"
                                  ? false
                                  : true
                              }
                              removeDependence={["Cause_of_Death"]}
                              checkfieldvalue={["Non-MG-Related"]}
                              handleBlur={handleBlur}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              values={values}
                              handleChange={handleChange}
                              setValues={setValues}
                              multiselect={false}
                            ></MgpCustomSelect>
                          </Box>
                        </Grid>
                      )}
                      {values?.["Is_the_patient_alive"] === "No" && (
                        <Grid item md={6}>
                          <Box position="relative">
                            <MgpCustomSelect
                              options={[
                                "Myasthenic crisis (respiratory failure)",
                                "Exacerbation of MG symptoms",
                                "Others, specify",
                              ]}
                              label={"Cause of Death"}
                              name={"Cause_of_Death"}
                              size="small"
                              viewMode={false}
                              error={Boolean(
                                touched["Cause_of_Death"] &&
                                  errors["Cause_of_Death"]
                              )}
                              helperText={
                                touched["Cause_of_Death"] &&
                                errors["Cause_of_Death"]
                              }
                              disabled={
                                values?.["Death_Related_Cause"] === "MG-Related"
                                  ? false
                                  : true
                              }
                              dependent={"Death_Related_Cause"}
                              fieldValue={["MG-Related"]}
                              handleBlur={handleBlur}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              setValues={setValues}
                              errors={errors}
                              values={values}
                              handleChange={handleChange}
                              multiselect={false}
                            ></MgpCustomSelect>
                          </Box>
                        </Grid>
                      )}
                      {values?.["Is_the_patient_alive"] === "No" && (
                        <Grid item md={6}>
                          <MgpTextboxFormik
                            fullWidth
                            size="small"
                            label="Others, specify"
                            name="Others_specify"
                            values={values}
                            disabled={
                              values?.["Cause_of_Death"] === "Others, specify"
                                ? false
                                : true
                            }
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            variant="outlined"
                            //   touched={touched}
                            errors={false}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid item md={12}>
                      <Padding size="20px 0px">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end", // Center the buttons horizontally
                            alignItems: "center",
                            pt: 2,
                          }}
                        >
                          <MgpButton
                            variant="contained"
                            // type="submit"
                            onClick={() => {
                              setMainPageloading(true);

                              if (values?.Is_the_patient_alive === "No") {
                                UpdatePatientLivingStatus({
                                  variables: {
                                    input: {
                                      PatientLivingStatus: {
                                        is_the_patient_alive:
                                          values?.Is_the_patient_alive === "No"
                                            ? false
                                            : true,
                                        date_of_death: values?.Date_of_death,
                                        death_related_cause:
                                          values?.Death_Related_Cause,
                                        cause_of_death: values?.Cause_of_Death,
                                        others_specify: values?.Others_specify,
                                      },
                                    },
                                    patientId:
                                      encounterData?.[0]?.["patient_id"],
                                  },
                                  onCompleted: (data) => {
                                    setMainPageloading(false);
                                    setAlertOption(true);
                                    setlivingStatusOpen(false);
                                    setMessage(
                                      "Living Status Updated successfully"
                                    );
                                  },
                                  onError: (error) => {
                                    alert(error);
                                    setMainPageloading(false);
                                  },
                                });
                              } else {
                                setlivingStatusOpen(false);
                              }
                            }}
                            disabled={disableForm}
                          >
                            Submit
                          </MgpButton>
                        </Box>
                      </Padding>
                    </Grid>
                  </>
                </>
              </form>
            );
          }}
        </Formik>
      </Container>
    </>
  );
}
