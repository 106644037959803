import { MgpAutoCompleteFreeSolo } from "./MgpAutoCompleteFreeSolo";

export const MgpAutoCompleteFreeSoloFormik = ({
  title,
  disabled,
  splits,
  name,
  label,
  error,
  handleBlur,
  setFieldValue,
  values,
  attr,
  size,
  limit,
  viewMode,
  allowed,
  value,
  helperText,
  comment,
  setCommentMessage,
  commentMessage,
  transferPatient,
  handleChangeNationalId,
  type,
}) => {
  return (
    <>
      <MgpAutoCompleteFreeSolo
        fullWidth
        sx={{ alignSelf: "end" }}
        type={type}
        error={error}
        size="small"
        // onKeyDown={handleKeyDown}
        disabled={disabled || viewMode ? true : false}
        helperText={helperText}
        label={title}
        name={name}
        value={values[name]}
        onKeyDown={(event) => {
          if (allowed !== undefined) {
            // console.log("allowed", allowed);

            const allowedKeys = allowed;

            // Limit the length to 1 character
            if (
              value.length >= 1 &&
              event.key !== "Backspace" &&
              event.key !== "Delete"
            ) {
              event.preventDefault();
              return;
            }

            // Allow only allowed keys and handle "Delete" key
            if (!allowedKeys.includes(event.key) && event.key !== "Backspace") {
              event.preventDefault();
            }

            // Handle "Delete" key to clear the TextField value
            if (event.key === "Delete") {
              setFieldValue(name, "");
            }
          }
        }}
        InputLabelProps={{
          sx: {
            // fontFamily: "Montserrat",
            fontSize: "13px",
            fontWeight: "400",
            paddingTop: "2px",
            lineHeight: "28p",
          },
        }}
        inputProps={{
          maxLength: limit !== undefined ? 1 : 100,
          // fontFamily: "Montserrat",
          fontSize: "13px",
          fontWeight: "400",
          // paddingTop: "2px",
          // lineHeight: "28p",
        }}
        onChange={(e) => {
          const newValue = e.target.value;
          setFieldValue(name, newValue);

          if (values[splits[0]]?.[splits[1]]?.ischecked) {
            let sum = +newValue; // Start with the new value
            for (let key in values[splits[0]]?.[splits[1]]) {
              if (key !== "ischecked" && key !== "totalscore") {
                sum += +values[splits[0]]?.[splits[1]][key];
              }
            }
            setFieldValue(`${splits[0]}.${splits[1]}.totalscore`, sum);
          }
        }}
        onBlur={handleBlur}
        variant="outlined"
        setFieldValue={setFieldValue}
        values={values}
        title={title}
      />
    </>
  );
};
