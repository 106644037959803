import { colors } from "../../theme/colors";
import { MgpText } from "./MgpText";
import { MgpAppbar } from "./MgpAppbar";

export const Footer = () => {
  return (
    <MgpAppbar
      position="static"
      section={"Footer"}
      children={
        <>
          <MgpText variant="body2">
            COPYRIGHT © 2024 <span style={{ color: colors.NAVBAR }}>ITKAN</span>
            , ALL RIGHT RESERVED{" "}
          </MgpText>
          <img
            style={{ width: "70px" }}
            src={process.env.PUBLIC_URL + "/Itkan-W_edited.webp"}
            alt="footer"
          />
        </>
      }
    ></MgpAppbar>
  );
};
