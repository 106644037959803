import { TextareaAutosize } from "@mui/material";
import { minWidth } from "@mui/system";
import * as React from "react";

export default function MgpTextarea({
  value,
  handleChange,
  disabled = false,
  placeholder = "Please Specify",
  minRows = 13,
  width = "100%",
  padding = "",
  border = null,
}) {
  return (
    <>
      <TextareaAutosize
        style={{
          width: width,
          padding,
          borderColor: border,
        }}
        sx={{
          "& .textarea": {
            borderColor: "blue",
            outline: "none",
          },
        }}
        disabled={disabled}
        minRows={minRows}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />
    </>
  );
}
