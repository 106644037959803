import React from 'react'
import { Box, Backdrop } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
const Loader = ({ open }) => {
    return (
      <Backdrop open={open} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };
 
  
export default Loader