import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { ProcessGrapqlError, apollo_client } from "../helpers";

export const useRoles = () => {
  const ADD_ROLES = gql`
    mutation Mutation($roleName: String!, $accessRights: JSON!) {
      AddRole(role_name: $roleName, access_rights: $accessRights) {
        role_id
        role_name
        access_rights
        is_active
        created_on
        updated_on
      }
    }
  `;

  const GET_ROLES = gql`
    query Query {
      GetRoles {
        access_rights
        is_active
        role_id
        role_name
        created_on
        updated_on
      }
    }
  `;

  const DELETE_ROLES = gql`
    mutation Mutation($roleId: Int!) {
      DeleteRole(role_id: $roleId) {
        role_id
        role_name
        access_rights
        is_active
        created_on
        updated_on
      }
    }
  `;

  const UPDATE_ROLES = gql`
    mutation UpdateRole(
      $roleId: Int!
      $roleName: String!
      $accessRights: JSON!
    ) {
      UpdateRole(
        role_id: $roleId
        role_name: $roleName
        access_rights: $accessRights
      ) {
        role_id
        role_name
        access_rights
        is_active
        created_on
        updated_on
      }
    }
  `;
  const useAddRoles = useMutation(ADD_ROLES, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { AddRole } }) {
      try {
        const existingData = cache.readQuery({ query: GET_ROLES });
        if (existingData) {
          const newData = [...existingData.GetRoles, AddRole];
          cache.writeQuery({
            query: GET_ROLES,
            data: { GetRoles: newData },
          });
        } else {
          // Refetch data if it's not available in the cache
          apollo_client
            .query({
              query: GET_ROLES,
            })
            .then(({ data }) => {
              if (data && data.GetUsers) {
                const newData = [...data.GetRoles, AddRole];
                cache.writeQuery({
                  query: GET_ROLES,
                  data: { GetRoles: newData },
                });
              }
            })
            .catch((error) => {
              console.error("Error while refetching data:", error);
            });
        }
      } catch (error) {
        console.error(
          "An error occurred while updating the cache after updating a country:"
        );
      }
    },
  });
  const useUpdateRole = useMutation(UPDATE_ROLES, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { UpdateRole } }) {
      try {
        const existingData = cache.readQuery({ query: GET_ROLES });
        if (existingData) {
          const newData = existingData.GetRoles.map((item) =>
            item.role_id !== UpdateRole.role_id ? item : UpdateRole
          );
          cache.writeQuery({
            query: GET_ROLES,
            data: { GetRoles: newData },
          });
        } else {
          // Refetch data if it's not available in the cache
          apollo_client
            .query({
              query: GET_ROLES,
            })
            .then(({ data }) => {
              if (data && data.GetUsers) {
                const newData = data.GetRoles.map((item) =>
                  item.role_id !== UpdateRole.role_id ? item : UpdateRole
                );
                cache.writeQuery({
                  query: GET_ROLES,
                  data: { GetRoles: newData },
                });
              }
            })
            .catch((error) => {
              console.error("Error while refetching data:", error);
            });
        }
      } catch (error) {
        console.error(
          "An error occurred while updating the cache after updating a country:"
        );
      }
    },
  });
  const useDeleteRole = useMutation(DELETE_ROLES, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { DeleteRole } }) {
      const existing = cache.readQuery({
        query: GET_ROLES,
        fetchPolicy: "cache-and-network",
      });
      if(existing){
      const newRoles = existing.GetRoles.filter(
        (item) => item.role_id !== DeleteRole.role_id
      );
      cache.writeQuery({
        query: GET_ROLES,
        data: { GetRoles: newRoles },
      });
    } else {
      // Refetch data if it's not available in the cache
      apollo_client
        .query({
          query: GET_ROLES,
        })
        .then(({ data }) => {
          if (data && data.GetUsers) {
            const newData = data.GetRoles.map((item) =>
              item.role_id !== DeleteRole.role_id ? item : DeleteRole
            );
            cache.writeQuery({
              query: GET_ROLES,
              data: { GetRoles: newData },
            });
          }
        })
        .catch((error) => {
          console.error("Error while refetching data:", error);
        });
    }
    }
  });
  const useGetRoles = useLazyQuery(GET_ROLES);

  return { useAddRoles, useGetRoles, useUpdateRole, useDeleteRole };
};
