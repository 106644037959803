import { MultiCascader, Radio, Tag } from "rsuite";
import MgpTextarea from "./MgpTextarea";
import { useEffect, useState } from "react";
import "../MgpMulticascader.css";
import { RedAsterisk } from "./MgpAccordion";
import { colors } from "../../theme/colors";

export const MgpMulticascader = ({
  splits,
  name,
  label,
  title,
  touched,
  errors,
  error,
  handleBlur,
  setFieldValue,
  values,
  data,
  dependent,
  required,
  attr,
  size,
  viewMode,
  uncheckedValues,
  disabled,
  commentData,
  encountedDataLatestStatus,
  setValues,
  edit,
  mainTitle,
  followuplable,
  handleChangefrom,
  attribute,
}) => {
  const [value, setValue] = useState({});
  const [cascadervalue, setCascadervalue] = useState([]);
  const [disablekeys, SetDisableKeys] = useState(false);
  const handleChange = (val, event, name) => {
    event.preventDefault();
    setValue({ ...value, [name]: val });
  };

  useEffect(() => {
    if (cascadervalue.includes("None")) {
      console.log("cascadervalue1", value);

      const parentLabels = data
        .map((item) => item.value)
        .filter((label) => label !== "None");
      SetDisableKeys(parentLabels);
      setCascadervalue(["None"]);
      setValue({});
      return;
    } else {
      if (cascadervalue.length > 0) {
        const parentLabels = data
          .map((item) => item.value)
          .filter((label) => label === "None");
        SetDisableKeys(parentLabels);
      } else {
        SetDisableKeys([]);
      }
    }
    if (values?.[name] === "") {
      setValue({});
      setCascadervalue([]);
    }
  }, [values]);
  useEffect(() => {
    if (values?.[name]) {
      const data = JSON.parse(values?.[name]);
      setValue(data?.value);
      setCascadervalue(data?.cascadervalue);
    }
  }, []);
  // console.log("valuesss", cascadervalue, values?.[name]);

  const handleMultiChange = (value, event, selectedOption) => {
    let arr = [];
    if (value.includes("None")) {
      console.log("cascadervalue1", value);

      const parentLabels = data
        .map((item) => item.value)
        .filter((label) => label !== "None");
      SetDisableKeys(parentLabels);
      setCascadervalue(["None"]);
      setValue({});
      return;
    } else {
      console.log("cascadervalue2", value);
      if (value.length > 0) {
        console.log("cascadervalue3", value);

        const parentLabels = data
          .map((item) => item.value)
          .filter((label) => label === "None");
        SetDisableKeys(parentLabels);
      } else {
        console.log("cascadervalue4", value);

        SetDisableKeys([]);
      }
    }
    if (selectedOption) {
      let values = selectedOption.split(" -> ");
      if (values.length > 1) {
        for (let i = 0; i < values.length - 1; i++) {
          let string = values.slice(0, i + 1).join(" -> ");
          if (!cascadervalue.includes(string)) {
            arr.push(string);
          }
        }
      }
    }

    setCascadervalue((previous) => [...value, ...arr]);
  };

  useEffect(() => {
    if (cascadervalue.length > 0) {
      setFieldValue(name, JSON.stringify({ cascadervalue, value }));
    } else {
      setFieldValue(name, JSON.stringify({ cascadervalue: [], value: {} }));
    }
  }, [cascadervalue, value, name, setFieldValue]);

  const handleToggleChange = (value) => {
    let values = value.split(" -> ");
    if (values.length > 1) {
      let arr = [];
      for (let i = 0; i < values.length - 1; i++) {
        let string = values.slice(0, i + 1).join(" -> ");
        if (!cascadervalue.includes(string)) {
          arr.push(string);
        }
      }
      setCascadervalue((previous) => [...previous, ...arr]);
    }
    setCascadervalue((previous) => [
      ...previous.filter(
        (elem) =>
          !(
            elem.split(" -> ").length === value.split(" -> ").length &&
            elem.split(" -> ").slice(0, -1).join(" -> ") ===
              value.split(" -> ").slice(0, -1).join(" -> ")
          )
      ),
      value,
    ]);
  };

  const customRenderMenuItem = (itemLabel, item) => {
    if (
      (itemLabel === "Other_textbox" ||
        itemLabel === "Other, Specify_textbox") &&
      value[item.value] === undefined
    ) {
      // console.log("selectedvalues");
      setValue({ ...value, [item.value]: "" });
    }
    console.log(
      "jkdfjkdfkj",
      cascadervalue.includes(
        item.value
          .split(" -> ")
          .slice(0, item.value.split(" -> ").length - 1)
          .join(" -> ")
      )
    );
    if (
      !cascadervalue.includes(
        item.value
          .split(" -> ")
          .slice(0, item.value.split(" -> ").length - 1)
          .join(" -> ")
      )
    ) {
      if (value[item.value] !== "") {
        delete value[item.value];
      }
    }
    return (
      <div key={item.value}>
        {itemLabel === "Other_textbox" ||
        itemLabel === "Other, Specify_textbox" ? (
          <MgpTextarea
            disabled={
              !cascadervalue.includes(
                item.value
                  .split(" -> ")
                  .slice(0, item.value.split(" -> ").length - 1)
                  .join(" -> ")
              )
            }
            placeholder={item.placeholder}
            value={value[item.value]}
            name={item.value}
            handleChange={(event) => {
              const regex = /^[\x20-\x7E]*$/;
              if (regex.test(event.target.value)) {
                handleChange(event.target.value, event, item.value);
              }
            }}
          />
        ) : item?.isToggle ? (
          <Radio
            checked={cascadervalue.includes(item.value)}
            onChange={() => {
              handleToggleChange(item.value);
            }}
          >
            {itemLabel}
          </Radio>
        ) : (
          itemLabel
        )}
      </div>
    );
  };

  const renderChips = (values) => {
    if (!Array.isArray(values)) {
      return null;
    }

    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          overflow: "auto",
          gap: 4,
          minHeight: "25px",
          width: "100%",
        }}
      >
        {values.map((val) => {
          if (!val.children) {
            handleChangefrom(name, attribute);
          }
          return !val.children ? (
            <Tag
              style={{
                fontSize: "15px",
                fontWeight: "500",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                display: "flex",
                justifyContent: "center",
              }}
              key={val.value}
              closable
              onClose={() => handleChipClose(val.value)}
            >
              {val.value}
            </Tag>
          ) : value[`${val.value} -> Other_textbox`] ||
            value[`${val.value} -> Other, Specify_textbox`] ? (
            <Tag
              key={val.value}
              closable
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "15px",
                fontWeight: "500",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              }}
              onClose={() => handleChipClose(val.value)}
            >
              {val.value} {" -> "}
              {value[`${val.value} -> Other_textbox`] ||
                value[`${val.value} -> Other, Specify_textbox`]}
            </Tag>
          ) : null;
        })}
      </div>
    );
  };

  const handleChipClose = (value) => {
    setCascadervalue((prevValues) => prevValues.filter((val) => val !== value));
  };

  const filterDependentItems = (data) => {
    return data
      .filter((item) => {
        if (!item.dependent) {
          return item;
        } else {
          if (values[item.dependent] === item.dependentValue) {
            return item;
          }
        }
      })
      .map((item) => {
        if (item.children) {
          return { ...item, children: filterDependentItems(item.children) };
        }
        return item;
      });
  };
  const urlWindow = window.location;

  const hasAsterisk = mainTitle?.endsWith("*");
  const hasAsteriskmainTitle = followuplable?.endsWith("*");

  return (
    <div>
      {mainTitle ? (
        <div
          style={{
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontSize: "16px",
            fontWeight: "500", // Adjusted fontWeight for bolder text
            color: colors.GOLD,
            paddingBottom: "4px",
          }}
        >
          {urlWindow.pathname.split("/")[3] === "newVisit" ||
          values?.["basicVisit_typeofvisit"] === "Follow Up Visit" ? (
            followuplable === undefined ? (
              hasAsterisk ? (
                <>
                  {mainTitle?.slice(0, -1)}
                  <RedAsterisk>*</RedAsterisk>
                </>
              ) : (
                mainTitle
              )
            ) : hasAsteriskmainTitle ? (
              <>
                {followuplable?.slice(0, -1)}
                <RedAsterisk>*</RedAsterisk>
              </>
            ) : (
              followuplable
            )
          ) : hasAsterisk ? (
            <>
              {mainTitle?.slice(0, -1)}
              <RedAsterisk>*</RedAsterisk>
            </>
          ) : (
            mainTitle
          )}
        </div>
      ) : null}
      <MultiCascader
        value={cascadervalue}
        style={{
          width: "100%",
          ...(error ? { border: "1px solid red", borderRadius: "4px" } : {}),
          ...(encountedDataLatestStatus === 4 ||
          encountedDataLatestStatus === 6 ||
          disabled ||
          viewMode
            ? edit && { opacity: 0.7 }
            : {}),
        }}
        searchable={false}
        placement="bottomStart"
        placeholder={title}
        menuWidth={"200px"}
        menuHeight={"252px"}
        onChange={(value, event) =>
          handleMultiChange(value, event, value[value.length - 1])
        }
        disabled={
          encountedDataLatestStatus === 4 || encountedDataLatestStatus === 6
            ? !edit
              ? true
              : disabled
            : disabled || viewMode
            ? true
            : false
        }
        cascade={false}
        onClose={() => {}}
        uncheckableItemValues={uncheckedValues}
        renderMenuItem={customRenderMenuItem}
        data={filterDependentItems(data)}
        renderValue={(value, selectedItems) => renderChips(selectedItems)}
        values={values}
        onBlur={handleBlur}
        error={error}
        disabledItemValues={disablekeys || []} // Corrected prop name
      />
    </div>
  );
};
