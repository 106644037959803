import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { StepButton, StepLabel } from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { colors } from "../../theme/colors";
import { postProcessData, preProcessData } from "../../hooks";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function MgpProgressBar({
  commentData,
  steps,
  completed,
  activeStep,
  setActiveStep,
  setCompleted,
  setSelectedSection,
  crfErrors,
  crfTouch,
  encountedData,
  formikRef,
  patient_selected_section,
  encountedDataLatestStatus,
  submitErrors,
}) {
  const handleStep = (step, label) => () => {
    setActiveStep(step);
    setSelectedSection(label);
  };

  function areAllFieldsEmpty(data) {
    const excludeFields = [
      "",
      "cause",
      "countrylist",
      "created",
      "date",
      "death",
      "doctor",
      "firstVisit",
      "hospital",
      "is",
      "lastVisit",
      "nationality",
      "others",
      "patient",
      "registryno",
      "status",
      "totalVisits",
      "updated",
      "MGseverity",
      "PIS",
      "surgerythymectomys",
      "ImmunosuppressiveTreatment",
      "rapidimmunotherapies",
      "ImmunosuppressiveTreatmentCurrentMedication",
    ];
    if (typeof data === "string") {
      // For strings, check if the string is empty
      return data.trim() === "";
    }

    if (Array.isArray(data)) {
      // For arrays, check if every element is empty (recursive)
      return data.every((item) => areAllFieldsEmpty(item, excludeFields));
    }

    if (typeof data === "object" && data !== null) {
      // For objects, check if every value is empty (recursive)
      return Object.entries(data).every(([key, value]) => {
        // Skip fields that are in the excludeFields array
        if (excludeFields.includes(key)) {
          return true; // Consider excluded fields as "empty"
        }
        return areAllFieldsEmpty(value, excludeFields);
      });
    }

    // For other types (null, undefined, boolean, numbers), consider them empty if they are falsey
    return data === "" || data === null || data === false || data === undefined;
  }

  function checkSectionFilled(section) {
    const demography = [
      "gender",
      "dateofbirth",
      "year",
      "month",
      "nationality",
      "countrylist",
      "city",
      "region",
      "maritalstatus",
      "educationallevel",
      "employment",
      "smokerstatus",
    ];

    // If the section contains a "gender" field, check only the specified fields
    if (section?.hasOwnProperty("gender")) {
      for (let field of demography) {
        if (
          !section.hasOwnProperty(field) ||
          section[field] === "" ||
          section[field] === null
        ) {
          console.log("Missing or empty field:", field);
          return false; // Field is empty, null, or missing
        }
      }
      return true; // All specified fields are filled
    }

    const excludedFields = [
      "initialdistributionofsymptomsothers",
      "symptomsatonsetothers",
      "MGFA",
      "CauseofDeath",
      "DeathRelatedCause",
      "OthersSpecify",
      "dateofdeath",
      "MGseverity",
      "unknown",
      "mainreasonformghospitalizationothers",
      "specifyward",
      "underlyingcause",
      "hospitalizationsprimarilyduemg",
      "pregnancyhistoryafterdiagnosis",
      "mainreasonformghospitalization",
      "hospitalizationsrelatedmyastheniagravis",
      "pregnancy",
      "depressed",
      "difficultyspeaking",
      "enjoyhobbies",
      "feeloverwhelmed",
      "frustratedmg",
      "makeplans",
      "needsoffamily",
      "occupationalskills",
      "socialactivity",
      "totalscore",
      "troubledriving",
      "troubleeating",
      "troubleeyes",
      "troubleperformingpersonal",
      "troublepublicplaces",
      "troublewalking",
      "breathing",
      "chewing",
      "doublevisionlateralgaze",
      "eyeclosure",
      "hipflexion",
      "neckflexionorextension",
      "ptosisupwardease",
      "shoulderabduction",
      "swallowing",
      "talking",
      "doublevision",
      "eyeliddroop",
      "impairmentabilitytoarisechair",
      "impairmentabilitytobrush",
      "selectoption",
      "paraclinicalinvestigation",
      "myastheniagravisfoundationofamerica",
      "magneticresonanceimaging",
      "magneticresonanceimagingMRI",
      "forcedvitalcapacity",
      "Pleasespecify",
      "serologicaltests",
    ];
    if (section?.hasOwnProperty("surgerythymectomy")) {
      if (
        formikRef?.["TreatmentInterventionCurrentMedication_None_isChecked"] ===
          true &&
        formikRef?.["TreatmentIntervention_PreviousNone_isChecked"] === true &&
        formikRef?.["TreatmentIntervention_surgerythymectomy"] === "No"
      ) {
        return true;
      } else {
        if (
          formikRef?.[
            "TreatmentInterventionCurrentMedication_None_isChecked"
          ] === true &&
          formikRef?.["TreatmentIntervention_PreviousNone_isChecked"] ===
            true &&
          formikRef?.["TreatmentIntervention_surgerythymectomy"] === "Yes"
        ) {
          console.log("enteredthe");
          const excludedFieldsTreatment = [
            "PreviousNone",
            "acetylcholinesteraseinhibitors",
            "corticosteroidstreatment",
            "rapidimmunotherapies",
            "ImmunosuppressiveTreatment",
            "CyclicintravenousimmunoglobulinsIVIG",
            "CyclicPlasmapheresisPLEX",
            "BoneMarrowTransplant(BMT)",
            "others",
            formikRef?.["TreatmentIntervention_Histopathologyresult"] ===
            "Thymoma"
              ? "Whatwasthethymomastagingsystemused"
              : "Whatwasthethymomastagingsystemused",
            formikRef?.["TreatmentIntervention_Histopathologyresult"] ===
            "Thymoma"
              ? ""
              : "Whatwasthethymomastagingsystemused?",
            "acetylcholinesteraseinhibitorsCurrentMedication",
            "corticosteroidstreatmentCurrentMedication",
            "ImmunosuppressiveTreatmentCurrentMedication",
            "corticosteroidstreatmentCurrentMedicationothers",
            "BoneMarrowTransplant",
          ];
          for (let field in section) {
            if (!excludedFieldsTreatment.includes(field)) {
              const fieldValue = section[field];
              if (
                typeof fieldValue === "object" &&
                fieldValue !== null &&
                fieldValue.hasOwnProperty("ischecked")
              ) {
                if (
                  fieldValue.ischecked === "" ||
                  fieldValue.ischecked === false
                ) {
                  return false;
                }
              }
              if (
                fieldValue === "" ||
                (typeof fieldValue === "object" &&
                  Array.isArray(fieldValue) &&
                  fieldValue.length === 0)
              ) {
                return false;
              }
              if (typeof fieldValue === "object" && fieldValue !== null) {
                if (!checkSectionFilled(fieldValue)) {
                  return false;
                }
              }
            }
          }
          return true;
        }
      }
    }

    const dynamicOptionalFieldRegex = [
      /^previouspregnancyoutcomesothers\d+$/,
      /^mainreasonformghospitalizationothers\d+$/,
      /^Whatwasthelengthofeachstayindays\d+$/,
      /^underlyingcauseothers\d+$/,
      /^hospitalizationsrelatedmyastheniagravis\d+$/,
      /^hospitalizationsprimarilyduemg\d+$/,
      /^specifyward\d+$/,
      /^pregnancy\d+$/,
      /^underlyingcause\d+$/,
      /^mainreasonformghospitalization\d+$/,
    ];
    if (section?.hasOwnProperty("dateofdiagnosis")) {
      if (
        !section?.hasOwnProperty(
          "myastheniagravisfoundationofamericapostinterventionstatus"
        )
      ) {
        return false; // Return false if this key is missing
      } else {
        // Iterate through each field in the section
        for (let field in section) {
          if (!excludedFields.includes(field)) {
            const fieldValue = section[field];
            const isDynamicOptionalField = dynamicOptionalFieldRegex.some(
              (regex) => regex.test(field)
            );
            if (isDynamicOptionalField && fieldValue === "") {
              continue;
            }
            if (
              typeof fieldValue === "object" &&
              fieldValue !== null &&
              fieldValue.hasOwnProperty("ischecked")
            ) {
              if (
                fieldValue.ischecked === "" ||
                fieldValue.ischecked === false
              ) {
                return false;
              }
            }
            if (
              fieldValue === "" ||
              (typeof fieldValue === "object" &&
                Array.isArray(fieldValue) &&
                fieldValue.length === 0)
            ) {
              return false;
            }

            if (typeof fieldValue === "object" && fieldValue !== null) {
              if (!checkSectionFilled(fieldValue)) {
                return false;
              }
            }
          }
        }
      }
    }
    if (section?.hasOwnProperty("paraclinicalinvestigation")) {
      const investigations = [
        "repetitivenervestimulation",
        "icepacktest",
        "forcedvitalcapacity",
        "edrophoniumtest",
        "chestimagingexam",
        "singlefiberelectromyography",
      ];

      const containsAllInvestigations = investigations.every((test) => {
        const value = section[test];
        if (test === "forcedvitalcapacity") {
          const fvc = value?.forcedvitalcapacity;
          const resultInML = value?.resultinml;
          const resultInPercent = value?.["resultin%"];
          return fvc || resultInML || resultInPercent;
        }

        return value !== undefined && value !== null && value !== "";
      });

      console.log("containsInvestigation", containsAllInvestigations);

      if (!containsAllInvestigations) {
        return false;
      } else {
        // Iterate through each field in the section
        for (let field in section) {
          if (!excludedFields.includes(field)) {
            const fieldValue = section[field];
            const isDynamicOptionalField = dynamicOptionalFieldRegex.some(
              (regex) => regex.test(field)
            );
            if (isDynamicOptionalField && fieldValue === "") {
              continue;
            }
            if (
              typeof fieldValue === "object" &&
              fieldValue !== null &&
              fieldValue.hasOwnProperty("ischecked")
            ) {
              if (
                fieldValue.ischecked === "" ||
                fieldValue.ischecked === false
              ) {
                return false;
              }
            }
            if (
              fieldValue === "" ||
              (typeof fieldValue === "object" &&
                Array.isArray(fieldValue) &&
                fieldValue.length === 0)
            ) {
              return false;
            }

            if (typeof fieldValue === "object" && fieldValue !== null) {
              if (!checkSectionFilled(fieldValue)) {
                return false;
              }
            }
          }
        }
      }
    }
    // Iterate through each field in the section
    for (let field in section) {
      if (!excludedFields.includes(field)) {
        const fieldValue = section[field];
        const isDynamicOptionalField = dynamicOptionalFieldRegex.some((regex) =>
          regex.test(field)
        );
        if (isDynamicOptionalField && fieldValue === "") {
          continue;
        }
        if (
          typeof fieldValue === "object" &&
          fieldValue !== null &&
          fieldValue.hasOwnProperty("ischecked")
        ) {
          if (fieldValue.ischecked === "" || fieldValue.ischecked === false) {
            return false;
          }
        }
        if (
          fieldValue === "" ||
          (typeof fieldValue === "object" &&
            Array.isArray(fieldValue) &&
            fieldValue.length === 0)
        ) {
          return false;
        }

        if (typeof fieldValue === "object" && fieldValue !== null) {
          if (!checkSectionFilled(fieldValue)) {
            return false;
          }
        }
      }
    }

    return true; // All fields are valid
  }

  const uniqueKeys = completed
    ? [...new Set(Object.keys(completed).map((elem) => elem.split("_")[0]))]
    : [];
  console.log("uniqueKeys", uniqueKeys);
  const urlWindow = window.location;
  const acceptedPathsList = ["Edit"];
  console.log("hasErrors", submitErrors, formikRef);
  return (
    <Box sx={{ width: "100%", paddingLeft: "10px" }}>
      <Stepper nonLinear activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => {
          const hasComment =
            commentData?.counts &&
            Object.keys(commentData?.counts)?.some(
              (elem) => elem.split("_")[0] === label.key
            );
          const hasErrors =
            submitErrors &&
            Object.keys(submitErrors)?.some(
              (elem) => elem.split("_")[0] === label.key
            );
          let mandatorydata = postProcessData(formikRef)[label.key];
          let isFilled = checkSectionFilled(mandatorydata);
          console.log("isFilled", isFilled, mandatorydata);
          let AreAllFieldsEmpty = areAllFieldsEmpty(mandatorydata);
          if (
            encountedDataLatestStatus !== 0 ||
            encountedDataLatestStatus === null
          ) {
            isFilled = true;
            AreAllFieldsEmpty = false;
          }
          if (encountedDataLatestStatus === null) {
            AreAllFieldsEmpty = true;
          }
          console.log(
            "completedchecked",
            encountedDataLatestStatus,
            AreAllFieldsEmpty,
            isFilled,
            label.key,
            mandatorydata,
            formikRef?.current?.values
          );

          if (label.key !== "MedicationRequest") {
            return (
              <Step key={label.key} completed={completed[index + 1]}>
                <StepButton
                  color="inherit"
                  onClick={handleStep(index, label.key)}
                >
                  <StepLabel
                    StepIconProps={{
                      icon:
                        index === 0 ? (
                          urlWindow.pathname.split("/")[3] === "Edit" ? (
                            <AssignmentIndIcon />
                          ) : (encountedData?.["basicVisit_typeofvisit"] ===
                              "" &&
                              urlWindow.pathname.split("/")[3] ===
                                "newVisit") ||
                            encountedData?.["basicVisit_typeofvisit"] ===
                              "Follow Up Visit" ? (
                            uniqueKeys.includes(label.key) ? (
                              index + 1
                            ) : !AreAllFieldsEmpty ? (
                              <CheckCircleIcon
                                style={{
                                  color: AreAllFieldsEmpty
                                    ? colors.PRIMARY
                                    : !isFilled
                                    ? "#FFA500"
                                    : colors.PRIMARY, // #1976d2 is the hex code for the primary color
                                  fontSize: "24px",
                                }}
                              />
                            ) : (
                              index + 1
                            )
                          ) : (
                            <AssignmentIndIcon />
                          )
                        ) : encountedData?.["basicVisit_typeofvisit"] ===
                          "Follow Up Visit" ? (
                          uniqueKeys.includes(label.key) ? (
                            index + 1
                          ) : !AreAllFieldsEmpty ? (
                            <CheckCircleIcon
                              style={{
                                color: AreAllFieldsEmpty
                                  ? colors.PRIMARY
                                  : !isFilled
                                  ? "#FFA500"
                                  : colors.PRIMARY, // #1976d2 is the hex code for the primary color
                                fontSize: "24px",
                              }}
                            />
                          ) : (
                            index + 1
                          )
                        ) : uniqueKeys.includes(label.key) ? (
                          index
                        ) : !AreAllFieldsEmpty ? (
                          <CheckCircleIcon
                            style={{
                              color: AreAllFieldsEmpty
                                ? colors.PRIMARY
                                : !isFilled
                                ? "#FFA500"
                                : colors.PRIMARY, // #1976d2 is the hex code for the primary color
                              fontSize: "24px",
                            }}
                          />
                        ) : (
                          index
                        ),
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          color: hasErrors
                            ? "red"
                            : areAllFieldsEmpty(mandatorydata)
                            ? colors.PRIMARY
                            : !isFilled
                            ? "#FFA500"
                            : colors.PRIMARY,
                          fontSize: "14px",
                        }}
                      >
                        {label.label}
                      </span>
                      {hasComment ? (
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "#3B82F6",
                            marginLeft: "5px",
                          }}
                        ></div>
                      ) : null}
                    </div>
                  </StepLabel>
                </StepButton>
              </Step>
            );
          }
          return null;
        })}
      </Stepper>
    </Box>
  );
}
