import { Container, Grid, Stack } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  MgpButton,
  MgpDD,
  MgpTextboxFormik,
  Padding,
  MgpTextbox
} from "../../../components/global";
import { MgpCustomSelect } from "../../../components/global/MgpDropdown";
import { useCity, useCountry, useMaster } from "../../../hooks";
import { useHospital } from "../../../hooks/useHospital";
import Loader from "../../Loader/Loader";
export const UpdateHospital = ({
  setAlertOption,
  setMainPageloading,
  setMessage,
  setAlertType,
  closed = () => { },
  edit = {},
}) => {
  const {
    hospital_id = "",
    hospital_name = "",
    hospital_code = "",
    address = "",
    city_id = "",
    country_id = "",
    region_id = ""
  } = edit;
  console.log("country_id", country_id);
  const { useAddHospital, useUpdateHospital } = useHospital();
  // const [GetCountriesAll] = GetCountries;
  const [AddHospital] = useAddHospital;
  const [UpdateHospital] = useUpdateHospital;

  const { cities, countries, regions, setSelectedRegion, setSelectedCountry } = useMaster();
  return (
    <>
      <Container fluid="true">
        <Formik
          initialValues={{
            hospitalName: hospital_name,
            hospitalCode: hospital_code,
            address: address,
            cityId: city_id,
            countryId: country_id,
            regionId: region_id
          }}
          validationSchema={yup.object().shape({
            hospitalName: yup
              .string()
              .min(2, "Too Short!")
              .max(150, "Too Long!")
              .required("Hospital name is required"),
            hospitalCode: yup
              .string()
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Hospital code is required"),
            address: yup
              .string()
              .min(2, "Too Short!")
              .max(200, "Too Long!")
              .required("Address is required"),
              countryId: yup.string().required("Country is required"),
            regionId: yup.string().required("Region is required"),
            cityId:yup.string().required("City is required"),
          })}
          onSubmit={(payload) => {
            console.log("hittt")
            setMainPageloading(true);
            if (hospital_id === "") {
              console.log("options", payload, city_id);

              AddHospital({
                variables: {
                  hospitalName: payload.hospitalName,
                  hospitalCode: payload.hospitalCode,
                  address: payload.address,
                  cityId: Number(payload.cityId),
                },
                onCompleted: () => {
                  setMainPageloading(false);
                  closed();
                  setMainPageloading(false);
                  setAlertOption(true);
                  setAlertType("success")
                  setMessage("Hospital Added Successfully");
                },
                onError: (error) => {
                  if (error?.networkError?.result)
                    setMessage(error.networkError?.result?.errors[0]?.message)
                  else
                    setMessage("Something went wrong! Please try again")
                  setMainPageloading(false)
                  setAlertOption(true)
                  setAlertType("error")
                }
              });
            } else {
              UpdateHospital({
                variables: {
                  hospitalName: payload.hospitalName,
                  hospitalCode: payload.hospitalCode,
                  address: payload.address,
                  hospitalId: Number(hospital_id),
                  cityId: Number(payload.cityId),
                },
                onCompleted: () => {
                  setMainPageloading(false);
                  closed();
                  setMainPageloading(false);
                  setAlertOption(true);
                  setMessage("Hospital Updated Successfully");
                  setAlertType("success")
                },
                onError: (error) => {
                  if (error?.networkError?.result)
                    setMessage(error.networkError?.result?.errors[0]?.message)
                  else
                    setMessage("Something went wrong! Please try again")
                  setMainPageloading(false)
                  setAlertOption(true)
                  setAlertType("error")
                }
              });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <MgpTextbox
                    fullWidth
                    size="small"
                    label="Hospital Name"
                    name="hospitalName"
                    value={values.hospitalName}
                    onChange={(e)=>{
                      setFieldValue("hospitalName",e.target.value)
                    }}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    error={touched["hospitalName"] && !!errors["hospitalName"]}
                    helperText={touched["hospitalName"] && errors["hospitalName"]}
                  />
                </Grid>
                <Grid item md={6}>
                  <MgpTextboxFormik
                    fullWidth
                    size="small"
                    label="Hospital Code"
                    name="hospitalCode"
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    error={touched["hospitalCode"] && !!errors["hospitalCode"]}
                    helperText={touched["hospitalCode"] && errors["hospitalCode"]}
                  />
                </Grid>
                <Grid item md={6}>
                  <MgpTextbox
                    fullWidth
                    size="small"
                    label="Address"
                    name="address"
                    splits={"address".split(".")}
                    value={values["address"]}
                    handleChange={handleChange}
                    onChange={(e)=>{
                      setFieldValue("address",e.target.value)
                    }}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    error={touched["address"] && !!errors["address"]}
                    helperText={touched["address"] && errors["address"]}
                  />
                </Grid>
                <Grid item md={6}>
                  <MgpDD
                    label="Country"
                    variant="outlined"
                    size="small"
                    name="countryId"
                    error={Boolean(touched["countryId"] && errors["countryId"])}
                    fullWidth
                    onChange={(e) => {
                      handleChange(e);
                      setSelectedCountry(e.target.value);
                    }}
                    options={countries}
                    helperText={touched["countryId"] && errors["countryId"]}
                    onBlur={handleBlur}
                    value={values["countryId"]}
                    multiselect={false}
                  />
                </Grid>
                <Grid item md={6}>
                  <MgpDD
                    label="Region"
                    variant="outlined"
                    size="small"
                    name="regionId"
                    error={Boolean(touched["regionId"] && errors["regionId"])}
                    fullWidth
                    onChange={(e) => {
                      handleChange(e);
                      setSelectedRegion(e.target.value);
                    }}
                    options={regions}
                    helperText={touched["regionId"] && errors["regionId"]}
                    onBlur={handleBlur}
                    value={values["regionId"]}
                    multiselect={false}
                  />
                </Grid>
                <Grid item md={6}>
                  <MgpDD
                    label="City"
                    variant="outlined"
                    size="small"
                    name="cityId"
                    error={Boolean(touched["cityId"] && errors["cityId"])}
                    fullWidth
                    onChange={handleChange}
                    options={cities}
                    helperText={touched["cityId"] && errors["cityId"]}
                    onBlur={handleBlur}
                    value={values["cityId"]}
                    multiselect={false}
                  />
                </Grid>
                <Grid md={8}></Grid>
                <Grid item md={12}>
                  <Stack
                    spacing={2}
                    justifyContent={"flex-end"}
                    direction={"row"}
                  >
                    <MgpButton onClick={closed} variant="contained">
                      Cancel
                    </MgpButton>
                    <MgpButton type="submit" variant="contained">
                      {!hospital_id ? "Save" : "Update"}
                    </MgpButton>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Container>
    </>
  );
};
