import { Backdrop, Box, CircularProgress, Container, Stack } from "@mui/material";
import { MgpButton, MgpText } from "../../components/global";
import MgpTextarea from "../../components/global/MgpTextarea";
import { useState } from "react";
import { useEncounter } from "../../hooks";
import { useNavigate } from "react-router-dom";
import MgpAlertBar from "../../components/global/MgpAlertBar";

export const PatientReject = ({
  reject,
  setOpenReject,
  Update_Encounter,
  setEncountedDataLatestStatus,
  messageHeading,
  Delete,
  DeletePatient,
  patientid,
}) => {
  const navigate = useNavigate();
  const [alertOption, setAlertOption] = useState(false);
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [border, setBorder] = useState(null)
  const [loading, setLoading] = useState(false);
  return (
    <Container>
      <Stack spacing={2} justifyContent="center">
        <MgpText>{messageHeading}</MgpText>
        <Box>
          <MgpTextarea
            value={message}
            border={border}
            handleChange={(e) => {
              setMessage(e.target.value);
            }}
            minRows={4}
            placeholder={!Delete ? "Comment" : "Reason"}
          />
        </Box>
        <MgpButton
          variant="contained"
          onClick={() => {
            if (Delete) {
              if (message !== "") {
                setLoading(true);
                setBorder(null)
                DeletePatient({
                  variables: { patientId: Number(patientid), reason: message },
                  onCompleted: () => {
                    setSuccessMessage("Patient Deleted Success");
                    setAlertOption(true);
                    setMessage("");
                    setOpenReject(false);
                    setAlertOption(false);
                    setLoading(false);

                  },
                  onError: (error) => {
                    alert(error);
                    setLoading(false);

                  },
                });
              }

              else
                setBorder("red")
            } else {
              setLoading(true);
              Update_Encounter({
                variables: {
                  encounter_id: Number(reject),
                  status: 3,
                  remark: message,
                },
                onCompleted: () => {
                  setSuccessMessage("CRF Rejection Success");
                  setAlertOption(true);
                  setMessage("");
                  setEncountedDataLatestStatus(3);
                  setOpenReject(false);
                  setTimeout(() => {
                    setAlertOption(false);
                    setLoading(false);
                    navigate("/app/patients/patientlist");
                  }, 3000);
                },
                onError: (error) => {
                  alert(error);
                  setLoading(false);

                },
              });
            }
          }}
          disabled={loading}
        >
          {!Delete ? "Reject" : "Delete"}
        </MgpButton>
      </Stack>
      {loading && (
          <Backdrop open={loading} style={{ zIndex: 1 }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      <MgpAlertBar
        open={alertOption}
        setOpen={setAlertOption}
        alertType="success"
        message={successMessage}
      />
    </Container>
  );
};
