import { Container, Grid } from "@mui/material";
import { MgpButton, MgpDD, MgpTextbox, MgpTextboxFormik } from "../../../components/global";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useRegion, useMaster } from "../../../hooks";
import { Formik } from "formik";
import { MgpCustomSelect } from "../../../components/global/MgpDropdown";
export const UpdateRegion = ({ setAlertOption, setMessage, setAlertType, setMainPageloading, closed = () => { }, edit = {} }) => {
  const { region_id = "", region_name = "", master_region_id = "", region_code } = edit;

  const { useAddRegion, useUpdateRegion } = useRegion();
  const [AddRegion] = useAddRegion;
  const [UpdateRegion] = useUpdateRegion;
  const {
    roles,
    hospitals,
    countries,
    master_regions,
    genders,
    visit_type,
  } = useMaster();
  return (
    <>
      <Container fluid="true">
        <Formik
          initialValues={{ regionName: region_name, regionId: region_id, masterRegionId: master_region_id, regionCode: region_code }}
          validationSchema={yup.object().shape({
            regionName: yup
              .string()
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Region name is required"),
              regionCode: yup
              .string()
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Region code is required"),
            masterRegionId: yup.string().required("Greater Region is required"),
          })}
          onSubmit={(payload) => {
            setMainPageloading(true)
            if (region_id === "") {
              try {
                AddRegion({
                  variables: {
                    regionName: payload.regionName,
                    regionId: Number(payload.regionId),
                    masterRegionId: Number(payload.masterRegionId),
                    regionCode: payload.regionCode
                  },
                  onCompleted: () => {
                    closed();
                    setMainPageloading(false)
                    setAlertOption(true)
                    setAlertType("success")
                    setMessage("Region Added Successfully")
                  },
                  onError: (error) => {
                    if (error?.networkError?.result)
                      setMessage(error.networkError?.result?.errors[0]?.message)
                    else
                      setMessage("Something went wrong! Please try again")
                    setMainPageloading(false)
                    setAlertOption(true)
                    setAlertType("error")
                  }
                });
              } catch (error) {
                console.log("The error in API call is", error)
                setMainPageloading(false)
                setAlertOption(true)
                setAlertType("error")
                if (error?.message) {
                  const errorMessage = error.message;
                  setMessage(errorMessage);
                } else {
                  setMessage("An unexpected error occurred");
                }
              }

            } else {
              UpdateRegion({
                variables: {
                  regionName: payload.regionName,
                  regionId: Number(payload.regionId),
                  masterRegionId: Number(payload.masterRegionId),
                  regionCode: payload.regionCode
                },
                onCompleted: () => {
                  closed();
                  setMainPageloading(false)
                  setAlertOption(true)
                  setAlertType("success")
                  setMessage("Region Updated Successfully")
                },
                onError: (error) => {
                  if (error?.networkError?.result)
                    setMessage(error.networkError.result.errors[0]?.message)
                  else
                    setMessage("Something went wrong! Please try again")
                  setMainPageloading(false)
                  setAlertOption(true)
                  setAlertType("error")
                }
              });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={3}
                sx={{ minHeight: "10vh", position: "relative" }}
              >
                <Grid item md={6}>
                  <MgpTextbox
                    fullWidth
                    size="small"
                    label="Region Name"
                    name="regionName"
                    value={values.regionName}
                    handleChange={handleChange}
                    onChange={(e)=>{
                      setFieldValue("regionName",e.target.value)
                    }}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    error={Boolean(touched["regionName"] && errors["regionName"])}
                    helperText={touched["regionName"] && errors["regionName"]} 
                  />
                </Grid>
                <Grid item md={6}>
                  <MgpTextboxFormik
                    fullWidth
                    size="small"
                    label="Region Code"
                    name="regionCode"
                    splits={"regionCode".split(".")}
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    error={Boolean(touched["regionCode"] && errors["regionCode"])}
                    helperText={touched["regionCode"] && errors["regionCode"]} 
                  />
                </Grid>
                <Grid item md={6}>
                  <MgpDD
                    label="Greater Region Name"
                    variant="outlined"
                    size="small"
                    name="masterRegionId"
                    error={Boolean(touched["masterRegionId"] && errors["masterRegionId"])}
                    fullWidth
                    onChange={(e) => {
                      handleChange(e);
                      // setSelectedCountry(e.target.value);
                    }}
                    options={master_regions}
                    helperText={touched["masterRegionId"] && errors["masterRegionId"]}
                    onBlur={handleBlur}
                    value={values["masterRegionId"]}
                    multiselect={false}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  sx={{
                    textAlign: "right !important",
                    position: "absolute",
                    bottom: "0px",
                    right: "0px",
                    display: "flex",
                    gap: "15px",
                  }}
                >
                  <MgpButton onClick={closed} variant="contained">
                    Cancel
                  </MgpButton>
                  <MgpButton type="submit" variant="contained">
                    {!region_id ? "Save" : "Update"}
                  </MgpButton>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Container>
    </>
  );
};
