import { embedDashboard } from "@superset-ui/embedded-sdk";
import React, { useEffect, useState } from "react";
import { useReport } from "../hooks/useReport";
import { MgpButton, MgpDD } from "../components/global";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  Box,
  Backdrop,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Tab,
} from "@mui/material";
import { DashboardFilter } from "./DashboardFilter";
import { ENV, StorageHelper } from "../helpers";
import dashboard from "../helpers/dashboards.json";
import { TabContext, TabList } from "@mui/lab";

const requestLoginData = {
  username: "admin",
  password: "admin",
  provider: "db",
};

export const EmbedAnalyticalDashboard = () => {
  const { useGetGuestToken } = useReport();
  const [GetGuestToken, { data, loading, error }] = useGetGuestToken;
  const REFRESH_THRESHOLD_MS = 5000; // 60 * 2 * 1000; // 5 minutes before expiry
  const [isFilterOpen, setFilterOpen] = React.useState(false);
  const [section, setSection] = React.useState("all");
  const [mainPageloading, setMainPageloading] = useState(true);
  const [view, setView] = useState("graph");
  const [self, setSelf] = useState("1");
  let guestToken;
  let tokenExpirationTimeout;
  const [selectedOption, setSelectedOption] = useState("");
  async function refreshGuestToken(filters) {
    let filter = filters;
    if (!filters) {
      filter = selectedOption;
    }
    try {
      const token = await GetGuestTokenApi(filter);
      const expiresIn = 300000;
      guestToken = token;
      if (tokenExpirationTimeout) {
        clearTimeout(tokenExpirationTimeout);
      }
      tokenExpirationTimeout = setTimeout(refreshGuestToken, expiresIn * 0.9);
      return guestToken;
    } catch (error) {
      console.log("error", error);
      setTimeout(refreshGuestToken, 5000);
      return guestToken;
    }
  }

  const GetGuestTokenApi = (filters = {}) => {
    return new Promise((resolve, reject) => {
      GetGuestToken({
        variables: {
          dashboardType: "analytics",
          filters: filters ? filters : {},
          section: section,
          view: view,
          self: self === "1" ? true : false,
        },
        fetchPolicy: "network-only",
        onCompleted: (res) => {
          console.log("res", res);
          resolve(res.GetGuestToken.token);
        },
        onError: (err) => {
          reject(err);
        },
      });
    });
  };

  useEffect(() => {
    embeddingReport();
  }, [section, view, self]);

  const embeddingReport = (filters) => {
    refreshGuestToken(filters).then((token) => {
      embedDashboard({
        id: dashboard.find(
          (elem) =>
            elem.roleId ===
            parseInt(JSON.parse(StorageHelper.get("user"))?.role_id)
        )?.analyticsDashboardIds[view][section],
        supersetDomain: ENV.superset_host,
        mountPoint: document.getElementById("dashboard-container"), // any html element that can contain an iframe
        fetchGuestToken: () => token,
        dashboardUiConfig: {
          hideTitle: true,
          hideTab: true,
          hideChartControls: false,
          filters: {
            //gender: true,
            visible: false,
            expanded: false,
          },
        },
      });
      document.getElementById("dashboard-container").children[0].width = "100%";
      document.getElementById("dashboard-container").children[0].height =
        "100%";
      document.getElementById("dashboard-container").children[0].frameBorder =
        "0";
    });
  };

  const handleChange = (values) => {
    setSelectedOption(values);
    embeddingReport(values);
  };
  const handleTab = (event, newValue) => {
    setFilterOpen(false);
    setView(newValue);
  };
  //
  return (
    <>
      <TabContext value={view}>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", marginBottom: "15px" }}
        >
          <TabList onChange={handleTab} aria-label="" variant="fullWidth">
            <Tab label="Graph View" value="graph" sx={{ fontSize: "12px" }} />
            <Tab label="Table View" value="table" sx={{ fontSize: "12px" }} />
          </TabList>
        </Box>
      </TabContext>
      <Grid container spacing={3} direction="row" paddingBottom={2}>
        <Grid
          item
          xs={6}
          md={
            parseInt(JSON.parse(StorageHelper.get("user"))?.role_id) === 4
              ? 6
              : 9
          }
        >
          <MgpButton
            variant="contained"
            onClick={() => {
              setFilterOpen(!isFilterOpen);
            }}
          >
            <FilterAltOutlinedIcon></FilterAltOutlinedIcon> Filter By
          </MgpButton>
        </Grid>
        {parseInt(JSON.parse(StorageHelper.get("user"))?.role_id) === 4 ? (
          <Grid item xs={6} md={3}>
            <MgpDD
              label="Select Patients"
              variant="outlined"
              size="small"
              name="section"
              multiselect={false}
              fullWidth
              value={self}
              options={[
                {
                  label: "My Patients",
                  value: "1",
                },
                {
                  label: "Hospital Patients",
                  value: "2",
                },
              ]}
              onChange={(e) => {
                setSelectedOption("")
                setSelf(e.target.value);
              }}
              // onBlur={handleBlur}
              // error={Boolean(touched.section && errors.section)}
              // helperText={touched.section && errors.section}
            />
          </Grid>
        ) : null}
        <Grid item xs={6} md={3}>
          <MgpDD
            label="Select Dashboard"
            variant="outlined"
            size="small"
            name="section"
            multiselect={false}
            fullWidth
            value={section}
            options={[
              {
                label: "All",
                value: "all",
              },
              {
                label: "Demographics",
                value: "demographics",
              },
              {
                label: "Diagnosis",
                value: "diagnosis",
              },
              {
                label: "Medical Tests",
                value: "medicalTest",
              },
              {
                label: "Treatment/Intervention",
                value: "treatment/intervention",
              },
              {
                label: "Pregnancy History",
                value: "PregnancyHistory",
              },
              {
                label: "Clinical Assessment",
                value: "ClinicalAssessment",
              },
              {
                label: "Hospitalization",
                value: "hospitalization",
              },
            ]}
            onChange={(e) => {
              setSection(e.target.value);
            }}
            // onBlur={handleBlur}
            // error={Boolean(touched.section && errors.section)}
            // helperText={touched.section && errors.section}
          />
        </Grid>
      </Grid>

      {isFilterOpen && (
        <Box paddingBottom={2}>
          <DashboardFilter
            handleChange={handleChange}
            setMainPageloading={setMainPageloading}
            isAnalytics={true}
            self={self}
          ></DashboardFilter>
        </Box>
      )}
      <div id="dashboard-container" style={{ width: "100%", height: "100%" }} />
    </>
  );
};
