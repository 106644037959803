import * as React from "react";
import { colors } from "../../theme/colors";
import { Link } from "react-router-dom";
import { MgpMenu } from "./MgpMenu";
import { MgpText } from "./MgpText";
import { Box } from "@mui/material";

export const NavSection = ({ sections,currentPath }) => {
  const { page, type,link,option, startIcon } = sections;
  const isActive = link === currentPath;
  const hasActiveSubOption = option && option.some(subOption => subOption.link === currentPath);
  switch (type) {
    case "text":
      return (
        <MgpText
          component={Link}
          to={sections.link}
          sx={{
            color: colors.SECONDARY_TEXT,
            fontWeight: isActive || hasActiveSubOption ? "bold" : "500",
            textDecoration: "none !important",
            display: "flex",
            alignItems: "center",
             borderBottom: isActive || hasActiveSubOption ? `2px solid ${colors.PRIMARY}` : 'none'
          }}
          target={sections.module === "consentForm" ? "_blank" : ""}
        >
          <Box sx={{ marginRight: "6px", marginTop: "5px" }}>{startIcon}</Box>
          <span>{page}</span>
        </MgpText>
      );
    case "menu":
      return (
        <div>
          <MgpMenu
            startIcon={
              <>
                {startIcon}
              </>
            }
            options={sections.option}
            id={page}
            variant="text"
            sx={{ fontSize: "20px !important", fontWeight: isActive || hasActiveSubOption ? "bold" : "500"  }}
            isActive={isActive || hasActiveSubOption}
            currentPath={currentPath}
          />
        </div>
      );
    default:
      return <></>;
  }
};
