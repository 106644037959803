import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MailIcon from "@mui/icons-material/Mail";
import PersonIcon from "@mui/icons-material/AccountCircle";
import Badge from "@mui/material/Badge";
import { colors } from "../../theme/colors";
import { NavSection } from "./NavSection";
import { settings, pages } from "../../helpers/navbarData";
import { MgpAppbar } from "./MgpAppbar";
import { MgpText } from "./MgpText";
import { MgpMenu } from "./MgpMenu";
import { useEffect } from "react";
import { StorageHelper } from "../../helpers/storageHelper.js";

import { useNotification, useRbac } from "../../hooks";
import { Stack } from "@mui/system";
import { Padding } from "./Padding";
import MgpNotification from "./MgpNotification";
import { ENV } from "../../helpers";
import { useLocation } from "react-router-dom";

function Navbar() {
  const [navsections, setNavsections] = React.useState([]);
  const [userDetails, setUserDetails] = React.useState({});
  const { useGetRbac, getActionRbac } = useRbac();
  const [GetRbac, { data, loading, error }] = useGetRbac;
  const { rbac: rbacdata } = useRbac();
  const { useNotify, useGetNotifications } = useNotification();
  const [GetNotifications] = useGetNotifications;
  const { data: notify, loading: notiLoading } = useNotify;
  const [notification, setNotification] = React.useState([]);
  const [loaders, setLoaders] = React.useState(false);
  const subinvestigator_id = parseInt(StorageHelper.get("subinvestigator_id"));

  const location = useLocation();
  const currentPath = location.pathname;

  let options = settings;
  if (subinvestigator_id) {
    options = [{ section: "Logout", link: "/login" }];
  }
  // React.useEffect({

  //   if(subinvestigator_id){
  //     options=[
  //       { section: "Logout", link: "/login" },
  //     ]
  //   }

  // },[])

  React.useEffect(() => {
    GetNotifications({
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        setNotification([
          ...data?.GetNotifications.filter((elem) => !elem.read),
        ]);
      },
    });
    // GetRbac({
    //   onCompleted: ({ GetRbac: rbacdata }) => {
    //     console.log("rbacdata", rbacdata);
    //     setRbac(rbacdata);

    //     const moduleAccessRights = rbacdata.find(
    //       (rbac) => rbac.code === "modules"
    //     );

    //     const finalPages = pages
    //       .map((menuItem) => {
    //         if (
    //           menuItem.type === "text" &&
    //           moduleAccessRights.access.some(
    //             (accessMenu) =>
    //               accessMenu.code.toLowerCase() ===
    //                 menuItem.code.toLowerCase() && accessMenu.status
    //           )
    //         ) {
    //           return menuItem;
    //         } else if (menuItem.type === "menu") {
    //           const submenuOption = menuItem.option.filter((subMenuItem) =>
    //             moduleAccessRights.access.some(
    //               (accessMenu) =>
    //                 accessMenu.code.toLowerCase() ===
    //                   subMenuItem.code.toLowerCase() && accessMenu.status
    //             )
    //           );
    //           return submenuOption.length > 0
    //             ? { ...menuItem, option: submenuOption }
    //             : null;
    //         }
    //         return null;
    //       })
    //       .filter(Boolean);
    //     setNavsections([...finalPages]);
    //   },
    //   onError: () => {
    //     setRbac(false);
    //   },
    // });

    const filterMenuItems = (menuItems) => {
      return menuItems
        .map((menuItem) => {
          console.log("menuItem", menuItem);

          // Check if it's a text item and has RBAC access
          if (
            menuItem.type === "text" &&
            getActionRbac(menuItem.module, menuItem.code)
          ) {
            return menuItem;
          }

          // Check if it's a menu item
          if (menuItem.type === "menu") {
            const submenuOption = menuItem.option.filter((subMenuItem) => {
              if (subMenuItem?.option) {
                return subMenuItem.option.filter((subMenuItems) => {
                  return getActionRbac(subMenuItems.module, subMenuItems.code);
                });
              } else {
                return getActionRbac(subMenuItem.module, subMenuItem.code);
              }
            });
            console.log("submenuOption", submenuOption);
            return submenuOption.length > 0
              ? { ...menuItem, option: submenuOption }
              : null;

            // const submenuOption = Array.isArray(menuItem.option)
            //   ? filterMenuItems(menuItem.option) // Recursively filter the options
            //   : menuItem.option.filter((subMenuItem) => {
            //       return getActionRbac(subMenuItem.module, subMenuItem.code);
            //     });

            // // Only return the menu item if it has any accessible sub-options
            // if (submenuOption.length > 0) {
            //   return { ...menuItem, option: submenuOption };
            // }

            // // Check if the menu itself has RBAC access
            // if (getActionRbac(menuItem.module, menuItem.code)) {
            //   return { ...menuItem, option: [] }; // Return the menu with an empty option array if it has access but no visible sub-options
            // }
          }

          return null; // Exclude items that don't meet criteria
        })
        .filter(Boolean); // Remove null values from the result
    };

    // Use this function to filter pages
    const finalPages = filterMenuItems(pages);
    console.log("finalPages", finalPages);

    setNavsections([...finalPages]);
    let user = JSON.parse(StorageHelper.get("userDetails"));
    setUserDetails({ ...user });
  }, [loaders]);

  React.useEffect(() => {
    if (
      !notiLoading &&
      JSON.parse(StorageHelper.get("user"))?.email ===
        notify?.PushNotification?.id
    ) {
      console.log("🚀 ~ React.useEffect ~ notify:", notify);
      setNotification([...notification, notify?.PushNotification]);
    }
  }, [notify]);

  const WelcomeTitle = () => {
    if (JSON.parse(StorageHelper.get("subinvestigator_id"))) {
      return (
        <Box>
          <MgpText variant="body2">
            Welcome Dr.{" "}
            {JSON.parse(StorageHelper.get("sub_investigator"))?.first_name}
          </MgpText>
          <MgpText variant="body2">Sub Investigator</MgpText>
          <MgpText
            variant="body2"
            fontSize={10}
            color={colors.NOTIFICATION_BELL}
            mt={0.5}
            sx={{ textTransform: "none" }}
          >
            Logged in as a sub investigator for
          </MgpText>
          <MgpText
            variant="body2"
            fontSize={10}
            color={colors.NOTIFICATION_BELL}
          >
            Dr. {JSON.parse(StorageHelper.get("user"))?.first_name} (Principal
            Investigator)
          </MgpText>
        </Box>
      );
    } else {
      return (
        <Box>
          <MgpText variant="body2">
            Welcome{" "}
            {(JSON.parse(StorageHelper.get("user"))?.role_id ===
              parseInt(ENV.doctor_role_id) ||
              JSON.parse(StorageHelper.get("user"))?.role_id ===
                parseInt(ENV.medical_lead_role_id)) &&
              "Dr. "}
            {JSON.parse(StorageHelper.get("user"))?.first_name}
          </MgpText>
          <MgpText variant="body2">
            {JSON.parse(StorageHelper.get("user"))?.role_id ===
            parseInt(ENV.doctor_role_id)
              ? "Neurologist"
              : JSON.parse(StorageHelper.get("user"))?.role_id ===
                parseInt(ENV.quality_user_role_id)
              ? "Monitor"
              : JSON.parse(StorageHelper.get("user"))?.role_name}
          </MgpText>
        </Box>
      );
    }
  };
  return (
    <>
      <MgpAppbar
        position="sticky"
        section={"Navbar"}
        children={
          <>
            <img
              src={process.env.PUBLIC_URL + "/logo.png"}
              alt="logo"
              width={"210px"}
              height={"80px"}
            />
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <MgpText
                sx={{ fontWeight: 600, marginLeft: "20px" }}
                onClick={() => {}}
              >
                Myasthenia Gravis Patient Registry
              </MgpText>
            </Box>
            {/* <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
            >
              <Badge variant="dot" color="secondary">
                <MailIcon sx={{ color: colors.NAVBAR }} />
              </Badge>
            </IconButton> */}
            <MgpNotification
              loaders={loaders}
              setLoaders={setLoaders}
              badgeContent={notification?.length}
              notification={notification}
              setNotification={setNotification}
            />
            {/* <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            ></IconButton> */}
            <div>
              <MgpMenu
                options={options}
                // variant={"contained"}
                id={WelcomeTitle()}
                // id={`Welcome ${
                //   JSON.parse(StorageHelper.get("user"))?.first_name
                // } `}
                startIcon={
                  <>
                    <PersonIcon sx={{ height: "20px" }} />
                  </>
                }
              >
                {" "}
              </MgpMenu>
            </div>
          </>
        }
      />
      <Padding size="10px 24px">
        <Stack spacing={3} direction={"row"} alignItems={"center"}>
          {navsections.map((e, idx) => (
            <NavSection key={idx} sections={e} currentPath={currentPath} />
          ))}
        </Stack>
      </Padding>
    </>
  );
}
export default Navbar;
