import { Container, Stack } from "@mui/material";
import { MgpButton, MgpText } from "../../../components/global";
import MgpTextarea from "../../../components/global/MgpTextarea";
import { useMedicationRequest } from "../../../hooks/useMedicationRequest";
import { useState } from "react";

export const NotDispensedForm=({reject,setOpenReject})=>{
    const { useUpdatePatientsMedicationRequestStatus } = useMedicationRequest();
  const [UpdatePatientsMedicationRequestStatus] = useUpdatePatientsMedicationRequestStatus;
  const [message,setMessage]=useState("")
    return (
        <Container>
        <Stack spacing={2} justifyContent="center">
        <MgpText>Please give reason for not dispensed!</MgpText>
       <MgpTextarea value={message} handleChange={(e)=>{setMessage(e.target.value)}} minRows={4} placeholder={"Write reason here"}/>
       <MgpButton onClick={()=>{
        UpdatePatientsMedicationRequestStatus({
          variables: {
            requestId: Number(reject),
            status: 5,
            comment: message,
          },
          onCompleted: () => {
            setMessage('')
            setOpenReject(false);
          },
        });
       }}>Save</MgpButton>
       </Stack>
       </Container>
    )
}