import {
    MgpText,
} from "./../components/global";
import { useEffect, useState } from "react";
import { useActivityLog } from "./../hooks";
import Loader from "./Loader/Loader";
import moment from "moment";
import { JsonToTable } from "react-json-to-table";
import { useParams } from "react-router-dom";


export const ActivityLogDetails = () => {
    const [mainPageloading, setMainPageloading] = useState(true);
    const { useGetActivityLog } = useActivityLog();
    const [payload, setPayload] = useState({});
    // const { RbacWrapper } = useRbac();
    const [GetActivityLog] = useGetActivityLog;
    const params = useParams();
    useEffect(() => {
        handleViewDetails(parseInt(params.activity_log_id))
    }, [])

    const handleViewDetails = (activity_log_id) => {
        setMainPageloading(true);
        GetActivityLog({
            variables: { activityLogId: activity_log_id },
            fetchPolicy: "cache-and-network",
            onCompleted: (data) => {
                setPayload(data?.GetActivityLog?.payload);
                setMainPageloading(false);
                //setIsLoading(false);
            },
        });
    };

    const updateValues = (value) => {
        if (Array.isArray(value)) {
            return value.map(updateValues);
        } else if (typeof value === "object" && value !== null) {
            const updatedObject = {};
            for (const key in value) {
                if (
                    key === "created_on" ||
                    key === "updated_on" ||
                    key === "encounter_startdate" ||
                    key === "encounter_enddate"
                ) {
                    updatedObject[key] = value[key]
                        ? moment(value[key]).format("Do MMM YYYY, h:mm a")
                        : "";
                } else if (value.hasOwnProperty(key)) {
                    updatedObject[key] = updateValues(value[key]);
                }
            }
            return updatedObject;
        }
        return value;
    };
    if (mainPageloading) {
        return <Loader open={mainPageloading} />;
    }

    return (
        <>
            <MgpText variant="h5" sx={{paddingBottom:"5px"}}>View Details</MgpText>
            <JsonToTable json={updateValues(payload)} />

        </>
    );
};
