import { createTheme } from "@mui/material/styles";
import { colors } from "./colors";
import shadows from "./shadows";
import typography from "./typography";

const theme = createTheme({
  palette: {
    background: {
      default: colors.BG,
    },
    primary: {
      contrastText: colors.PRIMARY_CONTRAST,
      main: colors.PRIMARY,
      light: colors.PRIMARY_LIGHT,
      dark: colors.PRIMARY_DARK,
    },
    secondary: {
      contrastText: colors.SECONDARY_CONTRAST,
      main: colors.SECONDARY,
      light: colors.SECONDARY_LIGHT,
      dark: colors.SECONDARY_DARK,
    },
    text: {
      primary: colors.PRIMARY_TEXT,
      secondary: colors.SECONDARY_TEXT,
    },
  },
  shadows,
  typography,
});

export default theme;
