import { Container, Grid } from "@mui/material";
import { MgpButton, MgpDD, MgpTextboxFormik } from "../../../components/global";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { useMasterRegion, useMaster } from "../../../hooks";
import { Formik } from "formik";
import { MgpCustomSelect } from "../../../components/global/MgpDropdown";
export const UpdateSuperRegion = ({ setMainPageloading,setAlertOption,setMessage,closed = () => {}, edit = {} }) => {
  const { master_region_id = "", master_region_name = "" } = edit;

  const { useAddMasterRegion, useUpdateMasterRegion } = useMasterRegion();
  const [AddMasterRegion] = useAddMasterRegion;
  const [UpdateMasterRegion] = useUpdateMasterRegion;
  const {
    roles,
    hospitals,
    countries,
    genders,
    visit_type,
  } = useMaster();
  return (
    <>
      <Container fluid="true">
        <Formik
          initialValues={{ masterRegionName: master_region_name, masterRegionId: master_region_id }}
          validationSchema={yup.object().shape({
            masterRegionName: yup
              .string()
              .min(2, "Too Short!")
              .max(50, "Too Long!")
              .required("Required")
          })}
          onSubmit={(payload) => {
            setMainPageloading(true)
            if (master_region_id === "") {
              AddMasterRegion({
                variables: {
                    masterRegionName: payload.masterRegionName,
                    masterRegionId: Number(payload.masterRegionId),
                },
                onCompleted: () => {
                  closed();
                  setMainPageloading(false)
                  setAlertOption(true)
                  setMessage("Greater Region Added Successfully")
                },
              });
            } else {
              UpdateMasterRegion({
                variables: {
                    masterRegionName: payload.masterRegionName,
                    masterRegionId: Number(payload.masterRegionId),
                },
                onCompleted: () => {
                    console.log("55564")
                  closed();
                  setMainPageloading(false)
                  setAlertOption(true)
                  setMessage("Greater Region Updated Successfully")
                },
              });
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched,
            values,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={3}
                sx={{ minHeight: "50vh", position: "relative" }}
              >
                <Grid item md={6}>
                  <MgpTextboxFormik
                    fullWidth
                    size="small"
                    label="Greater Region Name"
                    name="masterRegionName"
                    splits={"masterRegionName".split(".")}
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    variant="outlined"
                    touched={touched}
                    errors={errors}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  sx={{
                    textAlign: "right !important",
                    position: "absolute",
                    bottom: "0px",
                    right: "0px",
                    display: "flex",
                    gap: "15px",
                  }}
                >
                  <MgpButton onClick={closed} variant="contained">
                    Cancel
                  </MgpButton>
                  <MgpButton type="submit" variant="contained">
                    {!master_region_id ? "Save" : "Update"}
                  </MgpButton>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Container>
    </>
  );
};
