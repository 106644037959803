import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { ProcessGrapqlError, apollo_client } from "../helpers";

export const useCountry = () => {
  const ADD_COUNTRY = gql`
  mutation Mutation($countryName: String!, $countryCode: String!, $isSaudi: Boolean,$isGcc: Boolean) {
    AddCountry(country_name: $countryName, country_code: $countryCode, is_saudi: $isSaudi, is_gcc: $isGcc) {
      country_id
      country_name
      country_code
      is_saudi
      is_gcc
      is_active
      created_on
      updated_on
    }
  }
  `;

  const GET_COUNTRIES = gql`
  query GetCountries($isGcc: Boolean) {
    GetCountries(is_gcc: $isGcc) {
      country_id
      country_name
      country_code
      is_saudi
      is_gcc
      is_active
      created_on
      updated_on
    }
  }
  `;

  const DELETE_COUNTRY = gql`
    mutation Mutation($countryId: Int!) {
      DeleteCountry(country_id: $countryId) {
        country_id
        country_name
        country_code
        is_active
        created_on
        updated_on
      }
    }
  `;

  const UPDATE_COUNTRY = gql`
  mutation UpdateCountry($countryId: Int!, $countryName: String!, $countryCode: String!, $isSaudi: Boolean, $isGcc:Boolean) {
    UpdateCountry(country_id: $countryId, country_name: $countryName, country_code: $countryCode, is_saudi: $isSaudi, is_gcc: $isGcc) {
      country_id
      country_name
      country_code
      is_saudi
      is_gcc
      is_active
      created_on
      updated_on
    }
  }
  `;
  const useAddCountry = useMutation(ADD_COUNTRY, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { AddCountry } }) {
      try {
        const existingData = cache.readQuery({ query: GET_COUNTRIES });
        if (existingData) {
          const newData = [AddCountry, ...existingData.GetCountries];
          cache.writeQuery({
            query: GET_COUNTRIES,
            data: { GetCountries: newData },
          });
        } else {
          // Refetch data if it's not available in the cache
          apollo_client
            .query({
              query: GET_COUNTRIES,
            })
            .then(({ data }) => {
              if (data && data.GetCountries) {
                const updatedData = [AddCountry, ...data.GetCountries];
                cache.writeQuery({
                  query: GET_COUNTRIES,
                  data: { GetCountries: updatedData },
                });
              }
            })
            .catch((error) => {
              console.error("Error while refetching data:", error);
            });
        }
      } catch (error) {
        console.error(
          "An error occurred while updating the cache after updating a country:"
        );
      }
    },
  });

  const useUpdateCountry = useMutation(UPDATE_COUNTRY, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { UpdateCountry } }) {
      try {
        const existingData = cache.readQuery({ query: GET_COUNTRIES });
        if (existingData) {
          const newData = existingData.GetCountries.map((item) =>
            item.country_id !== UpdateCountry.country_id ? item : UpdateCountry
          );
          cache.writeQuery({
            query: GET_COUNTRIES,
            data: { GetCountries: newData },
          });
        } else {
          // Refetch data if it's not available in the cache
          apollo_client
            .query({
              query: GET_COUNTRIES,
            })
            .then(({ data }) => {
              if (data && data.GetCountries) {
                const newData = data.GetCountries.map((item) =>
                  item.country_id !== UpdateCountry.country_id
                    ? item
                    : UpdateCountry
                );
                cache.writeQuery({
                  query: GET_COUNTRIES,
                  data: { GetCountries: newData },
                });
              }
            })
            .catch((error) => {
              console.error("Error while refetching data:", error);
            });
        }
      } catch (error) {
        console.error(
          "An error occurred while updating the cache after updating a country:"
        );
      }
    },
  });

  const useDeleteCountry = useMutation(DELETE_COUNTRY, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { DeleteCountry } }) {
      const existing = cache.readQuery({
        query: GET_COUNTRIES,
        fetchPolicy: "cache-and-network",
      });
      console.log("newCountries", existing);
      if (existing) {
        const newCountries = existing.GetCountries.filter(
          (item) => item.country_id !== DeleteCountry.country_id
        );
        cache.writeQuery({
          query: GET_COUNTRIES,
          data: { GetCountries: newCountries },
        });
      } else {
        // Refetch data if it's not available in the cache
        apollo_client
          .query({
            query: GET_COUNTRIES,
          })
          .then(({ data }) => {
            if (data && data.GetCountries) {
              const newData = data.GetCountries.map((item) =>
                item.country_id !== DeleteCountry.country_id
                  ? item
                  : DeleteCountry
              );
              cache.writeQuery({
                query: GET_COUNTRIES,
                data: { GetCountries: newData },
              });
            }
          })
          .catch((error) => {
            console.error("Error while refetching data:", error);
          });
      }

    },
  });
  const useGetCountries = useLazyQuery(GET_COUNTRIES);

  return { useAddCountry, useGetCountries, useUpdateCountry, useDeleteCountry };
};
