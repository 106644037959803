import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function MgpAlertBar({ open, setOpen, alertType, message }) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setOpen()
    // setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={setOpen}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={open}
        onClose={setOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert
          onClose={setOpen}
          severity={alertType}
          variant="filled"
          action={action}
          sx={{ width: "100%" }}
          key={"topcenter"}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
