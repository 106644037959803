import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import BarChartIcon from "@mui/icons-material/BarChart";
import AssessmentIcon from "@mui/icons-material/Assessment";
import InsightsIcon from "@mui/icons-material/Insights";
import { StorageHelper } from "./storageHelper";
export const settings = [
  { section: "UpdatePassword", link: "/" },
  { section: "UpdateProfile", link: "/" },
  // { section: "Account", link: "/" },
  // { section: "Dashboard", link: "/" },
  { section: "Logout", link: "/login" },
];

// export const pages = [
//   { page: "Home", link: "/app/patients", type: "text" },
//   {
//     page: "Patients",
//     type: "menu",
//     option: [{ section: "patient", link: "/app/patients" }],
//   },
//   {
//     page: "Analytics Dashboard",
//     type: "menu",
//     option: [{ section: "Analytics", link: "/analytics" }],
//   },
//   {
//     page: "Reports",
//     type: "menu",
//     option: [{ section: "Reports", link: "/reports" }],
//   },
//   { page: "Informed Consent", link: "/", type: "text" },
//   { page: "Code Book", link: "/", type: "text" },
//   { page: "User Manual", link: "/", type: "text" },
// ];

export const pages = [
  {
    page: "Home",
    link: "/app/patients",
    type: "text",
    module: "dashboard",
    code: "doctors_dashboard",
    startIcon: <AssessmentIcon />,
  },
  {
    page: "Patients",
    type: "menu",
    startIcon: <PersonOutlineIcon />,

    option: [
      {
        section: "Patient List",
        link: "/app/patients/patientlist",
        module: "patients",
        code: "view_patient",
      },
      // {
      //   section: "Analytics Dashboard",
      //   link: "/app/analytics",
      //   module: "analytics",
      //   code: "analytics_dashboard",
      // },
      {
        section: "Transferred Patient List",
        link: "/app/patients/transfer",
        module: "patients",
        code: "patient_transfer",
      },
      {
        section: "Deleted Patient List",
        link: "/app/patients/deletedPatients",
        module: "patients",
        code: "view_deleted_patient",
      },
    ],
  },
  {
    page: "Informed Consent Form",
    link: "http://65.2.31.102:9000/mgp/Saudi National Myasthenia Gravis Registry Informed Consent Form- PDF.pdf",
    type: "text",
    module: "consentForm",
    code: "download",
    startIcon: <LibraryBooksIcon sx={{ fontSize: "20px" }} />,
  },
  // {
  //   page: "Medication",
  //   type: "menu",
  //   option: [
  //     {
  //       section: "Medication Requests",
  //       link: "/app/medication",
  //       module: "medication",
  //       code: "medication_requests",
  //     },
  //   ],
  // },
  {
    page: "User Management",
    type: "menu",
    startIcon: <GroupOutlinedIcon />,
    option: [
      {
        section: "User",
        link: "/app/users",
        module: "users",
        code: "view_all",
      },
      {
        section: "Roles",
        link: "/app/roles",
        module: "roles",
        code: "view_All",
      },
    ],
  },
  {
    page: "Settings",
    type: "menu",
    startIcon: <SettingsOutlinedIcon />,
    option: [
      {
        section: "Countries",
        link: "/app/country",
        module: "country",
        code: "view_country",
      },
      {
        section: "Regions",
        link: "/app/region",
        module: "region",
        code: "view_region",
      },
      {
        section: "Cities",
        link: "/app/city",
        module: "city",
        code: "view_city",
      },
      {
        section: "Hospitals",
        link: "/app/hospital",
        module: "hospital",
        code: "view_hospitals",
      },

      // {
      //   section: "Greater Region",
      //   link: "/app/greaterRegion",
      //   module: "greaterRegion",
      //   code: "view_greater_region",
      // },
    ],
  },
  {
    page: "Analytics Dashboard",
    type: "text",
    module: "analytics",
    code: "analytics_dashboard",
    link: "/app/analytics",
    startIcon: <AssessmentIcon />,
    // option: [
    //   {
    //     section: "Analytics Dashboard",
    //     link: "/app/analytics",
    //     module: "analytics",
    //     code: "analytics_dashboard",
    //   },
    // ],
  },
  {
    page: "Extract Data",
    type: "menu",
    module: "codebook",
    code: "codebook",
    startIcon: <LibraryBooksIcon />,
    option: [
      {
        section: "Code Book",
        link: "/app/codebook",
        module: "codebook",
        code: "code_book",
      },
      {
        section: "Non-Coded Data",
        type: "menu",
        option: [
          {
            section: "Non-Coded Mandatory Data",
            link: "/app/codebook/detailed",
            module: "codebook",
            code: "detailed_report",
          },
          {
            section: "Non-Coded Full Data Set",
            link: "/app/codebook/summary",
            module: "codebook",
            code: "summary_report",
          },
        ],
      },
      {
        section: "Coded Data",
        type: "menu",
        option: [
          {
            section: "Coded Mandatory Data",
            link: "/app/codebook/detailed",
            module: "codebook",
            code: "detailed_report",
          },
          {
            section: "Coded Full Data Set",
            link: "/app/codebook/summary",
            module: "codebook",
            code: "summary_report",
          },
        ],
      },
    ],
  },

  {
    page: "Reports",
    type: "menu",
    startIcon: <SettingsOutlinedIcon />,
    option: [{ section: "Reports", link: "/", code: "reports" }],
  },
  { page: "Code Book", link: "/", type: "text", code: "codeBlock" },
  { page: "User Manual", link: "/", type: "text", code: "userManual" },
  {
    page: "Patients Medication Requests",
    link: "/app/patientsMedicationRequests",
    type: "text",
    code: "patients_medication_requests",
  },
  {
    page: "Activity Logs",
    type: "text",
    module: "activitylogs",
    code: "activity_logs",
    link: "/app/activitylogs",
    startIcon: <InsightsIcon />,
  },
];
