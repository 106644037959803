import { Box, IconButton, Stack, CircularProgress } from "@mui/material";
import {
  AlertDialog,
  MgpButton,
  MgpModal,
  MgpTable,
  MgpTableStatus,
  MgpText,
  Padding,
  TableConfig,
} from "../../../components/global";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useMedicationRequest, useRbac } from "../../../hooks";
import {
  MRT_GlobalFilterTextField,
  useMaterialReactTable,
} from "material-react-table";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { DispensedForm } from "./DispensedForm";
import { NotDispensedForm } from "./NotDispensedForm";
import MedicationRequest from "../../MedicationRequest";
import Loader from "../../Loader/Loader";
import { mkConfig, generateCsv, download } from "export-to-csv";

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

export const MedicationRequests = ({ from, id }) => {
  const [reject, setOpenReject] = useState(false);
  const [accept, setOpenAccept] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [mainPageloading, setMainPageloading] = useState(true);
  const [medicationCurrentSelection, setMedicationCurrentSelection] = useState(
    []
  );
  //const [dispense_ref, setDispense] = useState(false);
  const {
    useGetPatientsMedicationRequests,
    useUpdatePatientsMedicationRequestStatus,
    useGetPatientsMedicationRequestsByPatientId,
  } = useMedicationRequest();
  const [UpdatePatientsMedicationRequestStatus] =
    useUpdatePatientsMedicationRequestStatus;
  const { RbacWrapper, rbac } = useRbac();
  const [GetPatientsMedicationRequests, { data, loading, error }] =
    useGetPatientsMedicationRequests;
  useEffect(() => {
    GetPatientsMedicationRequests();
  }, [GetPatientsMedicationRequests]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "registry_no", //access nested data with dot notation
        header: "Registry No.",
      },
      {
        accessorKey: "national_id",
        header: "National ID",
      },
      {
        accessorKey: "patient_name", //normal accessorKey
        header: "Patient Name",
      },
      {
        accessorKey: "doctor_name", //normal accessorKey
        header: "Doctor",
      },
      {
        accessorKey: "hospital_name", //normal accessorKey
        header: "Hospital",
      },
      {
        accessorKey: "details", //normal accessorKey
        header: "Medication",
        Cell: ({ row }) => <CustomComponent details={row.original.details} />,
      },
      {
        accessorKey: "created_on", //normal accessorKey
        header: "Created On",
      },
      {
        accessorKey: "dispensed_date", //normal accessorKey
        header: "Dispensed Date",
      },
      {
        accessorKey: "status_enum", //normal accessorKey
        header: "Status",
        Cell: ({ row }) => {
          return (
            <MgpTableStatus
              value={row.original?.status}
              label={row.original?.status_enum}
            />
          );
        },
      },
      {
        accessorKey: "dispensed_comment", //normal accessorKey
        header: "Comment",
      },
    ],
    []
  );

  let filteredcolumns = columns.filter((row) =>
    rbac?.tables?.["medication_table"]?.includes(row.accessorKey)
  );
  const [
    GetPatientsMedicationRequestsByPatientId,

    {
      data: PatientsMedicationRequestsByPatientIdData,
      loading: PatientsMedicationRequestsByPatientIdLoading,
      error: PatientsMedicationRequestsByPatientIdError,
    },
  ] = useGetPatientsMedicationRequestsByPatientId;
  useEffect(() => {
    GetPatientsMedicationRequestsByPatientId({
      variables: { patientId: parseInt(id) },
    });
  }, [GetPatientsMedicationRequestsByPatientId]);

  const handleOpen = () => {
    setOpen(true);
  };
  const CustomComponent = ({ details }) => {
    return details.map((item) => {
      return (
        <p>
          {item.medicine} - {item.dosage} - {item.regimen}
        </p>
      );
    });
  };
  console.log(
    "PatientsMedicationRequestsByPatientIdData",
    PatientsMedicationRequestsByPatientIdData
  );
  const table = useMaterialReactTable({
    columns: filteredcolumns,
    data:
      PatientsMedicationRequestsByPatientIdData?.GetPatientsMedicationRequestsByPatientId ??
      [],
    ...TableConfig,
    enableRowActions: true,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },

    renderTopToolbar: ({ table }) => (
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: "0.5rem",
          p: "16px 0px",
          justifyContent: "space-between",
        })}
      >
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <MRT_GlobalFilterTextField table={table} />
        </Box>
        <Box>
          <RbacWrapper module={"medication"} code={"export"}>
            <MgpButton
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
            >
              Export
            </MgpButton>
          </RbacWrapper>
        </Box>
      </Box>
    ),
    renderRowActions: ({ row }) => {
      return (
        <>
          {/* <RbacWrapper module={"medication"} code={"edit"}> */}

          <RbacWrapper module={"medication"} code={"dispense_medicine"}>
            {from != "VIEW_PATIENT" && (
              <IconButton
                size="small"
                onClick={() => {
                  handleAccept(row.original);
                }}
              >
                <LibraryAddCheckIcon />
              </IconButton>
            )}
          </RbacWrapper>
          {/* <RbacWrapper module={"medication"} code={"delete"}> */}
          {/* </IconButton> */}
          {/* // </RbacWrapper> */}
          <RbacWrapper module={"medication"} code={"view_medication_details"}>
            <Tooltip title={"View Medication Request Details"} arrow>
              <IconButton
                size="small"
                onClick={() => {
                  setMedicationCurrentSelection(row.original.details);

                  handleOpen();
                }}
              >
                <VisibilityOutlinedIcon />
              </IconButton>
            </Tooltip>
          </RbacWrapper>

          {/* <RbacWrapper module={"medication"} code={"not_dispense_medicine"}>
            <IconButton
              size="small"
              onClick={() => {
                from != "VIEW_PATIENT" && handleCancel(row.original);
                from == "VIEW_PATIENT" && handleOpen();
              }}
            >
              {from != "VIEW_PATIENT" && <CancelPresentationIcon />}
              {from == "VIEW_PATIENT" && <VisibilityOutlinedIcon />}
            </IconButton>
          </RbacWrapper> */}
        </>
      );
    },
    state: { isLoading ,columnOrder:filteredcolumns.map((e)=>e.accessorKey)},
    renderTableBody: () => {
      return isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null;
    },
  });
  useEffect(() => {
    if (data || error) {
      setMainPageloading(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [data, error]);
  const handleExportData = () => {
    const filteredData = table
      .getFilteredRowModel()
      .rows.map((row) => row.original);
    let dataToExport = filteredData ? filteredData : data.rows;
    const csv = generateCsv(csvConfig)(dataToExport);
    download(csvConfig)(csv);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // const handleDispense = (row) => {
  //   setDispense(row.request_id);
  // };

  const handleAccept = (row) => {
    setOpenAccept(row.request_id);
  };

  const handleCancel = (row) => {
    setOpenReject(row.request_id);
  };
  if (mainPageloading) {
    return <Loader open={mainPageloading} />;
  }
  return (
    <>
      {/* {dispense_ref && (
        <AlertDialog
          open={true}
          negative={() => {
            setDispense(false);
          }}
          positive={(param) => {
            UpdatePatientsMedicationRequestStatus({
              variables: { requestId: Number(param), status: 4 },
            });
          }}
          param={dispense_ref}
          title={"Warning"}
          description={"Are you sure you want to dispense?"}
        />
      )} */}
      <MgpModal
        styling={{}}
        open={open}
        onClose={handleClose}
        title={"Medication Request"}
      >
        <Stack>
          {/* <MgpText variant="h4">Medication Request</MgpText> */}
          <MedicationRequest
            obj={{}}
            viewMode={false}
            newVisit={false}
            handleClose={handleClose}
            dataSelected={medicationCurrentSelection}
            from="MEDICATION_HISTORY"
          />
        </Stack>
      </MgpModal>
      <MgpModal
        open={accept}
        onClose={() => {
          setOpenAccept(false);
        }}
      >
        <DispensedForm reject={accept} setOpenReject={setOpenAccept} />
      </MgpModal>

      <MgpModal
        open={reject}
        onClose={() => {
          setOpenReject(false);
        }}
      >
        <NotDispensedForm reject={reject} setOpenReject={setOpenReject} />
      </MgpModal>

      <Padding size="0px">
        {from != "VIEW_PATIENT" && (
          <MgpText variant="h5">Medication Requests</MgpText>
        )}
        <MgpTable table={table} />
      </Padding>
    </>
  );
};
