import { InputLabel } from "@mui/material";
import { useState, useMemo } from "react";
import { MgpTextbox } from "./MgpTextbox";
import * as React from "react";
import Box from "@mui/material/Box";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export const MgpTextLable = ({
  splits,
  name,
  label,
  error,
  attr,
  touched,
  setFieldValue,
  errors,
  values,
  handleBlur,
}) => {
  const positionRef = React.useRef({
    x: 0,
    y: 0,
  });
  const popperRef = React.useRef(null);
  const areaRef = React.useRef(null);

  const handleMouseMove = (event) => {
    positionRef.current = { x: event.clientX, y: event.clientY };

    if (popperRef.current != null) {
      popperRef.current.update();
    }
  };

  return (
    <div>
      <Tooltip
        title={
          attr.error && <div dangerouslySetInnerHTML={{ __html: error }} />
        }
        placement="top"
        arrow
        PopperProps={{
          popperRef,
          anchorEl: {
            getBoundingClientRect: () => {
              return new DOMRect(
                positionRef.current.x,
                areaRef.current.getBoundingClientRect().y,
                0,
                0
              );
            },
          },
        }}
      >
        <InputLabel ref={areaRef} onMouseMove={handleMouseMove} sx={{ m: 1 }}>
          {label}
        </InputLabel>
      </Tooltip>
    </div>
  );
};

// export default function TextboxLable( splits,
//   name,
//   label,
//   touched,
//   setFieldValue,
//   errors,
//   values,
//   handleBlur,) {
//   const positionRef = React.useRef({
//     x: 0,
//     y: 0,
//   });
//   const popperRef = React.useRef(null);
//   const areaRef = React.useRef(null);

//   const handleMouseMove = (event) => {
//     positionRef.current = { x: event.clientX, y: event.clientY };

//     if (popperRef.current != null) {
//       popperRef.current.update();
//     }
//   };

//   return (
//     <Tooltip
//       title="Add"
//       placement="top"
//       arrow
//       PopperProps={{
//         popperRef,
//         anchorEl: {
//           getBoundingClientRect: () => {
//             return new DOMRect(
//               positionRef.current.x,
//               areaRef.current.getBoundingClientRect().y,
//               0,
//               0,
//             );
//           },
//         },
//       }}
//     >
//       <Box
//         ref={areaRef}
//         onMouseMove={handleMouseMove}
//         sx={{
//           bgcolor: 'primary.main',
//           color: 'primary.contrastText',
//           p: 2,
//         }}
//       >
//         Hover
//       </Box>
//     </Tooltip>
//   );
// }
