import React, { createContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { gql, useLazyQuery } from "@apollo/client";
const RbacContext = createContext();

export const RedirectTo = ({ path = "/" }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(path);
  }, [path]);
  return <>404</>;
};

const GET_RBAC = gql`
  query Query {
    GetRbac
  }
`;

export const RbacContextProvider = ({ children }) => {
  const [rbac, setRbac] = React.useState(false);

  const GET_RBAC = gql`
    query Query {
      GetRbac
    }
  `;
  const useGetRbac = useLazyQuery(GET_RBAC, {
    fetchPolicy: "cache-and-network",
  });
  const [GetRbac] = useGetRbac;

  ///app/roles
  const { pathname } = useLocation();
  const module = pathname.split("/").filter((item) => item !== "")[1];
  const values = React.useMemo(() => ({ rbac, setRbac }), [rbac, setRbac]);
  const RouteGuard = ({ module, children, rbac }) => {
    return (
      <>
        {rbac ? (
          <>
            {getAccesForRoute(module, rbac) ? (
              children
            ) : (
              <RedirectTo path="/login" />
            )}
          </>
        ) : (
          <>{children}</>
        )}
      </>
    );
  };

  React.useEffect(() => {
    GetRbac({
      onCompleted: ({ GetRbac: rbacdata }) => {
        setRbac(rbacdata);
      },
      onError: () => {
        setRbac(false);
      },
    });
  }, [GetRbac]);

  return (
    <RbacContext.Provider value={values}>
      {rbac && (
        <RouteGuard module={module} rbac={rbac}>
          {children}
        </RouteGuard>
      )}
    </RbacContext.Provider>
  );
};

const getAccessByModuleCode = (module, code, rbac) => {
  return rbac?.access
    ?.find((i) => i.code === module)
    ?.access.find((j) => j.code === code)?.status;
};
const getAccesForRoute = (module, rbac) => {
  console.log('module, rbac',module, rbac);
  console.log('rbac?.access?.[0]?.access',rbac?.access?.[0]?.access);
  const rbacstatus = rbac?.access?.[0]?.access.find(
    (i) => i.code === module
  )?.status;
  console.log('rbacstatus',rbacstatus);
  return rbacstatus;
};
// Define the Rbac component with the declared props type
export const Rbac = ({ module, children, code }) => {
  return <>{getAccessByModuleCode(module, code) && children}</>;
};

export const useRbac = () => {
  const { rbac, setRbac } = React.useContext(RbacContext);
  const RbacWrapper = ({ module, children, code }) => {
    return <>{getAccessByModuleCode(module, code, rbac) && children}</>;
  };
  const getActionRbac = (module, code) => {
    return getAccessByModuleCode(module, code, rbac);
  };

  const useGetRbac = useLazyQuery(GET_RBAC);

  return {
    RbacWrapper,
    getActionRbac,
    useGetRbac,
    rbac,
  };
};
