import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material"; 
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import { MgpButton } from "./MgpButton";
import Typography from "@mui/material/Typography";

export function AlertDialog({
  positive = () => {},
  negative = () => {},
  open: defaultOpen = false,
  title,
  description,
  param,
}) {
  const [open, setOpen] = React.useState(defaultOpen);

  const handlePositive = () => {
    setOpen(false);
    positive(param);
  };

  const handleNegative = () => {
    setOpen(false);
    negative();
  };

  return (
    <div>
  <Dialog
    open={open}
    // onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    sx={{
      "& .MuiDialog-container": {
        "& .MuiPaper-root": {
          width: "100%",
          maxWidth: "600px", // Adjusted width
        },
      },
    }}
  >
    <DialogTitle
      id="alert-dialog-title"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: '0',
      }}
    >
      <Typography variant="h2" color="primary" sx={{ fontWeight: 'bold',    fontSize: "19px" }}> {/* Increased title size */}
        {title}
      </Typography>
      <IconButton
        onClick={handleNegative}
        size="large"
        title="Close dialog"
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        <Typography
          variant="h1"
          sx={{
            // fontWeight: 'bold',
            color: 'black',
            fontSize: '16px',
            fontWeight: 500
          }}
        >
          {description}
        </Typography>
      </DialogContentText>
    </DialogContent>
    <DialogActions
      sx={{
        justifyContent: 'center',
        padding: '16px',
      }}
    >
        <MgpButton
        onClick={handleNegative}
        variant="outlined" 
        sx={{
          fontSize: '16px',
          padding: '10px 20px',
          width: '100px',  
          borderColor: 'primary',
          color: "primary",
          backgroundColor: 'white', 
          marginRight: '10px', 
        }}
      >
        Cancel
      </MgpButton>
     
      <Button
        onClick={handlePositive}
        color="primary"
        variant="contained"
        sx={{
          fontSize: '16px',
          padding: '10px 20px',
          autoFocus: true,
          width: '100px',  
        }}
      >
        Yes
      </Button>
    </DialogActions>
  </Dialog>



    </div>
  );
}
