import { Box, IconButton, Paper, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Padding } from "./Padding";

const style = {
  position: "absolute",
  padding: "16px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  boxShadow: 24,
  // overflowY: "scroll",
};

export function MgpModal({
  title,
  styling,
  open,
  children,
  onClose = () => {},
}) {
  return (
    <div>
      <Modal
        xs={{ minHeight: "30%" }}
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={{ ...style, ...styling, width:styling?.width?styling?.width:"55%" }}>
          {title && (
            <Box
              sx={{
                marginBottom: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
            </Box>
          )}
          <Box sx={{ overflowY: "auto", maxHeight: "80vh" }}>
            <Padding size="8px 0px">{children}</Padding>
          </Box>

          <IconButton
            onClick={onClose}
            sx={{
              marginBottom: "30px",
              marginLeft: "24px",
              position: "absolute",
              top: 6,
              right: 6,
            }}
          >
            <CancelIcon />
          </IconButton>
        </Paper>
      </Modal>
    </div>
  );
}
