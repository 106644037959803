import React, { createContext, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
const MasterContext = createContext();

const CITY_DD = gql`
  query Query {
    GetCities {
      value: city_id
      label: city_name
    }
  }
`;

const ROLE_DD = gql`
  query Query {
    GetRoles {
      value: role_id
      label: role_name
    }
  }
`;

const REGION_DD = gql`
  query Query {
    GetRegions {
      value: region_id
      label: region_name
    }
  }
`;

const MASTER_REGION_DD = gql`
  query Query {
    GetMasterRegions {
      value: master_region_id
      label: master_region_name
    }
  }
`;

const HOSPITAL_DD = gql`
  query Query {
    GetHospitals {
      value: hospital_id
      label: hospital_name
      region_name: region_name
      city_name: city_name
    }
  }
`;

const CONTRIES_DD = gql`
  query GetCountries($isGcc: Boolean) {
    GetCountries(is_gcc: $isGcc) {
      value: country_id
      label: country_name
    }
  }
`;

const GET_CITIES_BY_COUNTRY_DD = gql`
  query GetCitiesByCountry($countryId: Int!) {
    GetCitiesByCountry(country_id: $countryId) {
      value: city_id
      label: city_name
    }
  }
`;
const GET_REGIONS_BY_COUNTRY_DD = gql`
  query GetRegionsByCountry($countryId: Int!) {
    GetRegionsByCountry(country_id: $countryId) {
      value: region_id
      label: region_name
    }
  }
`;

const GET_CITIES_BY_REGION_DD = gql`
  query GetCitiesByRegion($regionId: Int!) {
    GetCitiesByRegion(region_id: $regionId) {
      value: city_id
      label: city_name
    }
  }
`;

const GET_HOSPITAL_BY_CITY = gql`
  query GetHospitalsByCity($cityId: Int!) {
    GetHospitalsByCity(city_id: $cityId) {
      value: hospital_id
      label: hospital_name
    }
  }
`;

const GET_GENDER_ENUM = gql`
  query GetEnums($enum: String) {
    GetEnums(enum: $enum) {
      label
      value
    }
  }
`;

const GET_ALL_DOCTORS = gql`
  query GetAllDoctors {
    GetAllDoctors {
      label: full_name
      value: user_id
    }
  }
`;

const GET_DOCTORS = gql`
  query GetDoctors($hospitalId: Int!, $isDoctorOnly: Boolean) {
    GetDoctors(hospital_id: $hospitalId, is_doctor_only: $isDoctorOnly) {
      label: full_name
      value: user_id
    }
  }
`;

const GET_USERS_DD = gql`
  query GetUsers {
    GetUsers {
      label: full_name
      value: user_id
    }
  }
`;

export const MasterContextProvider = ({ children }) => {
  const [master, setMaster] = React.useState(false);
  const values = React.useMemo(
    () => ({ master, setMaster }),
    [master, setMaster]
  );
  const [GetCity] = useLazyQuery(CITY_DD, {
    fetchPolicy: "cache-and-network",
  });

  const [GetRoles] = useLazyQuery(ROLE_DD, {
    fetchPolicy: "cache-and-network",
  });

  const [GetHospitals] = useLazyQuery(HOSPITAL_DD, {
    fetchPolicy: "cache-and-network",
  });

  const [GetRegions] = useLazyQuery(REGION_DD, {
    fetchPolicy: "cache-and-network",
  });

  const [GetMasterRegions] = useLazyQuery(MASTER_REGION_DD, {
    fetchPolicy: "cache-and-network",
  });
  const [GetCountries] = useLazyQuery(CONTRIES_DD, {
    fetchPolicy: "cache-and-network",
  });

  const [GetEnums] = useLazyQuery(GET_GENDER_ENUM, {
    fetchPolicy: "cache-and-network",
  });

  const [GetAllDoctors] = useLazyQuery(GET_ALL_DOCTORS, {
    fetchPolicy: "cache-and-network",
  });

  const [GetUsers] = useLazyQuery(GET_USERS_DD, {
    fetchPolicy: "cache-and-network",
  });
  React.useEffect(() => {
    GetCity({
      onCompleted: ({ GetCities: data }) => {
        setMaster((prev) => {
          return { ...prev, cities: data };
        });
      },
    });
    GetRoles({
      onCompleted: ({ GetRoles: data }) => {
        setMaster((prev) => {
          return { ...prev, roles: data };
        });
      },
    });
    GetHospitals({
      onCompleted: ({ GetHospitals: data }) => {
        setMaster((prev) => {
          return { ...prev, hospitals: data };
        });
      },
    });
    GetRegions({
      onCompleted: ({ GetRegions: data }) => {
        setMaster((prev) => {
          return { ...prev, regions: data };
        });
      },
    });
    GetMasterRegions({
      onCompleted: ({ GetMasterRegions: data }) => {
        setMaster((prev) => {
          return { ...prev, master_regions: data };
        });
      },
    });
    GetCountries({
      variables: { isGcc: true },
      onCompleted: ({ GetCountries: data }) => {
        setMaster((prev) => {
          return { ...prev, countries: data };
        });
      },
    });
    GetEnums({
      variables: { enum: "gender" },
      onCompleted: ({ GetEnums: data }) => {
        setMaster((prev) => {
          return { ...prev, genders: data };
        });
      },
    });
    GetAllDoctors({
      onCompleted: ({ GetAllDoctors: data }) => {
        setMaster((prev) => {
          return { ...prev, doctors: data };
        });
      },
    });
    GetUsers({
      onCompleted: ({ GetUsers: data }) => {
        setMaster((prev) => {
          return { ...prev, users: data };
        });
      },
    });
  }, []);

  return (
    <MasterContext.Provider value={values}>{children}</MasterContext.Provider>
  );
};

export const useMaster = () => {
  const { master, setMaster } = React.useContext(MasterContext);

  const [GetCitiesByCountry] = useLazyQuery(GET_CITIES_BY_COUNTRY_DD, {
    fetchPolicy: "cache-and-network",
  });

  const [GetRegionsByCountry] = useLazyQuery(GET_REGIONS_BY_COUNTRY_DD, {
    fetchPolicy: "cache-and-network",
  });

  const [GetCitiesByRegion] = useLazyQuery(GET_CITIES_BY_REGION_DD, {
    fetchPolicy: "cache-and-network",
  });

  const [GetHospitalsByCity] = useLazyQuery(GET_HOSPITAL_BY_CITY, {
    fetchPolicy: "cache-and-network",
  });

  const [GetDoctors] = useLazyQuery(GET_DOCTORS, {
    fetchPolicy: "cache-and-network",
  });
  // const setSelectedCountry = (countryId) => {
  //   GetCitiesByCountry({
  //     variables: { countryId: Number(countryId) },
  //     onCompleted: ({ GetCitiesByCountry: data }) => {
  //       console.log("🚀 ~ setSelectedCountry ~ data:", data);
  //       setMaster((prev) => {
  //         return { ...prev, cities: data };
  //       });
  //     },
  //     onError: (err) => {
  //       console.log("🚀 ~ setSelectedCountry ~ err:", err);
  //     },
  //   });
  // };

  const setSelectedCountry = (countryId) => {
    GetRegionsByCountry({
      variables: { countryId: Number(countryId) },
      onCompleted: ({ GetRegionsByCountry: data }) => {
        console.log("🚀 ~ setSelectedCountry ~ data:", data);
        setMaster((prev) => {
          return { ...prev, regions: data };
        });
      },
      onError: (err) => {
        console.log("🚀 ~ setSelectedCountry ~ err:", err);
      },
    });
  };

  const setSelectedHospital = (hospitalId) => {
    GetDoctors({
      variables: { hospitalId: Number(hospitalId), isDoctorOnly: true },
      onCompleted: ({ GetDoctors: data }) => {
        console.log("🚀 ~ setSelectedHospital ~ data:", data);
        setMaster((prev) => {
          return { ...prev, doctors: data };
        });
      },
      onError: (err) => {
        console.log("🚀 ~ setSelectedHospital ~ err:", err);
      },
    });
  };

  const setSelectedRegion = (regionId) => {
    GetCitiesByRegion({
      variables: { regionId: Number(regionId) },
      onCompleted: ({ GetCitiesByRegion: data }) => {
        console.log("🚀 ~ setSelectedCountry ~ data:", data);
        setMaster((prev) => {
          return { ...prev, cities: data };
        });
      },
      onError: (err) => {
        console.log("🚀 ~ setSelectedCountry ~ err:", err);
      },
    });
  };

  const setSelectedCity = (cityId) => {
    GetHospitalsByCity({
      variables: { cityId: Number(cityId) },
      onCompleted: ({ GetHospitalsByCity: data }) => {
        console.log("🚀 ~ setSelectedCity ~ data:", data);
        setMaster((prev) => {
          return { ...prev, hospitals: data };
        });
      },
      onError: (err) => {
        console.log("🚀 ~ setSelectedCity ~ err:", err);
      },
    });
  };

  const {
    cities = [],
    roles = [],
    hospitals = [],
    countries = [],
    regions = [],
    master_regions = [],
    genders = [],
    visit_type = [],
    doctors = [],
    users = [],
  } = master;

  return {
    cities,
    roles,
    hospitals,
    countries,
    regions,
    master_regions,
    genders,
    setSelectedCountry,
    setSelectedRegion,
    setSelectedCity,
    setSelectedHospital,
    visit_type,
    doctors,
    users,
  };
};
