import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { ProcessGrapqlError, apollo_client } from "../helpers";

export const useMasterRegion = () => {
  const ADD_MASTER_REGION = gql`
    mutation Mutation($masterRegionName: String!) {
      AddMasterRegion(master_region_name: $masterRegionName) {
        master_region_id
        master_region_name
        is_active
        created_on
        updated_on
      }
    }
  `;

  const GET_MASTER_REGIONS = gql`
    query GetMasterRegions {
      GetMasterRegions {
        master_region_id
        master_region_name
        is_active
        created_on
        updated_on
      }
    }
  `;

  const DELETE_MASTER_REGION = gql`
    mutation DeleteMasterRegion($masterRegionId: Int!) {
      DeleteMasterRegion(master_region_id: $masterRegionId) {
        master_region_id
        master_region_name
        is_active
        created_on
        updated_on
      }
    }
  `;

  const UPDATE_MASTER_REGION = gql`
    mutation UpdateMasterRegion(
      $masterRegionId: Int!
      $masterRegionName: String!
    ) {
      UpdateMasterRegion(
        master_region_id: $masterRegionId
        master_region_name: $masterRegionName
      ) {
        master_region_id
        master_region_name
        is_active
        created_on
        updated_on
      }
    }
  `;

  const useGetMasterRegions = useLazyQuery(GET_MASTER_REGIONS);

  const useAddMasterRegion = useMutation(ADD_MASTER_REGION, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { AddMasterRegion } }) {
      try {
        const existingData = cache.readQuery({ query: GET_MASTER_REGIONS });
        if (existingData) {
          const newData = [...existingData.GetMasterRegions, AddMasterRegion];
          cache.writeQuery({
            query: GET_MASTER_REGIONS,
            data: { GetMasterRegions: newData },
          });
        } else {
          // Refetch data if it's not available in the cache
          apollo_client
            .query({
              query: GET_MASTER_REGIONS,
            })
            .then(({ data }) => {
              if (data && data.GetMasterRegions) {
                const newData = [...data.GetMasterRegions, AddMasterRegion];
                cache.writeQuery({
                  query: GET_MASTER_REGIONS,
                  data: { GetMasterRegions: newData },
                });
              }
            })
            .catch((error) => {
              console.error("Error while refetching data:", error);
            });
        }
      } catch (error) {
        console.error(
          "An error occurred while updating the cache after updating a country:"
        );
      }
    },
  });
  const useUpdateMasterRegion = useMutation(UPDATE_MASTER_REGION, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { UpdateMasterRegion } }) {
      try {
        const existingData = cache.readQuery({ query: GET_MASTER_REGIONS });
        if (existingData) {
          const newData = existingData.GetMasterRegions.map((item) =>
            item.master_region_id !== UpdateMasterRegion.master_region_id
              ? item
              : UpdateMasterRegion
          );
          cache.writeQuery({
            query: GET_MASTER_REGIONS,
            data: { GetMasterRegions: newData },
          });
        } else {
          // Refetch data if it's not available in the cache
          apollo_client
            .query({
              query: GET_MASTER_REGIONS,
            })
            .then(({ data }) => {
              if (data && data.GetMasterRegions) {
                const newData = data.GetMasterRegions.map((item) =>
                  item.master_region_id !== UpdateMasterRegion.master_region_id
                    ? item
                    : UpdateMasterRegion
                );
                cache.writeQuery({
                  query: GET_MASTER_REGIONS,
                  data: { GetMasterRegions: newData },
                });
              }
            })
            .catch((error) => {
              console.error("Error while refetching data:", error);
            });
        }
      } catch (error) {
        console.error(
          "An error occurred while updating the cache after updating a country:"
        );
      }
    },
  });
  const useDeleteMasterRegion = useMutation(DELETE_MASTER_REGION, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { DeleteMasterRegion } }) {
      const existing = cache.readQuery({ query: GET_MASTER_REGIONS });
      const newRegions = existing.GetMasterRegions.filter(
        (item) => item.master_region_id !== DeleteMasterRegion.master_region_id
      );
      cache.writeQuery({
        query: GET_MASTER_REGIONS,
        data: { GetMasterRegions: newRegions },
      });
    },
  });

  return {
    useAddMasterRegion,
    useGetMasterRegions,
    useUpdateMasterRegion,
    useDeleteMasterRegion,
  };
};
