import {
  Backdrop,
  CircularProgress,
  Container,
  Grid,
  Stack,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { MgpButton, MgpDD } from "../components/global";
import { useMaster } from "../hooks";
import { useState } from "react";
import { MgpDatePicker } from "../components/global/MgpDatePicker";
import moment from "moment";
import { MgpDatePickerOther } from "../components/global/MgpDatePickerOther";

export const ActivityLogFilter = ({ handleChange = () => {} }) => {
  const { users } = useMaster();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const validationSchema = yup.object().shape({});
  const from = moment().subtract(7, "day").format("YYYY-MM-DD");
  const to = moment().format("YYYY-MM-DD");
  return (
    <>
      <Container fluid>
        <Formik
          initialValues={{ user_id: "" }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleChange(values);
          }}
        >
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            resetForm,
            touched,
            errors,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={4}>
                  <MgpDD
                    label="Select Users"
                    variant="outlined"
                    size="small"
                    name="user_id"
                    multiselect={false}
                    value={values.user_id}
                    fullWidth
                    options={users}
                    onChange={(e) => {
                      //handleChange(e);
                      setFieldValue("user_id", e.target.value);
                    }}
                    onBlur={handleBlur}
                    error={Boolean(touched.user_id && errors.user_id)}
                    helperText={touched.user_id && errors.user_id}
                  />
                </Grid>

                <Grid item md={4}>
                  <MgpDatePickerOther
                    viewMode={false}
                    size="small"
                    fullWidth
                    onChange={setFieldValue}
                    errors={Boolean(errors?.["from"] && touched?.["from"])}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    handleChange={handleChange}
                    label={"From"}
                    name={"from"}
                    values={values}
                    handleBlur={handleBlur}
                    startDate={"Form_Date"}
                    disablePast={false}
                    monthly={true}
                    disableFuture={true}
                    removetoday={true}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={4}>
                  <MgpDatePickerOther
                    viewMode={false}
                    size="small"
                    fullWidth
                    onChange={setFieldValue}
                    errors={Boolean(errors?.["to"] && touched?.["to"])}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    handleChange={handleChange}
                    label={"To"}
                    name={"to"}
                    values={values}
                    handleBlur={handleBlur}
                    startDate={"To_Date"}
                    disablePast={false}
                    monthly={true}
                    disableFuture={true}
                    removetoday={true}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={12}>
                  <Stack
                    spacing={2}
                    justifyContent={"flex-end"}
                    direction={"row"}
                  >
                    <MgpButton
                      onClick={() => {
                        resetForm();
                        setFieldValue("user_id", "");
                      }}
                      variant="contained"
                    >
                      Clear
                    </MgpButton>
                    <MgpButton type="submit" variant="contained">
                      Apply
                    </MgpButton>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Container>
      {loadingSubmit && (
        <Backdrop open={loadingSubmit} style={{ zIndex: 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
};
