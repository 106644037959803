import React from "react";

export const Mgp404Page = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404 - Page Not Found</h1>
      <p style={styles.paragraph}>
        Sorry, the page you are looking for does not exist.
      </p>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    marginTop: "20vh",
  },
  heading: {
    fontSize: "2rem",
    color: "#333",
  },
  paragraph: {
    fontSize: "1rem",
    color: "#666",
  },
};
