import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";
import { download, generateCsv, mkConfig } from "export-to-csv";
import {
  MRT_GlobalFilterTextField,
  useMaterialReactTable,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  MgpButton,
  MgpTable,
  MgpTableStatus,
  MgpText,
  TableConfig,
} from "../../components/global";

import { ENV } from "../../helpers";
import { useTransfer } from "../../hooks";
import { colors } from "../../theme/colors";
import Loader from "../Loader/Loader";
import StatusColors from "../../components/global/StatusColors";
import { downloadPdf } from "../../helpers/dowloadPdf";
import { localDate } from "../../components/global/MgpNotification";
import * as XLSX from 'xlsx';
const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

export const TransferHistory = () => {
  const [mainPageloading, setMainPageloading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { useGetTransferHistory } = useTransfer();
  const [GetTransferHistory, { data, loading, error }] = useGetTransferHistory;
  const params = useParams();

  useEffect(() => {
    GetTransferHistory({
      variables: { patientId: parseInt(params?.patient_id) },
    });
  }, [GetTransferHistory]);
  useEffect(() => {
    if (data) {
      setMainPageloading(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [data]);
  const handleExportData = (type) => {
    const filteredData = table
      .getFilteredRowModel()
      .rows.map((row) => row.original)
      .map((obj) => {
        const filteredObj = {};
        columns.forEach((key) => {
          if (obj.hasOwnProperty(key.accessorKey)) {
            if (key.accessorKey === "consent_date") {
              filteredObj[key.header] = localDate(obj[key.accessorKey]).split(
                ","
              )[0];
            } else if (
              (key.accessorKey === "new_registry_no" &&
                obj[key.accessorKey] == "") ||
              (key.accessorKey === "approved_on" && !obj[key.accessorKey]) ||
              (key.accessorKey === "status_comment" && !obj[key.accessorKey])
            ) {
              filteredObj[key.header] = "N/A";
            } else {
              filteredObj[key.header] = obj[key.accessorKey];
            }
          }
        });
        return filteredObj;
      });
    let dataToExport = filteredData ? filteredData : data.rows;
    let filename = "Patient Transfer History";
    if (dataToExport.length) {
      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      // Convert the data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
  
      // Calculate column widths based on headers and values
      const maxWidths = [];
      columns.forEach((col, colIndex) => {
        const header = col.header;
        maxWidths[colIndex] = header.length;
      });
  
      dataToExport.forEach((row) => {
        Object.keys(row).forEach((key, colIndex) => {
          const cellValue = row[key] ? row[key].toString() : '';
          maxWidths[colIndex] = Math.max(maxWidths[colIndex], cellValue.length);
        });
      });
  
      worksheet['!cols'] = maxWidths.map((width) => ({ wch: width + 2 }));
  
      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      
  
      // Handle file type and download
      if (type === "csv") {
        XLSX.writeFile(workbook, `${filename}.csv`, { bookType: 'csv' });
      } else if (type === "xlsx") {
        XLSX.writeFile(workbook, `${filename}.xlsx`, { bookType: 'xlsx' });
      }else {
        downloadPdf(dataToExport, filename);
      }
    }
  };
  const columns = useMemo(() => [
    {
      accessorKey: "index",
      header: "The Transfer",
      enableColumnFilter: false,
      Cell: ({ row }) => {
        const rowCount = data?.GetTransferHistory?.length ?? 0;
        return rowCount - row.index;
      },
    },
    {
      accessorKey: "old_registry_no",
      header: "Original Registry Number",
      Cell: ({ row }) => {
        let shouldRenderIcon = row.original.transfer_status === 2;
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {row.original.old_registry_no}{" "}
            {shouldRenderIcon ? (
              <Tooltip title="patient transferred" arrow>
                <CompareArrowsIcon
                  sx={{ fontSize: "16px", marginLeft: "7px" }}
                />
              </Tooltip>
            ) : (
              <></>
            )}
          </Box>
        );
      },
    },
    {
      accessorKey: "new_registry_no",
      header: "New Registry Number",
      Cell: ({ row }) => {
        return row.original.new_registry_no != "" ? (
          <>
            <Box
              sx={{
                alignItems: "center",
                color: colors.PRIMARY,
              }}
            >
              {row.original.new_registry_no}{" "}
            </Box>
          </>
        ) : (
          <>N/A</>
        );
      },
    },
    {
      accessorKey: "enrollment_date",
      header: "Date of Patient enrollment in the Registry",
      size: 210,
    },
    {
      accessorKey: "request_by",
      header: "Name of the Doctor who Raised the Request (Doctor 1)",
      size:250,
    },
    {
      accessorKey: "current_hospital",
      header: "Name of Old Hospital Patient was Transferred from (Hospital 1)",
      size:250,
    },

    {
      accessorKey: "transfer_doctor",
      header: "Name of New Doctor Patient Transferred to (Doctor 2)",
      size: 250,
    },
    {
      accessorKey: "transfer_hospital",
      header: "Name of New Site Patient was Transferred to (Hospital 2)",
      size: 250,
    },
    {
      accessorKey: "reason_transfer",
      header: "Reason For Transfer",
    },
    {
      accessorKey: "created_on",
      header: "Date Of Raising Request",
      size: 220,
    },
    {
      accessorKey: "consent_date",
      header: "Date Of Signed Request",
      size: 220,
      Cell: ({ row }) => {
        return <>{localDate(row.original.consent_date).split(",")[0]}</>;
      },
    },
    {
      accessorKey: "approved_on",
      header: "Date of Completed Transfer",
      size: 220,
      Cell: ({ row }) => {
        return (
          <>{row.original.approved_on ? row.original.approved_on : "N/A"}</>
        );
      },
    },
    {
      accessorKey: "transfer_status_enum",
      header: "Transfer Status",
      Cell: ({ row }) => {
        return (
          <MgpTableStatus
            value={row.original?.transfer_status}
            label={row.original?.transfer_status_enum}
            colorPending={
              parseInt(row.original?.transfer_status) === parseInt(1)
                ? "yellow"
                : ""
            }
          />
        );
      },
    },
    {
      accessorKey: "transfer_form",
      header: "Transfer Form",
      enableColumnFilter: false,
      Cell: ({ row }) => {
        return (
          <>
            {row.original.file_path !== "" ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Tooltip title="Consent form">
                  <a
                    href={`${ENV.file_download_url}/${row.original.file_path}`}
                    download="test"
                  >
                    <IconButton size="small">
                      <FileDownloadIcon
                        sx={{ height: "16px", color: colors.MENULISTPRIMARY }}
                      />
                    </IconButton>
                  </a>
                </Tooltip>
              </Box>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      accessorKey: "verbal_consent",
      header: "Was verbal consent obtained from the patient?",
      size:250,
      // enableColumnFilter: false,
      // Cell: ({ row }) => {
      //   return row.original.verbal_consent ? "Yes" : "No";
      // },
    },
    {
      accessorKey: "status_comment",
      header: "Reason for Rejection",
      Cell: ({ row }) => {
        return (
          <>
            {row.original.status_comment ? row.original.status_comment : "N/A"}
          </>
        );
      },
    },
    {
      accessorKey: "created_by_name",
      header: "Created By",
      size: 210,
    },
    {
      accessorKey: "updated_by_name",
      header: "Approved/Rejected By",
      size: 210,
    },
  ]);
  const table = useMaterialReactTable({
    columns,
    data: data?.GetTransferHistory ?? [],
    ...TableConfig,
    initialState: {
      ...TableConfig.initialState,
      columnPinning: {
        right: ["transfer_status_enum"],
      },
    },
    enableRowActions: false,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    renderTopToolbar: ({ table }) => (
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: "0.5rem",
          p: "16px 0px",
          justifyContent: "space-between",
        })}
      >
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <MRT_GlobalFilterTextField table={table} />
        </Box>

        <Stack spacing={2} direction={"row"}>
          <MgpButton
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={() => handleExportData("xlsx")}
            endIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export Excel
          </MgpButton>
          <MgpButton
            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
            onClick={() => handleExportData("pdf")}
            endIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Export PDF
          </MgpButton>
        </Stack>
      </Box>
    ),
    state: { isLoading, columnOrder: columns.map((e) => e.accessorKey) },
    renderTableBody: () => {
      return isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null;
    },
  });
  const statuses = [
    {
      label:
        "Pending - This status indicates that the patient transfer process is still ongoing and has not been finalized.",
      value: "1",
      color: "yellow",
    },
    {
      label:
        "Accepted - This status indicates that the new site has approved the patient transfer process.",
      value: "2",
    },
    {
      label:
        "Rejected - This status indicates that the new site has declined or refused the patient transfer process.",
      value: "3",
    },
  ];

  if (mainPageloading) {
    return <Loader open={mainPageloading} />;
  }

  return (
    <>
      <Stack>
        <MgpText variant="h5">Transfer Patient History</MgpText>
        <MgpTable table={table} />
        <StatusColors statuses={statuses}></StatusColors>
      </Stack>
    </>
  );
};
