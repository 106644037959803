import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  Observable,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ENV } from "./configs";
import { cache } from "./cache";
import axios from "axios";
import { from, throwError } from "rxjs";
import { switchMap, catchError } from "rxjs/operators";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { StorageHelper } from "./storageHelper";
const httpLink = createHttpLink({
  uri: ENV.api_url,
});
const wsLink = new GraphQLWsLink(
  createClient({
    url: ENV.socket_api_url,
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = StorageHelper.get("token");
  const subinvestigator_id = StorageHelper.get("subinvestigator_id");
  // return the headers to the context so httpLink can read them

  // Decoding the token to extract the data payload
  // const decodedToken = jwtDecode(token);

  // Extracting the expiration time
  // const expirationTime = decodedToken?.exp;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      subinvestigatorid: subinvestigator_id ? parseInt(subinvestigator_id) : "",
    },
    // passing the decoded token's expiration time as context
    // decoded: {
    //   exp: expirationTime,
    // },
  };
});
const refreshToken = async () => {
  try {
    // Call your refresh token API endpoint using Axios
    const response = await axios.post(
      ENV.api_url,
      {
        query:
          "mutation RefreshToken {\n  RefreshToken {\n    access_token\n  }\n}",
        operationName: "RefreshToken",
      },
      {
        headers: {
          // Set the Authorization header with the access token
          Authorization: `Bearer ${StorageHelper.get("token")}`,
        },
      }
    );
    const error = response.data?.errors;
    console.log("🚀 ~ refreshToken ~ error:", error);
    if (error) {
      window.open("/login", "_self");
    }
    const new_token = response.data?.data?.RefreshToken?.access_token;

    // Update the token in local storage
    StorageHelper.set("token", new_token);

    return new_token;
  } catch (error) {
    // Handle error if token refresh fails (e.g., network error)
    console.error("Error refreshing token:", error);
    throw error;
  }
};

const getOperationKey = (operation) => {
  return JSON.stringify({
    query: operation.query.loc?.source.body,
    variables: operation.variables,
  });
};

let isRefreshing = false;
let failedQueue = [];
let failedoperation = [];

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        if (err?.extensions?.code === "UNAUTHENTICATED") {
          const key = getOperationKey(operation);
          // Only attempt to refresh token if not already in progress
          if (!isRefreshing) {
            isRefreshing = true;
            return new Observable((observer) => {
              from(refreshToken())
                .pipe(
                  switchMap((token) => {
                    // Update the token in headers
                    operation.setContext(({ headers }) => ({
                      headers: {
                        ...headers,
                        authorization: `Bearer ${token}`,
                      },
                    }));

                    // Retry the failed request
                    return forward(operation);
                  }),
                  catchError((error) => {
                    // Handle errors during token refresh or retry
                    return throwError(error);
                  })
                )
                .subscribe({
                  next: (result) => {
                    // Reset refreshing state and process the failed queue
                    isRefreshing = false;
                    failedQueue.forEach((callback) => callback(result));
                    failedQueue = [];
                    failedoperation = [];
                    observer.next(result);
                    observer.complete();
                  },
                  error: (error) => {
                    // Reset refreshing state and handle error
                    isRefreshing = false;
                    failedQueue.forEach((callback) => callback(error));
                    failedQueue = [];
                    failedoperation = [];
                    observer.error(error);
                  },
                });
            });
          } else {
            failedoperation.push(key);
            // If a refresh is already in progress, queue the request
            return new Observable((observer) => {
              failedQueue.push((result) => {
                operation.setContext(({ headers }) => ({
                  headers: {
                    ...headers,
                    authorization: `Bearer ${result.token}`,
                  },
                }));
                if (!failedoperation.includes(key)) {
                  forward(operation).subscribe(observer);
                }
              });
            });
          }
        }
      }
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  }
);

export const ProcessGrapqlError = ({
  graphQLErrors,
  networkError,
  ...rest
}) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(message);
    });
  if (rest) {
    console.log(rest);
  }
};
export const apollo_client = new ApolloClient({
  link: errorLink.concat(authLink.concat(splitLink)),
  cache: cache,
});
