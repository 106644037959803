const {
  SUPER_ADMIN_ROLE_ID = "1",
  DOCTOR_ROLE_ID = "3",
  ITKAN_ROLE_ID = "5",
  ADMIN_ROLE_ID = "7",
  API_URL = "https://api-mgpr.sa-registries.com/graphql",
  SOCKET_URL = "wss://api-mgpr.sa-registries.com/graphql",
  FILE_UPLOAD_URL = "https://api-mgpr.sa-registries.com/upload",
  FILE_DOWNLOAD_URL = "https://cdn-mgpr.sa-registries.com/mgp",
  MEDICAL_LEAD_ROLE_ID = "4",
  QUALITY_USER_ROLE_ID = "2",
  SECONDARY_INVESTIGATOR_ROLE_ID = "14",
  SUPERSET_HOME_DASHBOARD = "f18b5f0b-8e5c-4fcb-ab39-7456c53c1150",
  SUPERSET_ANALYTICS_DASHBOARD = "ad127e24-3afc-40d1-9c33-56ccc980102b",
  SUPERSET_HOST_REACT = "https://superset-mgpr.sa-registries.com",
  REACT_URL = "https://sa-registries.com",
} = process.env;

export const ENV = {
  api_url: API_URL,
  socket_api_url: SOCKET_URL,
  super_admin_role_id: SUPER_ADMIN_ROLE_ID,
  doctor_role_id: DOCTOR_ROLE_ID,
  file_upload_url: FILE_UPLOAD_URL,
  file_download_url: FILE_DOWNLOAD_URL,
  medical_lead_role_id: MEDICAL_LEAD_ROLE_ID,
  itkan_role_id: ITKAN_ROLE_ID,
  admin_role_id: ADMIN_ROLE_ID,
  quality_user_role_id: QUALITY_USER_ROLE_ID,
  secondary_investigator_role_id: SECONDARY_INVESTIGATOR_ROLE_ID,
  superset_home_dashboard: SUPERSET_HOME_DASHBOARD,
  superset_analytics_dashboard: SUPERSET_ANALYTICS_DASHBOARD,
  superset_host: SUPERSET_HOST_REACT,
  react_url: REACT_URL,
};
