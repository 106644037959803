import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Stack,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import {
  AlertDialog,
  MgpButton,
  MgpModal,
  MgpTable,
  MgpText,
  MgpTextboxFormik,
  TableConfig,
} from "../../../components/global";
import { useEffect, useMemo, useState } from "react";
import Loader from "../../Loader/Loader";
import { useRbac } from "../../../hooks";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  MRT_FilterTextField,
  MRT_GlobalFilterTextField,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable,
} from "material-react-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { useUser } from "../../../hooks/useUsers";
import { UpdateUser } from "./UpdateUser.js";
import MgpAlertBar from "../../../components/global/MgpAlertBar.js";
import { useMaster } from "../../../hooks";
import { ENV } from "../../../helpers/configs.js";
import { downloadPdf } from "../../../helpers/dowloadPdf.js";
import * as XLSX from 'xlsx';

export const AddUser = () => {
  const { hospitals } = useMaster();
  const [open, setOpen] = useState(false);
  const [delete_ref, setDeleting] = useState(false);
  const { useGetUser, useDeleteUser } = useUser();
  const [edit, setEdit] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { RbacWrapper } = useRbac();
  const [GetUser, { data, loading, error }] = useGetUser;
  const [message, setMessage] = useState("");
  const [alertOption, setAlertOption] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [mainPageloading, setMainPageloading] = useState(true);
  const [DeleteUser] = useDeleteUser;
  const handleSubmitClose = () => {
    setAlertOption(false);
  };
  const handleNew = () => {
    setEdit({});
    setOpen(true);
  };

  // Custom filter function

  const arrayStringFilterFn = (row, id, filterValue) => {
    const cellValue = row.original[id];
    if (!cellValue || !Array.isArray(cellValue)) return false; // Ensure cellValue is an array
    const arrayValues = cellValue.map((elem) => elem.hospital_name);
    // console.log("The  cell value is filter function is called",arrayValues,"The filter Value is",filterValue)

    return arrayValues.some((value) =>
      value.toLowerCase().includes(filterValue.toLowerCase())
    );
  };

  // Custom filter function to pass to Material React Table
  const customFilterFn = (rows, id, filterValue) => {
    // console.log("the rows value i filter function is called",rows)
    return arrayStringFilterFn(rows, id, filterValue);
  };
  // For highlighting the filtered values
  const highlightText = (text, highlight) => {
    if (!highlight.trim()) {
      return text;
    }
    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "#ffc982" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  // Custom Sorting function

  const customSortFn = (rowA, rowB, id) => {
    const valueA = rowA.original[id]
      .map((elem) => elem.hospital_name)
      .join(", ");
    const valueB = rowB.original[id]
      .map((elem) => elem.hospital_name)
      .join(", ");
    return valueA.localeCompare(valueB);
  };
  const handleExportData = (type) => {
    const filteredData = table
    .getFilteredRowModel()
    .rows.map((row) => row.original)
    .map((obj) => {
      const filteredObj = {};
      columns.forEach((key) => {
        if (obj.hasOwnProperty(key.accessorKey)) {
          if(key.accessorKey === "is_active"){
            filteredObj[key.header] = obj[key.accessorKey]? "Active":"Inactive"
          }else if(key.accessorKey === "first_name"){
            filteredObj[key.header] = `${obj.first_name} ${obj.last_name}`
          }else{
            filteredObj[key.header] = obj[key.accessorKey];

          }
         
        }
      });
      return filteredObj;
    });
  let dataToExport = filteredData ? filteredData : data.rows;
  let filename = "User List";
  if (dataToExport.length) {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    // Convert the data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Calculate column widths based on headers and values
    const maxWidths = [];
    columns.forEach((col, colIndex) => {
      const header = col.header;
      maxWidths[colIndex] = header.length;
    });

    dataToExport.forEach((row) => {
      Object.keys(row).forEach((key, colIndex) => {
        const cellValue = row[key] ? row[key].toString() : '';
        maxWidths[colIndex] = Math.max(maxWidths[colIndex], cellValue.length);
      });
    });

    worksheet['!cols'] = maxWidths.map((width) => ({ wch: width + 2 }));

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
    

    // Handle file type and download
    if (type === "csv") {
      XLSX.writeFile(workbook, `${filename}.csv`, { bookType: 'csv' });
    } else if (type === "xlsx") {
      XLSX.writeFile(workbook, `${filename}.xlsx`, { bookType: 'xlsx' });
    }else {
      downloadPdf(dataToExport, filename);
    }
  }
  };

  useEffect(() => {
    GetUser({ fetchPolicy: "cache-and-network" });
  }, [GetUser]);
  useEffect(() => {
    if (data || error) {
      setMainPageloading(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [data, error]);
  useEffect(() => {
    if (!open) GetUser({ fetchPolicy: "cache-and-network" });
  }, [open]);
  const columns = useMemo(
    () => [
      {
        accessorKey: "first_name",
        header: "Name",
        Cell: ({ row }) => {
          return (
            <p>
              {row.original.first_name} {row.original.last_name}
            </p>
          );
        },
      },
      // {
      //   accessorKey: "last_name",
      //   header: "Last Name",
      // },
      {
        accessorKey: "gender_name",
        header: "Gender",
      },
      {
        accessorKey: "role_name",
        header: "Role",
      },
      {
        accessorKey: "phone",
        header: "Phone Number",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "username",
        header: "User Name"
      },
      {
        accessorKey: "address",
        header: "Address",
      },
      {
        accessorKey: "hospital_list",
        header: "Hospital Name",
        size: 350,

        // Cell: ({ row, column }) => {
        //   const filterValue = column.getFilterValue() || '';
        //   const cellValue = row.original.hospital_mapping
        //     .map((elem) => elem.hospital_name)
        //     .join(", ");
        //   return <p>{highlightText(cellValue, filterValue)}</p>;
        // },
        // filterFn: customFilterFn,
        // sortingFn: customSortFn,
      },
      {
        accessorKey: "primary_investigator_doctors",
        header: "Principal Investigators",
        size: 350,
      },
      // {
      //   accessorKey: "country_name", //normal accessorKey
      //   header: "Country",
      // },
      // {
      //   accessorKey: "city_name", //normal accessorKey
      //   header: "City",
      // },
      {
        accessorKey: "created_on", //normal accessorKey
        header: "Creation Date",
      },
      {
        accessorKey: "created_by_name",
        header: "Created By"
      },
      {
        accessorKey: "updated_on",
        header: "Updated Date"
      },
      {
        accessorKey: "updated_by_name",
        header: "Updated By"
      },
      {
        accessorKey: "is_active", //normal accessorKey
        header: "User Activity",
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return <p>{row.original.is_active ? "Active" : "Inactive"}</p>;
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: data?.GetUsers ?? [],
    ...TableConfig,
    enableRowActions: true,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },

    renderTopToolbar: ({ table }) => (
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: "0.5rem",
          p: "16px 0px",
          justifyContent: "space-between",
        })}
      >
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <MRT_GlobalFilterTextField table={table} />
        </Box>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <RbacWrapper module={"users"} code={"add"}>
            <MgpButton
              variant="contained"
              onClick={handleNew}
              endIcon={
                <NoteAddIcon
                  sx={{ marginTop: "-3px", fontSize: "16px !important" }}
                />
              }
            >
              Add User 
            </MgpButton>
          </RbacWrapper>
          <MgpButton
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => handleExportData('xlsx', "table")}
              endIcon={<FileDownloadIcon sx={{ fontSize: "16px !important" }} />}
              variant="contained"
            >
              Export Excel
            </MgpButton>
            <MgpButton
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => handleExportData('pdf', "table"
              )}
              endIcon={<FileDownloadIcon sx={{ fontSize: "16px !important" }} />}
              variant="contained"
            >
              Export PDF
            </MgpButton>
        </Box>
      </Box>
    ),
    renderRowActions: ({ row }) => {
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <RbacWrapper module={"users"} code={"edit"}>
            <Tooltip title={"Edit User"} arrow>
              <IconButton
                size="small"
                onClick={() => {
                  console.log("row.original", row.original);
                  setEdit(row.original);
                  console.log("🚀 ~ Users ~ row:", row);
                  setOpen(true);
                }}
              >
                <EditIcon sx={{ height: "16px" }} />
              </IconButton>
            </Tooltip>
          </RbacWrapper>
          <RbacWrapper module={"users"} code={"delete"}>
            {row.original.is_active && (
              <Tooltip title={"Delete User"} arrow>
                <IconButton
                  size="small"
                  onClick={() => {
                    handleDelete(row.original);
                  }}
                >
                  <DeleteIcon sx={{ height: "16px" }} />
                </IconButton>
              </Tooltip>
            )}
          </RbacWrapper>
        </Box>
      );
    },
    state: { isLoading, columnOrder: columns.map((e) => e.accessorKey) },
    renderTableBody: () => {
      return isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null;
    },
  });

  const handleDelete = (row) => {
    console.log("user_id", row.is_active);
    setDeleting(row.user_id);
  };
  if (mainPageloading) {
    return <Loader open={mainPageloading} />;
  }
  return (
    <>
      {delete_ref && (
        <AlertDialog
          open={delete_ref}
          negative={() => {
            setDeleting(false);
          }}
          positive={(param) => {
            setDeleting(false);
            setMainPageloading(true);
            DeleteUser({
              variables: { userId: Number(param) },
              onCompleted: () => {
                setMainPageloading(false);
                setAlertType("success");
                setMessage("The user has been removed successfully.");
                setAlertOption(true);
              },
              onError: () => {
                setMainPageloading(false);
              },
            });
          }}
          param={delete_ref}
          title={"User Deletion"}
          description={"Are you sure you want to delete this user?"}
        />
      )}

      <MgpModal
        title={edit.user_id ? "Update User" : "Add User"}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <UpdateUser
          setAlertOption={setAlertOption}
          setMainPageloading={setMainPageloading}
          setMessage={setMessage}
          setAlertType={setAlertType}
          edit={edit}
          closed={() => {
            setOpen(false);
          }}
        />
      </MgpModal>

      <Stack>
        <MgpText variant="h5">User List</MgpText>
        <MgpTable table={table} />
      </Stack>
      <MgpAlertBar
        open={alertOption}
        setOpen={handleSubmitClose}
        alertType={alertType}
        message={message}
      />
    </>
  );
};
