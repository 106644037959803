export const colors = {
  PRIMARY_LIGHT: "#33859C",
  PRIMARY: "#106881",
  PRIMARY_DARK: "#33859C",
  PRIMARY_CONTRAST: "#ffffff",
  SECONDARY_LIGHT: "#60A5FA",
  SECONDARY: "#3B82F6",
  SECONDARY_DARK: "#1D4ED8",
  SECONDARY_CONTRAST: "#ffffff",
  PRIMARY_TEXT: "#212121",
  SECONDARY_TEXT: "#106881",
  DEVIDER: "#BDBDBD",
  CONTRAST_TEXT: "#ffffff",
  BORDERACTIVE: "#33859C",
  BG: "#CFD8DC",
  GOLD: "#33859C",
  MENULISTPRIMARY: "#106881",
  MENULISTSECONDARY: "#BFBFBF",
  NAVBAR: "#808080",
  WHITE: "#ffffff",
  TEXTCOLOR: "#2D3317",
  TEXTBOX: "#0000FF",
  BLACK: "#000000",
  FOOTER: "linear-gradient(to right, white, teal)",
  ACCORDION: "#E6E6E6",
  ACCORDION_DISABLED: "#FDFDFD",
  ACCORDION_DISABLED_Gray: "#aaaaaa",
  ERROR: "#d32f2f",
  NOTIFICATION_BELL: "#2e7d32",
};
