import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Stack,
  CircularProgress,
} from "@mui/material";
import {
  AlertDialog,
  MgpButton,
  MgpModal,
  MgpTable,
  MgpText,
  MgpTextboxFormik,
  TableConfig,
} from "../../../components/global";
import { useEffect, useMemo, useState } from "react";
import { useRbac } from "../../../hooks";
import { Tooltip } from "@mui/material";
import {
  MRT_FilterTextField,
  MRT_GlobalFilterTextField,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable,
} from "material-react-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { UpdateHospital } from "./UpdateHospitals";
import { useHospital } from "../../../hooks/useHospital";
import Loader from "../../Loader/Loader";
import MgpAlertBar from "../../../components/global/MgpAlertBar";
import { downloadPdf } from "../../../helpers/dowloadPdf.js";
import * as XLSX from 'xlsx';
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export const Hospitals = () => {
  const [open, setOpen] = useState(false);
  const [delete_ref, setDeleting] = useState(false);
  const { useGetHospitals, useDeleteHospital } = useHospital();
  const [edit, setEdit] = useState({});
  const { RbacWrapper } = useRbac();
  const [GetHospitals, { data, loading, error }] = useGetHospitals;
  const [isLoading, setIsLoading] = useState(true);
  const [DeleteHospital] = useDeleteHospital;
  const [message, setMessage] = useState("");
  const [alertType,setAlertType] = useState("");
  const [alertOption, setAlertOption] = useState(false);
  const [mainPageloading, setMainPageloading] = useState(true);

  const handleNew = () => {
    setEdit({});
    setOpen(true);
  };
  const handleExportData = (type) => {
    const filteredData = table
    .getFilteredRowModel()
    .rows.map((row) => row.original)
    .map((obj) => {
      const filteredObj = {};
      columns.forEach((key) => {
        if (obj.hasOwnProperty(key.accessorKey)) {
          filteredObj[key.header] = obj[key.accessorKey];
         
        }
      });
      return filteredObj;
    });
  let dataToExport = filteredData ? filteredData : data.rows;
  let filename = "Hospital List";
  if (dataToExport.length) {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    // Convert the data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Calculate column widths based on headers and values
    const maxWidths = [];
    columns.forEach((col, colIndex) => {
      const header = col.header;
      maxWidths[colIndex] = header.length;
    });

    dataToExport.forEach((row) => {
      Object.keys(row).forEach((key, colIndex) => {
        const cellValue = row[key] ? row[key].toString() : '';
        maxWidths[colIndex] = Math.max(maxWidths[colIndex], cellValue.length);
      });
    });

    worksheet['!cols'] = maxWidths.map((width) => ({ wch: width + 2 }));

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
    

    // Handle file type and download
    if (type === "csv") {
      XLSX.writeFile(workbook, `${filename}.csv`, { bookType: 'csv' });
    } else if (type === "xlsx") {
      XLSX.writeFile(workbook, `${filename}.xlsx`, { bookType: 'xlsx' });
    }else {
      downloadPdf(dataToExport, filename);
    }
  }
  };
  useEffect(() => {
    GetHospitals();
  }, [GetHospitals]);
  const handleSubmitClose = () => {
    setAlertOption(false);
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "hospital_name", //access nested data with dot notation
        header: "Hospital",
        size: 330
      },
      {
        accessorKey: "address",
        header: "Address",
        size: 310

      },
      {
        accessorKey: "hospital_code",
        header: "Hospital Code",
      },
      {
        accessorKey: "site_number",
        header: "Site Number",
      },
      {
        accessorKey: "country_name", //normal accessorKey
        header: "Country",
      },
      {
        accessorKey: "region_name", //normal accessorKey
        header: "Region",
      },
      {
        accessorKey: "city_name", //normal accessorKey
        header: "City",
      },
      {
        accessorKey: "created_on", //normal accessorKey
        header: "Created On",
      },
    ],
    []
  );
  useEffect(() => {
    if (data || error) {
      setMainPageloading(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [data, error]);
  const table = useMaterialReactTable({
    columns,
    data: data?.GetHospitals ?? [],
    ...TableConfig,
    enableRowActions: true,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },

    renderTopToolbar: ({ table }) => (
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: "0.5rem",
          p: "16px 0px",
          justifyContent: "space-between",
        })}
      >
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <MRT_GlobalFilterTextField table={table} />
        </Box>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <RbacWrapper module={"hospital"} code={"add"}>
            <MgpButton
              variant="contained"
              onClick={handleNew}
              endIcon={<NoteAddIcon sx={{ marginTop: "-3px", fontSize: "16px !important" }} />}
            >
              Add Hospital
            </MgpButton>
          </RbacWrapper>
          <MgpButton
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => handleExportData('xlsx', "table")}
              endIcon={<FileDownloadIcon sx={{ fontSize: "16px !important" }} />}
              variant="contained"
            >
              Export Excel
            </MgpButton>
            <MgpButton
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => handleExportData('pdf', "table"
              )}
              endIcon={<FileDownloadIcon sx={{ fontSize: "16px !important" }} />}
              variant="contained"
            >
              Export PDF
            </MgpButton>
        </Box>
      </Box>
    ),
    renderRowActions: ({ row }) => {
      return (
        <Stack direction={"row"}>
          <RbacWrapper module={"hospital"} code={"edit"}>
            <Tooltip title={"Edit Hospital"} arrow>
              <IconButton
                size="small"
                onClick={() => {
                  setEdit(row.original);
                  console.log("🚀 ~ Hospital ~ row:", row);
                  setOpen(true);
                }}
              >
                <EditIcon sx={{ height: "16px" }} />
              </IconButton>
            </Tooltip>
          </RbacWrapper>
          <RbacWrapper module={"city"} code={"delete"}>
            <Tooltip title={"Delete Hospital"} arrow>
              <IconButton
                size="small"
                onClick={() => {
                  handleDelete(row.original);
                }}
              >
                <DeleteIcon sx={{ height: "16px" }} />
              </IconButton>
            </Tooltip>
          </RbacWrapper>
        </Stack>
      );
    },
    state: { isLoading, columnOrder: columns.map((e) => e.accessorKey) },
    renderTableBody: () => {
      return isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null;
    },
  });

  const handleDelete = (row) => {
    setDeleting(row.hospital_id);
  };
  if (mainPageloading) {
    return <Loader open={mainPageloading} />;
  }
  return (
    <>
      {delete_ref && (
        <AlertDialog
          open={true}
          negative={() => {
            setDeleting(false);
          }}
          positive={(param) => {
            setMainPageloading(true);
            DeleteHospital({
              variables: { hospitalId: Number(param) }, onCompleted: () => {
                setDeleting(false);
                setMainPageloading(false);
                setAlertOption(true);
                setAlertType("success")
                setMessage("Hospital Removed Successfully");
              }
            });
          }}
          param={delete_ref}
          title={"Hospital Deletion"}
          description={"Are you sure you want to delete this hospital?"}
        />
      )}

      <MgpModal
        title={edit.hospital_id ? "Update Hospital" : "Add Hospital"}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <UpdateHospital
          setAlertOption={setAlertOption}
          setMainPageloading={setMainPageloading}
          setMessage={setMessage}
          setAlertType = {setAlertType}
          edit={edit}
          closed={() => {
            setOpen(false);
          }}
        />
      </MgpModal>

      <Stack>
        <MgpText variant="h5">Hospital List</MgpText>
        <MgpTable table={table} />
      </Stack>
      <MgpAlertBar
        open={alertOption}
        setOpen={handleSubmitClose}
        alertType={alertType}
        message={message}
      />
    </>
  );
};
