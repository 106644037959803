import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { ProcessGrapqlError } from "../helpers";

export const useMedicationRequest = () => {
  const ADD_MEDICATION_REQUEST = gql`
    mutation AddMedicationRequest(
      $requests: [MedicationInput!]!
      $encounterId: Int!
    ) {
      AddMedicationRequest(requests: $requests, encounter_id: $encounterId) {
        details {
          id
          request_id
          medicine
          regimen
          comment
          medication_type
          old_medicine
          new_medicine
          other_regimen
          notes
          reason_for_medication
        }
      }
    }
  `;

  const GET_MEDICATION_REQUEST = gql`
    query GetMedicationRequests {
      GetMedicationRequests {
        request_id
        encounter_id
        status
        comment
        dosage
        regimen
        medication_type
        old_medicine
        new_medicine
        created_on
      }
    }
  `;

  const DELETE_MEDICATION_REQUEST = gql`
    mutation DeleteMedicationRequest($requestId: Int!) {
      DeleteMedicationRequest(request_id: $requestId) {
        request_id
        encounter_id
        status
        comment
        created_on
      }
    }
  `;

  const UPDATE_MEDICATION_REQUEST = gql`
    mutation UpdateMedicationRequest($encounterId: Int!) {
      UpdateMedicationRequest(encounter_id: $encounterId) {
        request_id
        encounter_id
        status
        comment
        created_on
      }
    }
  `;

  const GET_PATIENTS_MEDICATION_REQUESTS = gql`
    query GetPatientsMedicationRequests {
      GetPatientsMedicationRequests {
        request_id
        dispensed_date
        dispensed_comment
        status
        created_on
        updated_on
        doctor_name
        patient_name
        hospital_name
        details
        national_id
        registry_no
        status_enum
      }
    }
  `;

  const GET_PATIENTS_MEDICATION_REQUEST_BY_PATIENT_ID = gql`
    query GetPatientsMedicationRequests($patientId: Int!) {
      GetPatientsMedicationRequestsByPatientId(patient_id: $patientId) {
        request_id
        dispensed_date
        dispensed_comment
        status
        created_on
        updated_on
        doctor_name
        patient_name
        hospital_name
        details
        national_id
        registry_no
        status_enum
      }
    }
  `;

  const UPDATE_PATIENTS_MEDICATION_REQUEST_STATUS = gql`
    mutation UpdatePatientsMedicationRequestStatus(
      $requestId: Int!
      $status: Int
      $comment: String
    ) {
      UpdatePatientsMedicationRequestStatus(
        request_id: $requestId
        status: $status
        comment: $comment
      ) {
        request_id
        dispensed_date
        dispensed_comment
        status
        created_on
        updated_on
        doctor_name
        patient_name
        hospital_name
        details
        national_id
        registry_no
        status_enum
      }
    }
  `;

  const GET_MEDICATION_VOTE = gql`
    query GetMedicationVote($request_id: Int!) {
      GetMedicationVote(request_id: $request_id) {
        request_id
        user_name
        status
        comment
        created_on
      }
    }
  `;

  const GET_LAST_MEDICATION_REQUEST_BY_PATIENTID = gql`
    query GetLastMedicationRequest($patientId: Int!) {
      GetLastMedicationRequest(patient_id: $patientId) {
        request_id
        encounter_id
        status
        dispensed_date
        dispensed_comment
        updated_on
        id
        is_active
        comment
        dosage
        regimen
        medicine
        medication_type
        old_medicine
        new_medicine
        created_on
        other_regimen
        reason_for_medication
        notes
        status_enum
      }
    }
  `;

  const GET_GETPATIENTSMEDICATIONBYPATIENTID = gql`
    query GetPatientsMedicationRequestsByPatientId($patientId: Int!) {
      GetPatientsMedicationRequestsByPatientId(patient_id: $patientId) {
        request_id
        encounter_id
        dispensed_date
        dispensed_comment
        status
        created_on
        updated_on
        doctor_name
        patient_name
        hospital_name
        details {
          id
          request_id
          medicine
          dosage
          regimen
          comment
          medication_type
          old_medicine
          new_medicine
          other_regimen
          notes
          reason_for_medication
        }
        national_id
        registry_no
        status_enum
      }
    }
  `;

  const useAddMedicationRequests = useMutation(ADD_MEDICATION_REQUEST, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache) {
      // const existing = cache.readQuery({
      //   query: GET_GETPATIENTSMEDICATIONBYPATIENTID,
      //   variables: { patientId: parseInt(AddMedicationRequest.patient_id) },
      // });
      // console.log("errrr", existing);
      // const newData = [
      //   ...existing.GetPatientsMedicationRequestsByPatientId,
      //   AddMedicationRequest,
      // ];
      // cache.writeQuery({
      //   query: GET_GETPATIENTSMEDICATIONBYPATIENTID,
      //   data: { GetPatientsMedicationRequestsByPatientId: newData },
      // });
    },
  });
  const useUpdateMedicationRequest = useMutation(UPDATE_MEDICATION_REQUEST, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { UpdateMedicationRequest } }) {
      const existing = cache.readQuery({ query: GET_MEDICATION_REQUEST });
      const newData = existing.GetMedicationRequests.map((item) =>
        item.request_id !== UpdateMedicationRequest.request_id
          ? item
          : UpdateMedicationRequest
      );
      cache.writeQuery({
        query: GET_MEDICATION_REQUEST,
        data: { GetMedicationRequests: newData },
      });
    },
  });
  const useDeleteMedicationRequest = useMutation(DELETE_MEDICATION_REQUEST, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { DeleteMedicationRequest } }) {
      const existing = cache.readQuery({ query: GET_MEDICATION_REQUEST });
      const newMedicationRequests = existing.GetMedicationRequests.filter(
        (item) => item.request_id !== DeleteMedicationRequest.request_id
      );
      cache.writeQuery({
        query: GET_MEDICATION_REQUEST,
        data: { GetMedicationRequests: newMedicationRequests },
      });
    },
  });

  const useUpdatePatientsMedicationRequestStatus = useMutation(
    UPDATE_PATIENTS_MEDICATION_REQUEST_STATUS,
    {
      onError(error) {
        ProcessGrapqlError(error);
      },
      update(cache, { data: { UpdatePatientsMedicationRequestStatus } }) {
        const existing = cache.readQuery({
          query: GET_PATIENTS_MEDICATION_REQUESTS,
        });
        const newData = existing.GetPatientsMedicationRequests.map((item) =>
          item.request_id !== UpdatePatientsMedicationRequestStatus.request_id
            ? item
            : UpdatePatientsMedicationRequestStatus
        );
        cache.writeQuery({
          query: GET_PATIENTS_MEDICATION_REQUESTS,
          data: { GetPatientsMedicationRequests: newData },
        });
      },
    }
  );

  const useGetMedicationRequests = useLazyQuery(GET_MEDICATION_REQUEST);
  const useGetPatientsMedicationRequests = useLazyQuery(
    GET_PATIENTS_MEDICATION_REQUESTS
  );
  const useGetMedicationVote = useLazyQuery(GET_MEDICATION_VOTE);
  const useGetLastMedicationRequest = useLazyQuery(
    GET_LAST_MEDICATION_REQUEST_BY_PATIENTID
  );
  const useGetPatientsMedicationRequestsByPatientId = useLazyQuery(
    GET_GETPATIENTSMEDICATIONBYPATIENTID
  );

  return {
    useAddMedicationRequests,
    useGetMedicationRequests,
    useUpdateMedicationRequest,
    useDeleteMedicationRequest,
    useGetPatientsMedicationRequests,
    useUpdatePatientsMedicationRequestStatus,
    useGetMedicationVote,
    useGetLastMedicationRequest,
    useGetPatientsMedicationRequestsByPatientId,
  };
};
