import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import { useState } from "react";
import { useRoutes } from "react-router-dom";
import theme from "../theme";
import routes from "./routes";
import { ApolloProvider } from "@apollo/client";
import { StorageHelper, apollo_client } from "../helpers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "rsuite/dist/styles/rsuite-default.css";
import { useIdleTimer } from "react-idle-timer";
import { MgpSeesionTimeOutWarning } from "../components/global";
import MgpIdleLogout from "../components/global/MgpIdleLogout";
import Cookies from "js-cookie";

export const App = () => {
  const routing = useRoutes(routes);
  const [open, setOpen] = useState(false);
  // const onIdle = (event) => {
  //   if (window.location.href.includes("login")) {
  //     // pause();
  //     setOpen(false);
  //   } else {
  //     // start();
  //     setOpen(true);
  //   }
  // };

  const onActive = (event) => {
    // start();
    // setOpen(false);
  };

  const onAction = (event) => {
    // start();
    // setOpen(false);
  };

  // const {
  //   start,
  //   reset,
  //   activate,
  //   pause,
  //   resume,
  //   isIdle,
  //   isPrompted,
  //   isLeader,
  //   isLastActiveTab,
  //   getTabId,
  //   getRemainingTime,
  //   getElapsedTime,
  //   getLastIdleTime,
  //   getLastActiveTime,
  //   getIdleTime,
  //   getTotalIdleTime,
  //   getActiveTime,
  //   getTotalActiveTime,
  // } = useIdleTimer({
  //   onIdle,
  //   onActive,
  //   onAction,
  //   timeout: 0.2 * 60 * 1000, // 15 minute idle timeout
  //   promptBeforeIdle : 1000 * 1
  // });

  const onClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    // alert("You have been logged out due to inactivity.");
    StorageHelper.remove("subinvestigator_id");
    StorageHelper.remove("sub_investigator");
    StorageHelper.remove("user");
    StorageHelper.remove("default_page");
    StorageHelper.remove("assigned_hospitals");
    StorageHelper.remove("token");
    StorageHelper.remove("default_page");
    StorageHelper.clear();
    Cookies.remove("userMgpr");
    window.location.href = "/login";
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={apollo_client}>{routing}</ApolloProvider>
        </ThemeProvider>
      </LocalizationProvider>
      <MgpIdleLogout onLogout={handleLogout} />
      {/* {open && (
        <MgpSeesionTimeOutWarning open={open} onClose={onClose}></MgpSeesionTimeOutWarning>
      )} */}
    </>
  );
};
