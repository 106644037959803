import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { MgpText } from './MgpText';

export const MgpBackdrop=({openBackdrop,message})=>{
    return (<Backdrop
        sx={{ color: '#fff',display:"flex",flexDirection:"column", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
        <MgpText variant="h4" sx={{margin:"15px"}}>{message}</MgpText>
      </Backdrop>)
}