import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { ProcessGrapqlError } from "../helpers";

export const usePatient = (args) => {
  const isActive = args?.isActive ? args.isActive : true;
  const ADD_PATIENT = gql`
    mutation AddPatient($input: PatientInput) {
      AddPatient(input: $input) {
        patient_id
        first_name
        last_name
        email
        mobile
        national_id
        mrn_no
        gender
        dob
        age
        nationality
        country
        city
        region
        marital
        education
        employment
        smoking_status
        is_active
        created_on
        updated_on
        registry_no
        status_value
        is_the_patient_alive
        date_of_death
        death_related_cause
        cause_of_death
        others_specify
        consentform
      }
    }
  `;

  const GET_PATIENTS = gql`
    query GetPatients($isActive: Boolean, $hospitalId: ID, $self: Boolean) {
      GetPatients(is_active: $isActive, hospital_id: $hospitalId, self: $self) {
        patient_id
        encounter_id
        first_name
        last_name
        email
        mobile
        national_id
        mrn_no
        gender
        dob
        nationality
        country
        city
        region
        marital
        education
        employment
        smoking_status
        is_active
        created_on
        updated_on
        registry_no
        firstVisit
        lastVisit
        totalVisits
        hospital_name
        doctor_name
        status
        is_transferred
        status_value
        is_the_patient_alive
        date_of_death
        typeofvisit
        death_related_cause
        cause_of_death
        others_specify
        form_completed
        reason
        follow_up_count
        reminder_flag
        reminder_flag_enum
        hospital_code
        hospital_city
        consentform
        created_by
        updated_by
        created_by_name
        updated_by_name
      }
    }
  `;

  const GET_PATIENTS_RECORDS = gql`
    query GetPatientRecords(
      $isActive: Boolean
      $hospitalId: ID
      $self: Boolean
    ) {
      GetPatientRecords(
        is_active: $isActive
        hospital_id: $hospitalId
        self: $self
      ) {
        patient_id
        encounter_id
        first_name
        last_name
        email
        mobile
        national_id
        mrn_no
        gender
        dob
        nationality
        country
        city
        region
        marital
        education
        employment
        smoking_status
        is_active
        created_on
        updated_on
        registry_no
        firstVisit
        lastVisit
        totalVisits
        hospital_name
        doctor_name
        status
        is_transferred
        status_value
        is_the_patient_alive
        date_of_death
        typeofvisit
        death_related_cause
        cause_of_death
        others_specify
        form_completed
        reason
        follow_up_count
        reminder_flag
        reminder_flag_enum
        hospital_code
        hospital_city
        consentform
      }
    }
  `;

  const GET_PATIENTS_DASHBOARD = gql`
    query GetPatientsDashboard($filters: FiltersInput, $self: String) {
      GetPatientsDashboard(filters: $filters, self: $self) {
        patient_id
        registry_no
        firstvisit
        lastvisit
        totalvisits
        status
        status_value
        follow_up_count
      }
    }
  `;

  const DELETE_PATIENT = gql`
    mutation DeletePatient($patientId: ID!, $reason: String) {
      DeletePatient(patient_id: $patientId, reason: $reason) {
        patient_id
        encounter_id
        first_name
        last_name
        email
        mobile
        national_id
        mrn_no
        gender
        dob
        age
        nationality
        country
        city
        region
        marital
        education
        employment
        smoking_status
        is_active
        created_on
        updated_on
        registry_no
        firstVisit
        lastVisit
        totalVisits
        hospital_name
        doctor_name
        status
        status_value
        is_transferred
        is_draft
        is_the_patient_alive
        date_of_death
        death_related_cause
        cause_of_death
        others_specify
        reason
        consentform
      }
    }
  `;
  const UPDATE_LIVING_STATUS = gql`
    mutation UpdatePatientLivingStatus(
      $patientId: ID!
      $input: LivingStatusInput
    ) {
      UpdatePatientLivingStatus(patient_id: $patientId, input: $input) {
        patient_id
        encounter_id
        first_name
        last_name
        email
        mobile
        national_id
        mrn_no
        gender
        dob
        age
        nationality
        country
        city
        region
        marital
        education
        employment
        smoking_status
        is_active
        created_on
        updated_on
        registry_no
        firstVisit
        lastVisit
        totalVisits
        hospital_name
        doctor_name
        status
        status_value
        is_transferred
        is_draft
        is_the_patient_alive
        date_of_death
        death_related_cause
        cause_of_death
        others_specify
        consentform
      }
    }
  `;
  const UPDATE_PATIENT = gql`
    mutation UpdatePatient(
      $patientId: ID!
      $input: PatientInput
      $isAutoSave: Boolean
    ) {
      UpdatePatient(
        patient_id: $patientId
        input: $input
        is_auto_save: $isAutoSave
      ) {
        patient_id
        first_name
        last_name
        email
        mobile
        national_id
        mrn_no
        gender
        dob
        age
        nationality
        country
        city
        region
        marital
        education
        employment
        smoking_status
        is_active
        created_on
        updated_on
        registry_no
        firstVisit
        lastVisit
        totalVisits
        hospital_name
        doctor_name
        status
        status_value
        is_transferred
        is_draft
        is_the_patient_alive
        date_of_death
        death_related_cause
        cause_of_death
        others_specify
        consentform
      }
    }
  `;

  const VIEW_PATIENT = gql`
    query ViewPatient($patientId: Int!) {
      ViewPatient(patient_id: $patientId) {
        patient_id
        firstname: first_name
        lastname: last_name
        email
        phonenumber: mobile
        nationalid: national_id
        mrnno: mrn_no
        gender
        dateofbirth: dob
        age
        nationality
        countrylist: country
        city
        region
        maritalstatus: marital
        educationallevel: education
        employment
        smokerstatus: smoking_status
        is_active
        created_on
        updated_on
        registryno: registry_no

        firstVisit
        lastVisit
        totalVisits
        hospital_name
        doctor_name
        status
        status_value
        is_transferred
        is_draft
        is_the_patient_alive
        date_of_death
        death_related_cause
        cause_of_death
        others_specify
        consentform
      }
    }
  `;

  const IS_NATIONAL_ID_EXISTS = gql`
    query Query($nationalId: String!) {
      IsNationalIdExists(national_id: $nationalId){
      patient_id
      user_id
      }
    }
  `;

  const useAddPatient = useMutation(ADD_PATIENT, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    // update(cache, { data: { AddPatient } }) {
    //   const existing = cache.readQuery({ query: GET_PATIENTS,
    //     variables: { isActive } });
    //   const newData = [...existing.GetPatients, AddPatient];
    //   cache.writeQuery({
    //     query: GET_PATIENTS,
    //     variables: { isActive },
    //     data: { GetPatients: newData },
    //   });
    // },
  });

  const useUpdatePatientLivingStatus = useMutation(UPDATE_LIVING_STATUS, {
    onError(error) {
      ProcessGrapqlError(error);
    },
  });
  const useUpdatePatient = useMutation(UPDATE_PATIENT, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    // update(cache, { data: { UpdatePatient } }) {
    //   const existing = cache.readQuery({
    //     query: GET_PATIENTS,
    //     variables: { isActive },
    //   });
    //   const newData = existing.GetPatients.map((item) =>
    //     item.patient_id !== UpdatePatient.patient_id ? item : UpdatePatient
    //   );
    //   cache.writeQuery({
    //     query: GET_PATIENTS,
    //     variables: { isActive },
    //     data: { GetPatients: newData },
    //   });
    // },
  });
  const useDeletePatient = useMutation(DELETE_PATIENT, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { DeletePatient } }) {
      const existing = cache.readQuery({
        query: GET_PATIENTS,
        variables: { isActive },
      });
      const newPatients = existing.GetPatients.filter(
        (item) => item.patient_id !== DeletePatient.patient_id
      );
      cache.writeQuery({
        query: GET_PATIENTS,
        variables: { isActive },
        data: { GetPatients: newPatients },
      });
    },
  });
  const useGetPatients = useLazyQuery(GET_PATIENTS);
  const useGetPatientsRecords = useLazyQuery(GET_PATIENTS_RECORDS);
  const useGetPatientsDashboard = useLazyQuery(GET_PATIENTS_DASHBOARD);
  const useViewPatient = useLazyQuery(VIEW_PATIENT);
  const useNationalIdCheck = useLazyQuery(IS_NATIONAL_ID_EXISTS);

  return {
    useAddPatient,
    useGetPatients,
    useUpdatePatient,
    useDeletePatient,
    useViewPatient,
    useNationalIdCheck,
    useUpdatePatientLivingStatus,
    useGetPatientsDashboard,
    useGetPatientsRecords,
  };
};
