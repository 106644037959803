import * as React from "react";
import { MgpButton, MgpText, MgpTextboxFormik } from "../components/global";
import { Container, Grid } from "@mui/material";
import * as yup from "yup";
import { Formik } from "formik";
import { useAuth } from "../hooks";
import { MgpBackdrop } from "../components/global/MgpBackdrop";
import MgpAlertBar from "../components/global/MgpAlertBar";
import { useNavigate } from "react-router-dom";

export const ForgotPassword = ({ handleClose }) => {
  const { useCheckEmailExistence, useSendResetPasswordLink,useCheckUserExistence } = useAuth();
  const [isVerifiedEmail, setVerifiedEmail] = React.useState(false);
  const [CheckEmailExistence] = useCheckEmailExistence;
  const [CheckUsernameExistence] = useCheckUserExistence;
  const [SendResetPasswordLink] = useSendResetPasswordLink;
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [alertType, setAlertType] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [alertOption, setAlertOption] = React.useState(false);
  const handleSubmitClose = () => {
    setAlertOption(false);
  };
  const navigate = useNavigate();
  return (
    <Container fluid="true">
      <Formik
        initialValues={{
          username: "",
        }}
        validationSchema={yup.object().shape({
          username: yup
            .string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        })}
        onSubmit={(payload) => {
          setOpenBackdrop(true);

          CheckUsernameExistence({
            variables: {
              username: payload.username.toLowerCase().trim(),
            },
            onCompleted: ({ CheckUsernameExistence }) => {
              setOpenBackdrop(false);
              if (CheckUsernameExistence) {
                setVerifiedEmail(true);
                SendResetPasswordLink({
                  variables: {
                    username: payload.username.toLowerCase().trim(),
                  },
                  onCompleted: ({ SendResetPasswordLink }) => {
                    if (SendResetPasswordLink) {
                      setAlertOption(true)
                      setAlertType("success")
                      setMessage("Successfully sent the reset link.Please check your mail!")
                      setTimeout(() => {
                        setAlertOption(false);
                        setAlertType("")
                        setMessage("")
                        setOpenBackdrop(false);
                        handleClose(true);
                        navigate('/login')
                      }, 3000)
                    }
                  },
                  onError: ({ error }) => {
                    console.log("error", JSON.stringify(error));
                    setAlertOption(true)
                    setAlertType("error")
                    setMessage("Sorry unable to send mail. Please try after sometime.")
                    setOpenBackdrop(false);
                    setTimeout(() => {
                      setAlertOption(false);
                      setAlertType("")
                      setMessage("")
                    }, 3000)
                  },
                });
              } else {
                setAlertOption(true)
                setAlertType("error")
                setMessage("Username does not exists! Please contact ITKAN")
                setTimeout(() => {
                  setAlertOption(false);
                  setAlertType("")
                  setMessage("")
                }, 3000)
              }
            },
            onError: ({ error }) => {
              console.log("error", JSON.stringify(error));
              setAlertOption(true)
              setAlertType("error")
              setMessage("Sorry unable to send mail. Please try after sometime.")
              setOpenBackdrop(false);
              setTimeout(() => {
                setAlertOption(false);
                setAlertType("")
                setMessage("")
              }, 3000)
            },
          })

        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          values,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <MgpText variant="h4">Forgot Password</MgpText>
              </Grid>

              <Grid item md={12}>
                <MgpTextboxFormik
                  fullWidth
                  disabled={isVerifiedEmail}
                  size="small"
                  label="Username"
                  name="username"
                  values={values}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  variant="outlined"
                  touched={touched}
                  errors={errors}
                />
              </Grid>
              <Grid item md={12}>

                <MgpButton type="submit" variant="contained" fullWidth>
                  Send Reset Link
                </MgpButton>
                <MgpBackdrop
                  openBackdrop={openBackdrop}
                  message={"Sending Link..."}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <MgpAlertBar
        open={alertOption}
        setOpen={handleSubmitClose}
        alertType={alertType}
        message={message}
      />
    </Container>
  );
};
