import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Stack,
  CircularProgress,
} from "@mui/material";
import { UpdateRegion } from "./UpdateRegion";
import {
  AlertDialog,
  MgpButton,
  MgpModal,
  MgpTable,
  MgpText,
  TableConfig,
} from "../../../components/global";
import { useEffect, useMemo, useState } from "react";
import { useRegion, useRbac } from "../../../hooks";
import {
  MRT_GlobalFilterTextField,
  useMaterialReactTable,
} from "material-react-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../Loader/Loader";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import MgpAlertBar from "../../../components/global/MgpAlertBar";
import { downloadPdf } from "../../../helpers/dowloadPdf.js";
import * as XLSX from 'xlsx';
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export const Region = () => {
  const [open, setOpen] = useState(false);
  const [delete_ref, setDeleting] = useState(false);
  const [alertOption, setAlertOption] = useState(false);
  const [alertType,setAlertType] = useState("");
  const [message, setMessage] = useState(false);
  const [mainPageloading, setMainPageloading] = useState(true);
  const { useGetRegions, useDeleteRegion } = useRegion();
  const [edit, setEdit] = useState({});
  const { RbacWrapper } = useRbac();
  const [GetRegions, { data, loading, error }] = useGetRegions;

  const [isLoading, setIsLoading] = useState(true);
  const [DeleteRegion] = useDeleteRegion;
  const handleNew = () => {
    setEdit({});
    setOpen(true);
  };
  const handleExportData = (type) => {
    const filteredData = table
    .getFilteredRowModel()
    .rows.map((row) => row.original)
    .map((obj) => {
      const filteredObj = {};
      columns.forEach((key) => {
        if (obj.hasOwnProperty(key.accessorKey)) {
          filteredObj[key.header] = obj[key.accessorKey];
         
        }
      });
      return filteredObj;
    });
  let dataToExport = filteredData ? filteredData : data.rows;
  let filename = "Region List";
  if (dataToExport.length) {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    // Convert the data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);

    // Calculate column widths based on headers and values
    const maxWidths = [];
    columns.forEach((col, colIndex) => {
      const header = col.header;
      maxWidths[colIndex] = header.length;
    });

    dataToExport.forEach((row) => {
      Object.keys(row).forEach((key, colIndex) => {
        const cellValue = row[key] ? row[key].toString() : '';
        maxWidths[colIndex] = Math.max(maxWidths[colIndex], cellValue.length);
      });
    });

    worksheet['!cols'] = maxWidths.map((width) => ({ wch: width + 2 }));

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
    

    // Handle file type and download
    if (type === "csv") {
      XLSX.writeFile(workbook, `${filename}.csv`, { bookType: 'csv' });
    } else if (type === "xlsx") {
      XLSX.writeFile(workbook, `${filename}.xlsx`, { bookType: 'xlsx' });
    }else {
      downloadPdf(dataToExport, filename);
    }
  }
  };
  useEffect(() => {
    GetRegions();
  }, [GetRegions]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "region_name", //access nested data with dot notation
        header: "Region",
      },
      {
        accessorKey: "master_region_name", //access nested data with dot notation
        header: "Greater Region",
      },
      {
        accessorKey: "region_code", //access nested data with dot notation
        header: "Region Code",
      },
      {
        accessorKey: "created_on", //normal accessorKey
        header: "Created On",
      },
    ],
    []
  );
  useEffect(() => {
    if (data || error) {
      setMainPageloading(false);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [data, error]);
  const table = useMaterialReactTable({
    columns,
    data: data?.GetRegions ?? [],
    ...TableConfig,
    enableRowActions: true,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },

    renderTopToolbar: ({ table }) => (
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: "0.5rem",
          p: "16px 0px",
          justifyContent: "space-between",
        })}
      >
        <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <MRT_GlobalFilterTextField table={table} />
        </Box>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <RbacWrapper module={"region"} code={"add"}>
            <MgpButton
              variant="contained"
              onClick={handleNew}
              endIcon={<NoteAddIcon sx={{ marginTop: "-3px",fontSize:"16px !important" }} />}
            >
              Add Region
            </MgpButton>
          </RbacWrapper>
          <MgpButton
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => handleExportData('xlsx', "table")}
              endIcon={<FileDownloadIcon sx={{ fontSize: "16px !important" }} />}
              variant="contained"
            >
              Export Excel
            </MgpButton>
            <MgpButton
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={() => handleExportData('pdf', "table"
              )}
              endIcon={<FileDownloadIcon sx={{ fontSize: "16px !important" }} />}
              variant="contained"
            >
              Export PDF
            </MgpButton>
        </Box>
      </Box>
    ),
    renderRowActions: ({ row }) => {
      return (
        <>
          <RbacWrapper module={"region"} code={"edit"}>
            <IconButton
              size="small"
              onClick={() => {
                setEdit(row.original);
                setOpen(true);
              }}
            >
              <EditIcon  sx={{height: "16px"}}/>
            </IconButton>
          </RbacWrapper>
          <RbacWrapper module={"region"} code={"delete"}>
            <IconButton
              size="small"
              onClick={() => {
                handleDelete(row.original);
              }}
            >
              <DeleteIcon  sx={{height: "16px"}}/>
            </IconButton>
          </RbacWrapper>
        </>
      );
    },
    state: { isLoading ,columnOrder:columns.map((e)=>e.accessorKey)},
    renderTableBody: () => {
      return isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : null;
    },
  });

  const handleSubmitClose = () => {
    setAlertOption(false);
  };

  const handleDelete = (row) => {
    setDeleting(row.region_id);
  };
  if (mainPageloading) {
    return <Loader open={mainPageloading} />;
  }
  return (
    <>
      {delete_ref && (
        <AlertDialog
          open={true}
          negative={() => {
            setDeleting(false);
          }}
          positive={(param) => {
            setMainPageloading(true);
            DeleteRegion({
              variables: { regionId: Number(param) },
              onCompleted: () => {
                setDeleting(false);
                setMainPageloading(false);
                setAlertOption(true);
                setAlertType("success")
                setMessage("Region Removed Successfully");
              },
            });
          }}
          param={delete_ref}
          title={"Region Deletion"}
          description={"Are you sure you want to delete this region?"}
        />
      )}

      <MgpModal
        title={edit.region_id ? "Update Region" : "Add Region"}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <UpdateRegion
          edit={edit}
          setMainPageloading={setMainPageloading}
          setAlertOption={setAlertOption}
          setAlertType = {setAlertType}
          setMessage={setMessage}
          closed={() => {
            setOpen(false);
          }}
        />
      </MgpModal>

      <Stack>
        <MgpText variant="h5">Region List</MgpText>
        <MgpTable table={table} />
      </Stack>

      <MgpAlertBar
        open={alertOption}
        setOpen={handleSubmitClose}
        alertType={alertType}
        message={message}
      />
    </>
  );
};
