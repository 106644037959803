import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { ProcessGrapqlError, apollo_client } from "../helpers";

export const useRegion = () => {
  const ADD_REGION = gql`
    mutation Mutation($regionName: String!, $masterRegionId: Int!, $regionCode:String) {
      AddRegion(region_name: $regionName, master_region_id: $masterRegionId, region_code: $regionCode) {
        region_id
        master_region_id
        master_region_name
        region_name
        region_code
        is_active
        created_on
        updated_on
      }
    }
  `;

  const GET_REGIONS = gql`
    query Query {
      GetRegions {
        region_id
        master_region_id
        master_region_name
        region_name
        region_code
        is_active
        created_on
        updated_on
      }
    }
  `;

  const DELETE_REGION = gql`
    mutation Mutation($regionId: Int!) {
      DeleteRegion(region_id: $regionId) {
        region_id
      }
    }
  `;

  const UPDATE_REGION = gql`
    mutation UpdateRegion(
      $regionId: Int!
      $regionName: String!
      $masterRegionId: Int!
      $regionCode:String
    ) {
      UpdateRegion(
        region_id: $regionId
        region_name: $regionName
        region_code:$regionCode
        master_region_id: $masterRegionId
      ) {
        region_id
        master_region_id
        master_region_name
        region_code
        region_name
        is_active
        created_on
        updated_on
      }
    }
  `;
  const useAddRegion = useMutation(ADD_REGION, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { AddRegion } }) {
      try {
        const existingData = cache.readQuery({ query: GET_REGIONS });
        if (existingData) {
          const newData = [...existingData.GetRegions, AddRegion];
          cache.writeQuery({
            query: GET_REGIONS,
            data: { GetRegions: newData },
          });
        } else {
          // Refetch data if it's not available in the cache
          apollo_client
            .query({
              query: GET_REGIONS,
            })
            .then(({ data }) => {
              console.log("data", data);
              if (data && data.GetRegions) {
                const newData = [...data.GetRegions, AddRegion];
                cache.writeQuery({
                  query: GET_REGIONS,
                  data: { GetRegions: newData },
                });
              }
            })
            .catch((error) => {
              console.error("Error while refetching data:", error);
            });
        }
      } catch (error) {
        console.error(
          "An error occurred while updating the cache after updating a country:"
        );
      }
    },
  });
  const useUpdateRegion = useMutation(UPDATE_REGION, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { UpdateRegion } }) {
      try {
        const existingData = cache.readQuery({ query: GET_REGIONS });
        if (existingData) {
          const newData = existingData.GetRegions.map((item) =>
            item.region_id !== UpdateRegion.region_id ? item : UpdateRegion
          );
          cache.writeQuery({
            query: GET_REGIONS,
            data: { GetRegions: newData },
          });
        } else {
          // Refetch data if it's not available in the cache
          apollo_client
            .query({
              query: GET_REGIONS,
            })
            .then(({ data }) => {
              if (data && data.GetRegions) {
                const newData = data.GetRegions.map((item) =>
                  item.region_id !== UpdateRegion.region_id
                    ? item
                    : UpdateRegion
                );
                cache.writeQuery({
                  query: GET_REGIONS,
                  data: { GetRegions: newData },
                });
              }
            })
            .catch((error) => {
              console.error("Error while refetching data:", error);
            });
        }
      } catch (error) {
        console.error(
          "An error occurred while updating the cache after updating a country:"
        );
      }
    },
  });
  const useDeleteRegion = useMutation(DELETE_REGION, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { DeleteRegion } }) {
      const existing = cache.readQuery({ query: GET_REGIONS });
      if(existing){
        const newRegions = existing?.GetRegions?.filter(
          (item) => item.region_id !== DeleteRegion.region_id
        );
        cache.writeQuery({
          query: GET_REGIONS,
          data: { GetRegions: newRegions },
        });
      } else {
        // Refetch data if it's not available in the cache
        apollo_client
          .query({
            query: GET_REGIONS,
          })
          .then(({ data }) => {
            if (data && data.GetRegions) {
              const newData = data.GetRegions.map((item) =>
                item.region_id !== DeleteRegion.region_id
                  ? item
                  : DeleteRegion
              );
              cache.writeQuery({
                query: GET_REGIONS,
                data: { GetRegions: newData },
              });
            }
          })
          .catch((error) => {
            console.error("Error while refetching data:", error);
          });
      }
      
    },
  });

  const useGetRegions = useLazyQuery(GET_REGIONS);

  return {
    useAddRegion,
    useGetRegions,
    useUpdateRegion,
    useDeleteRegion,
  };
};
