import { embedDashboard } from "@superset-ui/embedded-sdk";
import React, { useEffect, useState } from "react";
import { useReport } from "../hooks/useReport";
import { MgpButton, MgpDD } from "../components/global";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Tab, Box, Grid } from "@mui/material";
import { DashboardFilter } from "./DashboardFilter";
import { ENV, StorageHelper } from "../helpers";
import dashboard from "../helpers/dashboards.json";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { PatientsDashboard } from "../../src/views/PatientsDashboard";
import { HospitalsDashboard } from "../../src/views/HospitalsDashboard";
import { useMaster } from "../hooks";

const requestLoginData = {
  username: "admin",
  password: "admin",
  provider: "db",
};

export const EmbedReport = () => {
  const { hospitals } = useMaster();
  const { useGetGuestToken } = useReport();
  const [self, setSelf] = useState("1");
  const [self2, setSelf2] = useState("1");
  const [GetGuestToken, { data, loading, error }] = useGetGuestToken;
  const REFRESH_THRESHOLD_MS = 5000; // 60 * 2 * 1000; // 5 minutes before expiry
  const [isGraphFilterOpen, setGraphFilterOpen] = useState(false);
  const [isTableFilterOpen, setTableFilterOpen] = useState(false);
  const [isPatientTableFilterOpen, setPatientTableFilterOpen] = useState(false);
  const [mainPageloading, setMainPageloading] = useState(true);
  const [value, setValue] = useState("1");
  const [hospitalId, setHospitalId] = useState(false);
  const [hospitalName, setHospitalName] = useState("");
  const assigned_hospitals = JSON.parse(
    StorageHelper.get("assigned_hospitals")
  );
  const [viewType, setViewType] = useState("graph");

  let guestToken;
  let tokenExpirationTimeout;
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedOption2, setSelectedOption2] = useState({});
  const [selectedOption3, setSelectedOption3] = useState({});
  async function refreshGuestToken(filters) {
    let filter = filters;
    if (!filters) {
      filter = selectedOption;
    }
    try {
      const token = await GetGuestTokenApi(filter);
      const expiresIn = 300000;
      guestToken = token;
      if (tokenExpirationTimeout) {
        clearTimeout(tokenExpirationTimeout);
      }
      tokenExpirationTimeout = setTimeout(refreshGuestToken, expiresIn * 0.9);
      return guestToken;
    } catch (error) {
      console.log("error", error);
      setTimeout(refreshGuestToken, 5000);
      return guestToken;
    }
  }

  const GetGuestTokenApi = (filters = {}) => {
    return new Promise((resolve, reject) => {
      GetGuestToken({
        variables: {
          dashboardType: "homeDashboard",
          self: self === "1" ? true : false,
          filters: filters ? filters : {},
        },
        fetchPolicy: "network-only",
        onCompleted: (res) => {
          console.log("res", res);
          resolve(res.GetGuestToken.token);
        },
        onError: (err) => {
          reject(err);
        },
      });
    });
  };

  useEffect(() => {
    embeddingReport();
  }, [self]);

  const embeddingReport = (filters) => {
    refreshGuestToken(filters).then((token) => {
      embedDashboard({
        id: dashboard.find(
          (elem) =>
            elem.roleId ===
            parseInt(JSON.parse(StorageHelper.get("user"))?.role_id)
        )?.homeDashboardId, // given by the Superset embedding UI
        supersetDomain: ENV.superset_host,
        mountPoint: document.getElementById("dashboard-container"), // any html element that can contain an iframe
        fetchGuestToken: () => token,
        dashboardUiConfig: {
          hideTitle: true,
          hideTab: true,
          hideChartControls: false,
          filters: {
            //gender: true,
            visible: false,
            expanded: false,
          },
        },
      });
      document.getElementById("dashboard-container").children[0].width = "100%";
      document.getElementById("dashboard-container").children[0].height =
        "900px";
      document.getElementById("dashboard-container").children[0].frameBorder =
        "0";
      // setTimeout(() => {
      //   var iframe = document.getElementsByTagName("iframe")[0];
      //   var elmnt =
      //     iframe.contentWindow.document.getElementsByClassName("css-z133oh");
      //   elmnt.style.visibility = "hidden";
      // }, 1000);
    });
  };

  const handleChange = (values, viewType) => {
    if (viewType === "graph") {
      setSelectedOption(values);
      embeddingReport(values);
    }
    if (viewType === "table") {
      setSelectedOption2(values);
    }
    if (viewType === "patient_table") {
      setSelectedOption3(values);
    }
  };

  const handleTab = (event, newValue) => {
    if (newValue === "1") {
      setSelectedOption({});
      embeddingReport();
      setViewType("graph");
    }
    if (newValue === "2") {
      setSelectedOption2({});
      setViewType("table");
      if (
        JSON.parse(StorageHelper.get("user"))?.role_id ===
          parseInt(ENV.doctor_role_id) ||
        JSON.parse(StorageHelper.get("user"))?.role_id ===
          parseInt(ENV.medical_lead_role_id) ||
        hospitalId
      ) {
        setSelectedOption3({});
        setViewType("patient_table");
      }
    }
    setValue(newValue);
    if (
      JSON.parse(StorageHelper.get("user"))?.role_id ===
        parseInt(ENV.doctor_role_id) ||
      JSON.parse(StorageHelper.get("user"))?.role_id ===
        parseInt(ENV.medical_lead_role_id)
    ) {
      const hospital = hospitals.filter((x) =>
        assigned_hospitals?.includes(x.value)
      )[0];
      setHospitalId(hospital.value);
      setHospitalName(hospital.label);
    }
    // if(hospitalId){
    //   setSelectedOption3({});
    //   setViewType("patient_table");
    // }
  };

  const handleHospitalChange = (hospital_id, hospital_name) => {
    setHospitalId(hospital_id);
    setHospitalName(hospital_name);
    if (hospital_id) {
      setViewType("patient_table");
    } else {
      setViewType("table");
      setSelectedOption2({});
    }
  };

  const handleFilterSection = (value, type) => {
    if (type === "graph") {
      setGraphFilterOpen(value);
    }
    if (type === "table") {
      setTableFilterOpen(value);
    }
    if (type === "patient_table") {
      setPatientTableFilterOpen(value);
    }
  };

  //
  return (
    <>
      <TabContext value={value}>
        {(JSON.parse(StorageHelper.get("user"))?.role_id ===
          parseInt(ENV.itkan_role_id) ||
          JSON.parse(StorageHelper.get("user"))?.role_id ===
            parseInt(ENV.quality_user_role_id) ||
          JSON.parse(StorageHelper.get("user"))?.role_id ===
            parseInt(ENV.doctor_role_id) ||
          JSON.parse(StorageHelper.get("user"))?.role_id ===
            parseInt(ENV.medical_lead_role_id)) && (
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTab} aria-label="" variant="fullWidth">
              <Tab label="Graph View" value="1" sx={{ fontSize: "12px" }} />
              <Tab label="Table View" value="2" sx={{ fontSize: "12px" }} />
            </TabList>
          </Box>
        )}
        <TabPanel value="1">
          {viewType === "graph" && (
            <>
              <Box paddingBottom={2}>
                <Grid container spacing={3} direction="row">
                  <Grid item xs={6} md={9}>
                    <MgpButton
                      variant="contained"
                      onClick={() => {
                        handleFilterSection(!isGraphFilterOpen, "graph");
                      }}
                    >
                      <FilterAltOutlinedIcon></FilterAltOutlinedIcon> Filter By
                    </MgpButton>
                  </Grid>
                  {parseInt(JSON.parse(StorageHelper.get("user"))?.role_id) ===
                  parseInt(ENV.medical_lead_role_id) ? (
                    <Grid item xs={6} md={3}>
                      <MgpDD
                        label="Select Patients"
                        variant="outlined"
                        size="small"
                        name="section"
                        multiselect={false}
                        fullWidth
                        value={self}
                        options={[
                          {
                            label: "My Patients",
                            value: "1",
                          },
                          {
                            label: "Hospital Patients",
                            value: "2",
                          },
                        ]}
                        onChange={(e) => {
                          setSelf(e.target.value);
                        }}
                        // onBlur={handleBlur}
                        // error={Boolean(touched.section && errors.section)}
                        // helperText={touched.section && errors.section}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Box>
              {isGraphFilterOpen && viewType === "graph" && (
                <Box paddingBottom={2}>
                  <DashboardFilter
                    handleChange={handleChange}
                    setMainPageloading={setMainPageloading}
                    isAnalytics={false}
                    viewType={"graph"}
                  ></DashboardFilter>
                </Box>
              )}
              <div
                id="dashboard-container"
                style={{ width: "100%", height: "100%" }}
              />
            </>
          )}
        </TabPanel>
        <TabPanel value="2">
          <>
            {!hospitalId && viewType === "table" && (
              <>
                <Box paddingBottom={2}>
                  <MgpButton
                    variant="contained"
                    onClick={() => {
                      handleFilterSection(!isTableFilterOpen, "table");
                    }}
                  >
                    <FilterAltOutlinedIcon></FilterAltOutlinedIcon> Filter By
                  </MgpButton>
                </Box>

                {isTableFilterOpen && viewType === "table" && (
                  <Box paddingBottom={2}>
                    <DashboardFilter
                      handleChange={handleChange}
                      setMainPageloading={setMainPageloading}
                      isAnalytics={false}
                      viewType={"table"}
                    ></DashboardFilter>
                  </Box>
                )}
              </>
            )}

            {(JSON.parse(StorageHelper.get("user"))?.role_id ===
              parseInt(ENV.itkan_role_id) ||
              JSON.parse(StorageHelper.get("user"))?.role_id ===
                parseInt(ENV.quality_user_role_id) ||
              JSON.parse(StorageHelper.get("user"))?.role_id ===
                parseInt(ENV.doctor_role_id)) &&
              !hospitalId && (
                <HospitalsDashboard
                  filters={selectedOption2}
                  handleHospitalChange={handleHospitalChange}
                />
              )}
            {hospitalId && viewType === "patient_table" && (
              <>
                <Box paddingBottom={2}>
                  <Grid container spacing={3} direction="row">
                    <Grid item xs={6} md={9}>
                      <MgpButton
                        variant="contained"
                        onClick={() => {
                          handleFilterSection(
                            !isPatientTableFilterOpen,
                            "patient_table"
                          );
                        }}
                      >
                        <FilterAltOutlinedIcon></FilterAltOutlinedIcon> Filter
                        By
                      </MgpButton>
                    </Grid>

                    {parseInt(JSON.parse(StorageHelper.get("user"))?.role_id) ===
                    parseInt(ENV.medical_lead_role_id) ? (
                      <Grid item xs={6} md={3}>
                        <MgpDD
                          label="Select Patients"
                          variant="outlined"
                          size="small"
                          name="section"
                          multiselect={false}
                          fullWidth
                          value={self2}
                          options={[
                            {
                              label: "My Patients",
                              value: "1",
                            },
                            {
                              label: "Hospital Patients",
                              value: "2",
                            },
                          ]}
                          onChange={(e) => {
                            setSelf2(e.target.value);
                          }}
                          // onBlur={handleBlur}
                          // error={Boolean(touched.section && errors.section)}
                          // helperText={touched.section && errors.section}
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Box>
                {isPatientTableFilterOpen && viewType === "patient_table" && (
                  <Box paddingBottom={2}>
                    <DashboardFilter
                      handleChange={handleChange}
                      setMainPageloading={setMainPageloading}
                      isAnalytics={false}
                      viewType={"patient_table"}
                    ></DashboardFilter>
                  </Box>
                )}
                <PatientsDashboard
                  hospitalName={hospitalName}
                  filters={{ ...selectedOption3, hospital_id: hospitalId }}
                  handleHospitalChange={handleHospitalChange}
                  self={self2}
                />
              </>
            )}
          </>
        </TabPanel>
      </TabContext>
    </>
  );
};
