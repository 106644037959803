import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { ProcessGrapqlError, apollo_client } from "../helpers";

export const useHospital = () => {
  const ADD_HOSPITALS = gql`
    mutation AddHospitals(
      $hospitalName: String!
      $hospitalCode: String!
      $address: String!
      $cityId: Int!
    ) {
      AddHospitals(
        hospital_name: $hospitalName
        hospital_code: $hospitalCode
        address: $address
        city_id: $cityId
      ) {
        hospital_id
        hospital_name
        hospital_code
        region_name
        region_id
        site_number
        address
        country_id
        city_id
        is_active
        country_name
        city_name
        created_on
      }
    }
  `;

  const GET_HOSPITALS = gql`
    query GetHospitals {
      GetHospitals {
        hospital_id
        hospital_name
        hospital_code
        region_name
        region_id
        site_number
        address
        city_id
        country_name
        city_name
        is_active
        country_id
        created_on
      }
    }
  `;

  const GET_HOSPITAL_DASHBOARDS = gql`
    query GetHospitalDashboards($filters: FiltersInput) {
      GetHospitalDashboards(filters: $filters) {
        hospital_id
        site_name
        site_code
        city_name
        region_name
        region_id
        city_id
        doctors
        medical_leads
        total_patient_registered
        date_of_first_registration
        date_of_last_registration
        total_crf
        pc_of_draft_crf
        total_draft_crf
        pc_of_ready_to_review_crf
        total_ready_to_review_crf
        pc_of_reviewed_crf
        total_reviewed_crf
        pc_of_returned_to_doctor_crf
        total_returned_to_doctor_crf
        pc_of_re_opened_crf
        total_re_opened_crf
        is_active
        created_on
        updated_on
      }
    }
  `;

  // const GET_HOSPITAL = gql`
  //   query GetHospital($hospitalId: Int!) {
  //     GetHospital(hospital_id: $hospitalId) {
  //       hospital_id
  //       hospital_name
  //       site_number
  //       address
  //       city_id
  //       is_active
  //     }
  //   }
  // `;

  const DELETE_HOSPITAL = gql`
    mutation DeleteHospital($hospitalId: ID!) {
      DeleteHospital(hospital_id: $hospitalId) {
        hospital_id
      }
    }
  `;

  const UPDATE_HOSPITAL = gql`
    mutation UpdateHospitals(
      $hospitalId: Int!
      $hospitalName: String!
      $address: String!
      $hospitalCode: String!
      $cityId: Int!
    ) {
      UpdateHospitals(
        hospital_id: $hospitalId
        hospital_name: $hospitalName
        address: $address
        city_id: $cityId
        hospital_code: $hospitalCode
      ) {
        hospital_id
        hospital_name
        hospital_code
        site_number
        address
        country_id
        country_name
        city_name
        city_id
        is_active
        created_on
      }
    }
  `;
  const useAddHospital = useMutation(ADD_HOSPITALS, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { AddHospitals } }) {
      const existing = cache.readQuery({
        query: GET_HOSPITALS,
        fetchPolicy: "cache-and-network",
      });
      if(existing){

      const newData = [AddHospitals, ...existing.GetHospitals];
      cache.writeQuery({
        query: GET_HOSPITALS,
        data: { GetHospitals: newData },
      });
    }else {
      apollo_client
        .query({
          query: GET_HOSPITALS,
        })
        .then(({ data }) => {
          if (data && data.GetHospitals) {
            const newData = data.GetHospitals.map((item) =>
              item.hospital_id !== AddHospitals.hospital_id ? item : AddHospitals
            );
            cache.writeQuery({
              query: GET_HOSPITALS,
              data: { GetHospitals: newData },
            });
          }
        })
        .catch((error) => {
          console.error("Error while refetching data:", error);
        });
    }
    },
  });
  const useUpdateHospital = useMutation(UPDATE_HOSPITAL, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { UpdateHospitals } }) {
      const existing = cache.readQuery({
        query: GET_HOSPITALS,
        fetchPolicy: "cache-and-network",
      });
      if (existing) {
        const newData = existing.GetHospitals.map((item) =>
          item.hospital_id !== UpdateHospitals.hospital_id
            ? item
            : UpdateHospitals
        );
        cache.writeQuery({
          query: GET_HOSPITALS,
          data: { GetHospitals: newData },
        });
      } else {
        apollo_client
          .query({
            query: GET_HOSPITALS,
          })
          .then(({ data }) => {
            if (data && data.GetHospitals) {
              const newData = data.GetHospitals.map((item) =>
                item.hospital_id !== UpdateHospitals.hospital_id
                  ? item
                  : UpdateHospitals
              );
              cache.writeQuery({
                query: GET_HOSPITALS,
                data: { GetHospitals: newData },
              });
            }
          })
          .catch((error) => {
            console.error("Error while refetching data:", error);
          });
      }
    },
  });
  const useDeleteHospital = useMutation(DELETE_HOSPITAL, {
    onError(error) {
      ProcessGrapqlError(error);
    },
    update(cache, { data: { DeleteHospital } }) {
      const existing = cache.readQuery({
        query: GET_HOSPITALS,
        fetchPolicy: "cache-and-network",
      });
      if(existing){
      const newHospitals = existing.GetHospitals.filter(
        (item) => item.hospital_id !== DeleteHospital.hospital_id
      );
      cache.writeQuery({
        query: GET_HOSPITALS,
        data: { GetHospitals: newHospitals },
      });
    } else {
      apollo_client
        .query({
          query: GET_HOSPITALS,
        })
        .then(({ data }) => {
          if (data && data.GetHospitals) {
            const newData = data.GetHospitals.map((item) =>
              item.hospital_id !== DeleteHospital.hospital_id ? item : DeleteHospital
            );
            cache.writeQuery({
              query: GET_HOSPITALS,
              data: { GetHospitals: newData },
            });
          }
        })
        .catch((error) => {
          console.error("Error while refetching data:", error);
        });
    }
    },
  });
  const useGetHospitals = useLazyQuery(GET_HOSPITALS);
  const useGetHospitalDashboards = useLazyQuery(GET_HOSPITAL_DASHBOARDS);

  return {
    useAddHospital,
    useGetHospitals,
    useUpdateHospital,
    useDeleteHospital,
    useGetHospitalDashboards,
  };
};
