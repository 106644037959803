import { Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";

export const MgpCheckbox = styled(Checkbox)(({ theme }) => ({
  width:"25px",
  marginLeft:"5px"
  // color: colors.SECONDARY_TEXT,
  // backgroundColor: colors.SECONDARY,
  // "&:hover": {
  //   backgroundColor: colors.SECONDARY_LIGHT,
  // },
  //   textTransform: "uppercase",
}));